import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from '../style.module.scss';


const CotacaoSemInteresse = ({
	motivos_recusa,
}) => (
	<div className="row">
		<div className="col-xs-12 col-md-3" styleName="content-block margin-top">
			<i className="fa fa-check-circle" aria-hidden="true" />
			<p styleName="labels">Status da cotação</p>
			<span styleName="price-color"><b>Não pude participar</b></span>
		</div>
		<div className="col-xs-12 col-md-3" styleName="content-block margin-top">
			<i className="fa fa-ban" aria-hidden="true" />
			<p styleName="labels">Motivos de recusa</p>
			{motivos_recusa.join(', ')}
		</div>
	</div>
);

CotacaoSemInteresse.propTypes = {
	motivos_recusa: PropTypes.array.isRequired,
};

export default CSSModules(CotacaoSemInteresse, styles, { allowMultiple: true });
