import update from 'immutability-helper';
import ui from '../store/ui';
import { UPDATE_UI, UPDATE_MODALS } from '../actions/action.types';
import { lockBody } from '../components/_functions/_lockBody';

const reducer = (state = ui, action) => {
	switch (action.type) {
	case UPDATE_UI:
		switch (action.field.length) {
		case 1:
			return update(state, {
				[action.field[0]]: { $set: action.value },
			});
		case 2:
			return update(state, {
				[action.field[0]]: {
					[action.field[1]]: { $set: action.value },
				},
			});
		default:
			return state;
		}

	case UPDATE_MODALS:
		lockBody(action.value);
		return update(state, { modals: { $set: { ...state.modals, [action.modal]: action.value } } });

	default:
		return state;
	}
};

export default reducer;
