import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/index';

// Components
import NotFound404 from './NotFound404';

class NotFound404Container extends Component {
	UNSAFE_componentWillMount() {
		this.props.history.push('/solicitacoes');
	}

	render() {
		return <NotFound404 {...this.props} />;
	}
}

NotFound404Container.propTypes = {
	history: PropTypes.object.isRequired,
};

const mapStateToProps = props => ({
	ui: props.ui,
});

const mapDispatchToProps = dispatch => ({
	updateUi: (field, value) => dispatch(actions.updateUi(field, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotFound404Container);
