
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../../actions/index';

// Components
import Solicitacoes from './Solicitacoes';
import Wrapper from '../../Wrapper/Wrapper';

// Functions
import {
	create as createRC,
	getRCsAtivos,
	getRCsExpirados,
	getRCsRecusados,
	getRCsCancelados,
	getRCSEmRefinamento,
	getList as getRCs,
} from '../../../services/apis/rcs';
import { adicionarNoChecklist } from '../../../services/apis/acoes_rcs';

import { selecionarPedidosParaFornecedor } from '../../../services/apis/fornecedores';

class SolicitacoesContainer extends Component {
	constructor() {
		super();
		this.state = {
			request_state: 2,
			request_state_expiradas: 0,
			request_state_recusadas: 0,
			request_state_canceladas: 0,
			request_state_em_refinamento: 0,
			list: [],
			list_expiradas: [],
			list_em_refinamento: [],
			list_recusadas: [],
			list_canceladas: [],
			list_outras_opps: [],
			section_selecionadas: true,
			section_expiradas: false,
			section_em_refinamento: true,
			section_recusadas: false,
			section_canceladas: false,
			section_outras_opps: false,
			modal_rc_duplicado: false,
		};
		this.openSection = this.openSection.bind(this);
		this.retornaSolicitacoes = this.retornaSolicitacoes.bind(this);
		this.retornaSolicitacoesEmRefinamento = this.retornaSolicitacoesEmRefinamento.bind(this);
		this.criarRC = this.criarRC.bind(this);
		this.handleModalRCDuplicado = this.handleModalRCDuplicado.bind(this);
	}

	componentDidMount() {
		document.title = 'Cotações abertas - Conaz';
		document.body.style.background = '#f6f6f6';
		window.scrollTo(0, 0);
		this.retornaSolicitacoesEmRefinamento();
	}

	componentWillUnmount() {
		document.body.style.background = '';
		this.props.updateUi(['alert_cotacao_enviada'], false);
	}

	handleModalRCDuplicado() {
		const modal_rc_duplicado = !this.state.modal_rc_duplicado;
		this.setState({ modal_rc_duplicado });
	}

	retornaSolicitacoes(responses) {
		const list = (responses[0] || [])
			.filter(rc => rc.tipo_selecao_fornecedor !== 4);
		const _list_outras_opps = responses[1]
			.concat((responses[0] || []).filter(rc => rc.tipo_selecao_fornecedor === 4));
		const list_outras_opps = _list_outras_opps
			.sort((a, b) => new Date(a.prazo_de_resposta) - new Date(b.prazo_de_resposta));
		this.setState({
			list,
			list_outras_opps,
		});
	}

	retornaSolicitacoesEmRefinamento() {
		this.setState({ request_state_em_refinamento: 1 });
		getRCSEmRefinamento()
			.then(response => this.setState({ list_em_refinamento: response, request_state_em_refinamento: 2 }))
			.catch(err => {
				console.log(err); // eslint-disable-line
				this.setState({ request_state_em_refinamento: 3 });
			});
	}

	retornaSolicitacoesExpiradas() {
		this.setState({ request_state_expiradas: 1 });
		getRCsExpirados()
			.then(response => {
				this.setState({ request_state_expiradas: 2, list_expiradas: response });
			})
			.catch(error => {
				console.log(error); // eslint-disable-line
				this.setState({ request_state_expiradas: 3 });
			});
	}

	retornaSolicitacoesRecusadas() {
		this.setState({ request_state_recusadas: 1 });
		getRCsRecusados()
			.then(response => {
				this.setState({ request_state_recusadas: 2, list_recusadas: response });
			})
			.catch(error => {
				console.log(error); // eslint-disable-line
				this.setState({ request_state_recusadas: 3 });
			});
	}

	retornaSolicitacoesCanceladas() {
		this.setState({ request_state_canceladas: 1 });
		getRCsCancelados()
			.then(response => {
				this.setState({ request_state_canceladas: 2, list_canceladas: response });
			})
			.catch(error => {
				console.log(error); // eslint-disable-line
				this.setState({ request_state_canceladas: 3 });
			});
	}

	openSection(section) {
		if (section === 'expiradas') {
			this.setState({ section_expiradas: !this.state.section_expiradas });
			if (this.state.list_expiradas.length === 0) {
				this.retornaSolicitacoesExpiradas();
			}
		} else if (section === 'Correções solicitadas') {
			this.setState({ section_em_refinamento: !this.state.section_em_refinamento });
			if (this.state.list_em_refinamento.length === 0) {
				this.retornaSolicitacoesEmRefinamento();
			}
		} else if (section === 'recusadas') {
			this.setState({ section_recusadas: !this.state.section_recusadas });
			if (this.state.list_recusadas.length === 0) {
				this.retornaSolicitacoesRecusadas();
			}
		} else if (section === 'canceladas') {
			this.setState({ section_canceladas: !this.state.section_canceladas });
			if (this.state.list_canceladas.length === 0) {
				this.retornaSolicitacoesCanceladas();
			}
		} else if (section === 'selecionadas') {
			this.setState({ section_selecionadas: !this.state.section_selecionadas });
			if (this.state.list.length === 0) {
				this.retornaSolicitacoes();
			}
		} else if (section === 'outras oportunidades') {
			this.setState({ section_outras_opps: !this.state.section_outras_opps });
		}
	}

	criarRC(pedido_id) {
		const { id, id_vendedor } = this.props.cadastro;

		const data = {
			fornecedor_id: id,
			pedido_id,
			preenchimento_fornecedor: true,
			tipo_selecao_fornecedor: 4,
			usuario_id: id_vendedor,
		};
		const newTab = window.open('about:blank', '_blank');
		createRC(data)
			.then(response => {
				const { uuid } = response.data;
				adicionarNoChecklist(uuid)
					.then(() => {
						newTab.location = `${window.location.origin}/cotacao/${uuid}`;
					});
			})
			.catch(error => {
				const message_conaz = ((error.response || {}).data || {}).message || '';
				if (message_conaz === 'Ja existe RC para o fornecedor informado' || message_conaz === 'Ja existe RC para o vendedor informado') {
					getRCs({ fields: 'uuid', status_ne: -30, 'usuario.id': id_vendedor, 'pedido.id': pedido_id })
						.then(response => {
							if (response.data.length > 0) {
								newTab.location = `${window.location.origin}/cotacao/${response.data[0].uuid}`;
							} else {
								this.setState({ modal_rc_duplicado: true });
								newTab.close();
							}
						});
				} else {
					newTab.close();
				}
			}).then(() => {
				this.setState({ list_outras_opps: this.state.list_outras_opps.filter(opp => opp.codigo_pedido !== pedido_id) });
			});
	}

	render() {
		return (
			<Wrapper
				loaded={false}
				promises={[() => getRCsAtivos(), () => selecionarPedidosParaFornecedor()]}
				buildResponse={this.retornaSolicitacoes}
				checa_fornecedor_vendedor
				{...this.props}>
				<Solicitacoes
					{...this.state}
					{...this.props}
					openSection={this.openSection}
					criarRC={this.criarRC}
					handleModalRCDuplicado={this.handleModalRCDuplicado} />
			</Wrapper>
		);
	}
}

const mapStateToProps = props => ({
	cadastro: props.cadastro,
	ui: props.ui,
});

const mapDispatchToProps = dispatch => ({
	resetCadastro: () => dispatch(actions.resetCadastro()),
	updateCadastro: (field, value) => dispatch(actions.updateCadastro(field, value)),
	updateUi: (field, value) => dispatch(actions.updateUi(field, value)),
	updateModals: (modal, value) => dispatch(actions.updateModals(modal, value)),
	reportErrors: (page, error, codigo_pedido, full_error, test) => dispatch(actions.reportErrors(page, error, codigo_pedido, full_error, test)),
});

SolicitacoesContainer.propTypes = {
	cadastro: PropTypes.object.isRequired,
	updateUi: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SolicitacoesContainer);
