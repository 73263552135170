import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const BasicModal = ({ updateModals, modalName, title, message, textoConfirmar, textoCancelar, onClickConfirmar, onClickCancelar, showConfirmarButton, showCancelarButton }) => (
	<div className="modal-content" styleName="modal-content">
		<div className="modal-body" styleName="modal-body">
			<button
				type="button"
				className="close"
				styleName="close-button"
				data-dismiss="modal"
				aria-label="Close"
				onClick={() => updateModals(modalName, false)}>
				<span aria-hidden="true">&times;</span>
			</button>
			<h3>{title}</h3>
			{typeof message === 'string' ? <p>{message}</p> : message}
			<div styleName="buttons">
				{
					showConfirmarButton && (
						<button
							type="button"
							className="btn btn-primary btn-lg"
							style={{ marginRight: '15px' }}
							onClick={() => { onClickConfirmar(); updateModals(modalName, false); }}>
							{textoConfirmar}
						</button>
					)
				}
				{
					showCancelarButton && (
						<button
							type="button"
							className="btn btn-default btn-lg"
							onClick={() => { onClickCancelar(); updateModals(modalName, false); }}>
							{textoCancelar}
						</button>
					)
				}
			</div>
		</div>
	</div>
);

BasicModal.propTypes = {
	updateModals: PropTypes.func,
	modalName: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
	textoConfirmar: PropTypes.string,
	textoCancelar: PropTypes.string,
	onClickConfirmar: PropTypes.func,
	onClickCancelar: PropTypes.func,
	showConfirmarButton: PropTypes.bool,
	showCancelarButton: PropTypes.bool,
};

BasicModal.defaultProps = {
	updateModals: () => {},
	textoConfirmar: 'OK',
	textoCancelar: 'Cancelar',
	onClickConfirmar: () => {},
	onClickCancelar: () => {},
	showConfirmarButton: false,
	showCancelarButton: false,
};

export default CSSModules(BasicModal, styles, { allowMultiple: true });
