import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const LabelCotacaoExpirada = ({	data_fechamento_compra, prazo_de_resposta }) => {
	const data_expiracao = moment(data_fechamento_compra).isBefore(prazo_de_resposta, 'day') ? data_fechamento_compra : prazo_de_resposta;
	const data_expiracao_view = data_expiracao ? moment(data_expiracao).format('DD/MM') : '';

	return (
		<div styleName="labels">
			<div styleName="label grey">{data_expiracao_view ? `Expirou em ${data_expiracao_view}` : 'Expirada' }</div>
		</div>
	);
};

LabelCotacaoExpirada.propTypes = {
	prazo_de_resposta: PropTypes.string,
	data_fechamento_compra: PropTypes.string,
};

LabelCotacaoExpirada.defaultProps = {
	prazo_de_resposta: '',
	data_fechamento_compra: '',
};

export default CSSModules(LabelCotacaoExpirada, styles, { allowMultiple: true });
