import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Functions
import { validaItens } from '../../_functions/_validaItens';
import { validaInfo } from '../../_functions/_validaInfo';
import { validaEmpresa } from '../../_functions/_validaEmpresa';

const withValidationSteps = (Comp) => (
	class PP extends Component {
		static propTypes = {
			cotacao: PropTypes.object.isRequired,
			updateModals: PropTypes.func,
		}

		static defaultProps = {
			updateModals: () => {},
		}

		validadeItens = () => {
			const { cotacao, updateModals } = this.props;
			const validacao_itens = validaItens(cotacao, 'itens');
			if (validacao_itens === 0) {
				return false;
			}
			if (validacao_itens === -1) {
				updateModals('sem_interesse', true);
				return false;
			}
			return true;
		}

		validadeInfo = () => {
			const { cotacao } = this.props;
			const validacao_info = validaInfo(cotacao.rc);
			if (validacao_info !== 1) {
				return false;
			}
			return true;
		}

		validadeEmpresa = () => {
			const { cotacao } = this.props;
			const validacao_empresa = validaEmpresa(cotacao.fornecedor_selecionado);
			if (validacao_empresa !== 1) {
				return false;
			}
			return true;
		}

		validateSteps = (next_page_key) => {
			const validade_itens = this.validadeItens();
			const validade_info = this.validadeInfo();
			const validade_empresa = this.validadeEmpresa();

			switch (next_page_key) {
			case 1: // dadosfinais
				return validade_itens;

			case 2: // empresa
				if (!validade_itens || !validade_info) {
					return false;
				}
				return true;

			case 3: // envio
			case 4: // envio
				if (!validade_itens || !validade_info || !validade_empresa) {
					return false;
				}
				return true;

			default:
				return true;
			}
		}

		// validateSteps = (next_page_key) => {
		// 	let validated = true;

		// 	switch (next_page_key) {
		// 	case 1: // dadosfinais
		// 		validated = this.validadeItens();
		// 		break;

		// 	case 2: // empresa
		// 		validated = this.validadeItens();
		// 		if (!validated) return validated;
		// 		validated = this.validadeInfo();
		// 		break;

		// 	case 3: // envio
		// 	case 4: // envio
		// 		validated = this.validadeEmpresa();
		// 		if (!validated) return validated;
		// 		validated = this.validadeItens();
		// 		if (!validated) return validated;
		// 		validated = this.validadeInfo();
		// 		break;

		// 	default:
		// 	}

		// 	return validated;
		// }

		render() {
			return (
				<Comp
					{...this.state}
					{...this.props}
					validateSteps={this.validateSteps} />
			);
		}
	}
);

export default withValidationSteps;
