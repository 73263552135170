import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import LoadingButton from '../../_parts/_loadings/LoadingButton/LoadingButton';
import ModalWrapper from '../../_parts/_modals/ModalWrapper/ModalWrapper';
import ModalSemInteresse from '../../_parts/_modals/ModalSemInteresse/ModalSemInteresse';
import ModalUpload from '../../_parts/_modals/ModalUpload/ModalUpload';
import ModalObservacoesNormal from '../../_parts/_modals/ModalObservacoesNormal/ModalObservacoesNormal';
import ModalGeral from '../../_parts/_modals/ModalGeral/ModalGeral';
import ModalConfirmReset from '../../_parts/_modals/ModalConfirmReset/ModalConfirmReset';
import ModalAvisoPrazoCotacao from '../../_parts/_modals/ModalAvisoPrazoCotacao/ModalAvisoPrazoCotacao';
import HeaderCotacao from '../../_parts/HeaderCotacao/HeaderCotacao';
import InfoClienteCotacao from '../../_parts/InfoClienteCotacao/InfoClienteCotacao';
import ItemRow from '../../_parts/ItemRow/ItemRow';
import LeftModalWrapper from '../../_parts/_modals/LeftModalWrapper/LeftModalWrapper';
import LeftModalConstrutorPerfil from '../../_parts/_modals/LeftModalConstrutorPerfil/LeftModalConstrutorPerfil';

const Preview = ({
	/* state */ criando_itens, observacoes_vista, tab_perfil_construtor,
	/* props */ ui, cotacao, history,
	/* pfunc */ updateModals, updateCotacao,
	/* lfunc */ closeModalObservacoes, criaItensResposta, openNaoTenhoInteresse, openPerfilConstrutor,
}) => {
	const { modals } = ui;
	const { rc } = cotacao;
	const cotacao_button_text = rc.status >= 20 ? 'Continuar cotação' : 'Quero participar';
	if (rc.status === -30 || rc.pedido.cancelado) {
		return (
			<div className="container" style={{ marginTop: '36px' }}>
				<div className="row">
					<div className="col-sm-12 error-wrapper">
						<i className="fa fa-exclamation-circle" aria-hidden="true" />
						<h3>Pedido cancelado!</h3>
						<p>Este pedido foi cancelado pelo construtor e não está mais disponível.</p>
					</div>
				</div>
			</div>
		);
	}

	if (rc.pedido.status === 10 || rc.pedido.status === 15) {
		return (
			<div className="container" style={{ marginTop: '36px' }}>
				<div className="row">
					<div className="col-sm-12 error-wrapper">
						<i className="fa fa-pencil" aria-hidden="true" />
						<h3>Pedido em edição!</h3>
						<p style={{ fontSize: '16px' }}>Este pedido está sendo editado pelo construtor neste momento.<br />Você será notificado por e-mail assim que ele concluir.</p>
					</div>
				</div>
			</div>
		);
	}

	return (
		<React.Fragment>
			<ModalWrapper visible={modals.sem_interesse} updateModals={updateModals}>
				<ModalSemInteresse
					rc_id={rc.uuid}
					history={history} />
			</ModalWrapper>
			<ModalWrapper visible={modals.anexos_cliente} updateModals={updateModals}>
				<ModalUpload
					rc_id={rc.uuid}
					anexos={rc.anexos_pedido}
					updateCotacao={updateCotacao}
					modal_to_close="anexos_cliente"
					edit={false} />
			</ModalWrapper>
			<ModalWrapper visible={modals.geral} updateModals={updateModals}>
				<ModalGeral
					updateCotacao={updateCotacao}
					icon="handshake-o"
					title="Cotação finalizada!"
					message="O construtor já realizou a compra desta cotação com outro fornecedor." />
			</ModalWrapper>
			<ModalWrapper visible={modals.reset_confirm} updateModals={updateModals}>
				<ModalConfirmReset />
			</ModalWrapper>
			<ModalWrapper visible={modals.aviso_prazo_cotacao} updateModals={updateModals}>
				<ModalAvisoPrazoCotacao />
			</ModalWrapper>
			<LeftModalWrapper visible={modals.perfil_construtor} updateModals={updateModals} modal="perfil_construtor">
				<LeftModalConstrutorPerfil
					exibir_contatos_pedido={rc.pedido.exibir_contato}
					usuario_pedido={rc.pedido.aprovacao_pedida_por.id !== undefined ? rc.pedido.aprovacao_pedida_por : rc.pedido.usuario}
					obra={rc.pedido.obra}
					tab={tab_perfil_construtor} />
			</LeftModalWrapper>

			<ModalWrapper visible={!observacoes_vista && (rc.pedido.observacoes_conaz !== '' || rc.pedido.observacoes !== '')} updateModals={updateModals}>
				<ModalObservacoesNormal
					closeModalObservacoes={closeModalObservacoes}
					observacoes={rc.pedido.observacoes_conaz || rc.pedido.observacoes}
				/>
			</ModalWrapper>

			<HeaderCotacao
				pedido_id={rc.pedido.id}
				isPreview
				data_envio={rc.data_envio}
				prazo_de_resposta={rc.pedido.prazo_de_resposta}
				data_de_entrega_desejada={rc.pedido.data_de_entrega_desejada} />

			<InfoClienteCotacao
				obra={rc.pedido.obra}
				data_de_entrega_desejada={rc.pedido.data_de_entrega_desejada}
				observacoes={rc.pedido.observacoes_conaz || rc.pedido.observacoes}
				anexos={rc.anexos_pedido}
				updateModals={updateModals}
				openPerfilConstrutor={openPerfilConstrutor} />

			<div className="container">
				{rc.pedido.itens_de_pedido.map((item, i) => (
					<ItemRow
						key={item.id}
						number={i + 1}
						item={item} />
				))}
				<div className="row">
					<div className="col-xs-12" style={{ textAlign: 'center', paddingTop: '30px', paddingBottom: '120px' }}>
						<div styleName="cotacao-footer-action-buttons">
							<button
								type="button"
								className="btn btn-default btn-lg"
								styleName="nao-posso-participar-button"
								onClick={openNaoTenhoInteresse}>
								<i className="fa fa-times" aria-hidden="true" />
								<span>Não posso participar</span>
							</button>
							{!criando_itens ? (
								<button
									type="button"
									className="btn btn-primary btn-lg older-button"
									onClick={criaItensResposta}>
									{cotacao_button_text}
								</button>
							) : (
								<button
									type="button"
									className="btn btn-primary btn-lg older-button"
									style={{ color: 'transparent' }}>
									<LoadingButton />{cotacao_button_text}
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

Preview.propTypes = {
	// =========== state
	criando_itens: PropTypes.bool.isRequired,
	observacoes_vista: PropTypes.bool.isRequired,
	tab_perfil_construtor: PropTypes.number.isRequired,
	// =========== local funcs
	closeModalObservacoes: PropTypes.func.isRequired,
	criaItensResposta: PropTypes.func.isRequired,
	openNaoTenhoInteresse: PropTypes.func.isRequired,
	openPerfilConstrutor: PropTypes.func.isRequired,
	// =========== store
	cotacao: PropTypes.object.isRequired,
	ui: PropTypes.object.isRequired,
	// =========== funcs
	updateModals: PropTypes.func.isRequired,
	updateCotacao: PropTypes.func.isRequired,
	// =========== router
	history: PropTypes.object.isRequired,
};

export default CSSModules(Preview, styles, { allowMultiple: true });
