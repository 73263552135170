import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import InputTextArea from '../_inputs/InputTextArea/InputTextArea';

function ItemRowObs({
	item,
	updateItem,
	autoSaveTimeout,
	itens_observacoes,
	updateCotacao,
}) {
	const {
		id,
		informacoes_adicionais,
	} = item;

	function addObservacoes() {
		const _itens_observacoes = [...itens_observacoes, id];
		updateCotacao(['itens_observacoes'], _itens_observacoes);
	}

	const has_obs = itens_observacoes
		.filter(i => i === id)
		.length > 0;

	if (has_obs) {
		return (
			<div styleName="observacao-wrapper field">
				<label>Observação</label>
				<InputTextArea
					type="item"
					item_id={id}
					field="informacoes_adicionais"
					default_value={informacoes_adicionais}
					updateFunction={updateItem}
					autoSaveTimeout={autoSaveTimeout} />
			</div>
		);
	}

	return (
		<div styleName="observacao-wrapper">
			<button styleName="add-observacao-btn" onClick={addObservacoes}>
				<i className="fa fa-plus fa-fw" /> Adicionar observação
			</button>
		</div>
	);
}

ItemRowObs.propTypes = {
	item: PropTypes.object.isRequired,
	updateItem: PropTypes.func.isRequired,
	autoSaveTimeout: PropTypes.func.isRequired,
	itens_observacoes: PropTypes.array.isRequired,
	updateCotacao: PropTypes.func.isRequired,
};

export default CSSModules(ItemRowObs, styles, { allowMultiple: true });
