import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import ModalWrapper from '../../_parts/_modals/ModalWrapper/ModalWrapper';
import ModalSemInteresse from '../../_parts/_modals/ModalSemInteresse/ModalSemInteresse';
import ModalGeral from '../../_parts/_modals/ModalGeral/ModalGeral';
import ModalUpload from '../../_parts/_modals/ModalUpload/ModalUpload';
import HeaderCotacao from '../../_parts/HeaderCotacao/HeaderCotacao';
import InfoClienteCotacao from '../../_parts/InfoClienteCotacao/InfoClienteCotacao';
import ItemRowOpenV2 from '../../_parts/ItemRowOpenV2';
import InputNumber from '../../_parts/_inputs/InputNumber/InputNumber';
import LeftModalWrapper from '../../_parts/_modals/LeftModalWrapper/LeftModalWrapper';
import LeftModalConstrutorPerfil from '../../_parts/_modals/LeftModalConstrutorPerfil/LeftModalConstrutorPerfil';
import Stepbar from '../../_parts/Stepbar';
import StepbarMobile from '../../_parts/Stepbar/StepbarMobile';
import EnviarRCmobile from '../../_parts/Stepbar/EnviarRCmobile';

// Functions
import { formatCurrency } from '../../_functions/_formatNumber';

const Itens = ({
	/* state */
	tab_perfil_construtor,
	new_saving,
	/* props */
	ui,
	cotacao,
	history,
	width,
	/* propf */
	updateItem,
	updateItemCaracteristica,
	updateItemCaracteristicaUnidade,
	updateCotacao,
	updateModals,
	/* funcs */
	autoSaveTimeout,
	autoSaveRcTimeout,
	openPerfilConstrutor,
	forceAutoSave,
}) => {
	const { modals } = ui;
	const { rc, itens_all_ids, itens_by_id, itens_observacoes } = cotacao;
	const valor_final_itens = itens_all_ids.reduce((r, c) => {
		const item = itens_by_id[c];
		const quantidade = item.quantidade || 0;
		const preco_unitario = item.preco_unitario || 0;
		// sub_total
		const sub_total = quantidade * preco_unitario;
		// desconto
		const desconto = item.desconto || 0;
		const valor_desconto = (sub_total / 100) * desconto;
		// total
		const total = sub_total - valor_desconto || 0;
		return r + total;
	}, 0);
	const valor_final = valor_final_itens + rc.custos_adicionais + rc.valor_transporte;
	const is_mobile = width <= 1020 || width <= 768;

	return (
		<>
			<ModalWrapper visible={modals.geral} updateModals={updateModals}>
				<ModalGeral
					icon="exclamation-circle"
					title="Preço dos Itens Inválido"
					message="Você preencheu “Preço Incluso no Total” para todos os seus itens, ou seja, você está sem um preço total. É necessário preencher os valores da sua cotação. Qualquer dúvida entrar em contato com suporte!"
				/>
			</ModalWrapper>
			<ModalWrapper visible={modals.sem_interesse} updateModals={updateModals}>
				<ModalSemInteresse rc_id={rc.uuid} history={history} nao_tem_itens />
			</ModalWrapper>
			<ModalWrapper visible={modals.anexos} updateModals={updateModals}>
				<ModalUpload rc_id={rc.uuid} anexos={rc.anexos} updateCotacao={updateCotacao} />
			</ModalWrapper>
			<ModalWrapper visible={modals.anexos_cliente} updateModals={updateModals}>
				<ModalUpload
					rc_id={rc.uuid}
					anexos={rc.anexos_pedido}
					updateCotacao={updateCotacao}
					modal_to_close="anexos_cliente"
					edit={false}
				/>
			</ModalWrapper>
			<LeftModalWrapper
				visible={modals.perfil_construtor}
				updateModals={updateModals}
				modal="perfil_construtor"
			>
				<LeftModalConstrutorPerfil
					exibir_contatos_pedido={rc.pedido.exibir_contato}
					usuario_pedido={
						rc.pedido.aprovacao_pedida_por.id !== undefined
							? rc.pedido.aprovacao_pedida_por
							: rc.pedido.usuario
					}
					obra={rc.pedido.obra}
					tab={tab_perfil_construtor}
				/>
			</LeftModalWrapper>
			{is_mobile && (
				<StepbarMobile
					cotacao={cotacao}
					history={history}
					title="1. Preenchimento dos itens"
					new_saving={new_saving}
					updateModals={updateModals}
					modals={modals}
				/>
			)}
			<HeaderCotacao
				pedido_id={rc.pedido.id}
				data_envio={rc.data_envio}
				prazo_de_resposta={rc.pedido.prazo_de_resposta}
				data_de_entrega_desejada={rc.pedido.data_de_entrega_desejada}
			/>
			<InfoClienteCotacao
				obra={rc.pedido.obra}
				data_de_entrega_desejada={rc.pedido.data_de_entrega_desejada}
				observacoes={rc.pedido.observacoes_conaz}
				anexos={rc.anexos_pedido}
				updateModals={updateModals}
				openPerfilConstrutor={openPerfilConstrutor}
			/>

			<div className="container">
				{!is_mobile && (
					<Stepbar
						cotacao={cotacao}
						history={history}
						new_saving={new_saving}
						updateModals={updateModals}
						forceAutoSave={forceAutoSave}
					/>
				)}
				<div className="row">
					<div className="col-xs-12">
						<button
							type="button"
							styleName="anexos-button"
							onClick={() => updateModals('anexos', true)}
						>
							<i className="fa fa-paperclip fa-rotate-90" /> <p>Anexos</p>{' '}
							<span>{rc.anexos.length}</span>
						</button>
					</div>
				</div>
				{itens_all_ids.map((id, i) => (
					<ItemRowOpenV2
						key={id}
						number={i + 1}
						item={itens_by_id[id]}
						updateItem={updateItem}
						updateItemCaracteristica={updateItemCaracteristica}
						updateItemCaracteristicaUnidade={updateItemCaracteristicaUnidade}
						autoSaveTimeout={autoSaveTimeout}
						itens_observacoes={itens_observacoes}
						updateCotacao={updateCotacao}
					/>
				))}

				<div className="row">
					<div className="col-xs-12">
						<p><br /></p>
					</div>
				</div>

				<div className="row">
					<div className="col-xs-12">
						<div styleName="item-row-top">
							<div styleName="nome">Valor da cotação</div>
						</div>
						<div styleName="item-row-bottom">
							<div className="row">
								<div className="col-xs-12">
									Opcões de frete e observações serão solicitadas na finalização, ao clicar em
									Continuar.
								</div>
							</div>
							<div className="row">
								<div className="col-xs-12 col-lg-4">
									<label style={{ paddingTop: '20px' }}>Impostos / Adicionais (opcional)</label>
									<InputNumber
										type="cotacao"
										field={['rc', 'custos_adicionais']}
										default_value={rc.custos_adicionais}
										autoSaveTimeout={autoSaveRcTimeout}
										updateFunction={updateCotacao}
										currency
									/>
								</div>
								{!is_mobile && (
									<div className="col-xs-12 col-lg-8" styleName="valor-final-wrapper">
										<label>Valor final</label>
										<div styleName="valor-final">
											<b>{formatCurrency(valor_final, 'R$')}</b>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-xs-12">
						<p><br /></p><p><br /></p><p><br /></p><p><br /></p><p><br /></p><p><br /></p>
					</div>
				</div>
			</div>
			{is_mobile && (
				<EnviarRCmobile
					cotacao={cotacao}
					history={history}
					forceAutoSave={forceAutoSave}
				/>
			)}
		</>
	);
};

Itens.propTypes = {
	// =========== local
	tab_perfil_construtor: PropTypes.number.isRequired,
	new_saving: PropTypes.number.isRequired,
	// =========== local funcs
	autoSaveTimeout: PropTypes.func.isRequired,
	autoSaveRcTimeout: PropTypes.func.isRequired,
	forceAutoSave: PropTypes.func.isRequired,
	// =========== store
	cotacao: PropTypes.object.isRequired,
	ui: PropTypes.object.isRequired,
	width: PropTypes.number.isRequired,
	// =========== funcs
	updateModals: PropTypes.func.isRequired,
	updateCotacao: PropTypes.func.isRequired,
	updateItem: PropTypes.func.isRequired,
	updateItemCaracteristica: PropTypes.func.isRequired,
	updateItemCaracteristicaUnidade: PropTypes.func.isRequired,
	openPerfilConstrutor: PropTypes.func.isRequired,
	// =========== router
	history: PropTypes.object.isRequired,
};

export default CSSModules(Itens, styles, { allowMultiple: true });
