import React from 'react';
import PropTypes from 'prop-types';
import hash from 'object-hash';
import _ from 'lodash';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';
import './paginate.css';

// Components
import EmptyNoteSVG from '../../_parts/EmptyNoteSVG/EmptyNoteSVG';
import EnviadasTitleRow from './EnviadasTitleRow';
import EnviadasRow from './EnviadasRow';
import AbaStatus from './AbaStatus';
import Pagination from './Paginacao';
import LoadingPage from '../../_parts/_loadings/LoadingPage/LoadingPage';

function Enviadas({
	// =========== state
	loading,
	notification,
	// avaliacaoDropdown,
	// =========== local funcs
	hideAlert,
	requestByTab,
	setLoading,
	handleAvaliacao,
	// filterRCs,
	// =========== props
	updateUi,
	ui,
	// =========== props funcs
}) {
	const { tabs, tab_number_selected } = ui;
	const _tabs = _.orderBy(_.values(tabs), 'number');
	const tab_selected = _tabs.filter(tab => tab.number === tab_number_selected)[0];
	const current_tab = tabs[tab_selected.index];

	return (
		<div>
			{notification && (
				<div styleName="alert-pedido-enviado">
					Cotação enviada com sucesso!
					<button
						type="button"
						className="close"
						data-dismiss="alert"
						aria-label="Close"
						onClick={hideAlert}
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
			)}

			<div className="container" styleName="main-container">
				<div className="row">
					<div className="col-xs-12" styleName="pedidos-nav-wrapper">
						<ul styleName="pedidos-nav">
							{_tabs.map(({ number, label, rcs_length, index, called }) => (
								<AbaStatus
									key={number}
									tab_number={number}
									active={tab_number_selected === number}
									status_name={label}
									updateUi={updateUi}
									fetchRcs={() => requestByTab(number, index, called)}
									rcs_length={rcs_length}
									loading={loading[index]}
								/>
							))}
						</ul>
					</div>
				</div>
				{loading[tab_selected.index] ? (
					<LoadingPage />
				) : (
					<>
						{current_tab.current_page_rcs.length > 0 ? (
							<div>
								<EnviadasTitleRow />
								<div className="row">
									<div className="col-xs-12">
										{current_tab.current_page_rcs
											.map((enviada, i) => (
												<EnviadasRow
													key={hash(i)}
													tabindex={tab_selected.index}
													// avaliacaoDropdown={avaliacaoDropdown}
													handleAvaliacao={handleAvaliacao}
													{...enviada} />
											))}
									</div>
								</div>
								{current_tab.pagination_count > 1 && (
									<Pagination
										{...{
											tabs,
											tab_selected,
											updateUi,
											loading,
											setLoading,
											current_tab,
										}}
									/>
								)}
							</div>
						) : (
							<div className="row">
								<div className="col-xs-12" styleName="empty-all">
									<EmptyNoteSVG />
									<h3>Você não possui propostas nesta lista</h3>
								</div>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
}

Enviadas.propTypes = {
	// =========== state
	loading: PropTypes.object.isRequired,
	notification: PropTypes.bool.isRequired,
	// avaliacaoDropdown: PropTypes.bool.isRequired,
	// =========== local funcs
	hideAlert: PropTypes.func.isRequired,
	requestByTab: PropTypes.func.isRequired,
	handleAvaliacao: PropTypes.func.isRequired,
	setLoading: PropTypes.object.isRequired,
	// filterRCs: PropTypes.func.isRequired,
	// =========== props
	ui: PropTypes.object.isRequired,
	// =========== props funcs
	updateUi: PropTypes.func.isRequired,
};

export default CSSModules(Enviadas, styles, { allowMultiple: true });
