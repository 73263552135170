import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
// import { handleRequestErrors } from '../../_functions/_handleRequestErrors';
import { getLocalstorageData } from '../../../_functions/_getLocalstorageData';
import { _delete } from '../../../../services/apis/requests';

class ModalUpload extends Component {
	constructor() {
		super();
		this.state = {
			files: [],
			error_files: [],
			error_files_sizes: [],
			deleting: false,
		};
		this.checkSuccess = this.checkSuccess.bind(this);
		this.splitFiles = this.splitFiles.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.setState({ files: this.props.anexos });
	}

	splitFiles(e) {
		e.preventDefault();
		this.setState({ error_files: [] });
		const max_size = 8000000;
		const { files } = this.state;
		const files_to_add = e.target.files;
		const files_new = [...files];
		const error_files_sizes_new = [];
		let key_new = files_new.length === 0 ? 0 : files_new.length;

		for (let i = 0; i < files_to_add.length; i++) {
			if (files_to_add[i].size <= max_size) {
				const single_file = {
					key: key_new,
					criado_em: '',
					id: 0,
					nome: files_to_add[i].name,
					tamanho: files_to_add[i].size,
					url: '',
					visto: false,
					file: files_to_add[i],
					progress: 0,
					success: 0,
					error: false,
					error_message: '',
				};
				files_new.push(single_file);
				this.sendFile(files_to_add[i], key_new, files_new);
				key_new++;
			} else {
				error_files_sizes_new.push(files_to_add[i].name);
			}
		}
		this.setState({ files: files_new, error_files_sizes: error_files_sizes_new });

		for (let i = key_new; i < files_to_add.length; i++) {
			if (files_to_add[i].size <= max_size) this.sendFile(files[i].file, files[i].key, files_new);
		}
	}

	sendFile(file, key, files) {
		const token = getLocalstorageData('user', 'token');
		const base_url = getLocalstorageData('environment', 'base_url');
		const { rc_id } = this.props;
		const url = `${base_url}/conaz/v2/rcs/${rc_id}/anexos`;
		const data = new FormData();
		data.append('files', file, file.name);

		const setProgress = files_new => this.setState({ files: files_new });

		const config = {
			onUploadProgress(progress) {
				const percent_completed = Math.round((progress.loaded * 100) / progress.total);
				const files_new = files.slice();
				files_new[key].progress = percent_completed;
				setProgress(files_new);
			},
		};

		axios.defaults.headers.common.token = token;
		axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
		axios.post(url, data, config).then(response => {
			setTimeout(() => {
				const files_new = [...files];
				files_new[key].success = 1;
				files_new[key].url = response.data[0].url;
				files_new[key].criado_em = response.data[0].criado_em;
				files_new[key].id = response.data[0].id;
				this.props.updateCotacao(['rc', 'anexos'], files_new);
				this.setState({ files: files_new });
				this.removeErrors(files_new);
			}, 800);
		}).catch(error => {
			const files_new = [...files];
			files_new[key].success = 2;
			this.props.updateCotacao(['anexos'], files_new);
			const error_files_new = [...this.state.error_files, file.name];
			this.setState({ files: files_new, error_files: error_files_new });
			if (error.response && error.response.data && error.response.data.message) {
				this.setState({ error_message: error.response.data.message });
			}
			this.removeErrors(files_new);
		});
	}

	deleteFile(key) {
		const { rc_id } = this.props;
		const { files } = this.state;
		const anexo_id = files[key].id;
		this.setState({ deleting: true });
		const delete_url = `/conaz/v2/rcs/${rc_id}/anexos/${anexo_id}`;
		_delete(delete_url).then(() => {
			// const files_new = files.filter(f => f.key !== key && f.id !== anexo_id).map((a, i) => ({ ...a, key: i }));
			const files_new = files.filter(file => file.key !== key).map((a, i) => ({ ...a, key: i }));
			this.setState({ files: files_new, deleting: false });
			this.props.updateCotacao(['rc', 'anexos'], files_new);
		}).catch(() => {
			this.setState({ deleting: false });
		});
	}

	removeErrors(_files) {
		const files = _files.filter(f => f.success !== 2).map((a, i) => ({ ...a, key: i }));
		this.setState({ files });
	}

	checkSuccess() {
		const { files } = this.state;
		let all_success = true;
		for (let i = 0; i < files.length; i++) {
			if (files[i].success === 0 || files[i].success === 2) all_success = false;
		}
		if (all_success) return true;
		return false;
	}

	render() {
		const { files, error_files, error_files_sizes, deleting, error_message } = this.state;
		const { edit, updateModals, modal_to_close } = this.props;
		const all_success = this.checkSuccess();

		return (
			<div className="modal-content">
				<div className="modal-header" styleName="modal-header">
					<button
						type="button"
						className="close"
						styleName="close-button"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => updateModals(modal_to_close, false)}>
						<span aria-hidden="true">&times;</span>
					</button>
					<h4 className="modal-title">Arquivos</h4>
				</div>
				<div className="modal-body" styleName="modal-body">
					{error_files_sizes.length === 1 && (
						<div className="alert alert-danger" role="alert" style={{ margin: '0 auto', textAlign: 'center' }}>
							<i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" /> O arquivo <b>{error_files_sizes[0]}</b> ultrapassa o tamanho máximo permitido (8Mb) e não pôde ser enviado.
						</div>
					)}
					{error_files_sizes.length > 1 && (
						<div className="alert alert-danger" role="alert" style={{ margin: '0 auto', textAlign: 'center' }}>
							<i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" /> Os arquivos <b>{error_files_sizes.join(', ').replace(/, (?![\s\S]*, )/, ' e ')}</b> ultrapassam o tamanho máximo permitido (8Mb) e não puderam ser enviados.
						</div>
					)}
					{error_files.length > 0 && (
						<div className="alert alert-danger" role="alert" style={{ margin: '0 auto', textAlign: 'center', background: 'none', border: 'none' }}>
							<i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" /> {error_message}
						</div>
					)}
					{files.map((file, f) => {
						const wrapper_class = edit ? 'file-wrapper' : 'file-wrapper only-view';
						/* eslint-disable react/no-array-index-key */
						return (
							<div key={f} styleName={wrapper_class}>
								{edit ? (
									<React.Fragment>
										{(file.success === 1 && all_success && !deleting) ? (
											<button
												type="button"
												styleName="delete-file-button"
												onClick={() => this.deleteFile(file.key)}>
												<i className="fa fa-trash" aria-hidden="true" />
											</button>
										) : (
											<button
												type="button"
												styleName="delete-file-button"
												style={{ opacity: '0.4' }}>
												<i className="fa fa-trash" aria-hidden="true" />
											</button>
										)}
										<div styleName="file-name">
											<span>{f + 1}.</span> {file.nome} {file.success === 1 && <i className="fa fa-check fa-fw" aria-hidden="true" style={{ color: '#58d258' }} />}
										</div>
									</React.Fragment>
								) : (
									<div styleName="file-name">
										<a
											href={file.url}
											target="_blank"
											rel="noopener noreferrer"
											download>
											<span>{f + 1}.</span> {file.nome}
										</a>
									</div>
								)}
								{(edit && file.success === 0) && (
									<div className="progress">
										<div className="progress-bar" role="progressbar" aria-valuenow={file.progress} aria-valuemin="0" aria-valuemax="100" style={{ width: `${file.progress}%` }} />
									</div>
								)}
								{(edit && file.success === 2) && (
									<div className="progress">
										<div className="progress-bar" role="progressbar" aria-valuenow={file.progress} aria-valuemin="0" aria-valuemax="100" style={{ width: `${file.progress}%`, backgroundColor: '#f34b4b' }} />
									</div>
								)}
							</div>
						);
						/* eslint-enable react/no-array-index-key */
					})}
					{(!!edit && !!all_success) && (
						<div styleName="box">
							<input type="file" name="file-1[]" id="file-1" styleName="inputfile" multiple onChange={this.splitFiles} />
							<label htmlFor="file-1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17"><path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" fill="#6495ed" /></svg> <span>Clique aqui para selecionar seus arquivos&hellip;</span></label>
						</div>
					)}
					{(!!edit && !all_success) && (
						<div styleName="box" style={{ opacity: '0.5', textAlign: 'center', padding: '17px 10px 16px' }}>
							<label htmlFor="file-1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17"><path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" fill="#6495ed" /></svg> <span>Clique aqui para selecionar seus arquivos&hellip;</span></label>
						</div>
					)}
				</div>
			</div>
		);
	}
}

ModalUpload.propTypes = {
	rc_id: PropTypes.string.isRequired,
	anexos: PropTypes.array.isRequired,
	updateCotacao: PropTypes.func.isRequired,
	updateModals: PropTypes.func,
	modal_to_close: PropTypes.string,
	edit: PropTypes.bool,
};

ModalUpload.defaultProps = {
	updateModals: () => {},
	modal_to_close: 'anexos',
	edit: true,
};

export default CSSModules(ModalUpload, styles, { allowMultiple: true });
