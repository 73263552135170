import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';


const NormalStage = ({ active, done, connection, label }) => {
	let icons_cls = 'icons';
	let text_cls = 'text';

	if (active) {
		icons_cls = 'icons active';
		text_cls = 'text active';
	} else if (done) {
		icons_cls = 'icons done';
		text_cls = 'text done';
	}

	return (
		<div styleName={icons_cls}>
			<div styleName="icon"><i className="fa fa-check" aria-hidden="true" /></div>
			{connection && <div styleName="connection"><div styleName="preenchimento" /></div>}
			<div styleName={text_cls}>
				{label}
			</div>
		</div>
	);
};

NormalStage.propTypes = {
	active: PropTypes.bool,
	done: PropTypes.bool,
	label: PropTypes.string.isRequired,
	connection: PropTypes.bool,
};

NormalStage.defaultProps = {
	active: false,
	done: false,
	connection: false,
};

export default CSSModules(NormalStage, styles, { allowMultiple: true });
