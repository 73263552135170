import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import shallowequal from 'shallowequal';

/* eslint--disable */

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import Condicao2 from './Condicao2';

// Functions
import { _post, _put } from '../../../services/apis/requests';
import { formas_pagamento, prazos_pagamento_full } from '../../_content/_condicoes_pagamento';

class CondicoesPagamento2 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tip_compra_parcial: -1,
		};
		this.showTip = this.showTip.bind(this);
		this.createCondicaoPagamento = this.createCondicaoPagamento.bind(this);
		this.atualizaCondicaoPagamento = this.atualizaCondicaoPagamento.bind(this);
		this.deleteCondicaoPagamento = this.deleteCondicaoPagamento.bind(this);
	}

	showTip(e, id) {
		e.preventDefault();
		this.setState({ tip_compra_parcial: id });
	}

	createCondicaoPagamento() {
		const nova_condicao = {
			desconto: 0,
			forma: 0,
			outra_forma: '',
			outro_prazo: '',
			prazo: 0,
		};
		_post('/conaz/v2/condicoes_de_pagamento/', nova_condicao).then(response => {
			const { condicoes_de_pagamento, condicoes_de_pagamento_by_id, updateCotacao, autoSaveRcTimeout } = this.props;
			const _condicoes_de_pagamento = [...condicoes_de_pagamento, response.data.id];
			const _condicoes_de_pagamento_by_id = { ...condicoes_de_pagamento_by_id, [response.data.id]: response.data };
			updateCotacao(['rc', 'condicoes_de_pagamento_by_id'], _condicoes_de_pagamento_by_id);
			updateCotacao(['rc', 'condicoes_de_pagamento'], _condicoes_de_pagamento);
			autoSaveRcTimeout();
		}).catch(error => {
			console.log(error); // eslint-disable-line
		});
	}

	atualizaCondicaoPagamento(condicao) {
		_put(`/conaz/v2/condicoes_de_pagamento/${condicao.id}`, condicao);
		// _put(`/conaz/v2/condicoes_de_pagamento/${condicao.id}`, condicao).then(response => {
		// 	console.log(response);
		// }).catch(error => {
		// 	console.log(error);
		// });
		this.props.autoSaveRcTimeout();
	}

	deleteCondicaoPagamento(condicao) {
		const { condicoes_de_pagamento, updateCotacao, autoSaveRcTimeout } = this.props;
		const _condicoes_de_pagamento = condicoes_de_pagamento.filter(x => x !== condicao.id);
		updateCotacao(['rc', 'condicoes_de_pagamento'], _condicoes_de_pagamento);
		autoSaveRcTimeout();
	}

	render() {
		const {
			tip_compra_parcial,
		} = this.state;
		const {
			condicoes_de_pagamento,
			condicoes_de_pagamento_by_id,
			updateCotacao,
			valor_final_itens,
			custos_adicionais,
			valor_final,
			valor_transporte,
			condicao_de_preferencia,
		} = this.props;

		const existe_preferencia = (
			condicao_de_preferencia.id !== undefined &&
			condicao_de_preferencia.id !== null &&
			(
				condicao_de_preferencia.forma !== 0 ||
				condicao_de_preferencia.prazo !== 0
			)
		);
		// forma
		const forma_preferencia = (existe_preferencia && condicao_de_preferencia.forma === -1)
			? condicao_de_preferencia.outra_forma
			: (formas_pagamento[condicao_de_preferencia.forma] || {}).view;
		// prazo
		const prazo_preferencia = (existe_preferencia && condicao_de_preferencia.prazo === -1)
			? condicao_de_preferencia.outro_prazo
			: (prazos_pagamento_full[condicao_de_preferencia.prazo] || {}).view;
		const preferencia = !existe_preferencia
			? <em style={{ color: '#b7babd' }}>Não informado</em>
			: <span style={{ padding: '2px 5px', background: '#fffaa4' }}><b>{forma_preferencia}: {prazo_preferencia}</b></span>;

		return (
			<React.Fragment>
				<div className="row">
					<div className="col-xs-12">
						<div id="tooltip_condicoes_pagamento" className="tooltip top" role="tooltip" style={{ marginTop: '-54px' }}>
							<div className="tooltip-arrow" style={{ borderTopColor: '#ee2622', left: '10%' }} />
							<div className="tooltip-inner" style={{ backgroundColor: '#ee2622' }}>Preencha todas as condições de pagamento</div>
						</div>
						<label>Pagamento <span className="obrigatorio-label">*</span></label>
						<p style={{ margin: '0 0 18px' }}>Preferência do Construtor: {preferencia}</p>
						{condicoes_de_pagamento.map((id, c) => (
							<Condicao2
								key={id}
								order={c}
								condicao={condicoes_de_pagamento_by_id[id]}
								condicoes_de_pagamento_by_id={condicoes_de_pagamento_by_id}
								updateCotacao={updateCotacao}
								autoSave={this.atualizaCondicaoPagamento}
								deleteCondicaoPagamento={this.deleteCondicaoPagamento}
								valor_final_itens={valor_final_itens}
								custos_adicionais={custos_adicionais}
								valor_final={valor_final}
								valor_transporte={valor_transporte}
								tip_compra_parcial={tip_compra_parcial}
								showTip={this.showTip} />
						))}
					</div>
				</div>
				<div className="row" styleName="row-space">
					<div className="col-xs-12">
						<button type="button" className="btn btn-default" styleName="add-nova-condicao-btn" onClick={this.createCondicaoPagamento}>
							<i className="fa fa-plus fa-fw" /> Adicionar forma de pagamento
						</button>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

CondicoesPagamento2.propTypes = {
	condicoes_de_pagamento: PropTypes.array.isRequired,
	condicoes_de_pagamento_by_id: PropTypes.object.isRequired,
	updateCotacao: PropTypes.func.isRequired,
	autoSaveRcTimeout: PropTypes.func.isRequired,
	valor_final_itens: PropTypes.number.isRequired,
	custos_adicionais: PropTypes.number.isRequired,
	valor_final: PropTypes.number.isRequired,
	valor_transporte: PropTypes.number.isRequired,
	condicao_de_preferencia: PropTypes.object.isRequired,
};

export default CSSModules(CondicoesPagamento2, styles, { allowMultiple: true });
