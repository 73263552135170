const user = {
	logged: false,
	token: '',
	id: 0,
	email: '',
	nome: '',
	cnpj: '',
};

export default user;
