import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import Header from '../../_parts/Header/Header';
import AnexosModal from '../../_parts/AnexosModal/AnexosModal';
import ItemRowRevisao from '../../_parts/ItemRowRevisao/ItemRowRevisao';
import ItemRow from '../../_parts/ItemRow/ItemRow';
import BasicModal from '../../_parts/_modals/BasicModal/BasicModal';
import ModalWrapper from '../../_parts/_modals/ModalWrapper/ModalWrapper';
import ModalResetRC from '../../_parts/_modals/ModalResetRC/ModalResetRC';
import LeftModalWrapper from '../../_parts/_modals/LeftModalWrapper/LeftModalWrapper';
import LeftModalConstrutorPerfil from '../../_parts/_modals/LeftModalConstrutorPerfil/LeftModalConstrutorPerfil';
import InfoClienteCotacao from '../../_parts/InfoClienteCotacao/InfoClienteCotacao';
import ReviewCompra from './ReviewCompra';
import ModalConfirmacaoVenda from '../../_parts/_modals/ModalConfirmacaoVenda/ModalConfirmacaoVenda';
import ModalRecusaCompra from '../../_parts/_modals/ModalSemInteresse/ModalRecusaCompra';
import Alert from './Alert';
import InfoCotacao from './InfoCotacao/InfoCotacao';
import ModalPortal from '../../_parts/_modals/ModalPortal/ModalPortal';
import ModalWrapperPortal from '../../_parts/_modals/ModalPortal/ModalWrapperPortal';
import ModalConfirm from '../../_parts/_modals/ModalConfirm/ModalConfirm';

// Content
import { formas_pagamento, prazos_pagamento_full } from '../../_content/_condicoes_pagamento';
// import { defineStatusCompra } from '../../_functions/_defineStatusCompra';

// Functions
import { formatCurrency } from '../../_functions/_formatNumber';

const Review = ({
	/* state */
	rc,
	compras_visiveis,
	compra,
	confirma_edicao,
	resetando,
	resetando_error,
	oc_confirmada,
	oc_recusada,
	usuario_diferente,
	confirmando_oc,
	error_confirmando_oc,
	alert_cotacao_enviada,
	tooltip_atualizar,
	cotacao_info_collapsed,
	pedido_info_collapsed,
	itens_info_collapsed,
	modal_nao_aceita_parcial,
	nao_aceita_parcial_compra_minima,
	tab_perfil_construtor,
	/* local */
	transporte_opcoes,
	/* props */
	ui,
	history,
	/* pfunc */
	updateModals,
	/* funcs */
	confirmaEdicao,
	handleChangeUrl,
	resetaCotacao,
	confirmarOC,
	showTooltip,
	hideTooltip,
	expandInfo,
	handleModalNaoAceitaParcial,
	openPerfilConstrutor,
	updateViewRecusada,
}) => {
	const { modals } = ui;

	const {
		observacoes,
		sub_total,
		preco_total,
		codigo_do_fornecedor,
		validade,
		condicoes_de_pagamento,
		transporte,
		valor_transporte,
		transporte_proprio,
		status,
		prazo_de_entrega,
		data_de_resposta,
		pedido,
		custos_adicionais,
		anexos_pedido,
		usuario,
		motivos_recusa,
	} = rc;

	const total_valor_compra = compras_visiveis
		.map(c => c.valor_total)
		.reduce((ac, cv) => ac + cv, 0);

	const compra_to_show = compra;
	const retirada = transporte === 2;

	// data_resposta
	const codigo_pedido = pedido.codigo;
	const titulo_pedido = `#${pedido.codigo} - ${pedido.titulo}`;
	const data_entrega_desejada = pedido.data_de_entrega_desejada;

	const anexos_cotacao = rc.anexos;
	const itens_de_resposta = rc.itens_de_resposta.sort((a, b) => a.item_de_pedido.ordem - b.item_de_pedido.ordem);

	const data_resposta_local_moment = moment.utc(data_de_resposta)._d;
	const data_resposta_local = moment(data_resposta_local_moment).format();
	const data_hora_resposta_split = data_resposta_local.split('T');
	const data_resposta_split = data_hora_resposta_split[0].split('-');
	const data_resposta_view = data_resposta_split[2] !== undefined ? `${data_resposta_split[2]}/${data_resposta_split[1]}/${data_resposta_split[0]}` : '-';
	const hora_resposta_split = typeof data_hora_resposta_split[1] === 'string' ? data_hora_resposta_split[1].split(':') : '';
	const hora_resposta_view = hora_resposta_split[0] !== undefined ? `${hora_resposta_split[0]}:${hora_resposta_split[1]}` : '';
	// data_entrega_desejada
	const entrega_desejada_split = data_entrega_desejada.split('-');
	const entrega_desejada_view = entrega_desejada_split[2] !== undefined ? `${entrega_desejada_split[2]}/${entrega_desejada_split[1]}/${entrega_desejada_split[0]}` : '-';
	// validade
	const validade_split = validade !== null ? validade.split('-') : '';
	const validade_view = validade_split[2] !== undefined ? `${validade_split[2]}/${validade_split[1]}/${validade_split[0]}` : '-';

	const pedido_cancelado = pedido.cancelado;

	const orderByDate = arr => _.orderBy(arr, _compra => new Date(_compra.data_de_entrega));


	if (status === -30) {
		return (
			<div className="container" style={{ marginTop: '36px' }}>
				<div className="row">
					<div className="col-sm-12 error-wrapper">
						<i className="fa fa-exclamation-circle" aria-hidden="true" />
						<h3>Cotação cancelada!</h3>
						<p>Esta cotação foi cancelada e não está mais disponível.</p>
					</div>
				</div>
			</div>
		);
	}

	if (pedido.status === 10) {
		return (
			<div className="container" style={{ marginTop: '36px' }}>
				<div className="row">
					<div className="col-sm-12 error-wrapper">
						<i className="fa fa-pencil" aria-hidden="true" />
						<h3>Pedido em edição!</h3>
						<p style={{ fontSize: '16px' }}>Este pedido está sendo editado pelo construtor neste momento.<br />Você será notificado por e-mail assim que ele concluir.</p>
					</div>
				</div>
			</div>
		);
	}

	const valor_parcial = nao_aceita_parcial_compra_minima === 0
		? 'com esta condição de pagamento'
		: `menores que R$ ${nao_aceita_parcial_compra_minima}`;

	/* eslint-disable */

	return (
		<div>
			{modal_nao_aceita_parcial && (
				<ModalPortal>
					<ModalWrapperPortal closeModal={handleModalNaoAceitaParcial}>
						<ModalConfirm
							title="Compra parcial"
							message={<p>O sistema avisou o construtor que <b>você não aceitava compras parciais {valor_parcial}</b>, mas foi enviada esta OC mesmo assim.<br /><br />Se isto ainda não foi alinhado com você, entre em contato pedindo para cancelar esta compra.</p>}
							// successFunc={this.goToCriarCompraLocal}
							button_type="secondary"
							button_text="Entendi!" />
					</ModalWrapperPortal>
				</ModalPortal>
			)}
			<ModalWrapper visible={modals.anexos_cliente} updateModals={updateModals}>
				<AnexosModal
					anexos={anexos_pedido}
					tipo_anexo="cliente"
					nome_modal="anexos_cliente"
					updateModals={updateModals} />
			</ModalWrapper>
			<ModalWrapper visible={modals.anexos} updateModals={updateModals}>
				<AnexosModal
					anexos={anexos_cotacao}
					tipo_anexo="fornecedor"
					nome_modal="anexos"
					updateModals={updateModals} />
			</ModalWrapper>
			<ModalWrapper visible={modals.reset_rc} updateModals={updateModals}>
				<ModalResetRC history={history} />
			</ModalWrapper>
			<LeftModalWrapper visible={modals.perfil_construtor} updateModals={updateModals} modal="perfil_construtor">
				<LeftModalConstrutorPerfil
					exibir_contatos_compra={compras_visiveis.length > 0 || status === 50}
					exibir_contatos_pedido={rc.pedido.exibir_contato}
					usuario_pedido={rc.pedido.aprovacao_pedida_por.id !== undefined ? rc.pedido.aprovacao_pedida_por : rc.pedido.usuario}
					usuario_compra={compras_visiveis.length > 0 ? compra_to_show.usuario_construtor : null}
					obra={rc.pedido.obra}
					tab={tab_perfil_construtor} />
			</LeftModalWrapper>
			<ModalWrapper visible={modals.confirmacao_venda} updateModals={updateModals}>
				<ModalConfirmacaoVenda
					confirmacaoEnviada={confirmando_oc}
					confirmOC={confirmarOC}
					updateModals={updateModals}
					compra_id={compras_visiveis.length > 0 ? compra_to_show.id : -1}
					error_confirmando_oc={error_confirmando_oc}
					usuario_diferente={usuario_diferente}
					usuario={usuario}
					history={history} />
			</ModalWrapper>
			<ModalWrapper visible={modals.recusa_venda} updateModals={updateModals}>
				<ModalRecusaCompra
					updateModals={updateModals}
					compra_id={compras_visiveis.length > 0 ? compra_to_show.id : -1}
					compra={compras_visiveis.length > 0 ? compra_to_show : {}}
					updateViewRecusada={updateViewRecusada} />
			</ModalWrapper>
			<ModalWrapper visible={modals.alerta_nome_comercial} updateModals={updateModals}>
				<BasicModal
					title="Atenção"
					message={<p>Identificamos que você utilizou palavras como <b>&quot;LTDA&quot;, &quot;MEI&quot; ou &quot;S.A&quot;</b> no cadastro do nome comercial da sua empresa, o que nos parece ser o nome da Razão Social. Recomendamos que <b>altere seu cadastro</b> para algo mais reconhecível aos construtores, assim conseguirem identificar a sua empresa mais facilmente.</p>}
					modalName="alerta_nome_comercial"
					onClickConfirmar={() => { history.push('/editar-empresa'); }}
					showConfirmarButton
					showCancelarButton
					textoConfirmar="Editar cadastro"
					textoCancelar="Não quero alterar" />
			</ModalWrapper>

			{alert_cotacao_enviada && <Alert />}

			<Header
				title={titulo_pedido} />

			<InfoClienteCotacao
				obra={rc.pedido.obra}
				data_de_entrega_desejada={rc.pedido.data_de_entrega_desejada}
				observacoes={rc.pedido.observacoes_conaz}
				anexos={rc.anexos_pedido}
				updateModals={updateModals}
				show_obs_anexos={false}
				openPerfilConstrutor={openPerfilConstrutor}
				white_buttons />

			<div className="container" styleName="main-container">

				<div className="row">
					<div className="col-sm-12">
						<div styleName="review-block no-pad2">
							<div styleName="info-title">TODAS AS VENDAS DESSA COTAÇÃO</div>

						{orderByDate(compras_visiveis).map((c, index) => ( // TODO order
							<div key={c.id} styleName="content-compras">
								<div styleName={`review-block no-pad2 no-shadow ${compra.id === c.id ? 'open' : ''}`}>
									<div styleName="info-title">
										<span>{index + 1}. Detalhes da venda</span>
										{c.id !== compra.id && <button onClick={() => handleChangeUrl(c.id)}>
											Ver detalhes da proposta
										</button>}
										{c.id !== compra.id &&
											<span style={{ float: 'right', fontWeight: '500', fontSize: '18px', lineHeight: '21px' }}>
											<b>{formatCurrency(c.valor_total, 'R$', 2)}</b>
										</span>}
									</div>
									{c.id === compra.id && <ReviewCompra
										retirada={retirada}
										compra={compra}
										confirmada={oc_confirmada}
										recusada={oc_recusada}
										confirmarOC={() => updateModals('confirmacao_venda', true)}
										recusarOC={() => updateModals('recusa_venda', true)}
										pedido_id={codigo_pedido} />}
								</div>
							</div>
						))}

						<div styleName="info-total-compras">
							<span>{formatCurrency(total_valor_compra, 'R$', 2)}</span>
							<span>Valor Total das Vendas</span>
						</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-sm-12">
						<hr styleName="hr-blocks" />
					</div>
				</div>

				<InfoCotacao
					formas_pagamento={formas_pagamento}
					prazos_pagamento_full={prazos_pagamento_full}
					confirma_edicao={confirma_edicao}
					resetando={resetando}
					resetando_error={resetando_error}
					tooltip_atualizar={tooltip_atualizar}
					transporte_opcoes={transporte_opcoes}
					confirmaEdicao={confirmaEdicao}
					resetaCotacao={resetaCotacao}
					showTooltip={showTooltip}
					hideTooltip={hideTooltip}
					observacoes={observacoes}
					sub_total={sub_total}
					preco_total={preco_total}
					codigo_do_fornecedor={codigo_do_fornecedor}
					condicoes_de_pagamento={condicoes_de_pagamento}
					valor_transporte={valor_transporte}
					prazo_de_entrega={prazo_de_entrega}
					custos_adicionais={custos_adicionais}
					data_resposta_view={data_resposta_view}
					hora_resposta_view={hora_resposta_view}
					validade_view={validade_view}
					anexos_cotacao={anexos_cotacao}
					status_pedido={rc.pedido.status}
					status={status}
					transporte={transporte}
					transporte_proprio={transporte_proprio}
					motivos_recusa={motivos_recusa}
					cotacao_info_collapsed={cotacao_info_collapsed}
					expandInfo={expandInfo}
					pedido_cancelado={pedido_cancelado}
					compra={compras_visiveis.length > 0 ? compra_to_show : {}} />
				<div className="row">
					<div className="col-sm-12">
						<hr styleName="hr-blocks" />
					</div>
				</div>
				<div className="row">
					<div className="col-sm-12">
						<div styleName="review-block no-pad2" style={{ minHeight: '0' }}>
							<div styleName="info-title">Informações dos Itens Cotados</div>
							{itens_info_collapsed && (
								<button
									type="button"
									styleName="content-to-expand"
									onClick={() => expandInfo('itens_info_collapsed')}>
									<i className="fa fa-angle-down fa-fw" aria-hidden="true" /> Expandir informações <i className="fa fa-angle-down fa-fw" aria-hidden="true" />
								</button>
							)}
						</div>
					</div>
				</div>
				{(!itens_info_collapsed && itens_de_resposta.length > 0) && itens_de_resposta.map((idr, i) => (
					<ItemRowRevisao
						key={idr.id}
						number={i + 1}
						item={idr} />
				))}
				{(!itens_info_collapsed && itens_de_resposta.length === 0) && rc.pedido.itens_de_pedido.map((item, i) => (
					<ItemRow
						key={item.id}
						number={i + 1}
						item={item} />
				))}
				{!itens_info_collapsed && (
					<button
						type="button"
						styleName="content-to-expand to-collapse"
						style={{ margin: '-30px 0 20px' }}
						onClick={() => expandInfo('itens_info_collapsed')}>
						<i className="fa fa-angle-up fa-fw" aria-hidden="true" />
					</button>
				)}
				<div className="row">
					<div className="col-sm-12">
						<hr styleName="hr-blocks" />
					</div>
				</div>
				<div className="row">
					<div className="col-sm-12">
						<div styleName="review-block no-pad2" style={{ minHeight: '40px' }}>
							<div styleName="info-title">Informações gerais do Pedido</div>
							{!pedido_info_collapsed ? (
								<React.Fragment>
									<div styleName="content">
										<div className="row">
											<div className="col-xs-12 col-md-6" styleName="content-block">
												<i className="fa fa-hashtag" aria-hidden="true" />
												<p styleName="labels">Código</p>
												<span styleName="price-color"><b style={{ fontSize: '21px' }}>#{codigo_pedido}</b></span>
											</div>
											<div className="col-xs-12 col-md-6" styleName="content-block">
												<i className="fa fa-calendar-check-o" aria-hidden="true" />
												<p styleName="labels">Entrega estimada</p>
												{entrega_desejada_view}
											</div>
										</div>
										<div className="row">
											<div className="col-xs-12 col-md-6" styleName="content-block margin-top">
												<i className="fa fa-files-o" aria-hidden="true" />
												<p styleName="labels">Anexos do construtor</p>
												{rc.anexos_pedido.map((anexo, a) => ( // eslint-disable-next-line react/no-array-index-key
													<div key={a} styleName="anexo-row">
														<span>{a + 1}</span> <a href={anexo.url} target="_blank" rel="noopener noreferrer" download>{anexo.nome}</a>
													</div>
												))}
												{rc.anexos_pedido.length === 0 && <em style={{ color: '#c3c6c9', fontSize: '14px' }}>0 arquivos</em>}
											</div>
											<div className="col-xs-12 col-md-6" styleName="content-block margin-top">
												<i className="fa fa-comment" aria-hidden="true" />
												<p styleName="labels">Observações do construtor</p>
												{rc.pedido.observacoes_conaz !== '' ? <p style={{ fontSize: '14px' }}>&#34;{rc.pedido.observacoes_conaz}&#34;</p> : <em style={{ color: '#c3c6c9', fontSize: '14px' }}>Nenhuma observação.</em>}
											</div>
										</div>
									</div>
									<button
										type="button"
										styleName="content-to-expand to-collapse"
										onClick={() => expandInfo('pedido_info_collapsed')}>
										<i className="fa fa-angle-up fa-fw" aria-hidden="true" />
									</button>
								</React.Fragment>
							) : (
								<button
									type="button"
									styleName="content-to-expand"
									onClick={() => expandInfo('pedido_info_collapsed')}>
									<i className="fa fa-angle-down fa-fw" aria-hidden="true" /> Expandir informações <i className="fa fa-angle-down fa-fw" aria-hidden="true" />
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

Review.propTypes = {
	// =========== local
	rc: PropTypes.object.isRequired,
	confirma_edicao: PropTypes.bool.isRequired,
	resetando: PropTypes.bool.isRequired,
	resetando_error: PropTypes.bool.isRequired,
	oc_confirmada: PropTypes.bool.isRequired,
	oc_recusada: PropTypes.bool.isRequired,
	confirmando_oc: PropTypes.bool.isRequired,
	error_confirmando_oc: PropTypes.bool.isRequired,
	transporte_opcoes: PropTypes.array.isRequired,
	compras_visiveis: PropTypes.array.isRequired,
	usuario_diferente: PropTypes.bool.isRequired,
	alert_cotacao_enviada: PropTypes.bool.isRequired,
	tooltip_atualizar: PropTypes.bool.isRequired,
	cotacao_info_collapsed: PropTypes.bool.isRequired,
	pedido_info_collapsed: PropTypes.bool.isRequired,
	itens_info_collapsed: PropTypes.bool.isRequired,
	modal_nao_aceita_parcial: PropTypes.bool.isRequired,
	nao_aceita_parcial_compra_minima: PropTypes.number.isRequired,
	compra: PropTypes.object.isRequired,
	tab_perfil_construtor: PropTypes.number.isRequired,
	// =========== local funcs
	confirmaEdicao: PropTypes.func.isRequired,
	resetaCotacao: PropTypes.func.isRequired,
	confirmarOC: PropTypes.func.isRequired,
	showTooltip: PropTypes.func.isRequired,
	hideTooltip: PropTypes.func.isRequired,
	expandInfo: PropTypes.func.isRequired,
	handleChangeUrl: PropTypes.func.isRequired,
	handleModalNaoAceitaParcial: PropTypes.func.isRequired,
	openPerfilConstrutor: PropTypes.func.isRequired,
	updateViewRecusada: PropTypes.func.isRequired,
	// =========== store
	ui: PropTypes.object.isRequired,
	// =========== funcs
	updateModals: PropTypes.func.isRequired,
	// =========== router
	history: PropTypes.object.isRequired,
};

export default CSSModules(Review, styles, { allowMultiple: true });
