const ui = {
	base_url: '',
	environment: '',
	modals: {
		anexos: false,
		anexos_cliente: false,
		error: false,
		fornecedor: false,
		sem_interesse: false,
		geral: false,
		reset_rc: false,
		reset_confirm: false,
		aviso_prazo_cotacao: false,
		perfil_construtor: false,
		confirmacao_venda: false,
		steper_mobile: false,
		confirmar_desvinculacao_fornecedor: false,
		alerta_nome_comercial: false,
		termos_de_uso: false,
		recusa_venda: false,
		modal_corona_alert: 'ja_visualizado',
	},
	tab_number_selected: 1,
	tabs: {
		em_andamento: {
			label: 'EM ANDAMENTO',
			number: 0,
			index: 'em_andamento',
			current_page_link: 0,
			pagination_count: 1,
			current_page_rcs: [],
			rcs_length: 0,
			called: false,
		},
		aguardando_confirmacao: {
			label: 'AGUARDANDO SUA CONFIRMAÇÃO',
			number: 1,
			index: 'aguardando_confirmacao',
			current_page_link: 0,
			pagination_count: 1,
			current_page_rcs: [],
			rcs_length: 0,
			called: false,
		},
		vendas: {
			label: 'VENDAS',
			number: 2,
			index: 'vendas',
			current_page_link: 0,
			pagination_count: 1,
			current_page_rcs: [],
			rcs_length: 0,
			called: false,
		},
		sem_sucesso: {
			label: 'SEM SUCESSO',
			number: 3,
			index: 'sem_sucesso',
			current_page_link: 0,
			pagination_count: 1,
			current_page_rcs: [],
			rcs_length: 0,
			called: false,
		},
		canceladas: {
			label: 'CANCELADAS',
			number: 4,
			index: 'canceladas',
			current_page_link: 0,
			pagination_count: 1,
			current_page_rcs: [],
			rcs_length: 0,
			called: false,
		},
		recusadas: {
			label: 'RECUSADAS',
			number: 5,
			index: 'recusadas',
			current_page_link: 0,
			pagination_count: 1,
			current_page_rcs: [],
			rcs_length: 0,
			called: false,
		},
	},
	pagination: {
		cotacoes: 1,
	},
	tawkto: false,
	changes: {
		cotacoes: false,
		compras: false,
	},
	dropdown: {
		id: 0,
		open: false,
	},
	dropdown_open: false,
	account_menu: false,
	obras_menu: false,
	actions_menu: false,
	compra_actions_menu: false,
	descartar_pedido_menu: false,
	salvando_cotacao: 0,
	search_state: 0,
	search: '',
	search_results: {
		pedidos: [],
		compras: [],
	},
	alert_cotacao_enviada: false,
	alerts: {
		success: {
			visible: false,
			message: '',
		},
		escolhe_cotacao: {
			visible: false,
			message: '',
		},
	},
};

export default ui;
