import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';


const Secao = ({ name, children, req_state, section_open, rcs, openSection, legenda }) => {
	const labelQtdSolicitacoes = (_req_state, _rcs) => {
		if (_req_state === 1) {
			return '(carregando...)';
		}
		if (_req_state === 2) {
			return `(${_rcs.length})`;
		}
		return '';
	};

	const button_expand_class = (section_open && legenda)
		? 'button-expand-area legenda'
		: 'button-expand-area';

	return (
		<React.Fragment>
			<div className="row">
				<div className="col-xs-12">
					<button
						styleName={button_expand_class}
						onClick={() => openSection(name)}>{`${name} ${labelQtdSolicitacoes(req_state, rcs)}`}
						<div
							styleName="icon">
							{section_open ? '-' : '+'}
						</div>
					</button>
					{(section_open && legenda) && (
						<div styleName="legenda-2">{legenda}</div>
					)}
				</div>
			</div>
			{section_open && (
				<div className="row">
					<div className="col-xs-12 col-sm-6">
						{rcs
							.filter((solicitacao, s) => s % 2 === 0)
							.map(solicitacao => (
								React.Children
									.map(children, child => (
										React.cloneElement(child, { ...solicitacao, key: solicitacao.rc_id })
									))
							))}
					</div>
					<div className="col-xs-12 col-sm-6">
						{rcs
							.filter((solicitacao, s) => s % 2 === 1)
							.map(solicitacao => (
								React.Children
									.map(children, child => (
										React.cloneElement(child, { ...solicitacao, key: solicitacao.rc_id })
									))
							))}
					</div>
				</div>
			)}
		</React.Fragment>
	);
};

Secao.propTypes = {
	name: PropTypes.string.isRequired,
	children: PropTypes.element.isRequired,
	req_state: PropTypes.number.isRequired,
	section_open: PropTypes.bool.isRequired,
	rcs: PropTypes.array.isRequired,
	openSection: PropTypes.func.isRequired,
	legenda: PropTypes.string,
};

Secao.defaultProps = {
	legenda: '',
};

export default CSSModules(Secao, styles, { allowMultiple: true });
