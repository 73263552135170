import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shallowequal from 'shallowequal';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import TagSimilar from './TagSimilar';
import EscolhaDoItem from './EscolhaDoItem';
import ItemRowCaracs from './ItemRowCaracs';
import ItemRowValues from './ItemRowValues';

// Functions
import { formatDecimals } from '../../_functions/_formatNumber';

class ItemRowOpenV2 extends Component {
	shouldComponentUpdate(nextProps, nextState) {
		if (!shallowequal(this.props, nextProps)) return true;
		if (!shallowequal(this.state, nextState)) return true;
		return false;
	}

	render() {
		const {
			number,
			updateItem,
			updateItemCaracteristica,
			updateItemCaracteristicaUnidade,
			autoSaveTimeout,
			itens_observacoes,
			item,
			updateCotacao,
		} = this.props;
		const {
			item_de_pedido,
			item_preenchido,
			status,
			editado,
		} = item;

		const caracteristicas_pedido = item_de_pedido.item_preenchido.caracteristicas_preenchidas
			.filter(c => c.caracteristica.visao_construtor !== 0 && c.opcoes_preenchidas.length > 0)
			.sort((a, b) => a.caracteristica.ordem - b.caracteristica.ordem);

		const item_row_top_class = !editado
			? 'item-row-top'
			: 'item-row-top border-alert';
		const item_row_bottom_class = !editado
			? 'item-row-bottom'
			: 'item-row-bottom border-alert';
		const number_class = number.length > 1
			? 'number multiple'
			: 'number';

		return (
			<div className="row">
				<div className="col-xs-12">
					{editado && (
						<div styleName="border-alert-item-alterado">Este item foi alterado pelo construtor</div>
					)}
					<div styleName={`${item_row_top_class} ${status === -1 ? 'dont-have' : ''}`}>
						<div styleName={number_class}>{number}</div>
						<div styleName="nome">
							<div>
								{item_preenchido.item.nome}
								<TagSimilar item={item} status={status} />
							</div>
						</div>
						<div styleName="quantidade-top">
							Quantidade solicitada:
							<b> {formatDecimals(item_de_pedido.quantidade)} {item_de_pedido.unidade}</b>
						</div>
						{caracteristicas_pedido.map(c => {
							const valor = c.opcoes_preenchidas
								.map(op => (
									(op.unidade !== '' && op.unidade !== null && op.unidade !== undefined)
										? `${op.valor} ${op.unidade}`
										: op.valor
								))
								.join(', ');
							return valor !== '' ? (
								<div key={c.caracteristica.id} styleName="carac-pedido-wrapper">
									{c.caracteristica.nome}<p><b>{valor}</b></p>
								</div>
							) : false;
						})}
						{item_de_pedido.observacoes !== '' && (
							<div styleName="carac-pedido-wrapper">
								Observações
								<p><b>{item_de_pedido.observacoes}</b></p>
							</div>
						)}
					</div>
					<div styleName={item_row_bottom_class}>
						<EscolhaDoItem
							item={item}
							updateItem={updateItem}
							autoSaveTimeout={autoSaveTimeout}
						/>
						{status === 1 && (
							<div className="row">
								<ItemRowCaracs
									item={item}
									updateItem={updateItem}
									updateItemCaracteristica={updateItemCaracteristica}
									updateItemCaracteristicaUnidade={updateItemCaracteristicaUnidade}
									autoSaveTimeout={autoSaveTimeout}
								/>
								<ItemRowValues
									item={item}
									updateItem={updateItem}
									autoSaveTimeout={autoSaveTimeout}
									itens_observacoes={itens_observacoes}
									updateCotacao={updateCotacao}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

ItemRowOpenV2.propTypes = {
	number: PropTypes.number.isRequired,
	item: PropTypes.object.isRequired,
	updateItem: PropTypes.func.isRequired,
	updateItemCaracteristica: PropTypes.func.isRequired,
	updateItemCaracteristicaUnidade: PropTypes.func.isRequired,
	autoSaveTimeout: PropTypes.func.isRequired,
	itens_observacoes: PropTypes.array.isRequired,
	updateCotacao: PropTypes.func.isRequired,
};

export default CSSModules(ItemRowOpenV2, styles, { allowMultiple: true });
