import update from 'immutability-helper';
import cotacao from '../store/cotacao';
import { UPDATE_COTACAO, UPDATE_ITEM, UPDATE_ITEM_CARACTERISTICA, UPDATE_ITEM_CARACTERISTICA_UNIDADE } from '../actions/action.types';

const reducer = (state = cotacao, action) => {
	switch (action.type) {
	case UPDATE_COTACAO: {
		switch (action.field.length) {
		case 1:
			return update(state, {
				[action.field[0]]: { $set: action.value },
			});
		case 2:
			return update(state, {
				[action.field[0]]: {
					[action.field[1]]: { $set: action.value },
				},
			});
		case 3:
			return update(state, {
				[action.field[0]]: {
					[action.field[1]]: {
						[action.field[2]]: { $set: action.value },
					},
				},
			});
		case 4:
			return update(state, {
				[action.field[0]]: {
					[action.field[1]]: {
						[action.field[2]]: {
							[action.field[3]]: { $set: action.value },
						},
					},
				},
			});

		default:
			return state;
		}
	}

	case UPDATE_ITEM: {
		const { itens_by_id } = state;
		const item_editado = { ...itens_by_id[action.item_id], [action.field]: action.value };
		const _itens_by_id = { ...itens_by_id, [action.item_id]: item_editado };
		return update(state, { itens_by_id: { $set: _itens_by_id } });
	}

	case UPDATE_ITEM_CARACTERISTICA: {
		const { itens_by_id } = state;
		const caracteristicas_preenchidas = {
			...itens_by_id[action.item_id].item_preenchido.caracteristicas_preenchidas,
			[action.key]: {
				...itens_by_id[action.item_id].item_preenchido.caracteristicas_preenchidas[action.key],
				opcoes_preenchidas: action.value,
			},
		};
		const _itens_by_id = {
			...itens_by_id,
			[action.item_id]: {
				...itens_by_id[action.item_id],
				item_preenchido: {
					...itens_by_id[action.item_id].item_preenchido,
					caracteristicas_preenchidas,
				},
			},
		};
		return update(state, { itens_by_id: { $set: _itens_by_id } });
	}

	case UPDATE_ITEM_CARACTERISTICA_UNIDADE: {
		const { itens_by_id } = state;
		const caracteristicas_preenchidas = {
			...itens_by_id[action.item_id].item_preenchido.caracteristicas_preenchidas,
			[action.key]: {
				...itens_by_id[action.item_id].item_preenchido.caracteristicas_preenchidas[action.key],
				opcoes_preenchidas: {
					...itens_by_id[action.item_id].item_preenchido.caracteristicas_preenchidas[action.key].opcoes_preenchidas,
					unidade: action.value,
				},
			},
		};
		const _itens_by_id = {
			...itens_by_id,
			[action.item_id]: {
				...itens_by_id[action.item_id],
				item_preenchido: {
					...itens_by_id[action.item_id].item_preenchido,
					caracteristicas_preenchidas,
				},
			},
		};
		return update(state, { itens_by_id: { $set: _itens_by_id } });
	}

	default:
		return state;
	}
};

export default reducer;
