/*eslint-disable*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss'

class InputFieldSelect extends Component {
	constructor() {
		super();
		this.state = {
			opcoes_local: [],
			opcao_selecionada_local: { view: '', value: '' },
			all_options_selected: false,
			search: ''
		};
	}

	UNSAFE_componentWillMount() {
		this.updateComponent(this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (
			(this.props.opcao_selecionada !== nextProps.opcao_selecionada)
		 ||this.props.regioesLimpas !== nextProps.regioesLimpas) {
			this.updateComponent(nextProps);
		}
	}

	updateComponent(props) {
		const {
			opcoes,
			opcao_selecionada,
			regioesLimpas,
			all_options_label,
			all_options_selected,
		} = props;
		if (regioesLimpas && all_options_selected) {
			let opcao_selecionada_local = { view: '', value: '' };
			const opcoes_new = opcoes
				.sort((a, b) => {
					if (a.view.toUpperCase() < b.view.toUpperCase()) { return -1 }
					if (a.view.toUpperCase() > b.view.toUpperCase()) { return 1 }
					return 0;
				});
			this.setState({
				opcoes_local: opcoes_new,
				opcao_selecionada_local: opcao_selecionada_local
			});
		} else if (all_options_selected) {
			let opcao_selecionada_local = { view: all_options_label, value: 'all' };
			const opcoes_new = opcoes
				.sort((a, b) => {
					if (a.view.toUpperCase() < b.view.toUpperCase()) { return -1 }
					if (a.view.toUpperCase() > b.view.toUpperCase()) { return 1 }
					return 0;
				});
			this.setState({
				opcoes_local: opcoes_new,
				opcao_selecionada_local: opcao_selecionada_local
			});
		} else {
			let opcao_selecionada_local = { view: '', value: '' };
			const opcoes_new = opcoes
				.map(opcao => {
					if (opcao.value === opcao_selecionada) {
						opcao_selecionada_local.view = opcao.view;
						opcao_selecionada_local.value = opcao.value;
					}
					return opcao;
				})
				.sort((a, b) => {
					if (a.view.toUpperCase() < b.view.toUpperCase()) { return -1 }
					if (a.view.toUpperCase() > b.view.toUpperCase()) { return 1 }
					return 0;
				});
			this.setState({
				opcoes_local: opcoes_new,
				opcao_selecionada_local: opcao_selecionada_local
			});
		}
	}

	handleEditionState(opcao_view, opcao_value) {
		this.setState({ opcao_selecionada_local: { view: opcao_view, value: opcao_value }, search: '' });
		const { allowSendState } = this.props;
		allowSendState();
		if (this.search) { this.search.value = '' }
		this.props.auxiliarFunction(this.props.list_key, opcao_view, opcao_value);
	}

	handleEditionStateAllOptions() {
		const { handleSelectAllStates, allowSendState } = this.props;
		handleSelectAllStates()
		allowSendState();
		const { all_options_label } = this.props;
		if (all_options_label === '') {
			this.setState({
				opcao_selecionada_local: { view: 'Todo o Brasil', value: 'all' },
				all_options_selected: true, search: ''
			});
			if (this.search) { this.search.value = '' }
			this.props.auxiliarFunction(this.props.list_key, all_options_label, 'all');
			return;
		}
		this.setState({
			opcao_selecionada_local: { view: all_options_label, value: 'all' },
			all_options_selected: true, search: ''
		});
		if (this.search) { this.search.value = '' }
		this.props.auxiliarFunction(this.props.list_key, all_options_label, 'all');

	}

	handleSearchState(typed) {
		this.setState({ search: typed });
	}

	searchedOptions() {
		const { opcoes_local, opcao_selecionada_local, search } = this.state;
		return opcoes_local
			.filter(opcao => opcao.view.toLowerCase().indexOf(search.toLowerCase()) !== -1)
			.map((opcao, o) => {
				const select_button_class = opcao_selecionada_local.value === opcao.value ? 'select-button selected' : 'select-button';
				return (
					<li key={o} data-dropdown>
						<button styleName={select_button_class} onClick={() => this.handleEditionState(opcao.view, opcao.value)}>
							{opcao.view}
						</button>
					</li>
				)
			});
	}

	render() {
		const { opcoes_local, opcao_selecionada_local } = this.state;
		const { label, additional_class, all_options, all_options_label } = this.props;
		const dropdown_class = additional_class !== '' ? `dropdown ${additional_class}` : `dropdown`;

		return (
			<div className="form-group" styleName="form-group">
				<label>{label}</label>
				<div className="dropdown">
					<button className="btn btn-default dropdown-toggle" styleName={dropdown_class}
						type="button" aria-haspopup="true" aria-expanded="true"
						data-dropdown
					>
						{opcao_selecionada_local.view}<span className="caret" styleName="dropdown-caret" data-dropdown-2></span>
					</button>
					<ul className="dropdown-menu" styleName="dropdown-menu-multiple" data-dropchild>
						{opcoes_local.length > 30 && (
							<div styleName="dropdown-search" data-dropchild>
								<div styleName="top-border" data-dropchild />
								<li styleName="input" data-dropchild>
									<input
										type="text"
										className="form-control"
										placeholder="Busca..."
										data-dropchild
										ref={input => this.search = input}
										onChange={e => this.handleSearchState(e.target.value)} />
								</li>
								<li role="separator" className="divider" data-dropchild />
							</div>
						)}
						<div styleName="dropdown-menu-list" data-dropchild>
							{all_options && (
								<li data-dropchild>
									<button styleName="select-button" onClick={() => this.handleEditionStateAllOptions()}>
										Todo o Brasil
									</button>
								</li>
							)}
							{all_options && <li role="separator" className="divider" data-dropchild />}
							{this.searchedOptions()}
						</div>
					</ul>
				</div>
			</div>
		)
	}
}

InputFieldSelect.propTypes = {
	allowSendState: PropTypes.func.isRequired,
	regioesLimpas: PropTypes.bool.isRequired,
	handleSelectAllStates: PropTypes.func,
	label: PropTypes.string.isRequired,
	additional_class: PropTypes.string,
	opcoes: PropTypes.array.isRequired,
	opcao_selecionada: PropTypes.string.isRequired,
	all_options: PropTypes.bool,
	all_options_label: PropTypes.string,
	all_options_selected: PropTypes.bool,
	dropdown_open: PropTypes.bool.isRequired,
	updateUi: PropTypes.func.isRequired,
	list_key: PropTypes.number,
	auxiliarFunction: PropTypes.func
}

InputFieldSelect.defaultProps = {
	handleSelectAllStates: () => null,
	additional_class: '',
	all_options: false,
	all_options_label: 'Todas as opções',
	all_options_selected: false,
	auxiliarFunction: function () { }
}

export default CSSModules(InputFieldSelect, styles, { allowMultiple: true });
