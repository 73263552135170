import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// Components
import LoadingPage from '../_parts/_loadings/LoadingPage/LoadingPage';

// Functions
import { _get } from '../../services/apis/requests';
import { handleRequestErrors } from '../_functions/_handleRequestErrors';
import { formatNumbers, formatCurrency } from '../_functions/_formatNumber';
import { transporteText, pagamentoText, validation, docType } from './subfunctions';

import Impressao from './Impressao';

const ImpressaoContainer = props => {
	const [compra, setCompra] = useState({});
	const [requestState, setRequestState] = useState(1);

	useEffect(() => {
		_get(`/conaz/v2/compras/${props.match.params.id}`)
			.then(({ data }) => setCompra(data))
			.catch((err) => {
				setRequestState(3);
				handleRequestErrors(err);
			});
	}, []);

	useEffect(() => {
		if (compra.compra_id) setRequestState(2);
		else if (compra.id && validation(compra)) {
			const {
				condicao_de_pagamento,
				outra_condicao_de_pagamento,
				documento_comprador,
				id: compra_id,
				itens_de_compra,
				fornecedor,
				valor_total,
				sub_total,
				valor_transporte,
				transporte,
				obra,
				observacoes,
				observacoes_internas,
				pedido_id: cotacao_id,
				custos_adicionais,
				titulo,
				data_de_entrega,
			} = compra;

			const { desconto } = condicao_de_pagamento;

			const _compra = {
				titulo,
				compra_id,
				cotacao_id,
				desconto,
				custos_adicionais: formatCurrency(custos_adicionais, 2, 'R$'),
				total: formatCurrency(valor_total, 2, 'R$'),
				transporte: transporteText(transporte),
				valor_transporte: formatCurrency(valor_transporte, 2, 'R$'),
				observacoes,
				observacoes_internas,
				subtotal: formatCurrency(sub_total, 2, 'R$'),
			};

			if (condicao_de_pagamento.forma === -1 || condicao_de_pagamento.prazo === -1) {
				_compra.condicao_de_pagamento = pagamentoText(
					condicao_de_pagamento,
					outra_condicao_de_pagamento,
				);
			} else _compra.condicao_de_pagamento = pagamentoText(condicao_de_pagamento);

			const valor_total_item = sub_total - sub_total * (condicao_de_pagamento.desconto / 100);

			const fe = fornecedor.endereco;
			const oe = obra.endereco;

			const items = itens_de_compra
				.sort((a, b) => a.numero - b.numero)
				.map((item) => {
					const {
						preco_unitario: preco,
						desconto: desconto_item,
						id,
						item_preenchido,
						item_de_resposta,
						quantidade: qtd,
						informacoes_adicionais,
						unidade: un,
						excluido,
					} = item;

					const { status } = item_de_resposta;

					const _item = {
						id,
						desconto: desconto_item,
						nome: item_preenchido.item.nome,
						observacoes: informacoes_adicionais,
						un,
						status,
						excluido,
					};

					const item_sub_total = qtd * preco;

					_item.qtd = qtd;
					_item.preco = formatCurrency(preco, 2, 'R$');
					_item.total = formatCurrency(
						item_sub_total - item_sub_total * (desconto_item / 100),
						2,
						'R$',
					);

					const caracteristicas = item_preenchido.caracteristicas_preenchidas.map((carac) => {
						const aux = { caracteristica: carac.caracteristica.nome };
						if (carac.opcoes_preenchidas.length > 0) aux.valor = carac.opcoes_preenchidas[0].valor;
						else aux.valor = 'Não informado';

						return aux;
					});

					_item.caracteristicas = caracteristicas;

					return _item;
				});

			_compra.total_item = formatCurrency(valor_total_item, 2, 'R$');
			_compra.items = items;
			_compra.doc_comprador = formatNumbers(documento_comprador, 'cnpj');

			_compra.fornecedor = {
				nome: fornecedor.nome,
				endereco: `${fe.logradouro} - ${fe.numero} - ${fe.bairro},
				${' '}${fe.cidade} - ${fe.estado}, ${formatNumbers(fe.cep, 'cep')}`,
				doc: formatNumbers(fornecedor.documento, 'cnpj'),
			};
			_compra.dt_emitida = moment().format('DD/MM/YYYY - hh:mm a');
			_compra.dt_entrega = moment(data_de_entrega).format('DD/MM/YYYY');
			_compra.pagamento = {
				...condicao_de_pagamento,
			};

			const { pessoas } = obra;

			const financeiro =
				(pessoas || []).filter((p1) => p1.papeis.filter((p2) => p2 === 2).length > 0)[0] || {};

			const financeiro_nome = (financeiro.pessoa || {}).nome || '';
			const pessoa = (financeiro || {}).pessoa || {};
			let financeiro_doc_type = '';
			if ((pessoa.documento || '') !== '' || (pessoa.documento || '') !== undefined) {
				financeiro_doc_type = docType(pessoa.documento || '');
			}
			const financeiro_documento =
				financeiro.pessoa.documento !== '' &&
					financeiro.pessoa.documento !== undefined &&
					financeiro.pessoa.documento !== null &&
					financeiro_doc_type !== ''
					? formatNumbers(financeiro.pessoa.documento, financeiro_doc_type)
					: 'Sem registro';

			_compra.faturamento = {
				nome: financeiro_nome,
				doc: financeiro_documento,
			};

			_compra.obra_endereco = `${oe.logradouro} - ${oe.numero} - ${oe.bairro},
			${' '}${oe.cidade} - ${oe.estado}, ${formatNumbers(oe.cep, 'cep')}`;

			setCompra(_compra);
		} else if (requestState === 3) props.history.push(`/compra/${props.match.params.id}`);
	}, [compra]);

	return requestState !== 1 ? <Impressao compra={compra} /> : <LoadingPage />;
};

ImpressaoContainer.propTypes = {
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};

export default ImpressaoContainer;
