import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as actions from '../../../actions/index';

// Components
import Wrapper from '../../Wrapper/Wrapper';
import Itens from './Itens';

// Functions
import { _put, _post } from '../../../services/apis/requests';
import { reportarErro } from '../../../services/apis/http';
import withDocumentWidth from '../../../hoc/DocumentWidh/withDocumentWidht';


class ItensContainer extends Component {
	constructor() {
		super();
		this.state = {
			itens_to_save: [],
			salvando: false,
			salva: true,
			new_saving: 2,
			errors: false,
			tab_perfil_construtor: 0,
			opened_items: {},
		};
		this.autoSaveTimeout = this.autoSaveTimeout.bind(this);
		this.autoSaveRcTimeout = this.autoSaveRcTimeout.bind(this);
		this.openPerfilConstrutor = this.openPerfilConstrutor.bind(this);
		this.forceAutoSave = this.forceAutoSave.bind(this);
	}

	UNSAFE_componentWillMount() {
		window.scroll(0, 0);
		// redirect page 1
		const { match, cotacao, history, updateCotacao } = this.props;
		if (cotacao.rc.uuid === undefined) {
			history.replace(`/cotacao/${match.params.id}`);
			return;
		}

		const itens_all_ids = cotacao.itens_all_ids || [];
		if (itens_all_ids.length === 0) {
			history.replace(`/cotacao/${match.params.id}`);
			return;
		}

		// adiciona primeira condicao de pagamento
		if (cotacao.rc.condicoes_de_pagamento !== undefined && cotacao.rc.condicoes_de_pagamento.length === 0) {
			const nova_condicao = { desconto: 0, forma: 0, outra_forma: '', outro_prazo: '', prazo: 0 };
			_post('/conaz/v2/condicoes_de_pagamento/', nova_condicao).then(response => {
				const _condicoes_de_pagamento = [response.data.id];
				const _condicoes_de_pagamento_by_id = { [response.data.id]: response.data };
				updateCotacao(['rc', 'condicoes_de_pagamento_by_id'], _condicoes_de_pagamento_by_id);
				updateCotacao(['rc', 'condicoes_de_pagamento'], _condicoes_de_pagamento);
				this.autoSaveRcTimeout();
			});
		}
	}

	componentWillUnmount() {
		const { updateUi, updateModals } = this.props;
		updateUi(['salvando_cotacao'], 0);
		updateModals('sem_interesse', false);
		updateModals('geral', false);
		updateModals('anexos', false);
		updateModals('anexos_cliente', false);
		updateModals('perfil_construtor', false);
	}

	buildItemPreenchido(item_id) {
		const { itens_by_id } = this.props.cotacao;
		const item = { ...itens_by_id[item_id] };
		const caracteristicas_preenchidas = item.item_preenchido.caracteristicas_preenchidas_all_ids.map(c => {
			const opcoes_preenchidas = item.item_preenchido.caracteristicas_preenchidas[c].opcoes_preenchidas.map(opcao => {
				if (opcao.caracteristica_id !== null) return opcao;
				return { valor: opcao.valor, unidade: opcao.unidade, validada: false };
			});
			return {
				...item.item_preenchido.caracteristicas_preenchidas[c],
				opcoes_preenchidas,
			};
		});
		return { caracteristicas_preenchidas, item: item.item_preenchido.item };
	}

	buildItem(item_id) {
		const { itens_by_id } = this.props.cotacao;
		const item = { ...itens_by_id[item_id] };
		const item_preenchido = this.buildItemPreenchido(item_id);
		const item_de_resposta = {
			...item,
			item_preenchido,
			preco_unitario: item.preco_unitario === null ? 0 : item.preco_unitario,
		};
		delete item_de_resposta.item_resposta_origem_id;
		return item_de_resposta;
	}

	checkErrors(error) {
		const { cotacao, updateModals } = this.props;
		const message_conaz = (((error || {}).response || {}).data || {}).message || '';
		if (message_conaz === 'Pedido esta sendo editado') {
			window.location.replace(`${window.location.origin}/cotacao/${cotacao.rc.uuid}`);
			return;
		}

		console.log(error); // eslint-disable-line
		updateModals('error', true);
		reportarErro(error);
	}

	autoSaveTimeout(item_id) {
		const timeout = 120 * 1000;
		this.setState({ new_saving: 0, salva: false, errors: false });
		this.props.updateUi(['salvando_cotacao'], 0);
		const itens_to_save = [...this.state.itens_to_save, item_id]
			.filter((el, i, a) => i === a.indexOf(el));
		this.setState({ itens_to_save });
		window.clearTimeout(this.save_timeout);
		this.save_timeout = window.setTimeout(() => {
			this.autoSaveItens(itens_to_save);
		}, timeout);
	}

	forceAutoSave() {
		const { itens_to_save } = this.state;
		window.clearTimeout(this.save_timeout);
		this.autoSaveItens(itens_to_save);
	}

	autoSaveItens(itens_to_save) {
		this.setState({ new_saving: 1, itens_to_save: [] });
		this.props.updateUi(['salvando_cotacao'], 1);
		const all_puts = [];
		for (let i = 0; i < itens_to_save.length; i++) {
			const item_id = itens_to_save[i];
			const item_data = this.buildItem(item_id);
			all_puts.push(_put(`/conaz/v2/itens_de_resposta/${item_id}`, item_data));
		}
		Promise.all(all_puts).then(() => {
			this.setState({ new_saving: 2, salva: true });
			this.props.updateUi(['salvando_cotacao'], 2);
		}).catch(error => {
			this.setState({ new_saving: 3 });
			this.props.updateUi(['salvando_cotacao'], 0);
			this.checkErrors(error);
		});
	}

	autoSaveRcTimeout() {
		this.setState({ salva: false });
		this.props.updateUi(['salvando_cotacao'], 0);
		window.clearTimeout(this.save_rc_timeout);
		this.save_rc_timeout = window.setTimeout(() => {
			this.autoSaveRC();
		}, 3000);
	}

	autoSaveRC() {
		this.props.updateUi(['salvando_cotacao'], 1);
		const { uuid, custos_adicionais, condicoes_de_pagamento } = this.props.cotacao.rc;
		const condicoes_de_pagamento_ids = [...condicoes_de_pagamento];
		const rc_data = {
			custos_adicionais,
			condicoes_de_pagamento_ids,
		};
		_put(`/conaz/v2/rcs/${uuid}`, rc_data).then(() => {
			this.setState({ salva: true });
			this.props.updateUi(['salvando_cotacao'], 2);
		}).catch(error => {
			this.props.updateUi(['salvando_cotacao'], 0);
			this.checkErrors(error);
		});
	}

	openPerfilConstrutor(tab = 0) {
		const { updateModals } = this.props;
		this.setState({ tab_perfil_construtor: tab });
		updateModals('perfil_construtor', true);
	}

	render() {
		const { cotacao } = this.props;
		const loaded = cotacao.rc.uuid !== undefined;

		if (cotacao.rc.uuid === undefined) {
			return null;
		}

		return (
			<Wrapper
				loaded={loaded}
				{...this.props}
			>
				<Itens
					{...this.state}
					{...this.props}
					autoSaveTimeout={this.autoSaveTimeout}
					autoSaveRcTimeout={this.autoSaveRcTimeout}
					openPerfilConstrutor={this.openPerfilConstrutor}
					forceAutoSave={this.forceAutoSave}
				/>
			</Wrapper>
		);
	}
}

ItensContainer.propTypes = {
	// =========== store
	cadastro: PropTypes.object.isRequired,
	cotacao: PropTypes.object.isRequired,
	ui: PropTypes.object.isRequired,
	// =========== funcs
	updateCadastro: PropTypes.func.isRequired,
	updateCotacao: PropTypes.func.isRequired,
	updateUi: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	reportErrors: PropTypes.func.isRequired,
	// =========== router
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
};

const mapStateToProps = props => ({
	cadastro: props.cadastro,
	cotacao: props.cotacao,
	ui: props.ui,
});

const mapDispatchToProps = dispatch => ({
	resetCadastro: () => dispatch(actions.resetCadastro()),
	updateCadastro: (field, value) => dispatch(actions.updateCadastro(field, value)),
	updateCotacao: (field, value) => dispatch(actions.updateCotacao(field, value)),
	updateUi: (field, value) => dispatch(actions.updateUi(field, value)),
	updateItem: (item_id, field, value) => dispatch(actions.updateItem(item_id, field, value)),
	updateItemCaracteristica: (item_id, key, value) => dispatch(actions.updateItemCaracteristica(item_id, key, value)),
	updateItemCaracteristicaUnidade: (item_id, key, value) => dispatch(actions.updateItemCaracteristicaUnidade(item_id, key, value)),
	updateModals: (modal, value) => dispatch(actions.updateModals(modal, value)),
	reportErrors: (page, error, codigo_pedido, full_error, test) => dispatch(actions.reportErrors(page, error, codigo_pedido, full_error, test)),
});

export default compose(
	withDocumentWidth,
	connect(mapStateToProps, mapDispatchToProps),
)(ItensContainer);
