import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const ModalAvisoPrazoCotacao = ({ updateModals }) => (
	<div className="modal-content" styleName="modal-content">
		<div className="modal-body" styleName="modal-body">
			<button
				type="button"
				className="close"
				styleName="close-button"
				data-dismiss="modal"
				aria-label="Close"
				onClick={() => updateModals('aviso_prazo_cotacao', false)}>
				<span aria-hidden="true">&times;</span>
			</button>
			<h3>Importante</h3>
			<p>O prazo solicitado pelo construtor para envio dessa cotação foi ultrapassado, porém ele ainda não tomou a decisão. Você pode enviar sua proposta mesmo assim, contudo enviando fora do prazo, suas chances de venda diminuem.</p>
			<div styleName="buttons">
				<button
					type="button"
					className="btn btn-primary btn-lg"
					onClick={() => updateModals('aviso_prazo_cotacao', false)}>
					Entendi!
				</button>
			</div>
		</div>
	</div>
);

ModalAvisoPrazoCotacao.propTypes = {
	updateModals: PropTypes.func,
};

ModalAvisoPrazoCotacao.defaultProps = {
	updateModals: () => {},
};

export default CSSModules(ModalAvisoPrazoCotacao, styles, { allowMultiple: true });
