import React from 'react';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const NotFound404 = () => (
	<div className="container">
		<div className="row">
			<div className="col-xs-12">
				<p styleName="teste">404</p>
			</div>
		</div>
	</div>
);

export default CSSModules(NotFound404, styles, { allowMultiple: true });
