/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const InfoClienteCotacao = ({
	obra,
	observacoes,
	anexos,
	updateModals,
	show_obs_anexos,
	openPerfilConstrutor,
	white_buttons,
}) => {
	const is_mobile = window.innerWidth >= 320 && window.innerWidth <= 425;
	const { pessoas } = obra;
	// cliente
	const cliente = pessoas.filter(p1 => p1.papeis.filter(p2 => p2 === 1).length > 0)[0];
	const { logo_url } = cliente.pessoa;
	// endereço obra
	const bairro = ((obra || {}).endereco || {}).bairro || '';
	const cidade = ((obra || {}).endereco || {}).cidade || '';
	const estado = ((obra || {}).endereco || {}).estado || '';
	const tab_button_class = !white_buttons ? 'tab-button' : 'tab-button white';

	if (is_mobile) {
		return (
			<div className="container">
				<div className="row" styleName="wrapper">
					<button
						styleName="logo-2"
						style={{ backgroundImage: `url(${logo_url})` }}
						onClick={() => openPerfilConstrutor()} />
					<div className="col-xs-12 col-md-5">
						<div styleName="cliente mobile">
							<span>Responsável pela obra:</span>
							<br />
							<button
								styleName="btn-link-cliente mobile"
								onClick={() => openPerfilConstrutor()}>
								<p>{cliente.pessoa.nome}</p>
							</button>
						</div>
						{show_obs_anexos && (
							<div styleName="cliente mobile">
								<span>Obra em:</span>
								<br />
								<button
									styleName="btn-link-cliente mobile"
									onClick={() => openPerfilConstrutor()}>
									<p>
										{bairro !== '' && `${bairro}/`}
										{cidade}
										{estado !== '' && ` - ${estado}`}
									</p>
								</button>
							</div>
						)}
					</div>
					<button onClick={() => openPerfilConstrutor(1)} styleName="show-infos">
						<i className="fa fa-plus" />
						<p>Informações do construtor</p>
					</button>
				</div>
			</div>
		);
	}

	return (
		<div className="container">
			<div className="row" styleName="wrapper">
				<button
					styleName="logo-2"
					style={{ backgroundImage: `url(${logo_url})` }}
					onClick={() => openPerfilConstrutor()} />
				<div className="col-xs-12 col-md-5">
					<div styleName="cliente">
						<span>Responsável pela obra:</span>
						<button
							className="btn btn-link"
							styleName="btn-link-cliente"
							onClick={() => openPerfilConstrutor()}>
							{cliente.pessoa.nome}
						</button>
					</div>
					{show_obs_anexos && (
						<div styleName="cliente">
							<span>Obra em:</span>
							<button
								className="btn btn-link"
								styleName="btn-link-cliente"
								onClick={() => openPerfilConstrutor()}>
								{bairro !== '' && `${bairro}/`}
								{cidade}
								{estado !== '' && ` - ${estado}`}
							</button>
						</div>
					)}
					{observacoes !== '' && show_obs_anexos && (
						<div styleName="cliente">
							<div styleName="cliente" id="observacoes-inicio">
								<span>Observações:</span> {observacoes}
							</div>
						</div>
					)}
					{anexos.length > 0 && show_obs_anexos && (
						<div styleName="cliente">
							<div styleName="cliente" style={{ padding: '0 36px 0 0' }}>
								<span>Anexos: </span>
								<button
									className="btn btn-primary btn-sm"
									style={{
										backgroundColor: '#214986',
										color: '#fff',
										border: '1px solid #1e427a',
										padding: '5px 10px 6px',
									}}
									onClick={() => updateModals('anexos_cliente', true)}>
									Ver arquivos ({anexos.length})
								</button>
							</div>
						</div>
					)}
				</div>
				<div className="col-xs-12 col-md-7">
					<div styleName="tabs-wrapper">
						<button styleName={tab_button_class} onClick={() => openPerfilConstrutor(1)}>
							<b>Faturamento</b>
							<p>Dados da Empresa e do responsável financeiro</p>
						</button>
						<button styleName={tab_button_class} onClick={() => openPerfilConstrutor(2)}>
							<b>Entrega</b>
							<p>Endereço da Obra e contato do responsável</p>
						</button>
						<button styleName={tab_button_class} onClick={() => openPerfilConstrutor(3)}>
							<b>Contatos</b>
							<p>Nomes, e-mails e telefones disponíveis</p>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

InfoClienteCotacao.propTypes = {
	obra: PropTypes.object.isRequired,
	observacoes: PropTypes.string.isRequired,
	anexos: PropTypes.array,
	updateModals: PropTypes.func.isRequired,
	show_obs_anexos: PropTypes.bool,
	openPerfilConstrutor: PropTypes.func.isRequired,
	white_buttons: PropTypes.bool,
};

InfoClienteCotacao.defaultProps = {
	anexos: [],
	show_obs_anexos: true,
	white_buttons: false,
};

export default CSSModules(InfoClienteCotacao, styles, { allowMultiple: true });
