import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const LabelCotacaoAtiva = ({ prazo_de_resposta, status }) => {
	const prazo_de_resposta_split = prazo_de_resposta.split('-');
	const prazo_de_resposta_view = `${prazo_de_resposta_split[2]}/${prazo_de_resposta_split[1]}`;

	const today_date = moment();
	const prazo_de_resposta_date = moment(prazo_de_resposta);
	const last_day = prazo_de_resposta_date.isSame(today_date, 'day');
	const atrasada = prazo_de_resposta_date.isBefore(today_date, 'day');
	const visualizada = (
		status > 0
		&& status !== 36
		&& status !== 37
	);
	const refinamento = status === 36 || status === 37;
	return (
		<div styleName="labels">
			<div styleName="label blue">Resposta até {prazo_de_resposta_view}</div>
			{last_day && <div styleName="label red">Último dia</div>}
			{atrasada && <div styleName="label red">(Atrasada)</div>}
			{visualizada && <div styleName="label green">Já visualizada</div>}
			{refinamento && <div styleName="label orange">Correção solicitada</div>}
		</div>
	);
};

LabelCotacaoAtiva.propTypes = {
	prazo_de_resposta: PropTypes.string,
	status: PropTypes.number,
};

LabelCotacaoAtiva.defaultProps = {
	prazo_de_resposta: '',
	status: 0,
};

export default CSSModules(LabelCotacaoAtiva, styles, { allowMultiple: true });
