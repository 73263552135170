import { createRequest } from './http';

export const create = (data) => createRequest({ method: 'post', url: '/conaz/v2/fornecedores/', data });

export const update = (id, data) => {
	const url = `/conaz/v2/fornecedores/${id}`;
	return createRequest({ method: 'put', url, data });
};

export const get = (id) => createRequest({ method: 'get', url: `/conaz/v2/fornecedores/${id}` });

export const getList = (params = {}) => createRequest({ method: 'get', url: '/conaz/v2/fornecedores/', params });

export const verifyCnpjFornecedor = (params = {}) => createRequest({ method: 'get', url: '/conaz/v2/fornecedores/consulta_cnpj', params });

export const addUsuarioToFornecedor = (fornecedor_id, usuario_id, data) => createRequest({ method: 'post', url: `/conaz/v2/fornecedores/${fornecedor_id}/usuarios/${usuario_id}`, data });

export const removeUsuarioFromFornecedor = (fornecedor_id, usuario_id) => createRequest({ method: 'delete', url: `/conaz/v2/fornecedores/${fornecedor_id}/usuarios/${usuario_id}` });

export const selecionarPedidosParaFornecedor = () => createRequest({ method: 'get', url: '/conaz/v2/fornecedores/pedidos' })
	.then(response => response.data);
