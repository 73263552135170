/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'redux';
// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import withDocumentWidht from '../../../hoc/DocumentWidh/withDocumentWidht';
import Tooltip from '../_tooltips/ButtonTooltip/ButtonToolTip';

class TagSimilar extends Component {
	static getDerivedStateFromProps(nextProps) {
		const {
			item: {
				item_preenchido: {
					caracteristicas_preenchidas: caracteristicas_preenchidas_resposta,
					caracteristicas_preenchidas_all_ids,
				},
				item_de_pedido: {
					item_preenchido: {
						caracteristicas_preenchidas: caracteristicas_preenchidas_pedido,
					},
				},
			},
		} = nextProps;

		const isNotEqualGroup = _.filter(caracteristicas_preenchidas_all_ids, _id => {
			// filtra características diferentes do que o construtor preencheu
			const responseOption = _.find(caracteristicas_preenchidas_resposta,
				opt => opt.caracteristica.id.toString() === _id);

			const requestOption = _.find(caracteristicas_preenchidas_pedido, opt => {
				const { visao_construtor, visao_fornecedor } = opt.caracteristica;
				// se a característica do construtor for vísivel para o fornecedor
				const isValid =
					(visao_construtor === 2 || visao_construtor === 1)
					&& (visao_fornecedor === 2 || visao_fornecedor === 1)
					&& opt.opcoes_preenchidas.length > 0;
				return opt.caracteristica.id.toString() === _id && isValid;
			});
			let notEqual;

			const hasConstructorResponse = (
				requestOption !== undefined
				&& (requestOption.opcoes_preenchidas[0] || {}).valor !== ''
			);

			if (hasConstructorResponse) {
				const responseValue = (responseOption.opcoes_preenchidas[0] || {}).valor || 0;
				const responseUn = (responseOption.opcoes_preenchidas[0] || {}).unidade || '';
				const requestValue = (requestOption.opcoes_preenchidas[0] || {}).valor || 0;
				const requestUn = (requestOption.opcoes_preenchidas[0] || {}).unidade || '';
				// se valor nao tiver unidade ignorar verificação
				const unityLogic = (requestValue !== 0 && requestUn === '')
					? false : (responseUn !== requestUn);
				const has_response = responseOption.opcoes_preenchidas.length > 0;
				const has_response_option = (responseOption.opcoes_preenchidas[0] || {}).valor !== '';
				// se tiver valor e for diferente do construtor
				notEqual = (
					(
						has_response
						&& (
							has_response_option
							&& (responseValue !== requestValue || unityLogic)
						)
					)
				);
			}
			return notEqual;
		});
		if (isNotEqualGroup.length > 0) {
			return { isSimilar: true };
		}
		return { isSimilar: false };
	}

	state = {
		isSimilar: false,
		similarHover: false,
	}

	handleHoverSimilarTag = val => {
		this.setState({ similarHover: val });
	}

	render() {
		// eslint-disable-next-line no-unused-vars
		const { isSimilar, similarHover } = this.state;
		const { status, width: _width } = this.props;

		if (isSimilar && status === 1) {
			return (
				<div
					styleName="divSimilar"
					onMouseEnter={() => this.handleHoverSimilarTag(true)}
					onMouseLeave={() => this.handleHoverSimilarTag(false)}>
					{similarHover && _width > 420 &&
						<Tooltip
							styleTooltip={{
								position: 'relative',
								width: '100%',
								left: '-10px',
								top: '-75px',
							}}
							styleArrow={{
								position: 'relative',
								top: '-13px',
								left: '60px',
							}}
							text="Você preencheu campo(s) diferente(s) da requisição do construtor" />
					}
					<span> Produto similar</span>
				</div>
			);
		}
		return null;
	}
}

TagSimilar.propTypes = {
	status: PropTypes.number,
	width: PropTypes.number.isRequired,
};

TagSimilar.defaultProps = {
	status: 0,
};
const _TagSimilar = CSSModules(TagSimilar, styles, { allowMultiple: true });
export default compose(withDocumentWidht)(_TagSimilar);
