import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import Caracteristica from '../Caracteristica/Caracteristica';

function ItemRowCaracs({
	item,
	updateItem,
	updateItemCaracteristica,
	updateItemCaracteristicaUnidade,
	autoSaveTimeout,
}) {
	function filterCompletedOrderOption(id, options) {
		const option =
			_.find(options, opt => opt.caracteristica.id.toString() === id);
		if (option !== undefined) {
			return option.opcoes_preenchidas;
		}
		return {};
	}

	const {
		id,
		item_de_pedido,
		item_preenchido,
	} = item;
	const {
		caracteristicas_preenchidas_all_ids,
		caracteristicas_preenchidas,
	} = item_preenchido;

	const caracteristicas_resposta = (caracteristicas_preenchidas_all_ids || [])
		.sort((a, b) => caracteristicas_preenchidas[a].caracteristica.ordem - caracteristicas_preenchidas[b].caracteristica.ordem);
	const caracteristicas_pedido = item_de_pedido.item_preenchido.caracteristicas_preenchidas
		.filter(c => c.caracteristica.visao_construtor !== 0 && c.opcoes_preenchidas.length > 0)
		.sort((a, b) => a.caracteristica.ordem - b.caracteristica.ordem);

	return (
		<div className="col-xs-12 col-lg-6" styleName="left-column">
			{caracteristicas_resposta.map(c => {
				const key = caracteristicas_preenchidas[c].caracteristica.id;
				const caracteristicas_pedido_preenchidas = filterCompletedOrderOption(c, caracteristicas_pedido);

				return (
					<div key={key} styleName="carac-wrapper">
						<Caracteristica
							item_id={id}
							caracteristica_id={c}
							caracteristicas_preenchidas={caracteristicas_preenchidas[c]}
							caracteristicas_pedido_preenchidas={caracteristicas_pedido_preenchidas}
							updateItem={updateItem}
							updateItemCaracteristica={updateItemCaracteristica}
							updateItemCaracteristicaUnidade={updateItemCaracteristicaUnidade}
							autoSaveTimeout={autoSaveTimeout}
						/>
					</div>
				);
			})}
		</div>
	);
}

ItemRowCaracs.propTypes = {
	item: PropTypes.object.isRequired,
	updateItem: PropTypes.func.isRequired,
	updateItemCaracteristica: PropTypes.func.isRequired,
	updateItemCaracteristicaUnidade: PropTypes.func.isRequired,
	autoSaveTimeout: PropTypes.func.isRequired,
};

export default CSSModules(ItemRowCaracs, styles, { allowMultiple: true });
