const cotacoes = {
	cotacoes_by_id: {},
	cotacoes_all_ids: [],
	ativas: [],
	finalizadas: [],
	canceladas: [],
	rascunhos: [],
};

export default cotacoes;
