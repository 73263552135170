import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Components
import ModalWrapper from '../../_parts/_modals/ModalWrapper/ModalWrapper';
import ModalUpload from '../../_parts/_modals/ModalUpload/ModalUpload';
import HeaderCotacao from '../../_parts/HeaderCotacao/HeaderCotacao';
import InfoClienteCotacao from '../../_parts/InfoClienteCotacao/InfoClienteCotacao';
import LeftModalWrapper from '../../_parts/_modals/LeftModalWrapper/LeftModalWrapper';
import LeftModalConstrutorPerfil from '../../_parts/_modals/LeftModalConstrutorPerfil/LeftModalConstrutorPerfil';
import InputSelectGeral from '../../_parts/_inputs/InputSelectGeral/InputSelectGeral';

import Stepbar from '../../_parts/Stepbar';
import StepbarMobile from '../../_parts/Stepbar/StepbarMobile';
import EnviarRCmobile from '../../_parts/Stepbar/EnviarRCmobile';

// Functions
import { getLocalstorageData } from '../../_functions/_getLocalstorageData';
import { formatCnpj } from '../../_functions/_formatCnpj';

const Empresa = ({
	// =========== state
	tab_perfil_construtor,
	// =========== local funcs
	openPerfilConstrutor,
	trocaVendedor,
	cadastrarNovaEmpresa,
	handleFornecedor,
	// =========== store
	ui,
	width,
	cotacao,
	new_saving,
	// =========== props funcs
	updateModals,
	updateCotacao,
	// =========== router
	history,
}) => {
	const { modals } = ui;
	const { rc, fornecedor_selecionado } = cotacao;

	const usuario_pedido =
		rc.pedido.aprovacao_pedida_por.id !== undefined
			? rc.pedido.aprovacao_pedida_por
			: rc.pedido.usuario;

	const vendedor_nome = getLocalstorageData('user', 'nome');
	const vendedor_email = getLocalstorageData('user', 'email');
	const _vendedor_fornecedores = getLocalstorageData('user', 'fornecedores').filter(
		x => x.fornecedor.status >= 0,
	);
	const filtra_associados_pelo_construtor = _vendedor_fornecedores.filter(
		x => x.tipo_associacao !== 4,
	);
	const vendedor_fornecedores =
		filtra_associados_pelo_construtor.length > 0
			? [...filtra_associados_pelo_construtor]
			: [..._vendedor_fornecedores];

	const fornecedores_opcoes = vendedor_fornecedores.map(f => ({
		id: f.fornecedor.id,
		documento: f.fornecedor.documento,
		valor: f.fornecedor.nome,
	}));

	const is_mobile = width <= 1020 || width <= 768;

	return (
		<>
			<ModalWrapper visible={modals.anexos_cliente} updateModals={updateModals}>
				<ModalUpload
					rc_id={rc.uuid}
					anexos={rc.anexos_pedido}
					updateCotacao={updateCotacao}
					modal_to_close="anexos_cliente"
					edit={false}
				/>
			</ModalWrapper>
			<LeftModalWrapper
				visible={modals.perfil_construtor}
				updateModals={updateModals}
				modal="perfil_construtor"
			>
				<LeftModalConstrutorPerfil
					exibir_contatos_pedido={rc.pedido.exibir_contato}
					usuario_pedido={usuario_pedido}
					obra={rc.pedido.obra}
					tab={tab_perfil_construtor}
				/>
			</LeftModalWrapper>
			{is_mobile && (
				<StepbarMobile
					cotacao={cotacao}
					history={history}
					title="3. Informações da empresa"
					new_saving={new_saving}
					updateModals={updateModals}
					modals={modals}
				/>
			)}
			<HeaderCotacao
				pedido_id={rc.pedido.id}
				data_envio={rc.data_envio}
				prazo_de_resposta={rc.pedido.prazo_de_resposta}
				data_de_entrega_desejada={rc.pedido.data_de_entrega_desejada}
			/>

			<InfoClienteCotacao
				obra={rc.pedido.obra}
				data_de_entrega_desejada={rc.pedido.data_de_entrega_desejada}
				observacoes={rc.pedido.observacoes_conaz}
				anexos={rc.anexos_pedido}
				updateModals={updateModals}
				openPerfilConstrutor={openPerfilConstrutor}
			/>
			{!is_mobile && <Stepbar cotacao={cotacao} history={history} />}
			<div className="container">
				<div className="row">
					<div className="col-sm-12">
						<div styleName="review-block" style={{ minHeight: '0' }}>
							<div styleName="info-title">Informações da empresa</div>
							<div styleName="content">
								<label>
									Nome da empresa <span className="obrigatorio-label">*</span>
								</label>
								{vendedor_fornecedores.length > 1 ? (
									<>
										<div styleName="empresa-input">
											<div
												id="tooltip_fornecedor"
												className="tooltip top"
												role="tooltip"
												style={{ marginTop: '-58px' }}
											>
												<div
													className="tooltip-arrow"
													style={{ borderTopColor: '#ee2622', left: '10%' }}
												/>
												<div className="tooltip-inner" style={{ backgroundColor: '#ee2622' }}>
													Selecione a sua empresa
												</div>
											</div>
											<InputSelectGeral
												field_id="fornecedor_select"
												field="fornecedor"
												default_value={
													!fornecedor_selecionado.id ? '' : fornecedor_selecionado.valor
												}
												opcoes={fornecedores_opcoes}
												updateFunction={handleFornecedor}
												has_outro={false}
											/>
										</div>
										{fornecedor_selecionado.id !== undefined && (
											<div styleName="empresa-cnpj">
												CNPJ: {formatCnpj(fornecedor_selecionado.documento || '')}
											</div>
										)}
									</>
								) : (
									<div styleName="empresa-cnpj full">
										{vendedor_fornecedores.length > 0 && (
											<p>
												<b style={{ fontSize: '18px' }}>
													{vendedor_fornecedores[0].fornecedor.nome}
												</b>
												<br />
												CNPJ: {formatCnpj(vendedor_fornecedores[0].fornecedor.documento || '')}
											</p>
										)}
									</div>
								)}
								<Link to="#" onClick={cadastrarNovaEmpresa} styleName="cadastrar">
									<i className="fa fa-plus fa-fw" aria-hidden="true" /> Cadastrar nova empresa
								</Link>
							</div>
							<div styleName="content">
								<label>Vendedor selecionado</label>
								<p>
									{vendedor_nome}{' '}
									<span style={{ color: '#999ea7' }}>
										<br />({vendedor_email})
									</span>
								</p>
								<Link to="#" onClick={trocaVendedor}>
									<i className="fa fa-long-arrow-right fa-fw" aria-hidden="true" /> Trocar de
									vendedor
								</Link>
							</div>
						</div>
					</div>
					{is_mobile && <EnviarRCmobile cotacao={cotacao} history={history} />}
				</div>
			</div>
		</>
	);
};

Empresa.propTypes = {
	// =========== state
	tab_perfil_construtor: PropTypes.number.isRequired,
	new_saving: PropTypes.number.isRequired,
	// =========== local funcs
	openPerfilConstrutor: PropTypes.func.isRequired,
	trocaVendedor: PropTypes.func.isRequired,
	cadastrarNovaEmpresa: PropTypes.func.isRequired,
	handleFornecedor: PropTypes.func.isRequired,
	// =========== store
	ui: PropTypes.object.isRequired,
	cotacao: PropTypes.object.isRequired,
	width: PropTypes.number.isRequired,
	// =========== funcs
	updateModals: PropTypes.func.isRequired,
	updateCotacao: PropTypes.func.isRequired,
	// =========== router
	history: PropTypes.object.isRequired,
};

export default CSSModules(Empresa, styles, { allowMultiple: true });
