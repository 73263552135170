import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { _post } from '../../../../services/apis/requests';

class ModalRecusaCompra extends Component {
	constructor() {
		super();
		this.state = {
			opcoes_sem_interesse: [
				{ id: 1, mensagem: 'Estou sem estoque no momento' },
				{ id: 2, mensagem: 'Não realizo entrega nesse local' },
				{ id: 3, mensagem: 'A construtora está com pendência financeira' },
				{ id: 4, mensagem: 'Não consigo atender o prazo de entrega em obra' },
				{ id: 5, mensagem: 'Valor de compra abaixo do faturamento mínimo' },
				{ id: 6, mensagem: 'Construtora não repassou a documentação solicitada' },
				{ id: 7, mensagem: 'Preciso de mais informações do projeto' },
				{ id: 8, mensagem: 'O valor está diferente do cotado' },
				{ id: 9, mensagem: 'Não aceito compras parciais' },
			],
			opcoes_selecionadas: [],
			outro_motivo_selecionado: false,
			outro_motivo: '',
			enviando_sem_interesse: 0,
			confirm_contato_efetuado: false,
			fornecedor_nome: '',
			usuario_fornecedor_nome: '',
		};
		this.handleOutroMotivo = this.handleOutroMotivo.bind(this);
		this.enviaNaoTenhoInteresse = this.enviaNaoTenhoInteresse.bind(this);
		this.preEnvioSemInteresse = this.preEnvioSemInteresse.bind(this);
	}

	selectOpcao(opcao_id) {
		const { opcoes_selecionadas } = this.state;
		const _opcoes_selecionadas = opcoes_selecionadas.filter(o => o === opcao_id).length === 0
			? [...opcoes_selecionadas, opcao_id]
			: opcoes_selecionadas.filter(o => o !== opcao_id);
		this.setState({ opcoes_selecionadas: _opcoes_selecionadas });
	}

	handleOutroMotivo(e) {
		const outro_motivo = e.target.value;
		this.setState({ outro_motivo });
	}

	enviaNaoTenhoInteresse() {
		this.setState({
			enviando_sem_interesse: 1,
			confirm_contato_efetuado: false,
		});
		const { compra_id } = this.props;
		const { opcoes_selecionadas, outro_motivo } = this.state;
		const params = {
			codigos_recusa: opcoes_selecionadas,
			outro_motivo_recusa: outro_motivo,
		};
		_post(`/conaz/v2/compras/${compra_id}/recusar`, params).then(() => {
			this.setState({ enviando_sem_interesse: 2 });
			this.props.updateViewRecusada();
			this.props.updateModals('recusa_venda', false);
		}).catch(error => {
			const message = (error.response && error.response.data && error.response.data.message)
				? error.response.data.message
				: null;
			if (message === 'Não é possível recusar a compra porque sua conta não está compatível com os dados da compra.') {
				const { compra } = this.props;
				const fornecedor_nome = (compra.fornecedor || {}).nome || '';
				const usuario_fornecedor_nome = (compra.usuario_fornecedor || {}).nome || '';
				this.setState({
					fornecedor_nome,
					usuario_fornecedor_nome,
					enviando_sem_interesse: 4,
				});
				return;
			}

			this.setState({ enviando_sem_interesse: 3 });
		});
	}

	preEnvioSemInteresse() {
		const { opcoes_selecionadas } = this.state;
		if (opcoes_selecionadas.length > 0 && opcoes_selecionadas.filter(op => [6, 7, 8].indexOf(op) !== -1).length > 0) {
			this.setState({ confirm_contato_efetuado: true });
		} else {
			this.enviaNaoTenhoInteresse();
		}
	}

	render() {
		const {
			outro_motivo_selecionado,
			opcoes_sem_interesse,
			opcoes_selecionadas,
			outro_motivo,
			enviando_sem_interesse,
			confirm_contato_efetuado,
			fornecedor_nome,
			usuario_fornecedor_nome,
		} = this.state;
		const { updateModals } = this.props;

		const habilita_botao = (opcoes_selecionadas.length > 0 && !outro_motivo_selecionado) || (outro_motivo_selecionado && outro_motivo !== '');

		if (confirm_contato_efetuado) {
			return (
				<div className="modal-content">
					<div className="modal-header" styleName="modal-header">
						<h4 className="modal-title">Aviso</h4>
					</div>
					<div className="modal-body" styleName="modal-body">
						<p>Já entrou em contato com a construtora e mesmo assim não foi possível resolver?</p>
						<button
							type="button"
							className="btn btn-default   small"
							style={{ marginRight: '20px', marginBottom: '15px' }}
							onClick={() => updateModals('recusa_venda', false)}>
							Não, vou entrar em contato
						</button>
						<button
							type="button"
							className="btn btn-primary older-button small"
							style={{ marginBottom: '15px' }}
							onClick={this.enviaNaoTenhoInteresse}>
							Sim, quero recusar mesmo assim
						</button>
					</div>
				</div>
			);
		}

		return (
			<div className="modal-content">
				<div className="modal-header" styleName="modal-header">
					<button
						type="button"
						className="close"
						styleName="close-button"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => updateModals('recusa_venda', false)}>
						<span aria-hidden="true">&times;</span>
					</button>
					<h4 className="modal-title">Indique ao construtor o motivo de recusar a compra</h4>
				</div>
				<div className="modal-body" styleName="modal-body">
					{opcoes_sem_interesse.map(opcao => (
						<button
							key={opcao.id}
							type="button"
							styleName={opcoes_selecionadas.filter(o => o === opcao.id).length > 0 ? 'checkbox-button selected' : 'checkbox-button'}
							onClick={() => this.selectOpcao(opcao.id)}>
							<i className="fa fa-check-circle" styleName="check" />
							<i className="fa fa-circle" styleName="circle" />
							{opcao.mensagem}
						</button>
					))}
					<button
						type="button"
						styleName={outro_motivo_selecionado ? 'checkbox-button selected' : 'checkbox-button'}
						onClick={() => this.setState({ outro_motivo_selecionado: !outro_motivo_selecionado })}>
						<i className="fa fa-check-circle" styleName="check" />
						<i className="fa fa-circle" styleName="circle" />
						Outro
					</button>
					{outro_motivo_selecionado && (
						<textarea
							className="form-control"
							rows="2"
							value={outro_motivo}
							onChange={this.handleOutroMotivo} />
					)}
					<div styleName="button-wrapper">
						{enviando_sem_interesse === 3 && (
							<div className="alert alert-danger" role="alert" style={{ margin: '0 auto 20px', textAlign: 'center' }}>
								<i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" /> Erro ao enviar resposta. Clique em enviar e tente novamente.
							</div>
						)}
						{enviando_sem_interesse === 4 && (
							<div className="alert alert-danger" role="alert" style={{ margin: '0 auto 20px', textAlign: 'center' }}>
								Sua conta não está compatível com os dados da compra.<br />
								Fornecedor: {fornecedor_nome}<br />
								Vendedor: {usuario_fornecedor_nome}
							</div>
						)}
						<button
							type="button"
							className="btn btn-primary btn-block older-button small"
							disabled={enviando_sem_interesse === 1 || !habilita_botao}
							style={{ opacity: enviando_sem_interesse === 1 || !habilita_botao ? '0.5' : '1' }}
							onClick={this.preEnvioSemInteresse}>
							{enviando_sem_interesse === 1 ? 'Enviando' : 'Enviar'}
						</button>
					</div>
				</div>
			</div>
		);
	}
}

ModalRecusaCompra.propTypes = {
	updateModals: PropTypes.func,
	compra_id: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]).isRequired,
	compra: PropTypes.object.isRequired,
	updateViewRecusada: PropTypes.func,
};

ModalRecusaCompra.defaultProps = {
	updateModals: () => {},
	updateViewRecusada: () => {},
};

export default CSSModules(ModalRecusaCompra, styles, { allowMultiple: true });
