export const defineStatusCompra = compra => {
	const { status } = (compra || {});
	switch (status) {
	case -100:
		return 'deletada';
	case -50:
		return 'cancelada';
	case -40:
		return 'rascunho';
	case -20:
		return 'em_edicao';
	case -10:
		return 'em_aprovacao';
	case 0:
		return 'em_analise';
	// case 5:
	// 	return 'aguardando_aprovacao_sienge';
	case 10:
	default:
		return 'aguardando_confirmacao';
	case 20:
		return 'em_refinamento';
	case 30:
		return 'recusada_fornecedor';
	case 40:
		return 'confirmada';
	case 50:
		return 'entrega_confirmada';
	case 60:
		return 'finalizada';
	case 100:
		return 'recusada';
	}
};
