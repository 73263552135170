import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

class LeftModalWrapper extends Component {
	constructor() {
		super();
		this.state = {
			show: false,
		};
		this.escClose = this.escClose.bind(this);
		this.handleMouseDown = this.handleMouseDown.bind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.visible) {
			setTimeout(() => this.setState({ show: true }), 60);
			setTimeout(() => {
				window.addEventListener('keyup', this.escClose);
				window.addEventListener('mousedown', this.handleMouseDown);
			}, 400);
		} else {
			this.setState({ show: false });
			window.removeEventListener('keyup', this.escClose);
			window.removeEventListener('mousedown', this.handleMouseDown);
		}
	}

	escClose(e) {
		const { modal, updateModals } = this.props;
		if (e.keyCode === 27) updateModals(modal, false);
	}

	handleMouseDown(e) {
		const { modal, updateModals } = this.props;
		if (e.target.getAttribute('data-wrapper-close')) updateModals(modal, false);
	}

	render() {
		const { visible } = this.props;
		const { show } = this.state;
		const modal_class = show ? 'modal fade in' : 'modal fade';

		if (visible) {
			return (
				<div className={modal_class} styleName={modal_class} tabIndex="-1" role="dialog" data-wrapper-close>
					<div styleName="left-modal-dialog">
						{React.cloneElement(this.props.children, { updateModals: this.props.updateModals })}
					</div>
				</div>
			);
		}
		return null;
	}
}

LeftModalWrapper.propTypes = {
	visible: PropTypes.bool,
	updateModals: PropTypes.func.isRequired,
	modal: PropTypes.string.isRequired,
	children: PropTypes.object.isRequired,
};

LeftModalWrapper.defaultProps = {
	visible: false,
};

export default CSSModules(LeftModalWrapper, styles, { allowMultiple: true });
