import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import EmptyNoteSVG from '../../_parts/EmptyNoteSVG/EmptyNoteSVG';
import LabelCotacaoAtiva from './LabelCotacaoAtiva';
import LabelCotacaoExpirada from './LabelCotacaoExpirada';
import SolicitacoesRow from './SolicitacoesRow';
import Secao from './Secao';
import SecaoOportunidades from './SecaoOportunidades';
import ModalPortal from '../../_parts/_modals/ModalPortal/ModalPortal';
import ModalWrapperPortal from '../../_parts/_modals/ModalPortal/ModalWrapperPortal';
import ModalConfirm from '../../_parts/_modals/ModalConfirm/ModalConfirm';
import Alert from '../Review/Alert';

const Solicitacoes = ({
	/* state */
	request_state,
	request_state_expiradas,
	request_state_recusadas,
	request_state_canceladas,
	request_state_em_refinamento,
	list,
	list_expiradas,
	list_recusadas,
	list_canceladas,
	list_outras_opps,
	list_em_refinamento,
	section_em_refinamento,
	section_selecionadas,
	section_expiradas,
	section_recusadas,
	section_canceladas,
	section_outras_opps,
	modal_rc_duplicado,
	/* props */
	ui,
	/* funcs */
	openSection,
	criarRC,
	handleModalRCDuplicado,
}) => {
	const { alert_cotacao_enviada } = ui;
	const tem_oportunidades = list_outras_opps.length > 0;
	const first_col_class = tem_oportunidades
		? 'col-xs-12 col-lg-6'
		: 'col-xs-12 col-lg-8';
	const outras_oportunidades_class = tem_oportunidades
		? 'outras-oportunidades'
		: 'outras-oportunidades zero';
	let outras_oportunidades_title = list_outras_opps.length > 1
		? 'Outras oportunidades em aberto'
		: 'Outra oportunidade em aberto';
	if (list_outras_opps.length === 0) {
		outras_oportunidades_title = 'Nenhuma oportunidade em aberto no momento';
	}

	return (
		<React.Fragment>
			{alert_cotacao_enviada && <Alert />}
			{
				modal_rc_duplicado && (
					<ModalPortal>
						<ModalWrapperPortal closeModal={handleModalRCDuplicado}>
							<ModalConfirm
								title="Pedido cancelado"
								message="Este pedido foi cancelado pelo construtor e não está mais disponível."
								// successFunc={this.goToCriarCompraLocal}
								button_type="secondary"
								button_text="Entendi!" />
						</ModalWrapperPortal>
					</ModalPortal>
				)
			}
			{
				list_em_refinamento.length > 0 && (
					<div className="container" styleName="main-container first">
						<Secao
							name="Correções solicitadas"
							req_state={request_state_em_refinamento}
							section_open={section_em_refinamento}
							rcs={list_em_refinamento}
							openSection={openSection}>
							<SolicitacoesRow>
								<LabelCotacaoAtiva />
							</SolicitacoesRow>
						</Secao>
					</div>
				)
			}
			<div className="container" styleName="main-container first">
				{list.length > 0 ? (
					<Secao
						name="Solicitadas"
						req_state={request_state}
						section_open={section_selecionadas}
						rcs={list}
						openSection={openSection}
						legenda="Cotações em que sua resposta foi solicitada">
						<SolicitacoesRow>
							<LabelCotacaoAtiva />
						</SolicitacoesRow>
					</Secao>
				) : (
					<div className="row">
						<div className="col-xs-12" styleName="empty-all">
							<EmptyNoteSVG />
							<h3>Você não tem nenhuma nova solicitação</h3>
						</div>
					</div>
				)}
			</div>
			<div styleName="wrapper-outras-oportunidades">
				<div className="container" styleName="main-container oo">
					<div className="row" styleName="oo-row">
						<div className={first_col_class}>
							<div styleName={outras_oportunidades_class}>
								{tem_oportunidades && (
									<div styleName="oo-icon">{list_outras_opps.length}</div>
								)}
								<div styleName="oo-title">{outras_oportunidades_title}</div>
								<div styleName="oo-text">Com base no seu cadastro de insumos e área de atendimento, encontramos outras cotações que podem te interessar.</div>
							</div>
						</div>
						{tem_oportunidades && (
							<div className="col-xs-12 col-lg-6" styleName="oo-button-wrapper">
								{!section_outras_opps && (
									<button
										type="button"
										className="btn btn-primary btn-lg"
										styleName="oo-open-button"
										onClick={() => openSection('outras oportunidades')}>
										<i className="fa fa-angle-down fa-fw" aria-hidden="true" />
										Listar oportunidades
										<i className="fa fa-angle-down fa-fw" aria-hidden="true" />
									</button>
								)}
							</div>
						)}
					</div>
					<SecaoOportunidades
						section_open={section_outras_opps}
						rcs={list_outras_opps}>
						<SolicitacoesRow
							criarRC={criarRC}>
							<LabelCotacaoAtiva />
						</SolicitacoesRow>
					</SecaoOportunidades>
				</div>
			</div>
			<div className="container" styleName="main-container sec">
				<Secao
					name="expiradas"
					req_state={request_state_expiradas}
					section_open={section_expiradas}
					rcs={list_expiradas}
					openSection={openSection}>
					<SolicitacoesRow>
						<LabelCotacaoExpirada />
					</SolicitacoesRow>
				</Secao>

				<Secao
					name="recusadas"
					req_state={request_state_recusadas}
					section_open={section_recusadas}
					rcs={list_recusadas}
					openSection={openSection}>
					<SolicitacoesRow />
				</Secao>

				<Secao
					name="canceladas"
					req_state={request_state_canceladas}
					section_open={section_canceladas}
					rcs={list_canceladas}
					openSection={openSection}>
					<SolicitacoesRow />
				</Secao>
			</div>
		</React.Fragment>
	);
};

Solicitacoes.propTypes = {
	request_state: PropTypes.number.isRequired,
	request_state_expiradas: PropTypes.number.isRequired,
	request_state_canceladas: PropTypes.number.isRequired,
	request_state_recusadas: PropTypes.number.isRequired,
	request_state_em_refinamento: PropTypes.number.isRequired,
	list: PropTypes.array.isRequired,
	list_expiradas: PropTypes.array.isRequired,
	list_em_refinamento: PropTypes.array.isRequired,
	list_recusadas: PropTypes.array.isRequired,
	list_canceladas: PropTypes.array.isRequired,
	list_outras_opps: PropTypes.array.isRequired,
	section_em_refinamento: PropTypes.bool.isRequired,
	section_selecionadas: PropTypes.bool.isRequired,
	section_expiradas: PropTypes.bool.isRequired,
	section_recusadas: PropTypes.bool.isRequired,
	section_canceladas: PropTypes.bool.isRequired,
	section_outras_opps: PropTypes.bool.isRequired,
	modal_rc_duplicado: PropTypes.bool.isRequired,
	openSection: PropTypes.func.isRequired,
	criarRC: PropTypes.func.isRequired,
	handleModalRCDuplicado: PropTypes.func.isRequired,
	ui: PropTypes.object.isRequired,
};

export default CSSModules(Solicitacoes, styles, { allowMultiple: true });
