import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import shallowequal from 'shallowequal';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import InputSelectGeral from '../_inputs/InputSelectGeral/InputSelectGeral';
import InputNumber from '../_inputs/InputNumber/InputNumber';

// Functions
import { formatCurrency } from '../../_functions/_formatNumber';
import { clearItensAlerts } from '../../_functions/_clearItensAlerts';

// Content
import { formas_pagamento, prazos_pagamento } from '../../_content/_condicoes_pagamento';

class Condicao2 extends Component {
	static launchTopicCompraParcial(e) {
		e.preventDefault();
		if (window.inline_manual_player) {
			window.inline_manual_player.activateTopic(54353);
		}
	}

	constructor(props) {
		super(props);
		this.state = {
			outra_forma: false,
			outra_forma_value: '',
			outro_prazo: false,
			outro_prazo_value: '',
			desconto_value: props.condicao.desconto || 0,
			aceita_parcial: props.condicao.aceita_parcial !== null ? props.condicao.aceita_parcial : -1,
			compra_minima: props.condicao.compra_minima || 0,
		};
		this.handleAll = this.handleAll.bind(this);
		this.editOutraForma = this.editOutraForma.bind(this);
		this.editOutroPrazo = this.editOutroPrazo.bind(this);
		this.editDesconto = this.editDesconto.bind(this);
		this.editCompraMinima = this.editCompraMinima.bind(this);
		this.handleOutraForma = this.handleOutraForma.bind(this);
		this.handleOutroPrazo = this.handleOutroPrazo.bind(this);
		this.checkOutraForma = this.checkOutraForma.bind(this);
		this.checkOutroPrazo = this.checkOutroPrazo.bind(this);
	}

	UNSAFE_componentWillMount() {
		// this.setState({ desconto_value: this.props.condicao.desconto });
		this.checkOutro();
	}

	checkOutro() {
		const { forma, outra_forma, prazo, outro_prazo } = this.props.condicao;
		if (forma === -1 && outra_forma !== '') this.setState({ outra_forma: true, outra_forma_value: outra_forma });
		if (prazo === -1 && outro_prazo !== '') this.setState({ outro_prazo: true, outro_prazo_value: outro_prazo });
	}

	handleAll(field, opcao) {
		const { condicao, condicoes_de_pagamento_by_id, updateCotacao, autoSave } = this.props;
		const _condicao = { ...condicao, [field]: opcao.id };
		const _condicoes_de_pagamento_by_id = { ...condicoes_de_pagamento_by_id, [condicao.id]: _condicao };
		updateCotacao(['rc', 'condicoes_de_pagamento_by_id'], _condicoes_de_pagamento_by_id);
		autoSave(_condicao);
		clearItensAlerts();
	}

	editOutraForma(e) {
		const outra_forma_value = e.target.value.toString();
		this.setState({ outra_forma_value });
		this.saveEditReduxTimeout();
	}

	editOutroPrazo(e) {
		const outro_prazo_value = e.target.value.toString();
		this.setState({ outro_prazo_value });
		this.saveEditReduxTimeout();
	}

	editDesconto(field, desconto_value) {
		this.setState({ desconto_value });
		this.saveEditReduxTimeout();
	}

	editCompraParcial(aceita_parcial) {
		this.setState({ aceita_parcial });
		this.saveEditReduxTimeout();
	}

	editCompraMinima(field, compra_minima) {
		this.setState({ compra_minima });
		this.saveEditReduxTimeout();
	}

	saveEditReduxTimeout() {
		clearItensAlerts();
		window.clearTimeout(this.save_timeout);
		this.save_timeout = window.setTimeout(() => this.saveEditRedux(), 500);
	}

	saveEditRedux() {
		const {
			condicao,
			condicoes_de_pagamento_by_id,
			updateCotacao,
			autoSave,
		} = this.props;
		const {
			outra_forma,
			outra_forma_value,
			outro_prazo,
			outro_prazo_value,
			desconto_value,
		} = this.state;
		const aceita_parcial = this.state.aceita_parcial !== null
			? this.state.aceita_parcial
			: -1;
		const compra_minima = this.state.compra_minima || 0;
		const _condicao = {
			...condicao,
			desconto: desconto_value,
			forma: !outra_forma ? condicao.forma : -1,
			outra_forma: !outra_forma ? '' : outra_forma_value,
			prazo: !outro_prazo ? condicao.prazo : -1,
			outro_prazo: !outro_prazo ? '' : outro_prazo_value,
		};
		if (aceita_parcial !== -1) _condicao.aceita_parcial = aceita_parcial;
		if (compra_minima >= 0) _condicao.compra_minima = compra_minima;
		const _condicoes_de_pagamento_by_id = { ...condicoes_de_pagamento_by_id, [condicao.id]: _condicao };
		updateCotacao(['rc', 'condicoes_de_pagamento_by_id'], _condicoes_de_pagamento_by_id);
		autoSave(_condicao);
	}

	handleOutraForma() {
		const outra_forma = !this.state.outra_forma;
		const { condicao, condicoes_de_pagamento_by_id, updateCotacao } = this.props;
		const _condicao = { ...condicao, forma: -1, outra_forma: '' };
		const _condicoes_de_pagamento_by_id = { ...condicoes_de_pagamento_by_id, [condicao.id]: _condicao };
		updateCotacao(['rc', 'condicoes_de_pagamento_by_id'], _condicoes_de_pagamento_by_id);
		if (outra_forma) setTimeout(() => document.getElementById(`outra_forma_${condicao.id}`).focus(), 60);
		this.setState({ outra_forma });
	}

	handleOutroPrazo() {
		const outro_prazo = !this.state.outro_prazo;
		const { condicao, condicoes_de_pagamento_by_id, updateCotacao } = this.props;
		const _condicao = { ...condicao, prazo: -1, outro_prazo: '' };
		const _condicoes_de_pagamento_by_id = { ...condicoes_de_pagamento_by_id, [condicao.id]: _condicao };
		updateCotacao(['rc', 'condicoes_de_pagamento_by_id'], _condicoes_de_pagamento_by_id);
		if (outro_prazo) setTimeout(() => document.getElementById(`outro_prazo_${condicao.id}`).focus(), 60);
		this.setState({ outro_prazo });
	}

	checkOutraForma() {
		const { outra_forma, outra_forma_value } = this.state;
		if (outra_forma && outra_forma_value === '') this.handleOutraForma();
	}

	checkOutroPrazo() {
		const { outro_prazo, outro_prazo_value } = this.state;
		if (outro_prazo && outro_prazo_value === '') this.handleOutroPrazo();
	}

	render() {
		const {
			outra_forma,
			outra_forma_value,
			outro_prazo,
			outro_prazo_value,
			desconto_value,
			aceita_parcial,
			compra_minima,
		} = this.state;
		const {
			order,
			condicao,
			valor_final_itens,
			custos_adicionais,
			// valor_final,
			valor_transporte,
			condicoes_de_pagamento_by_id,
			deleteCondicaoPagamento,
			tip_compra_parcial,
			showTip,
		} = this.props;

		// forma
		const forma_opcoes = Object.keys(formas_pagamento).map(id => ({
			id: formas_pagamento[id].value,
			valor: formas_pagamento[id].view,
		})).filter(f => f.id !== -1 && f.id !== 0);
		const forma_selecionada = condicao.forma > 0 ? formas_pagamento[condicao.forma].view : '';
		// prazo
		const prazo_opcoes = Object.keys(prazos_pagamento)
			.map(id => ({
				id: prazos_pagamento[id].value,
				valor: prazos_pagamento[id].view,
				order: prazos_pagamento[id].order,
			}))
			.filter(p => p.id !== -1 && p.id !== 0)
			.sort((a, b) => a.order - b.order);

		const prazo_selecionado = condicao.prazo > 0 ? prazos_pagamento[condicao.prazo].view : '';

		// valor final
		// const valor_final_desconto = (valor_final - ((valor_final / 100) * desconto_value)) + valor_transporte;
		const valor_desconto = (valor_final_itens / 100) * desconto_value;
		const valor_final_desconto = (valor_final_itens - valor_desconto) + valor_transporte + custos_adicionais;

		const primeira_condicao_id = Object.keys(condicoes_de_pagamento_by_id).map(id => Number(id))[0];

		const link_tip_compra_parcial_class = tip_compra_parcial === condicao.id
			? { fontSize: '13px' }
			: { fontSize: '13px', color: '#949aa5' };

		return (
			<div id="condicao_wrapper" styleName="condicao-wrapper">
				<div styleName="left-content">
					<div styleName="forma">
						<div id={`tooltip_condicao_pagamento_${condicao.id}`} className="tooltip top" role="tooltip" style={{ marginTop: '-54px' }}>
							<div className="tooltip-arrow" style={{ borderTopColor: '#ee2622', left: '10%' }} />
							<div className="tooltip-inner" style={{ backgroundColor: '#ee2622' }}>Preencha todas as condições de pagamento</div>
						</div>
						<label>Forma de pagamento <span className="obrigatorio-label">*</span></label>
						{!outra_forma ? (
							<InputSelectGeral
								field="forma"
								default_value={forma_selecionada}
								opcoes={forma_opcoes}
								updateFunction={this.handleAll}
								handleOutro={this.handleOutraForma} />
						) : (
							<input
								id={`outra_forma_${condicao.id}`}
								type="text"
								className="form-control input-md"
								value={outra_forma_value}
								onChange={this.editOutraForma}
								onBlur={this.checkOutraForma} />
						)}
					</div>
					<div styleName="prazo">
						<label>Prazo <span className="obrigatorio-label">*</span></label>
						{!outro_prazo ? (
							<InputSelectGeral
								field="prazo"
								default_value={prazo_selecionado}
								opcoes={prazo_opcoes}
								updateFunction={this.handleAll}
								handleOutro={this.handleOutroPrazo} />
						) : (
							<input
								id={`outro_prazo_${condicao.id}`}
								type="text"
								className="form-control input-md"
								value={outro_prazo_value}
								onChange={this.editOutroPrazo}
								onBlur={this.checkOutroPrazo} />
						)}
					</div>
					<div styleName="desconto with-icon">
						<label id={`desconto1_compra_parcial_onboarding_${order}`} style={{ paddingRight: '4px' }}>Desconto (em %)</label>
						{condicao.id !== primeira_condicao_id && (
							<button type="button" styleName="delete" onClick={() => deleteCondicaoPagamento(condicao)}><i className="fa fa-trash" /></button>
						)}
						<InputNumber
							type="cotacao"
							field_id={`desconto2_compra_parcial_onboarding_${order}`}
							field="desconto"
							default_value={desconto_value}
							updateFunction={this.editDesconto} />
					</div>
					<div styleName="compra-parcial">
						<div id={`tooltip_compra_parcial_${condicao.id}`} className="tooltip top" role="tooltip" style={{ marginTop: '-54px' }}>
							<div className="tooltip-arrow" style={{ borderTopColor: '#ee2622', left: '10%' }} />
							<div className="tooltip-inner" style={{ backgroundColor: '#ee2622' }}>Preencha todas as condições de pagamento</div>
						</div>
						<label id={`label_compra_parcial_onboarding_${order}`}>Aceita compra parcial? <span className="obrigatorio-label">*</span></label>
						<div styleName="compra-parcial-options">
							<button
								type="button"
								styleName={aceita_parcial === 1 ? 'checkbox-button selected' : 'checkbox-button'}
								onClick={() => this.editCompraParcial(1)}>
								<i className="fa fa-check-circle" styleName="check" />
								<i className="fa fa-circle" styleName="circle" />
								Sim
							</button>
							<button
								type="button"
								styleName={aceita_parcial === 0 ? 'checkbox-button selected' : 'checkbox-button'}
								onClick={() => this.editCompraParcial(0)}>
								<i className="fa fa-check-circle" styleName="check" />
								<i className="fa fa-circle" styleName="circle" />
								Não
							</button>
							<button
								id={`depende_compra_parcial_onboarding_${order}`}
								type="button"
								styleName={aceita_parcial === 2 ? 'checkbox-button selected' : 'checkbox-button'}
								onClick={() => this.editCompraParcial(2)}>
								<i className="fa fa-check-circle" styleName="check" />
								<i className="fa fa-circle" styleName="circle" />
								Depende
							</button>
						</div>
						{tip_compra_parcial === condicao.id && (
							<div styleName="onboarding-wrapper">
								<div styleName="message-shadow">
									<div styleName="message">
										<h4>Compra parcial</h4>
										<p>Quando o comprador reduz o pedido (diminuindo quantidades ou retirando itens) no momento da compra.</p>
										<div styleName="footer">
											<button
												type="button"
												className="btn btn-secondary-conaz round-border"
												onClick={e => showTip(e, -1)}>
												Entendi!
											</button>
										</div>
									</div>
								</div>
							</div>
						)}
						<a
							href="#"
							style={link_tip_compra_parcial_class}
							// onClick={e => showTip(e, condicao.id)}
							onClick={e => this.constructor.launchTopicCompraParcial(e)}>
							Saiba o que é compra parcial
						</a>
					</div>
					{aceita_parcial === 2 && (
						<div styleName="valor-minimo">
							<div id={`tooltip_valor_minimo_${condicao.id}`} className="tooltip top" role="tooltip" style={{ marginTop: '-54px' }}>
								<div className="tooltip-arrow" style={{ borderTopColor: '#ee2622', left: '10%' }} />
								<div className="tooltip-inner" style={{ backgroundColor: '#ee2622' }}>Preencha o valor mínimo para uma compra</div>
							</div>
							<label>Qual o valor mínimo para você manter a condição acima?</label>
							<div style={{ maxWidth: '230px' }}>
								<InputNumber
									type="cotacao"
									field={['rc', 'custos_adicionais']}
									default_value={compra_minima}
									updateFunction={this.editCompraMinima}
									currency />
							</div>
						</div>
					)}
				</div>
				<div styleName="valor">
					<label>Subtotal</label>
					<div style={{ marginBottom: '6px' }}>{formatCurrency(valor_final_itens - valor_desconto, 'R$')}</div>
					<label>Adicionais + Transporte</label>
					<div>{formatCurrency(custos_adicionais + valor_transporte, 'R$')}</div>
					<div style={{ color: '#707376' }}>--------------------------</div>
					<b>{formatCurrency(valor_final_desconto, 'R$')}</b>
				</div>
			</div>
		);
	}
}

Condicao2.propTypes = {
	order: PropTypes.number.isRequired,
	condicao: PropTypes.object.isRequired,
	condicoes_de_pagamento_by_id: PropTypes.object.isRequired,
	updateCotacao: PropTypes.func.isRequired,
	autoSave: PropTypes.func.isRequired,
	deleteCondicaoPagamento: PropTypes.func.isRequired,
	valor_final_itens: PropTypes.number.isRequired,
	custos_adicionais: PropTypes.number.isRequired,
	// valor_final: PropTypes.number.isRequired,
	valor_transporte: PropTypes.number.isRequired,
	tip_compra_parcial: PropTypes.number.isRequired,
	showTip: PropTypes.func.isRequired,
};

export default CSSModules(Condicao2, styles, { allowMultiple: true });
