import React from 'react';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const EnviadasTitleRow = () => (
	<div className="row" styleName="pedido-titles-row">
		<div className="col-xs-12 col-sm-2 col-md-1" styleName="pedido-row-padding" style={{ textAlign: 'center', paddingRight: '0', paddingLeft: '0' }}>Enviado em</div>
		<div className="col-xs-12 col-sm-3 col-md-4" styleName="pedido-row-padding">Título</div>
		<div className="col-xs-12 col-sm-2 col-md-1" styleName="pedido-row-padding" style={{ textAlign: 'center' }}>Código</div>
		<div className="col-xs-12 col-sm-2 col-md-2" styleName="pedido-row-padding" style={{ textAlign: 'center' }}>Entrega</div>
		<div className="col-xs-12 col-sm-2 col-md-4" styleName="pedido-row-padding">Valor</div>
	</div>
);


export default CSSModules(EnviadasTitleRow, styles, { allowMultiple: true });
