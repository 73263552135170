import React from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import {
	getRCEA,
	getRCAC,
	getRCCE,
	getRCSS,
	getRCCC,
	getRCRecusado,
} from '../../../services/apis/rcs';

export default function Pagination({
	tabs,
	tab_selected,
	updateUi,
	current_tab,
	setLoading,
}) {
	const { pagination_count, current_page_link, number } = tab_selected;
	function getRcsByPage({ selected }) {
		const _current_tab = current_tab;
		const requestArray = [
			getRCEA,
			getRCAC,
			getRCCE,
			getRCSS,
			getRCCC,
			getRCRecusado,
		];
		setLoading[tab_selected.index](true);
		const request = requestArray[number](selected + 1);

		request.then(({ data }) => {
			const { result, page } = data;
			_current_tab.current_page_rcs = result;
			_current_tab.called = true;
			_current_tab.pagination_count = Math.ceil(page.total / page.per_page);
			_current_tab.current_page_link = selected;
			updateUi(['tabs'], { ...tabs, [_current_tab.index]: _current_tab });
			setLoading[tab_selected.index](false);
		});
	}

	return (
		<ReactPaginate
			pageCount={pagination_count}
			forcePage={current_page_link}
			marginPagesDisplayed={1}
			pageRangeDisplayed={2}
			nextLabel={<i className="fa fa-chevron-right" />}
			previousLabel={<i className="fa fa-chevron-left" />}
			breakLabel="..."
			onPageChange={selected => getRcsByPage(selected)}
			containerClassName="pagination"
			pageLinkClassName="all-pages"
			breakLinkClassName="all-pages"
			activeLinkClassName="current-page"
			nextLinkClassName="all-pages"
			previousLinkClassName="all-pages" />
	);
}

Pagination.propTypes = {
	tabs: PropTypes.object.isRequired,
	tab_selected: PropTypes.object.isRequired,
	updateUi: PropTypes.func.isRequired,
	current_tab: PropTypes.object.isRequired,
	setLoading: PropTypes.object.isRequired,
};
