import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../../actions/index';

// Components
import SelecionaGrupos from './SelecionaGrupos';
import Wrapper from '../../Wrapper/Wrapper';

// Functions
import { getList as getGrupos } from '../../../services/apis/grupos';
import { editarFamilias } from '../../../services/apis/fornecedores_vendedores';
import { lockBody } from '../../_functions/_lockBody';
import { scrollToCenter } from '../../_functions/_scrollToCenter';
import { removeDuplicates } from '../../_functions/_arrayUtil';

class SelecionaGruposContainer extends Component {
	constructor() {
		super();
		this.state = {
			search_type: '',
			search_result: [],
			enviando: false,
			modal_error: false,
			page_locked: false,
			success: false,
			message: '',
			errorTitle: '',
		};
		this.editFamilias = this.editFamilias.bind(this);
		this.handleModal = this.handleModal.bind(this);
		this.submitGrupos = this.submitGrupos.bind(this);
		this.handleSearchType = this.handleSearchType.bind(this);
		this.limpaResultadosBusca = this.limpaResultadosBusca.bind(this);
		this.filterByGruposPais = this.filterByGruposPais.bind(this);
		this.retornaGrupos = this.retornaGrupos.bind(this);
		this.onClickVoltarButton = this.onClickVoltarButton.bind(this);
		this.limparGrupos = this.limparGrupos.bind(this);
	}

	componentDidMount() {
		if (!this.props.cadastro.id && this.props.cadastro.loaded) {
			this.props.history.replace('/empresas');
		}
	}

	UNSAFE_componentWillReceiveProps(newProps) {
		if (!newProps.cadastro.id && newProps.cadastro.loaded) {
			this.props.history.replace('/empresas');
		}
	}

	componentWillUnmount() {
		this.props.updateCadastro(['grupos'], {});
	}

	onClickVoltarButton() {
		const boas_vindas = this.props.match.path === '/boas-vindas-2';
		const path_to_back = boas_vindas ? '/boas-vindas' : '/empresas';
		this.props.history.push(path_to_back);
	}

	retornaGrupos(responses) {
		const response = responses[0];
		this.props.updateCadastro(['all_grupos'], response.data);
		this.montaEstruturaInicial();
		this.retornaAccountData();
	}

	montaEstruturaInicial() {
		const _all_grupos = this.props.cadastro.all_grupos || [];
		const grupos_new = _all_grupos.filter(grupo => grupo.grupos_pais.length === 0)
			.map((grupo, g) => ({
				key: g,
				id: grupo.id,
				nome: grupo.nome,
				selected: false,
				subgrupos: [],
			}));
		this.props.updateCadastro(['grupos'], grupos_new);
	}

	retornaAccountData() {
		const { id, empresas, grupos_ids_outros_vendedores } = this.props.cadastro;
		const fornecedor_selecionado = (empresas[id] || {});
		const grupos_salvos = fornecedor_selecionado.grupos || [];
		const grupos_ids = grupos_salvos.map(g => g.grupo.id).concat(grupos_ids_outros_vendedores);
		this.preencheGrupos(removeDuplicates(grupos_ids));
	}

	handleModal(modal, value) {
		this.setState({ [modal]: value, page_locked: value });
		lockBody(value);
		if (this.state.success) {
			this.props.history.push('/empresas');
		}
	}

	limparGrupos() {
		this.montaEstruturaInicial();
		setTimeout(() => {
			this.preencheGrupos([]);
		}, 100);
	}

	limpaResultadosBusca(keys) {
		this.setState({ search_result: [], search_type: '' });
		setTimeout(() => {
			const el = document.getElementById(keys.join('_'));
			scrollToCenter(el);
		}, 100);
	}

	handleSearchType(e) {
		const { name, value } = e.target;
		const { grupos } = this.props.cadastro;
		const search_result_obj = {};
		const value_lowercase = value.toLowerCase().trim();

		if (value_lowercase === '') {
			this.setState({ [name]: value, search_result: [] });
			return;
		}
		grupos.forEach((grupo, g) => {
			if (grupo.nome.toLowerCase().indexOf(value) !== -1) {
				grupo.subgrupos.forEach((subgrupo, sg) => {
					subgrupo.familias.forEach((familia, f) => {
						search_result_obj[familia.id] = {
							label: familia.nome,
							bottom_label: [grupo.nome, subgrupo.nome],
							keys: [g, sg, f],
							selected: familia.selected,
							id: familia.id,
						};
					});
				});
			}
			grupo.subgrupos.forEach((subgrupo, sg) => {
				if (subgrupo.nome.toLowerCase().indexOf(value_lowercase) !== -1) {
					subgrupo.familias.forEach((familia, f) => {
						search_result_obj[familia.id] = {
							label: familia.nome,
							bottom_label: [grupo.nome, subgrupo.nome],
							keys: [g, sg, f],
							selected: familia.selected,
							id: familia.id,
						};
					});
				}
				subgrupo.familias.forEach((familia, f) => {
					if (familia.nome.toLowerCase().indexOf(value_lowercase) !== -1) {
						search_result_obj[familia.id] = {
							label: familia.nome,
							bottom_label: [grupo.nome, subgrupo.nome],
							keys: [g, sg, f],
							selected: familia.selected,
							id: familia.id,
						};
					}
				});
			});
		});
		const _search_result = Object.keys(search_result_obj).map(f_id => search_result_obj[f_id]);
		_search_result.sort((a, b) => a.label.localeCompare(b.label));
		const all_words = value_lowercase.split(' ');

		// coloca negrito
		all_words.forEach(word => {
			_search_result.forEach((result, i) => {
				const { bottom_label, label } = _search_result[i];
				const search_label = label.search(new RegExp(word, 'i'));
				let label_front = label;
				if (search_label !== -1) {
					label_front = label.replace(new RegExp(word, 'i'), `<strong>${label.substr(search_label, word.length)}</strong>`);
				}
				const _bottom_label_front = [];

				bottom_label.forEach(blabel => {
					const search_blabel = blabel.search(new RegExp(word, 'i'));
					if (search_blabel !== -1) {
						_bottom_label_front.push(blabel.replace(new RegExp(word, 'i'), `<strong>${blabel.substr(search_blabel, word.length)}</strong>`));
					} else {
						_bottom_label_front.push(blabel);
					}
				});
				const bottom_label_front = _bottom_label_front.join(' / ');
				_search_result[i].label_front = `<React.Fragment>${label_front}<br /><small>${bottom_label_front}</small></React.Fragment>`;
			});
		});

		// arruma ordenacao
		const search_result = [];
		const l1 = [];
		const l2 = [];
		const l3 = [];
		const l4 = [];
		_search_result.slice().forEach(result => {
			const first_word_result = result.label.split(' ')[0];
			if (first_word_result.search(new RegExp(`\\b${all_words[0]}`, 'i')) !== -1) {
				l1.push(result);
			} else if (result.label.search(new RegExp(all_words[0], 'i')) !== -1) {
				l2.push(result);
			} else if (result.bottom_label[0].search(new RegExp(all_words[0], 'i')) !== -1) {
				l3.push(result);
			} else {
				l4.push(result);
			}
		});
		search_result.push(...l1, ...l2, ...l3, ...l4);
		this.setState({ [name]: value, search_result });
	}

	filterByGruposPais(id_pai) {
		const { all_grupos } = this.props.cadastro;
		const grupos = all_grupos.filter(g => g.grupos_pais.map(gp => gp.id).indexOf(id_pai) !== -1);
		return grupos.sort((a, b) => a.nome.localeCompare(b.nome));
	}

	editFamilias(keys, ativa_grupos = false) {
		const _grupos = (this.props.cadastro || {}).grupos || [];
		const grupos_new = [..._grupos];

		switch (keys.length) {
		case 1: {
			grupos_new[keys[0]].selected = !grupos_new[keys[0]].selected;
			this.props.updateCadastro(['grupos'], grupos_new);
			break;
		}
		case 2: {
			grupos_new[keys[0]].subgrupos[keys[1]].selected = !grupos_new[keys[0]].subgrupos[keys[1]].selected;
			this.props.updateCadastro(['grupos'], grupos_new);
			break;
		}
		case 3: {
			if (ativa_grupos) {
				grupos_new[keys[0]].selected = true;
				grupos_new[keys[0]].subgrupos[keys[1]].selected = true;
				grupos_new[keys[0]].subgrupos[keys[1]].familias[keys[2]].selected = true;
			} else {
				grupos_new[keys[0]].subgrupos[keys[1]].familias[keys[2]].selected = !grupos_new[keys[0]].subgrupos[keys[1]].familias[keys[2]].selected;
			}
			const new_id = grupos_new[keys[0]].subgrupos[keys[1]].familias[keys[2]].id;
			const { selected } = grupos_new[keys[0]].subgrupos[keys[1]].familias[keys[2]];
			const grupos_new_3 = grupos_new
				.map(grupo => {
					grupo.subgrupos
						.map(subgrupo => {
							subgrupo.familias
								.map(familia => {
									const _familia = familia;
									if (familia.id === new_id) { _familia.selected = selected; }
									return _familia;
								});
							return subgrupo;
						});
					return grupo;
				});

			const { search_result } = this.state;
			search_result.forEach((result, i) => {
				if (result.id === new_id) {
					search_result[i].selected = selected;
				}
			});
			this.setState({ search_result });
			this.props.updateCadastro(['grupos'], grupos_new_3);
			break;
		}
		default:
		}
	}

	preencheGrupos(grupos_ids) {
		const _grupos = (this.props.cadastro || {}).grupos || [];
		const grupos_new = [..._grupos];
		for (let i = 0; i < grupos_new.length; i++) {
			if (grupos_new[i].subgrupos.length === 0) {
				const subgrupos = this.filterByGruposPais(grupos_new[i].id);
				const subgrupos_new = subgrupos
					.map((subgrupo, sg) => ({
						key: sg,
						id: subgrupo.id,
						nome: subgrupo.nome,
						selected: false,
						familias: [],
					}));
				grupos_new[i].subgrupos = subgrupos_new;
			}
			for (let j = 0; j < grupos_new[i].subgrupos.length; j++) {
				if (grupos_new[i].subgrupos[j].familias.length === 0) {
					const familias = this.filterByGruposPais(grupos_new[i].subgrupos[j].id);

					const familias_new = familias
						.map((familia, f) => ({
							key: f,
							id: familia.id,
							nome: familia.nome,
							selected: false,
						}));
					grupos_new[i].subgrupos[j].familias = familias_new;
				}
				for (let k = 0; k < grupos_new[i].subgrupos[j].familias.length; k++) {
					if (grupos_ids.indexOf(grupos_new[i].subgrupos[j].familias[k].id) !== -1) {
						grupos_new[i].selected = true;
						grupos_new[i].subgrupos[j].selected = true;
						grupos_new[i].subgrupos[j].familias[k].selected = true;
					}
				}
			}
		}
		this.props.updateCadastro(['grupos'], grupos_new);
	}

	pegaFamiliasSelecionadas() {
		const familias_to_send = [];
		const { grupos } = this.props.cadastro;
		for (let g = 0; g < grupos.length; g++) {
			for (let sg = 0; sg < grupos[g].subgrupos.length; sg++) {
				for (let f = 0; f < grupos[g].subgrupos[sg].familias.length; f++) {
					if (grupos[g].subgrupos[sg].familias[f].selected) {
						familias_to_send.push(grupos[g].subgrupos[sg].familias[f].id);
					}
				}
			}
		}
		return familias_to_send;
	}

	submitGrupos() {
		const grupos_ids = this.pegaFamiliasSelecionadas();

		if (grupos_ids.length === 0) {
			this.setState({ success: false, message: 'Selecione ao menos uma família de mercado' });
		} else {
			this.setState({ enviando: true, message: '', success: false, errorTitle: '' });

			const boas_vindas = this.props.match.path === '/boas-vindas-2';
			const { id, empresas } = this.props.cadastro;
			const data = grupos_ids.map(grupo => ({ grupo_id: grupo }));
			const fornecedor_selecionado = (empresas[id] || {});
			const relacao_id = fornecedor_selecionado.id;

			editarFamilias(relacao_id, 1, data)
				.then(response => {
					this.props.updateCadastro(['empresas'], { ...this.props.cadastro.empresas, [response.data.fornecedor.id]: response.data });
					this.props.updateCadastro(['grupos_ids_outros_vendedores'], []);
					const message = `Famílias de mercado salvas com sucesso!${boas_vindas ? ' Redirecionando para suas cotações em aberto...' : ''}`;

					if (boas_vindas) {
						this.setState({ enviando: false, success: true, message });
						setTimeout(() => {
							this.setState({ message: '' });
							this.props.history.push('/solicitacoes');
						}, 3000);
					} else {
						this.setState({
							success: true,
							modal_error: true,
							enviando: false,
							errorTitle: 'Sucesso',
							message,
						});
					}
				})
				.catch(() => {
					this.setState({
						enviando: false,
						modal_error: true,
						success: false,
						message: '',
						errorTitle: '',
					});
				});
		}
	}

	render() {
		const { grupos } = this.props.cadastro;
		return (
			<Wrapper
				loaded={grupos.length > 0}
				promises={[() => getGrupos()]}
				buildResponse={this.retornaGrupos}
				{...this.props}>
				<SelecionaGrupos
					{...this.state}
					{...this.props}
					editFamilias={this.editFamilias}
					updateState={this.updateState}
					handleModal={this.handleModal}
					submitGrupos={this.submitGrupos}
					handleSearchType={this.handleSearchType}
					limpaResultadosBusca={this.limpaResultadosBusca}
					onClickVoltarButton={this.onClickVoltarButton}
					limparGrupos={this.limparGrupos} />
			</Wrapper>
		);
	}
}

const mapStateToProps = props => ({
	cadastro: props.cadastro,
	ui: props.ui,
});

const mapDispatchToProps = dispatch => ({
	resetCadastro: () => dispatch(actions.resetCadastro()),
	updateCadastro: (field, value) => dispatch(actions.updateCadastro(field, value)),
	updateUi: (field, value) => dispatch(actions.updateUi(field, value)),
	updateModals: (modal, value) => dispatch(actions.updateModals(modal, value)),
	reportErrors: (page, error, codigo_pedido, full_error, test) => dispatch(actions.reportErrors(page, error, codigo_pedido, full_error, test)),
});

SelecionaGruposContainer.propTypes = {
	cadastro: PropTypes.object.isRequired,
	updateCadastro: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelecionaGruposContainer);
