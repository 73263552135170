/* eslint-disable */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// Import Components
import Layout from './hoc/Layout/Layout';
import AllRedirects from './AllRedirects';
import NotFound404Container from './components/NotFound404/NotFound404Container';

// cadastro
import CadastroContainer from './components/CadastroFornecedor/Cadastro/CadastroContainer';
import CadastroNormalContainer from './components/CadastroFornecedor/Cadastro/CadastroNormalContainer';
import CadastroComFornecedorContainer from './components/CadastroFornecedor/Cadastro/CadastroComFornecedorContainer';
import SelecionaLocaisEntregaContainer from './components/CadastroFornecedor/BoasVindas/SelecionaLocaisEntregaContainer';
import SelecionaGruposContainer from './components/CadastroFornecedor/BoasVindas/SelecionaGruposContainer';
import ConfirmarCadastroContainer from './components/CadastroFornecedor/ConfirmarCadastro/ConfirmarCadastroContainer';
// cancelamento
import Unsubscribe from './components/Unsubscribe/Unsubscribe';

// edicao
import ListarEmpresasContainer from './components/CadastroFornecedor/Edicao/ListarEmpresasContainer';
import EditarEmpresaContainer from './components/CadastroFornecedor/Edicao/EditarEmpresaContainer'
import EditarContaContainer from './components/CadastroFornecedor/Edicao/EditarContaContainer';
import AlterarSenhaContainer from './components/CadastroFornecedor/Edicao/AlterarSenhaContainer';

// portal
import SolicitacoesContainer from './components/Portal/Solicitacoes/SolicitacoesContainer';
import Login from './components/Portal/Login/Login';
import EsqueciMinhaSenha from './components/Portal/EsqueciMinhaSenha/EsqueciMinhaSenha';
import RecuperarSenha from './components/Portal/EsqueciMinhaSenha/RecuperarSenha';
import ReviewContainer from './components/Portal/Review/ReviewContainer';
import ReviewSemRCContainer from './components/Portal/Review/ReviewSemRCContainer';
import EnviadasContainer from './components/Portal/Enviadas/EnviadasContainer';
import ConfirmaCotacaoContainerNovo from './components/Portal/ConfirmaCotacao/ConfirmaCotacaoContainerNovo';
import LogarComoUsuario from './components/Portal/LogarComoUsuario/LogarComoUsuario';

// avaliacoes
import Avaliacoes from './components/Avaliacoes';

// cotacao online
import PreviewContainer from './components/Cotacao/Preview/PreviewContainer';
import ItensContainer from './components/Cotacao/Itens/ItensContainer';
import DadosFinaisContainer from './components/Cotacao/DadosFinais/DadosFinaisContainer';
import IdentificacaoVendedorContainer from './components/Cotacao/IdentificacaoVendedor/IdentificacaoVendedorContainer';
import Empresa from './components/Cotacao/Empresa';
import RevisaoContainer from './components/Cotacao/Revisao/RevisaoContainer';

import Impressao from './components/Impressao';

import { isAuthenticated } from './services/login_manager';

const UnauthenticatedRoute = ({ component: Component, ...rest }) => (
	<Route {...rest} render={props => (
		!isAuthenticated() ? (
			<Component {...props} />
		) : (
			<Redirect to={{
				pathname: '/solicitacoes',
			}} />
		)
	)} />
);

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
	const { checa_fornecedor_vendedor } = rest;

	return (
		<Route {...rest} render={props => {
			if (!isAuthenticated()) {
				return <Redirect to={{ pathname: '/login', search: rest.location.search }} />;
			}
			return <Component {...props} />;
			}
		} />
	);
}

const App = () => (
	<Layout>
		<Switch>
			<Route path="/venda/:id/ver" exact component={ReviewSemRCContainer} />
			<Route path="/cotacao/:id/revisao" exact component={RevisaoContainer} />
			<Route path="/cotacao/:id/empresa" exact component={Empresa} />
			<Route path="/cotacao/:id/identificacao" exact component={IdentificacaoVendedorContainer} />
			<Route path="/cotacao/:id/dadosfinais" exact component={DadosFinaisContainer} />
			<Route path="/cotacao/:id/itens" exact component={ItensContainer} />
			<Route path="/cotacao/:id" exact component={PreviewContainer} />
			<Route path="/proposta/:id" exact component={AllRedirects} />
			<Route path="/cadastro" exact component={CadastroContainer} />
			<Route path="/cadastro/2" exact component={CadastroNormalContainer} />
			<Route path="/cadastro/3" exact component={CadastroComFornecedorContainer} />
			<AuthenticatedRoute path="/avaliacoes/:compra_id" exact component={Avaliacoes} />
			<AuthenticatedRoute path="/avaliacoes" exact component={Avaliacoes} />
			<UnauthenticatedRoute path="/login" exact component={Login} />
			<UnauthenticatedRoute path="/esqueci-minha-senha" exact component={EsqueciMinhaSenha} />
			<UnauthenticatedRoute path="/recuperar-senha/:token" exact component={RecuperarSenha} />
			<AuthenticatedRoute path="/boas-vindas" exact component={SelecionaLocaisEntregaContainer} />
			<AuthenticatedRoute path="/boas-vindas-2" exact component={SelecionaGruposContainer} />
			<AuthenticatedRoute path="/editar-entrega" exact component={SelecionaLocaisEntregaContainer} />
			<AuthenticatedRoute path="/editar-familias" exact component={SelecionaGruposContainer} />
			<AuthenticatedRoute path="/confirmar-cadastro" exact component={ConfirmarCadastroContainer} />
			<AuthenticatedRoute path="/solicitacoes" exact component={SolicitacoesContainer} />
			<AuthenticatedRoute path="/propostas/enviadas" exact component={EnviadasContainer} />
			<AuthenticatedRoute path="/empresas" exact component={ListarEmpresasContainer} />
			<AuthenticatedRoute path="/editar-empresa" exact component={EditarEmpresaContainer} />
			<AuthenticatedRoute path="/minha-conta" exact component={EditarContaContainer} />
			<AuthenticatedRoute path="/alterar-senha" exact component={AlterarSenhaContainer} />
			<Route path="/cancelar-recebimento" exact component={Unsubscribe} />
			<Route path="/proposta/:id/ver" exact component={ReviewContainer} />
			<Route path="/proposta/:id/ver/:compra_id" exact component={ReviewContainer} />
			<AuthenticatedRoute path="/c/:id/:link_boas_vindas" component={ConfirmaCotacaoContainerNovo} />
			<AuthenticatedRoute path="/c/:id" component={ConfirmaCotacaoContainerNovo} />
			<AuthenticatedRoute path="/impressao/:id" component={Impressao} />
			<AuthenticatedRoute path="/c" component={ConfirmaCotacaoContainerNovo} />
			<Route path="/logar_como_usuario/:token" component={LogarComoUsuario} />

			{/* 404 */}
			<AuthenticatedRoute path="/" exact component={SolicitacoesContainer} />
			<Route path="*" component={NotFound404Container} />
		</Switch>
	</Layout>
);

export default App;
