import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as actions from '../../../actions/index';

// Components
import withLoadCotacao from '../withLoadCotacao';
import withDocumentWidth from '../../../hoc/DocumentWidh/withDocumentWidht';

import Wrapper from '../../Wrapper/Wrapper';
import Revisao from './Revisao';

// Functions
import { get as getRC } from '../../../services/apis/rcs';

class RevisaoContainer extends Component {
	constructor() {
		super();
		this.state = {
			enviando_rc: false,
			fornecedor_selecionado: {},
			auto_confirm_sended: false,
			campos_vazios: false,
			tab_perfil_construtor: 0,
			modal_pedido_em_edicao: false,
			new_saving: 3,
		};
		this.buildResponse = this.buildResponse.bind(this);
		this.openPerfilConstrutor = this.openPerfilConstrutor.bind(this);
		this.handleModalPedidoEmEdicao = this.handleModalPedidoEmEdicao.bind(this);
	}

	UNSAFE_componentWillMount() {
		window.scroll(0, 0);
	}

	buildResponse(responses) {
		const { handleResponsesHoc, history, updateCotacao } = this.props;
		handleResponsesHoc(responses, history, updateCotacao);
	}

	handleModalPedidoEmEdicao() {
		const modal_pedido_em_edicao = !this.state.modal_pedido_em_edicao;
		this.setState({ modal_pedido_em_edicao });
	}

	openPerfilConstrutor(tab = 0) {
		const { updateModals } = this.props;
		this.setState({ tab_perfil_construtor: tab });
		updateModals('perfil_construtor', true);
	}

	render() {
		const { match, cotacao } = this.props;
		const { id } = match.params;
		const loaded = (cotacao.rc || {}).uuid === id;
		return (
			<Wrapper
				type="cotacao"
				promises={[() => getRC(id)]}
				loaded={loaded}
				buildResponse={this.buildResponse}
				{...this.props}>

				<Revisao
					{...this.state}
					{...this.props}
					openPerfilConstrutor={this.openPerfilConstrutor}
					handleModalPedidoEmEdicao={this.handleModalPedidoEmEdicao} />

			</Wrapper>
		);
	}
}

RevisaoContainer.propTypes = {
	// =========== store
	cotacao: PropTypes.object.isRequired,
	ui: PropTypes.object.isRequired,
	cadastro: PropTypes.object.isRequired,
	// =========== funcs
	updateCadastro: PropTypes.func.isRequired,
	updateCotacao: PropTypes.func.isRequired,
	updateUi: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	reportErrors: PropTypes.func.isRequired,
	// =========== router
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	// =========== hoc
	handleResponsesHoc: PropTypes.func.isRequired,
};

const mapStateToProps = props => ({
	cadastro: props.cadastro,
	cotacao: props.cotacao,
	ui: props.ui,
});

const mapDispatchToProps = dispatch => ({
	resetCadastro: () => dispatch(actions.resetCadastro()),
	updateCadastro: (field, value) => dispatch(actions.updateCadastro(field, value)),
	updateCotacao: (field, value) => dispatch(actions.updateCotacao(field, value)),
	updateUi: (field, value) => dispatch(actions.updateUi(field, value)),
	updateItem: (item_id, field, value) => dispatch(actions.updateItem(item_id, field, value)),
	updateItemCaracteristica: (item_id, key, value) => dispatch(actions.updateItemCaracteristica(item_id, key, value)),
	updateItemCaracteristicaUnidade: (item_id, key, value) => dispatch(actions.updateItemCaracteristicaUnidade(item_id, key, value)),
	updateModals: (modal, value) => dispatch(actions.updateModals(modal, value)),
	reportErrors: (page, error, codigo_pedido, full_error, test) => dispatch(actions.reportErrors(page, error, codigo_pedido, full_error, test)),
});

export default compose(
	withLoadCotacao,
	withDocumentWidth,
	connect(mapStateToProps, mapDispatchToProps),
)(RevisaoContainer);
