import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as actions from '../../../actions/index';

// Components
import withLoadCotacao from '../withLoadCotacao';
import withDocumentWidth from '../../../hoc/DocumentWidh/withDocumentWidht';
import Wrapper from '../../Wrapper/Wrapper';
import Empresa from './Empresa';

// Functions
import { get as getRC } from '../../../services/apis/rcs';
import { _post } from '../../../services/apis/requests';
import { validaItens } from '../../_functions/_validaItens';
import { validaInfo } from '../../_functions/_validaInfo';

class EmpresaContainer extends Component {
	static validadeItens(cotacao) {
		const validacao_itens = validaItens(cotacao, 'itens');
		if (validacao_itens === 0 || validacao_itens === -1) {
			return false;
		}
		return true;
	}

	static validadeInfo(cotacao) {
		const validacao_info = validaInfo(cotacao.rc);
		if (validacao_info !== 1) {
			return false;
		}
		return true;
	}

	state = {
		tab_perfil_construtor: 0,
		auto_confirm_sended: false,
		new_saving: 3,
	};

	componentDidMount() {
		window.scroll(0, 0);
	}

	finalizaPreenchimento(cotacao) {
		const { updateCotacao } = this.props;
		const status = (cotacao.rc || {}).status || 0;
		const uuid = (cotacao.rc || {}).uuid || null;
		const validade_itens = this.constructor.validadeItens(cotacao);
		const validade_info = this.constructor.validadeInfo(cotacao);

		if (
			validade_itens
			&& validade_info
			&& status <= 10
			&& uuid !== null
		) {
			_post(`/conaz/v2/rcs/${uuid}/finalizacao_preenchimento`, {})
				.then(() => {
					updateCotacao(['rc', 'status'], 30);
				});
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { cotacao } = nextProps;
		if (cotacao.rc.uuid !== undefined) {
			this.finalizaPreenchimento(cotacao);
		}
	}

	buildResponse = (responses) => {
		const { handleResponsesHoc, history, updateCotacao } = this.props;
		handleResponsesHoc(responses, history, updateCotacao);
	}

	openPerfilConstrutor = (tab = 0) => {
		const { updateModals } = this.props;
		this.setState({ tab_perfil_construtor: tab });
		updateModals('perfil_construtor', true);
	}

	trocaVendedor = (e) => {
		e.preventDefault();
		this.deslogaRedireciona('login');
	}

	cadastrarNovaEmpresa = (e) => {
		e.preventDefault();
		const { cotacao, history } = this.props;
		const { uuid } = cotacao.rc;
		history.push(`/cadastro?from=identificacao_vendedor&redirect_to=/cotacao/${uuid}/empresa?auto_confirm=true`);
	}

	deslogaRedireciona(where) {
		// desloga
		localStorage.setItem('token', '');
		localStorage.setItem('user', JSON.stringify({}));
		// redireciona
		const { cotacao, history } = this.props;
		const { uuid } = cotacao.rc;
		history.push(`/${where}?from=identificacao_vendedor&redirect_to=/cotacao/${uuid}/empresa?auto_confirm=true`);
	}

	handleFornecedor = (field, opcao) => {
		const { updateCotacao } = this.props;
		updateCotacao(['fornecedor_selecionado'], { ...opcao });
		if (document.getElementById('tooltip_fornecedor')) {
			document.getElementById('tooltip_fornecedor').className = 'tooltip top';
		}
	}

	render() {
		const { match, cotacao } = this.props;
		const { id } = match.params;
		const loaded = (cotacao.rc || {}).uuid === id;

		return (
			<Wrapper
				type="cotacao"
				promises={[() => getRC(id)]}
				loaded={loaded}
				buildResponse={this.buildResponse}
				{...this.props}>

				<Empresa
					{...this.state}
					{...this.props}
					openPerfilConstrutor={this.openPerfilConstrutor}
					trocaVendedor={this.trocaVendedor}
					cadastrarNovaEmpresa={this.cadastrarNovaEmpresa}
					handleFornecedor={this.handleFornecedor} />

			</Wrapper>
		);
	}
}

EmpresaContainer.propTypes = {
	// =========== store
	cotacao: PropTypes.object.isRequired,
	ui: PropTypes.object.isRequired,
	cadastro: PropTypes.object.isRequired,
	// =========== funcs
	updateCadastro: PropTypes.func.isRequired,
	updateCotacao: PropTypes.func.isRequired,
	updateUi: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	reportErrors: PropTypes.func.isRequired,
	// =========== router
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	// =========== hoc
	handleResponsesHoc: PropTypes.func.isRequired,
};

const mapStateToProps = props => ({
	cadastro: props.cadastro,
	cotacao: props.cotacao,
	ui: props.ui,
});

const mapDispatchToProps = dispatch => ({
	resetCadastro: () => dispatch(actions.resetCadastro()),
	updateCadastro: (field, value) => dispatch(actions.updateCadastro(field, value)),
	updateCotacao: (field, value) => dispatch(actions.updateCotacao(field, value)),
	updateUi: (field, value) => dispatch(actions.updateUi(field, value)),
	updateItem: (item_id, field, value) => dispatch(actions.updateItem(item_id, field, value)),
	updateItemCaracteristica: (item_id, key, value) => dispatch(actions.updateItemCaracteristica(item_id, key, value)),
	updateItemCaracteristicaUnidade: (item_id, key, value) => dispatch(actions.updateItemCaracteristicaUnidade(item_id, key, value)),
	updateModals: (modal, value) => dispatch(actions.updateModals(modal, value)),
	reportErrors: (page, error, codigo_pedido, full_error, test) => dispatch(actions.reportErrors(page, error, codigo_pedido, full_error, test)),
});

export default compose(
	withDocumentWidth,
	withLoadCotacao,
	connect(mapStateToProps, mapDispatchToProps),
)(EmpresaContainer);
