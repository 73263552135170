import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';


class BotaoCriaCotacao extends Component {
	constructor() {
		super();
		this.state = {
			criandoRc: false,
		};
	}

	UNSAFE_componentWillReceiveProps(newProps) {
		if (this.props.codigo_pedido !== newProps.codigo_pedido) {
			this.setState({ criandoRc: false });
		}
	}

	render() {
		return (
			<button
				className="btn btn-success"
				styleName="button-responder"
				onClick={() => { this.setState({ criandoRc: true }); this.props.criarRC(this.props.codigo_pedido); }}
				disabled={this.state.criandoRc}>
				{this.state.criandoRc ? 'Aguarde...' : 'Ver cotação'}
			</button>
		);
	}
}

BotaoCriaCotacao.propTypes = {
	codigo_pedido: PropTypes.number.isRequired,
	criarRC: PropTypes.func.isRequired,
};

export default CSSModules(BotaoCriaCotacao, styles, { allowMultiple: true });
