import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const ModalCorona = ({ updateModals }) => {
	const okClick = () => {
		localStorage.setItem('modal_corona_alert', 'ja_visualizado');
		updateModals('modal_corona_alert', 'ja_visualizado');
	};

	return (
		<div className="modal-content" styleName="modal-content">
			<div className="modal-body" styleName="modal-body">
				<button
					type="button"
					className="close"
					styleName="close-button"
					data-dismiss="modal"
					aria-label="Close"
					onClick={okClick}
				>
					<span aria-hidden="true">&times;</span>
				</button>
				<h3>Equipe Conaz e os cuidados com o Coronavírus</h3>
				<p>
					Como todos sabem, aqui no Brasil estamos dando início às medidas preventivas contra o novo <a href="https://coronavirus.saude.gov.br/">Coronavírus</a>.
					{' '}Por isso, a partir de 17/03/2020, a equipe Conaz passa a operar no modo home office.
				</p>
				<p>
					O sistema Conaz <b>continua funcionando normalmente</b> e nossos canais de atendimento (chat, e-mail e telefone) estarão disponíveis para o que precisarem. Ainda não temos previsão para retomarmos nossas atividades no escritório.
				</p>
				<p>
					A medida tem como objetivo zelar pela saúde de nossos colaboradores e diminuir as chances de propagação do vírus, evitando assim, a superlotação dos hospitais.
				</p>
				<p>
					Caso você tenha alguma dúvida, nosso time de Suporte continuará à disposição de segunda a sexta-feira, das 8:30h às 12h e das 13:30h às 17h.
				</p>
				<p><b>Equipe Conaz</b></p>
				<p>suporte.conaz@ambar.tech</p>
				<p>(16) 4042-0792</p>
			</div>
		</div>
	);
};

ModalCorona.propTypes = {
	updateModals: PropTypes.func.isRequired,
};

ModalCorona.defaultProps = {
	// updateModals: () => {},
};

export default CSSModules(ModalCorona, styles, { allowMultiple: true });
