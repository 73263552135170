import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from '../style.module.scss';

class MultipleOption extends Component {
	constructor() {
		super();
		this.handleOption = this.handleOption.bind(this);
	}

	handleOption(e) {
		e.preventDefault();
		const { option, handleEdition } = this.props;
		handleEdition(option.id);
	}

	render() {
		const { selected } = this.props;
		const { unidade, valor } = this.props.option;
		const option_view = `${valor} ${unidade}`;

		return (
			<li data-dropchild>
				{!selected ? (
					<button type="button" data-dropchild onClick={this.handleOption} styleName="checkbox-button">
						<div styleName="checkbox-icon" data-dropchild />
						{option_view}
					</button>
				) : (
					<button type="button" data-dropchild onClick={this.handleOption} styleName="checkbox-button checked">
						<div styleName="checkbox-icon" data-dropchild><i className="fa fa-check" aria-hidden="true" data-dropchild /></div>
						{option_view}
					</button>
				)}
			</li>
		);
	}
}

MultipleOption.propTypes = {
	option: PropTypes.object.isRequired,
	handleEdition: PropTypes.func.isRequired,
	selected: PropTypes.bool.isRequired,
};

export default CSSModules(MultipleOption, styles, { allowMultiple: true });
