/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { formatCurrency, formatCurrencyDecimalsFree, formatDecimals } from '../../_functions/_formatNumber';

const ItemRowRevisao = ({ number, item }) => {
	const { item_de_pedido, item_preenchido, informacoes_adicionais, status } = item;
	const { caracteristicas_preenchidas_all_ids, caracteristicas_preenchidas } = item_preenchido;
	const _quantidade = item.quantidade || 0;
	const _unidade = item.unidade || '';
	const _preco_unitario = item.preco_unitario || 0;
	const quantidade_diferente = (item_de_pedido.quantidade !== _quantidade || item_de_pedido.unidade !== _unidade);
	// sub_total
	const sub_total = _preco_unitario * _quantidade;
	// desconto
	const desconto = item.desconto || 0;
	const valor_desconto = (sub_total / 100) * desconto;
	// total
	const total = (sub_total - valor_desconto) || 0;

	const caracteristicas_to_map = caracteristicas_preenchidas_all_ids === undefined
		? (caracteristicas_preenchidas || []).sort((a, b) => a.caracteristica.ordem - b.caracteristica.ordem)
		: (caracteristicas_preenchidas_all_ids || []).sort((a, b) => caracteristicas_preenchidas[a].caracteristica.ordem - caracteristicas_preenchidas[b].caracteristica.ordem);
	const caracteristicas_pedido = item_de_pedido.item_preenchido.caracteristicas_preenchidas
		.filter(c => c.caracteristica.visao_construtor !== 0 && c.opcoes_preenchidas.length > 0)
		.sort((a, b) => a.caracteristica.ordem - b.caracteristica.ordem);

	if (item_preenchido.item !== undefined) {
		return (
			<div className="row">
				<div className="col-xs-12">
					<div styleName="review-block-item">
						<div styleName="number">{number}</div>
						<div styleName="nome">{item_preenchido.item.nome}</div>
						<div styleName="quantidade-top">
							Quantidade solicitada:
							<b> {formatDecimals(item_de_pedido.quantidade)} {item_de_pedido.unidade}</b>
						</div>
						<div styleName="properties">
							{caracteristicas_pedido.map((c, i) => {
								const valor = c.opcoes_preenchidas.map(op => ((op.unidade !== '' && op.unidade !== null && op.unidade !== undefined) ? `${op.valor} ${op.unidade}` : op.valor)).join(', ');
								return valor !== '' ? (
									<div key={i} styleName="carac-pedido-wrapper">
										{c.caracteristica.nome}<p><b>{valor}</b></p>
									</div>
								) : false;
							})}
							{item_de_pedido.observacoes !== '' && (
								<div styleName="carac-pedido-wrapper">
									Observações
									<p><b>{item_de_pedido.observacoes}</b></p>
								</div>
							)}
						</div>
						{status !== -1 && (
							<React.Fragment>
								<div styleName="title">
									<div styleName="text">Suas respostas</div>
									<div styleName="border" />
								</div>
								<div styleName="properties">
									{quantidade_diferente && (
										<div styleName="carac-pedido-wrapper">
											Quantidade
											<p><b>{formatDecimals(_quantidade)} {_unidade}</b></p>
										</div>
									)}
									{caracteristicas_to_map.map((c, i) => {
										const carac = caracteristicas_preenchidas_all_ids === undefined ? c : caracteristicas_preenchidas[c];
										// if (carac.caracteristica.visao_fornecedor !== 0 && carac.opcoes_preenchidas.length > 0) {
										const valor = carac.opcoes_preenchidas.map(op => ((op.unidade !== '' && op.unidade !== null && op.unidade !== undefined) ? `${op.valor} ${op.unidade}` : op.valor)).join(', ');
										return valor !== '' ? (
											<div key={i} styleName="carac-pedido-wrapper">
												{carac.caracteristica.nome}<p><b>{valor}</b></p>
											</div>
										) : false;
										// }
										// return false;
									})}
									{informacoes_adicionais !== '' && (
										<div styleName="carac-pedido-wrapper">
											Observação<p><b>{informacoes_adicionais}</b></p>
										</div>
									)}
								</div>
							</React.Fragment>
						)}
						{status === -1 && (
							<div styleName="values">
								<div styleName="label" style={{ paddingTop: '30px' }}>Não tenho este item</div>
							</div>
						)}
						{status === 0 && (
							<div styleName="values">
								<div styleName="label" style={{ paddingTop: '30px' }}>Incluso no preço total</div>
							</div>
						)}
						{status === 1 && (
							<div styleName="values">
								<div styleName="label padtop">
									Preço unitário
									<p styleName="unitario">{formatCurrencyDecimalsFree(_preco_unitario, 'R$')}</p>
								</div>
								<div styleName="label" style={{ paddingTop: '10px' }}>
									Desconto
									<p styleName="unitario">{desconto} %</p>
								</div>
								<div styleName="label" style={{ paddingTop: '10px' }}>
									Valor total
									<p styleName="total">{formatCurrency(total, 'R$')}</p>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="row">
			<div className="col-xs-12">
				<div styleName="review-block-item">
					<div styleName="number">{number}</div>
					<div styleName="nome">{item_de_pedido.item_preenchido.item.nome}</div>
					<div styleName="properties">
						<div styleName="carac-pedido-wrapper">
							Quantidade
							<p><b>{formatDecimals(_quantidade)} {_unidade}</b></p>
						</div>
						{caracteristicas_pedido.map((c, i) => {
							const valor = c.opcoes_preenchidas
								.map(op => (
									(op.unidade !== '' && op.unidade !== null && op.unidade !== undefined)
										? `${op.valor} ${op.unidade}`
										: op.valor
								))
								.join(', ');
							return valor !== '' ? (
								<div key={i} styleName="carac-pedido-wrapper">
									{c.caracteristica.nome}<p><b>{valor}</b></p>
								</div>
							) : false;
						})}
						{item_de_pedido.observacoes !== '' && (
							<div styleName="carac-pedido-wrapper">
								Observações
								<p><b>{item_de_pedido.observacoes}</b></p>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

ItemRowRevisao.propTypes = {
	number: PropTypes.number.isRequired,
	item: PropTypes.object.isRequired,
};

export default CSSModules(ItemRowRevisao, styles, { allowMultiple: true });
