const cadastro = {
	id: '',
	nome: '',
	status: -10,
	documento: '',
	site: '',
	razao_social: '',
	endereco: {
		cep: '',
		logradouro: '',
		numero: '',
		bairro: '',
		cidade: '',
		estado: '',
		complemento: '',
	},
	id_vendedor: '',
	nome_vendedor: '',
	telefones_vendedor: [{ numero: '', tipo: 1 }],
	email: '',
	senha: '',
	senha_repeat: '',
	usuarios: [],
	grupos: [],
	loaded: false,
	empresas: {},
	empresas_by_id: [],
	grupos_ids_outros_vendedores: [],
};

export default cadastro;
