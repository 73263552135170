import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Components
import ARComprador from './ARComprador';
import ARCriterio from './ARCriterio';
import ARAvaliacaoVendedor from './ARAvaliacaoVendedor';

// Functions
import { editDateBasicFormat } from '../../_functions/_editDateFormat';

const AvaliacaoRow = ({
	avaliacao,
	focused_compra,
}) => {
	const comentarioValidation = (status, comentario) => {
		if (status === 2) return <p>{comentario}</p>;
		if (status === 3) return <p>Comentário reprovado pela análise</p>;
		return <p>Comentário enviado para análise</p>;
	};

	const compra = (avaliacao.compra || [])[0] || {};
	const avaliacao_fornecedor = avaliacao.avaliacao_fornecedor || {};
	const avaliacao_vendedor = avaliacao.avaliacao_vendedor || {};
	const fornecedor_nome = (compra.fornecedor || {}).nome || '';

	const nota_fornecedor = (avaliacao_fornecedor.detalhe || {}).estrelas || 0;
	const respostas = (avaliacao_fornecedor.detalhe || {}).respostas || [];
	const qualidade = {
		1: 'Péssimo',
		2: 'Ruim',
		3: 'Regular',
		4: 'Boa',
		5: 'Excelente',
	};
	const comentario_fornecedor = (avaliacao_fornecedor.detalhe || {}).comentario || '';
	const comentario_vendedor = (avaliacao_vendedor.detalhe || {}).comentario || '';
	const status_comentario_fornecedor = (avaliacao_fornecedor.detalhe || {}).status_comentario || '';
	const status_comentario_vendedor = (avaliacao_fornecedor.detalhe || {}).status_comentario || '';

	const wrapper_class = focused_compra === compra.id
		? 'avaliacao-wrapper focused'
		: 'avaliacao-wrapper';

	return (
		<div className="row">
			<div className="col-xs-12">
				<div styleName={wrapper_class} id={`avaliacao-compra-row-${compra.id}`}>
					<div styleName="nota-compra">
						<div styleName="numero">{nota_fornecedor}.0</div>
						<div styleName="stars">
							{[1, 2, 3, 4, 5].map(n => {
								const start_class = n <= nota_fornecedor
									? 'active'
									: '';
								return (
									<i key={n} className="fa fa-star" styleName={start_class} aria-hidden="true" />
								);
							})}
						</div>
						<div styleName="qualidade">{qualidade[nota_fornecedor] || ''}</div>
						<div styleName="data">{editDateBasicFormat(avaliacao_fornecedor.criado_em)}</div>
					</div>
					<div styleName="info-compra">
						<ARComprador avaliacao={avaliacao} />
						<div styleName="empresa-vendedor">
							{fornecedor_nome}
						</div>
						<div styleName="criterios">
							{[1, 2, 3, 4].map(tipo => (
								<ARCriterio
									key={tipo}
									tipo={tipo}
									respostas={respostas}
								/>
							))}
							<ARAvaliacaoVendedor
								avaliacao_vendedor={avaliacao_vendedor}
							/>
						</div>
						{comentario_fornecedor !== '' && (
							<div styleName="comentarios">
								Comentário sobre a entrega
								{comentarioValidation(status_comentario_fornecedor, comentario_fornecedor)}
							</div>
						)}
						{comentario_vendedor !== '' && (
							<div styleName="comentarios">
								Comentário sobre o atendimento
								{comentarioValidation(status_comentario_vendedor, comentario_vendedor)}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

AvaliacaoRow.propTypes = {
	avaliacao: PropTypes.object.isRequired,
	focused_compra: PropTypes.number.isRequired,
};

export default CSSModules(AvaliacaoRow, styles, { allowMultiple: true });
