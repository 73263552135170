import axios from 'axios';
import { getLocalstorageData } from '../../components/_functions/_getLocalstorageData';

/* ------------------------------------------------------ *\
	get
\* ------------------------------------------------------ */
// export async function _get(url) {
// 	try {
// 		const base_url = getLocalstorageData('environment', 'base_url');
// 		// const token = getLocalstorageData('user', 'token');
// 		const token = localStorage.getItem('token');
// 		const full_url = `${base_url}${url}`;
// 		axios.defaults.headers.common.token = token;
// 		return await axios.get(full_url);
// 	} catch (err) {
// 		throw err;
// 	}
// }
export function _get(url) {
	const baseURL = getLocalstorageData('environment', 'base_url');
	const token = localStorage.getItem('token');
	return axios({
		method: 'get',
		url,
		baseURL,
		headers: {
			common: {
				...axios.defaults.headers.common,
				token,
			},
		},
		timeout: 120000,
	});
}

/* ------------------------------------------------------ *\
	put
\* ------------------------------------------------------ */
// export async function _put(url, data) {
// 	try {
// 		const base_url = getLocalstorageData('environment', 'base_url');
// 		// const token = getLocalstorageData('user', 'token');
// 		const token = localStorage.getItem('token');
// 		const full_url = `${base_url}${url}`;
// 		axios.defaults.headers.common.token = token;
// 		return await axios.put(full_url, data);
// 	} catch (err) {
// 		throw err;
// 	}
// }
export function _put(url, data) {
	const baseURL = getLocalstorageData('environment', 'base_url');
	const token = localStorage.getItem('token');
	return axios({
		method: 'put',
		url,
		baseURL,
		headers: {
			common: {
				...axios.defaults.headers.common,
				token,
			},
			'Cache-Control': 'no-cache,no-store,must-revalidate,max-age=-1,private',
		},
		timeout: 120000,
		data,
	});
}

/* ------------------------------------------------------ *\
	post
\* ------------------------------------------------------ */
// export async function _post(url, data) {
// 	try {
// 		const base_url = getLocalstorageData('environment', 'base_url');
// 		// const token = getLocalstorageData('user', 'token');
// 		const token = localStorage.getItem('token');
// 		const full_url = `${base_url}${url}`;
// 		axios.defaults.headers.common.token = token;
// 		return await axios.post(full_url, data);
// 	} catch (err) {
// 		throw err;
// 	}
// }
export function _post(url, data) {
	const baseURL = getLocalstorageData('environment', 'base_url');
	const token = localStorage.getItem('token');
	return axios({
		method: 'post',
		url,
		baseURL,
		headers: {
			common: {
				...axios.defaults.headers.common,
				token,
			},
			'Cache-Control': 'no-cache,no-store,must-revalidate,max-age=-1,private',
		},
		timeout: 120000,
		data,
	});
}

/* ------------------------------------------------------ *\
	delete
\* ------------------------------------------------------ */
// export async function _delete(url) {
// 	try {
// 		const base_url = getLocalstorageData('environment', 'base_url');
// 		// const token = getLocalstorageData('user', 'token');
// 		const token = localStorage.getItem('token');
// 		const full_url = `${base_url}${url}`;
// 		axios.defaults.headers.common.token = token;
// 		return await axios.delete(full_url);
// 	} catch (err) {
// 		throw err;
// 	}
// }
export function _delete(url) {
	const baseURL = getLocalstorageData('environment', 'base_url');
	const token = localStorage.getItem('token');
	return axios({
		method: 'delete',
		url,
		baseURL,
		headers: {
			common: {
				...axios.defaults.headers.common,
				token,
			},
			'Cache-Control': 'no-cache,no-store,must-revalidate,max-age=-1,private',
		},
		timeout: 120000,
	});
}
