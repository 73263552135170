import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../../actions/index';

// Components
import ReviewSemRC from './ReviewSemRC';
import Wrapper from '../../Wrapper/Wrapper';

// Functions
import { getCompra } from '../../../services/apis/rcs';
import { isAuthenticated } from '../../../services/login_manager';
import { confirmarCompra } from '../../../services/apis/compras';
import { setLocalstorageData } from '../../_functions/_getLocalstorageData';
import { reportarErro } from '../../../services/apis/http';

class ReviewSemRCContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			compra: {},
			oc_confirmada: false,
			oc_recusada: false,
			confirmando_oc: false,
			error_confirmando_oc: false,
			error_msg: null,
			usuario_diferente: false,
			cotacao_info_collapsed: false,
			pedido_info_collapsed: false,
			itens_info_collapsed: false,
			tab_perfil_construtor: 0,
		};
		this.transporte_opcoes = [
			{ value: 0, view: 'Incluso no preço' },
			{ value: 1, view: 'A pagar' },
			{ value: 2, view: 'Não realizo entrega' },
		];
		this.updateViewRecusada = this.updateViewRecusada.bind(this);
		this.expandInfo = this.expandInfo.bind(this);
		this.confirmarOC = this.confirmarOC.bind(this);
		this.retornaSolicitacao = this.retornaSolicitacao.bind(this);
		this.openPerfilConstrutor = this.openPerfilConstrutor.bind(this);
	}

	componentDidMount() {
		document.title = 'Venda - Conaz';
		document.body.style.background = '#f6f6f6';
		window.scrollTo(0, 0);
	}

	componentWillUnmount() {
		document.body.style.background = '';
	}

	updateViewRecusada() {
		this.setState({ oc_recusada: true });
	}

	retornaSolicitacao(responses) {
		const compra = {
			...responses[0].data,
			pedido_id: null,
		};
		const { id, fornecedor } = compra;
		document.title = `#${id} - Cotação - Conaz`;
		const compras_visiveis = [{ ...compra }];
		const info_collapsed = compras_visiveis.length > 0;
		this.setState({
			compra,
			cotacao_info_collapsed: info_collapsed,
			pedido_info_collapsed: info_collapsed,
			itens_info_collapsed: info_collapsed,
		});
		setTimeout(() => this.showModalUpdateNomeComercial(fornecedor), 0);
	}

	showModalUpdateNomeComercial(fornecedor) {
		const { nome } = fornecedor;
		const { empresas_by_id } = this.props.cadastro;
		const nome_only_letters = nome.toUpperCase().replace(/[^a-zA-z\s]/g, '');
		const nome_splitted = nome_only_letters.split(' ').filter(word => word !== '');
		const last_word = nome_splitted.length > 0 ? nome_splitted[nome_splitted.length - 1] : '';

		if (isAuthenticated() && ['ME', 'SA', 'LTDA'].indexOf(last_word) !== -1 && empresas_by_id.indexOf(fornecedor.id) !== -1) {
			this.props.updateModals(['alerta_nome_comercial'], true);
			setLocalstorageData('fornecedor_id', fornecedor.id);
		}
	}

	expandInfo(field) {
		const value = !this.state[field];
		this.setState({ [field]: value });
	}

	confirmarOC(compra_id) {
		const { compra } = this.state;
		const fornecedor_nome = (compra.fornecedor || {}).nome || '';
		const usuario_fornecedor_nome = (compra.usuario_fornecedor || {}).nome || '';

		/**
		 * Verifica se usuário logado diferente do RC
		 */
		// const { usuario_fornecedor } = this.state.compra;
		// const vendedor_id = Number(getLocalstorageData('user', 'id'));
		// if (usuario_fornecedor.id !== vendedor_id && isAuthenticated()) {
		// 	this.setState({ usuario_diferente: true });
		// 	return;
		// }
		/**
		 * Confirma ordem de compra
		 */
		this.setState({ confirmando_oc: true, error_confirmando_oc: false });
		confirmarCompra(compra_id).then(() => {
			this.setState({ oc_confirmada: true, confirmando_oc: false, error_msg: null });
			this.props.updateModals('confirmacao_venda', false);
		}).catch(error => {
			/**
			 * Compra já confirmada
			 */
			const message = (error.response && error.response.data && error.response.data.message)
				? error.response.data.message
				: null;
			if (message === 'Compra confirmada anteriormente') {
				this.setState({ oc_confirmada: true, confirmando_oc: false, error_msg: null });
				this.props.updateModals('confirmacao_venda', false);
				return;
			}
			if (message === 'Não é possível confirmar a compra porque sua conta não está compatível com os dados da compra.') {
				const error_msg = (
					<p>
						Sua conta não está compatível com os dados da compra.<br />
						Fornecedor: {fornecedor_nome}<br />
						Vendedor: {usuario_fornecedor_nome}
					</p>
				);
				this.setState({
					confirmando_oc: false,
					error_confirmando_oc: true,
					error_msg,
				});
				// this.props.updateModals('confirmacao_venda', false);
				return;
			}
			/**
			 * Erro
			 */
			console.log(error); // eslint-disable-line
			this.setState({ confirmando_oc: false, error_confirmando_oc: true, error_msg: null });
			reportarErro(error);
		});
	}

	openPerfilConstrutor(tab = 0) {
		const { updateModals } = this.props;
		this.setState({ tab_perfil_construtor: tab });
		updateModals('perfil_construtor', true);
	}

	render() {
		const { id } = this.props.match.params;

		return (
			<Wrapper
				type="venda-sem-cotacao"
				loaded={false}
				promises={[() => getCompra(id)]}
				buildResponse={this.retornaSolicitacao}
				{...this.props}>

				<ReviewSemRC
					{...this.state}
					{...this.props}
					updateViewRecusada={this.updateViewRecusada}
					transporte_opcoes={this.transporte_opcoes}
					confirmarOC={this.confirmarOC}
					expandInfo={this.expandInfo}
					openPerfilConstrutor={this.openPerfilConstrutor} />

			</Wrapper>
		);
	}
}

ReviewSemRCContainer.propTypes = {
	ui: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	updateModals: PropTypes.func.isRequired,
	updateUi: PropTypes.func.isRequired,
	cadastro: PropTypes.object.isRequired,
};

const mapStateToProps = props => ({
	cadastro: props.cadastro,
	ui: props.ui,
});

const mapDispatchToProps = dispatch => ({
	resetCadastro: () => dispatch(actions.resetCadastro()),
	updateCadastro: (field, value) => dispatch(actions.updateCadastro(field, value)),
	updateUi: (field, value) => dispatch(actions.updateUi(field, value)),
	updateModals: (modal, value) => dispatch(actions.updateModals(modal, value)),
	reportErrors: (page, error, codigo_pedido, full_error, test) => dispatch(actions.reportErrors(page, error, codigo_pedido, full_error, test)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewSemRCContainer);
