import React, { Component } from 'react';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

class Alert extends Component {
	constructor() {
		super();
		this.state = {
			success: false,
		};
		this.closeSuccess = this.closeSuccess.bind(this);
	}

	componentDidMount() {
		setTimeout(() => this.setState({ success: true }), 350);
		setTimeout(() => this.closeSuccess(), 14000);
	}

	closeSuccess() {
		this.setState({ success: false });
	}

	render() {
		const { success } = this.state;
		const success_class = success ? 'alert success in' : 'alert success';

		return (
			<div styleName={success_class}>
				<button styleName="close" onClick={this.closeSuccess}><span aria-hidden="true">&times;</span></button>
				Cotação enviada com sucesso!
			</div>
		);
	}
}

export default CSSModules(Alert, styles, { allowMultiple: true });
