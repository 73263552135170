import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

const ARCriterio = ({
	tipo,
	respostas,
}) => {
	const resposta = respostas
		.filter(r => r.tipo_questao === tipo)[0] || {};
	const icon = {
		true: <i className="fa fa-check" styleName="check" aria-hidden="true" />,
		false: <i className="fa fa-times" styleName="times" aria-hidden="true" />,
	} || <i className="fa fa-minus" aria-hidden="true" />;
	const text = {
		1: 'Entrega realizada no prazo',
		2: 'Documentos entregues corretamente',
		3: 'Venda de acordo com o solicitado',
		4: 'Materiais com boas condições de uso',
	} || '';

	return (
		<div styleName="criterio">
			{icon[resposta.resposta]}
			<p>{text[resposta.tipo_questao]}</p>
		</div>
	);
};

ARCriterio.propTypes = {
	tipo: PropTypes.number.isRequired,
	respostas: PropTypes.array.isRequired,
};

export default CSSModules(ARCriterio, styles, { allowMultiple: true });
