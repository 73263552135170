import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Components
import withValidationSteps from './withValidationSteps';
import EnviaRCButton from './EnviaRCButton';
import StepbarTotal from './StepbarTotal';

// Functions
import { isAuthenticated } from '../../../services/login_manager';

const Stepbar = props => {
	const [alert, setAlert] = useState(false);
	const [waiting, setWaiting] = useState(false);

	const pages = isAuthenticated()
		? ['itens', 'dadosfinais', 'empresa', 'revisao']
		: ['itens', 'dadosfinais', 'identificacao', 'revisao'];
	const page = document.URL.substr(document.URL.lastIndexOf('/') + 1);
	const current_page_key = _.findIndex(pages, p => page.indexOf(p) !== -1);

	const clearAlert = () => {
		setTimeout(() => setAlert(false), 4600);
	};

	const handleAlerts = () => {
		setAlert(true);
		clearAlert();
	};

	const handleNextNavigation = () => {
		clearInterval(window.waiting_interval);
		const { cotacao, history } = props;
		const uuid = (cotacao.rc || {}).uuid || '';
		const nextPage = pages[current_page_key + 1] || pages[pages.length - 1];

		const check = props.validateSteps(current_page_key + 1);
		if (!check) {
			handleAlerts();
			return;
		}

		history.push(`/cotacao/${uuid}/${nextPage}`);
	};

	const checkWaiting = () => {
		const { new_saving } = props;
		if (new_saving < 2) {
			setWaiting(true);
			return;
		}
		if (new_saving >= 2) {
			setWaiting(false);
		}
		handleNextNavigation();
	};

	const startSaving = () => {
		props.forceAutoSave();
		checkWaiting();
	};

	useEffect(() => {
		if (waiting) checkWaiting();
	});

	const handleNavigation = (target) => {
		const nextIndex = Number(target.getAttribute('data-index')) || 0;
		const check = props.validateSteps(nextIndex);
		if (!check && nextIndex > current_page_key) {
			handleAlerts();
			return;
		}

		const link = target.getAttribute('data-link') || '';
		const { cotacao, history } = props;
		const uuid = (cotacao.rc || {}).uuid || '';
		if (link !== '') {
			history.push(`/cotacao/${uuid}/${link}`);
		}
	};

	const checkWaitingNav = (target) => {
		const { new_saving } = props;
		if (new_saving < 2) {
			setWaiting(true);
			return;
		}
		if (new_saving >= 2) {
			setWaiting(false);
		}
		handleNavigation(target);
	};

	const startSavingNav = ({ target }) => {
		props.forceAutoSave();
		checkWaitingNav(target);
	};

	const handleActivePage = (index) => (
		current_page_key === index ? 'active' : ''
	);

	const {
		cotacao,
		history,
		updateModals,
		updateUi,
		isPreRefinamento,
		handleModalPedidoEmEdicao,
		setAttetion,
	} = props;

	return (
		<>
			<div styleName="stepbardiv">
				<div styleName="center-wrapper">
					<div styleName="stepbar">
						<button
							type="button"
							styleName={handleActivePage(0)}
							data-index={0}
							data-link="itens"
							onClick={startSavingNav}>
							Preenchimento dos itens
						</button>
						<button
							type="button"
							styleName={handleActivePage(1)}
							data-index={1}
							data-link="dadosfinais"
							onClick={startSavingNav}>
							Condições da proposta
						</button>
						<button
							type="button"
							styleName={handleActivePage(2)}
							data-index={2}
							data-link={isAuthenticated() ? 'empresa' : 'identificacao'}
							onClick={startSavingNav}>
							Informações da empresa
						</button>
						<button
							type="button"
							styleName={handleActivePage(3)}
							data-index={3}
							data-link="revisao"
							onClick={startSavingNav}>
							Revisão da proposta
						</button>
					</div>
					<StepbarTotal cotacao={cotacao} />
					<div styleName="nextbutton">
						{alert && (
							<div styleName="error-alert-2 visible">
								<div styleName="message">
									<p><i className="fa fa-exclamation-circle" aria-hidden="true" /> Campos obrigatórios em aberto</p>
									Preencha os campos destacados em vermelho para proceder com o envio da proposta.
								</div>
							</div>
						)}
						<EnviaRCButton
							isPreRefinamento={isPreRefinamento}
							current_page_key={current_page_key}
							validateSteps={props.validateSteps}
							setAttetion={setAttetion}
							history={history}
							waiting={waiting}
							startSaving={startSaving}
							cotacao={cotacao}
							updateModals={updateModals}
							updateUi={updateUi}
							handleAlerts={handleAlerts}
							handleModalPedidoEmEdicao={handleModalPedidoEmEdicao}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

Stepbar.propTypes = {
	history: PropTypes.object.isRequired,
	cotacao: PropTypes.object.isRequired,
	isPreRefinamento: PropTypes.bool,
	setAttetion: PropTypes.func,
	new_saving: PropTypes.number,
	validateSteps: PropTypes.func.isRequired,
	updateModals: PropTypes.func,
	updateUi: PropTypes.func,
	handleModalPedidoEmEdicao: PropTypes.func,
	forceAutoSave: PropTypes.func,
};

Stepbar.defaultProps = {
	new_saving: 2,
	isPreRefinamento: false,
	setAttetion: () => null,
	updateModals: () => {},
	updateUi: () => {},
	handleModalPedidoEmEdicao: () => {},
	forceAutoSave: () => {},
};

const _Stepbar = CSSModules(Stepbar, styles, { allowMultiple: true });
export default withValidationSteps(_Stepbar);
