import React, { useState, useEffect } from 'react';

export default function withDocumentWidth(Comp) {
	return function HOC(props) {
		const [width, setStateWidth] = useState(window.innerWidth);

		function getDocumentWidth() {
			setStateWidth(window.innerWidth);
		}

		useEffect(() => {
			window.addEventListener('resize', getDocumentWidth);
			return () => {
				window.removeEventListener('resize', getDocumentWidth);
			};
		}, []);

		return <Comp {...{ width }} {...props} />;
	};
}
