function checkElem(elem) {
	if (document.getElementById(elem)) {
		return true;
	}
	return false;
}

export const validaInfo = rc => {
	const {
		validade,
		prazo_de_entrega,
		transporte,
		condicoes_de_pagamento,
		condicoes_de_pagamento_by_id,
		transporte_proprio,
	} = rc;
	const valor_transporte = rc.valor_transporte || 0;

	const validade_check = (validade !== '' && validade !== null)
		? validade.clone().format('YYYY-MM-DD')
		: undefined;
	if (validade_check === undefined || validade_check === '2016-01-01') {
		if (checkElem('validade')) {
			document.getElementById('validade').focus();
		}
		if (checkElem('tooltip_validade')) {
			document.getElementById('tooltip_validade').className = 'tooltip top in';
		}
		return 0;
	}

	if (transporte === '' || transporte === null) {
		if (checkElem('transporte')) {
			document.getElementById('transporte').focus();
		}
		if (checkElem('tooltip_transporte')) {
			document.getElementById('tooltip_transporte').className = 'tooltip top in';
		}
		return 0;
	}

	if (
		(
			prazo_de_entrega === ''
			|| prazo_de_entrega === 0
			|| prazo_de_entrega === null
		)
		&& transporte_proprio !== 2
	) {
		if (checkElem('prazo_de_entrega')) {
			document.getElementById('prazo_de_entrega').focus();
		}
		if (checkElem('tooltip_prazo_de_entrega')) {
			document.getElementById('tooltip_prazo_de_entrega').className = 'tooltip top in';
		}
		return 0;
	}

	// if (transporte === 2 && (valor_transporte === '' || valor_transporte === 0 || transporte_proprio === null)) {
	// 	if (checkElem('transporte')) {
	// 		document.getElementById('transporte').focus();
	// 	}
	// 	if (checkElem('tooltip_transporte')) {
	// 		document.getElementById('tooltip_transporte').className = 'tooltip top in';
	// 	}
	// 	return 0;
	// }

	// if (transporte === 1 && (valor_transporte === '' || valor_transporte === 0 || transporte_proprio === null)) {
	// 	if (checkElem('transporte')) {
	// 		document.getElementById('transporte').focus();
	// 	}
	// 	if (checkElem('tooltip_transporte')) {
	// 		document.getElementById('tooltip_transporte').className = 'tooltip top in';
	// 	}
	// 	return 0;
	// }

	// valida valor do frete
	if (
		transporte === 1
		&& valor_transporte === 0
		&& transporte_proprio !== 2
	) {
		if (checkElem('valor_transporte')) {
			document.getElementById('valor_transporte').focus();
		}
		if (checkElem('tooltip_valor_transporte')) {
			document.getElementById('tooltip_valor_transporte').className = 'tooltip top in';
		}
		return 0;
	}

	// valida transporte_proprio
	if (
		transporte === 1
		&& transporte_proprio === null
	) {
		if (checkElem('tooltip_transporte_proprio')) {
			document.getElementById('tooltip_transporte_proprio').className = 'tooltip top in';
		}
		return 0;
	}

	if (
		transporte === 1
		&& transporte_proprio !== 2
		&& (
			valor_transporte === ''
			|| valor_transporte === 0
		)
	) {
		if (document.getElementById('transporte')) {
			document.getElementById('transporte').focus();
		}
		if (document.getElementById('tooltip_transporte')) {
			document.getElementById('tooltip_transporte').className = 'tooltip top in';
		}
		return 0;
	}

	for (let c = 0; c < condicoes_de_pagamento.length; c++) {
		const condicao = condicoes_de_pagamento_by_id[condicoes_de_pagamento[c]];
		if (
			condicao.forma === 0
			|| condicao.prazo === 0
			|| (condicao.prazo === -1 && condicao.outro_prazo === '')
			|| (condicao.forma === -1 && condicao.outra_forma === '')
		) {
			if (document.getElementById('condicao_wrapper')) {
				document.getElementById('condicao_wrapper').scrollIntoView();
			}
			if (document.getElementById(`tooltip_condicao_pagamento_${condicao.id}`)) {
				document.getElementById(`tooltip_condicao_pagamento_${condicao.id}`).className = 'tooltip top in';
			}
			return 0;
		}
		const aceita_parcial = condicao.aceita_parcial !== null
			? condicao.aceita_parcial
			: -1;
		const compra_minima = condicao.compra_minima || 0;
		if (aceita_parcial === -1) {
			if (document.getElementById('condicao_wrapper')) {
				document.getElementById('condicao_wrapper').scrollIntoView();
			}
			if (document.getElementById(`tooltip_compra_parcial_${condicao.id}`)) {
				document.getElementById(`tooltip_compra_parcial_${condicao.id}`).className = 'tooltip top in';
			}
			return 0;
		}
		if (aceita_parcial === 2 && compra_minima === 0) {
			if (document.getElementById('condicao_wrapper')) {
				document.getElementById('condicao_wrapper').scrollIntoView();
			}
			if (document.getElementById(`tooltip_valor_minimo_${condicao.id}`)) {
				document.getElementById(`tooltip_valor_minimo_${condicao.id}`).className = 'tooltip top in';
			}
			return 0;
		}
	}

	return 1;
};
