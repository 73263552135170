/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import enviaRC from '../../_functions/_enviaRC';
import { _post, _put } from '../../../services/apis/requests';

// Component
import LoadingButton from '../../_parts/_loadings/LoadingButton/LoadingButton';
import ModalPortal from '../../_parts/_modals/ModalPortal/ModalPortal';
import ModalWrapperPortal from '../../_parts/_modals/ModalPortal/ModalWrapperPortal';

function Refinamento({
	cotacao,
	handleModalPedidoEmEdicao,
	history,
	updateCotacao,
}) {
	const [enviando, setEnviando] = useState(0);
	const [editando, setEditando] = useState(0);
	const [alert, setAlert] = useState(false);
	const [modal_justificativa, setModalJustificativa] = useState(false);
	const [justificativa, handleJustification] = useState('');
	const { motivo_refinamento } = cotacao.rc;

	const clearAlert = () => {
		setTimeout(() => setAlert(false), 4600);
	};

	const handleAlerts = () => {
		setAlert(true);
		clearAlert();
	};

	const sendRC = () => {
		const { rc: { uuid } } = cotacao;
		if (justificativa === '') {
			setEnviando(2);
			return;
		}
		setEnviando(4);
		_put(`/conaz/v2/rcs/${uuid}`, { justificativa })
			.then(() => {
				enviaRC(cotacao, setEnviando, 1, 3, handleModalPedidoEmEdicao, handleAlerts);
				/*eslint-disable*/
			}).catch(err => console.log(err));
	};

	const chooseContentButton = () => {
		if (enviando === 1) {
			return 'Enviando...';
		}
		if (enviando === 4) {
			return (
				<div style={{ width: '40px', height: '20px' }}>
					<LoadingButton />
				</div>
			);
		}
		return 'Enviar';
	};

	const closeModal = () => {
		setEnviando(0);
		setModalJustificativa(false);
	};

	const handleEdition = () => {
		setEditando(1);
		const { rc: { uuid } } = cotacao;
		_post(`/conaz/v2/rcs/${uuid}/refinar`, {})
			.then(() => {
				updateCotacao(['rc', 'status'], 37);
				history.push(`/cotacao/${uuid}/itens`);
			})
			.catch(() => setEditando(2));
	};

	return (
		<>
			<div styleName="container">
				<h2 styleName="title">
					Foi identificado um possível erro de preenchimento
				</h2>
				<div styleName="body">
					<p styleName="message">{motivo_refinamento}</p>
				</div>
				<div styleName="buttons">
					<button
						styleName="not-correcation"
						onClick={() => setModalJustificativa(true)}>
						A proposta não precisa de correção, as informações estão corretas
					</button>
					<button
						styleName="correction"
						onClick={() => handleEdition()}>
						{editando === 1 ?
							<LoadingButton /> :
							'Fazer a correção'}
					</button>
				</div>
			</div>
			{modal_justificativa && (
				<ModalPortal>
					<ModalWrapperPortal closeModal={() => setModalJustificativa(false)}>
						<>
							<div className="modal-content" styleName="modal-content">
								<div className="modal-body" styleName="modal-body">
									<div styleName="content">
										<div styleName="title"><b>Justificativa:</b></div>
										<textarea
											className="form-control"
											styleName="textarea"
											maxLength="400"
											rows="5"
											value={justificativa}
											onChange={e => handleJustification(e.target.value)} />
										{enviando === 2 && justificativa === ''
											&& (
												<p styleName="warning">Necessário enviar uma justificativa para prosseguir.</p>
											)
										}
										<div>
											<button
												className="btn btn-clear-conaz round-border"
												onClick={() => closeModal()}
												style={{ marginRight: '15px' }}>
												Cancelar
											</button>
											<button
												type="button"
												className="btn btn-secondary-conaz round-border"
												onClick={sendRC}>
												{chooseContentButton()}
											</button>
										</div>
									</div>
								</div>
							</div>
						</>
					</ModalWrapperPortal>
				</ModalPortal>
			)}
		</>
	);
}

Refinamento.propTypes = {
	cotacao: PropTypes.shape({
		rc: PropTypes.shape({
			uuid: PropTypes.string.isRequired,
			motivo_refinamento: PropTypes.string,
		}),
	}),
	handleModalPedidoEmEdicao: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	updateCotacao: PropTypes.func.isRequired,
};

Refinamento.defaultProps = {
	cotacao: {
		rc: {
			motivo_refinamento: '',
		},
	},
};

export default CSSModules(Refinamento, styles, { allowMultiple: true });
