const findPos = (obj) => {
	let curleft = 0;
	let curtop = 0;
	if (obj.offsetParent) {
		curleft = obj.offsetLeft;
		curtop = obj.offsetTop;
		let _obj = obj;
		while (_obj.offsetParent) {
			_obj = _obj.offsetParent;
			curleft += _obj.offsetLeft;
			curtop += _obj.offsetTop;
		}
	}
	return [curleft, curtop];
};

export const scrollToCenter = (element) => {
	const height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

	const el_top = findPos(element)[1];
	const top = el_top + document.body.scrollTop;
	window.scrollTo(0, top - (height / 2));
};
