import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shallowequal from 'shallowequal';
import _ from 'lodash';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import InputText from '../_inputs/InputText/InputText';
import InputNumber from '../_inputs/InputNumber/InputNumber';
import InputSelect from '../_inputs/InputSelect/InputSelect';
import InputMultiple from '../_inputs/InputMultiple/InputMultiple';
import InputUnidade from '../_inputs/InputUnidade/InputUnidade';
import TagSugestao from '../TagSugestao/TagSugestao';

// Functions
import { caracteristicaType } from '../../_functions/_caracteristicaType';
import { buildOpcaoPreenchida } from '../../_functions/_buildOpcaoPreenchida';

class Caracteristica extends Component {
	constructor() {
		super();
		this.state = {
			outro: false,
			outra_unidade: false,
			caracteristica: true,
		};
		this.handleOutro = this.handleOutro.bind(this);
		this.handleOutraUnidade = this.handleOutraUnidade.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.checkOutro();
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (!shallowequal(this.props, nextProps)) return true;
		if (!shallowequal(this.state, nextState)) return true;
		return false;
	}

	checkOutro() {
		const { opcoes_preenchidas } = this.props.caracteristicas_preenchidas;
		const { opcoes, selecao, tipo_de_valor } = this.props.caracteristicas_preenchidas.caracteristica;
		const caracteristica_type = caracteristicaType(opcoes, selecao, tipo_de_valor);
		const validada = opcoes_preenchidas.length > 0
			? opcoes_preenchidas[0].validada
			: null;
		const valor = opcoes_preenchidas.length > 0
			? opcoes_preenchidas[0].valor
			: '';
		if (caracteristica_type === 'selecao' && !validada && valor !== '') {
			this.setState({ outro: true });
		}
		const unidade = opcoes_preenchidas.length > 0
			? opcoes_preenchidas[0].unidade
			: '';
		if (
			opcoes.length > 0
			&& caracteristica_type !== 'selecao'
			&& caracteristica_type !== 'multipla_escolha'
			&& unidade !== ''
			&& opcoes.filter(u => u.unidade === unidade).length === 0
		) {
			this.setState({ outra_unidade: true });
		}
	}

	handleOutro() {
		const outro = !this.state.outro;
		const { item_id, caracteristica_id, updateItemCaracteristica } = this.props;
		const opcao_zerada = buildOpcaoPreenchida('', '', null);
		updateItemCaracteristica(item_id, caracteristica_id, [opcao_zerada]);
		if (outro) setTimeout(() => document.getElementById(`caracteristica_${item_id}_${caracteristica_id}`).focus(), 60);
		this.setState({ outro });
	}

	handleOutraUnidade() {
		const outra_unidade = !this.state.outra_unidade;
		const { item_id, caracteristica_id, updateItemCaracteristica, caracteristicas_preenchidas } = this.props;
		const opcao_zerada_unidade = caracteristicas_preenchidas.opcoes_preenchidas.length > 0
			? { ...caracteristicas_preenchidas.opcoes_preenchidas[0], unidade: '' }
			: buildOpcaoPreenchida('', '', null);
		updateItemCaracteristica(item_id, caracteristica_id, [opcao_zerada_unidade]);
		if (outra_unidade) setTimeout(() => document.getElementById(`unidade_caracteristica_${item_id}_${caracteristica_id}`).focus(), 60);
		this.setState({ outra_unidade });
	}

	tagPreenchimentoFunction = crctPreenchida => {
		const {
			updateItemCaracteristica,
			autoSaveTimeout,
			caracteristica_id,
			item_id,
		} = this.props;
		this.setState({ caracteristica: false });
		updateItemCaracteristica(item_id, caracteristica_id, crctPreenchida);
		autoSaveTimeout(item_id);
	}

	render() {
		const { outro, outra_unidade, caracteristica } = this.state;
		const {
			item_id,
			caracteristica_id,
			caracteristicas_preenchidas,
			updateItemCaracteristica,
			autoSaveTimeout,
			caracteristicas_pedido_preenchidas,
		} = this.props;
		const {
			nome,
			opcoes,
			selecao,
			tipo_de_valor,
			visao_fornecedor,
			visao_construtor,
		} = caracteristicas_preenchidas.caracteristica;


		const caracteristica_type = caracteristicaType(opcoes, selecao, tipo_de_valor);
		const opcao_preenchida = caracteristicas_preenchidas.opcoes_preenchidas.length > 0
			? { ...caracteristicas_preenchidas.opcoes_preenchidas[0] }
			: buildOpcaoPreenchida('', '', null);
		const valor = caracteristicas_preenchidas.opcoes_preenchidas.length > 0
			? caracteristicas_preenchidas.opcoes_preenchidas[0].valor : '';
		const unidade = caracteristicas_preenchidas.opcoes_preenchidas.length > 0 ? caracteristicas_preenchidas.opcoes_preenchidas[0].unidade : '';
		// const has_unidade = opcoes.length > 0 && caracteristica_type !== 'selecao' && caracteristica_type !== 'multipla_escolha';
		const has_unidade = (opcoes.length > 0 && caracteristica_type !== 'selecao' && caracteristica_type !== 'multipla_escolha') ||
			(caracteristica_type === 'selecao' && outro && opcoes.length > 0 && opcoes[0].unidade !== '');
		const caracteristica_wrapper_class = !has_unidade ? 'caracteristica-wrapper' : 'caracteristica-wrapper has-unidade';
		const wrapper_id = `caracteristica_wrapper_${item_id}_${caracteristica_id}`;
		const unidade_wrapper_id = `unidade_caracteristica_wrapper_${item_id}_${caracteristica_id}`;
		const obrigatorio = visao_fornecedor === 2 ? <span className="obrigatorio-label">*</span> : '';
		const isEmpity = ((caracteristicas_preenchidas.opcoes_preenchidas[0] || {}).valor || '') !== '';
		const has_sugestao =
			(visao_construtor === 2 || visao_construtor === 1)
			&& (visao_construtor === 2 || visao_construtor === 1)
			&& !_.isEmpty(caracteristicas_pedido_preenchidas, true)
			&& !isEmpity
			&& !outro;

		const value = `${(caracteristicas_pedido_preenchidas[0] || {}).valor}  ${(caracteristicas_pedido_preenchidas[0] || {}).unidade}`;

		return (
			<React.Fragment>
				<div id={`tooltip_caracteristica_${item_id}_${caracteristica_id}`} className="tooltip top" role="tooltip" style={{ marginTop: '-34px' }}>
					<div className="tooltip-arrow" style={{ borderTopColor: '#ee2622', left: '10%' }} />
					<div className="tooltip-inner" style={{ backgroundColor: '#ee2622' }}>Este campo é obrigatório</div>
				</div>
				<label>{nome} {obrigatorio}</label>

				<div id={wrapper_id} className={has_sugestao ? `${caracteristica_wrapper_class} tag` : caracteristica_wrapper_class}>
					{caracteristica_type === 'texto' && (
						<InputText
							type="caracteristica"
							field_id={`caracteristica_${item_id}_${caracteristica_id}`}
							item_id={item_id}
							field={caracteristica_id}
							default_value={valor}
							opcao_preenchida={opcao_preenchida}
							updateFunction={updateItemCaracteristica}
							handleOutro={this.handleOutro}
							autoSaveTimeout={autoSaveTimeout} />
					)}
					{caracteristica_type === 'numero' && (
						<InputNumber
							type="caracteristica"
							field_id={`caracteristica_${item_id}_${caracteristica_id}`}
							item_id={item_id}
							field={caracteristica_id}
							default_value={valor}
							opcao_preenchida={opcao_preenchida}
							updateFunction={updateItemCaracteristica}
							autoSaveTimeout={autoSaveTimeout} />
					)}
					{(caracteristica_type === 'selecao' && !outro) && (
						<InputSelect
							type="caracteristica"
							field_id={`caracteristica_${item_id}_${caracteristica_id}`}
							item_id={item_id}
							field={caracteristica_id}
							default_value={`${valor} ${unidade}`}
							opcoes={opcoes}
							updateFunction={updateItemCaracteristica}
							handleOutro={this.handleOutro}
							autoSaveTimeout={autoSaveTimeout} />
					)}
					{(caracteristica_type === 'selecao' && outro && tipo_de_valor === 'texto') && (
						<InputText
							type="caracteristica"
							field_id={`caracteristica_${item_id}_${caracteristica_id}`}
							item_id={item_id}
							field={caracteristica_id}
							default_value={valor}
							opcao_preenchida={opcao_preenchida}
							updateFunction={updateItemCaracteristica}
							outro={outro}
							handleOutro={this.handleOutro}
							autoSaveTimeout={autoSaveTimeout} />
					)}
					{(caracteristica_type === 'selecao' && outro && tipo_de_valor === 'numero') && (
						<InputNumber
							type="caracteristica"
							field_id={`caracteristica_${item_id}_${caracteristica_id}`}
							item_id={item_id}
							field={caracteristica_id}
							default_value={valor}
							opcao_preenchida={opcao_preenchida}
							updateFunction={updateItemCaracteristica}
							outro={outro}
							handleOutro={this.handleOutro}
							autoSaveTimeout={autoSaveTimeout} />
					)}

					{caracteristica_type === 'multipla_escolha' && (
						<InputMultiple
							type="caracteristica"
							field_id={`caracteristica_${item_id}_${caracteristica_id}`}
							item_id={item_id}
							field={caracteristica_id}
							opcoes={opcoes}
							opcoes_selecionadas={caracteristicas_preenchidas.opcoes_preenchidas}
							updateFunction={updateItemCaracteristica}
							autoSaveTimeout={autoSaveTimeout}
							tipo_de_valor={tipo_de_valor} />
					)}
					{
						has_sugestao && caracteristica &&
						<TagSugestao
							value={value}
							onClick={
								() => this.tagPreenchimentoFunction(caracteristicas_pedido_preenchidas)} />
					}
				</div>
				{/* ANCHOR unidade */}
				{(opcoes.length > 0 && caracteristica_type !== 'selecao' && caracteristica_type !== 'multipla_escolha' && !outra_unidade) && (
					<div id={unidade_wrapper_id} className="unidade-wrapper">
						<InputUnidade
							type="unidade-caracteristica"
							field_id={`unidade_caracteristica_${item_id}_${caracteristica_id}`}
							item_id={item_id}
							field={caracteristica_id}
							default_value={unidade}
							opcao_preenchida={opcao_preenchida}
							opcoes={opcoes}
							updateFunction={updateItemCaracteristica}
							handleOutraUnidade={this.handleOutraUnidade}
							autoSaveTimeout={autoSaveTimeout} />
					</div>
				)}
				{(caracteristica_type === 'selecao' && outro && opcoes.length > 0 && opcoes[0].unidade !== '') && (
					<div id={unidade_wrapper_id} className="unidade-wrapper">
						<InputText
							type="unidade-caracteristica"
							field_id={`unidade_caracteristica_${item_id}_${caracteristica_id}`}
							item_id={item_id}
							field={caracteristica_id}
							default_value={unidade}
							opcao_preenchida={opcao_preenchida}
							updateFunction={updateItemCaracteristica}
							outro={outra_unidade}
							handleOutro={this.handleOutraUnidade}
							autoSaveTimeout={autoSaveTimeout} />

					</div>
				)}

				{(outra_unidade && (caracteristica_type === 'texto' || caracteristica_type === 'numero')) && (
					<div id={unidade_wrapper_id} className="unidade-wrapper">
						<InputText
							type="unidade-caracteristica"
							field_id={`unidade_caracteristica_${item_id}_${caracteristica_id}`}
							item_id={item_id}
							style={{ width: 'auto!important' }}
							field={caracteristica_id}
							default_value={unidade}
							opcao_preenchida={opcao_preenchida}
							updateFunction={updateItemCaracteristica}
							outro={outra_unidade}
							handleOutro={this.handleOutraUnidade}
							autoSaveTimeout={autoSaveTimeout} />
					</div>
				)}

			</React.Fragment>
		);
	}
}

Caracteristica.propTypes = {
	item_id: PropTypes.number.isRequired,
	caracteristica_id: PropTypes.string.isRequired,
	caracteristicas_preenchidas: PropTypes.object.isRequired,
	updateItemCaracteristica: PropTypes.func.isRequired,
	autoSaveTimeout: PropTypes.func.isRequired,
	caracteristicas_pedido_preenchidas: PropTypes
		.oneOfType([PropTypes.object, PropTypes.array]),
};

Caracteristica.defaultProps = {
	caracteristicas_pedido_preenchidas: {},
};

export default CSSModules(Caracteristica, styles, { allowMultiple: true });
