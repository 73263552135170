import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

function EscolhaDoItem({
	item,
	updateItem,
	autoSaveTimeout,
}) {
	function updateStatus(status) {
		updateItem(item.id, 'status', status);
		if (status === 0 || status === -1) {
			updateItem(item.id, 'preco_unitario', 0);
		}
		autoSaveTimeout(item.id);
		// remove alert
		if (document.getElementById(`tooltip_possui_item_${item.id}`)) {
			document.getElementById(`tooltip_possui_item_${item.id}`).className = 'tooltip right';
		}
	}

	const { status } = item;

	useEffect(() => {
		if (status === -1 || status === null || status === 0) {
			updateItem(item.id, 'status', 1);
		}
	}, []);

	// class
	/* eslint-disable no-nested-ternary */
	const btn_have_disabled = status === -1;
	const btn_donthave_disabled = status === 1;
	const btn_have_class = status === 1 ? 'have' :
		btn_have_disabled ? 'disabled' : '';
	const btn_donthave_class = status === -1 ? 'dont-have' :
		btn_donthave_disabled ? 'disabled' : '';

	return (
		<div className="row">
			<div className="col-xs-12">
				<div styleName="buttons-row">
					<div styleName="escolhe-item-buttons">
						{status === -1 && (
							<button
								className="btn btn-default"
								styleName={btn_have_class}
								onClick={() => updateStatus(1)}>
								Tenho este item
							</button>
						)}
						<button
							styleName={btn_donthave_class}
							onClick={() => updateStatus(-1)}
							className="btn btn-default">
							Não tenho este item
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

EscolhaDoItem.propTypes = {
	item: PropTypes.object.isRequired,
	updateItem: PropTypes.func.isRequired,
	autoSaveTimeout: PropTypes.func.isRequired,
};

export default CSSModules(EscolhaDoItem, styles, { allowMultiple: true });
