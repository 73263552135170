import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../../actions/index';

// Components
import Review from './Review';
import Wrapper from '../../Wrapper/Wrapper';

// Functions
import { get as getRC, resetStatus } from '../../../services/apis/rcs';
import { isAuthenticated } from '../../../services/login_manager';
import { confirmarCompra } from '../../../services/apis/compras';
import { getLocalstorageData, setLocalstorageData } from '../../_functions/_getLocalstorageData';
import { reportarErro } from '../../../services/apis/http';
import { _post } from '../../../services/apis/requests';
import { defineStatusCompra } from '../../_functions/_defineStatusCompra';

class ReviewContainer extends Component {
	static buildItensDePedidoObject(itens_de_pedido) {
		const itens_de_pedido_new = {};
		for (let i = 0; i < itens_de_pedido.length; i++) {
			itens_de_pedido_new[itens_de_pedido[i].item_de_pedido_id] = {
				propriedades: itens_de_pedido[i].propriedades,
			};
		}
		return itens_de_pedido_new;
	}

	constructor(props) {
		super(props);
		this.state = {
			itens_de_pedido_object: {},
			compras_visiveis: [],
			rc: {},
			compra: {},
			confirma_edicao: false,
			resetando: false,
			resetando_error: false,
			resetada: false,
			oc_confirmada: false,
			oc_recusada: false,
			confirmando_oc: false,
			error_confirmando_oc: false,
			usuario_diferente: false,
			alert_cotacao_enviada: props.ui.alert_cotacao_enviada,
			tooltip_atualizar: false,
			cotacao_info_collapsed: false,
			pedido_info_collapsed: false,
			itens_info_collapsed: false,
			modal_nao_aceita_parcial: false,
			nao_aceita_parcial_compra_minima: 0,
			tab_perfil_construtor: 0,
		};
		this.transporte_opcoes = [
			{ value: 0, view: 'Frete incluso no preço (CIF)' },
			{ value: 1, view: 'Frete a pagar' },
			{ value: 2, view: 'Frete por conta do cliente' },
		];
		this.updateViewRecusada = this.updateViewRecusada.bind(this);
		this.expandInfo = this.expandInfo.bind(this);
		this.confirmaEdicao = this.confirmaEdicao.bind(this);
		this.resetaCotacao = this.resetaCotacao.bind(this);
		this.confirmarOC = this.confirmarOC.bind(this);
		this.showTooltip = this.showTooltip.bind(this);
		this.hideTooltip = this.hideTooltip.bind(this);
		this.retornaSolicitacao = this.retornaSolicitacao.bind(this);
		this.handleModalNaoAceitaParcial = this.handleModalNaoAceitaParcial.bind(this);
		this.openPerfilConstrutor = this.openPerfilConstrutor.bind(this);
		this.reloadFullPage = this.reloadFullPage.bind(this);
	}

	componentDidMount() {
		document.title = 'Cotação - Conaz';
		document.body.style.background = '#f6f6f6';
		window.scrollTo(0, 0);
	}

	componentWillUnmount() {
		document.body.style.background = '';
		this.props.updateUi(['alert_cotacao_enviada'], false);
		this.props.updateModals('alerta_nome_comercial', false);
	}

	updateViewRecusada() {
		this.setState({ oc_recusada: true });
	}

	handleChangeUrl = compra_id => {
		const { history, match } = this.props;
		history.push(`/proposta/${match.params.id}/ver/${compra_id}`);
		this.reloadFullPage();
	}

	retornaSolicitacao(responses) {
		const rc = { ...responses[0].data };
		const { status, uuid, pedido, estah_resetado, fornecedor } = rc;
		const { compra_id } = this.props.match.params;
		if (
			status === 0
			|| status === 10
			|| status === 20
			|| status === 30
			|| status === 35
			|| estah_resetado
		) {
			this.props.history.push(`/cotacao/${uuid}`);
			return;
		}
		document.title = `#${pedido.codigo} - Cotação - Conaz`;
		const itens_de_pedido_object = this.constructor.buildItensDePedidoObject(pedido.itens_de_pedido);
		const compras_visiveis = (rc.compras || [])
			.filter(compra => {
				const status_compra = defineStatusCompra(compra);
				return (
					status_compra === 'aguardando_confirmacao'
					|| status_compra === 'confirmada'
					|| status_compra === 'entrega_confirmada'
					|| status_compra === 'finalizada'
				);
			});

		const info_collapsed = compras_visiveis.length > 0;
		this.setState({
			rc,
			itens_de_pedido_object,
			cotacao_info_collapsed: info_collapsed,
			pedido_info_collapsed: info_collapsed,
			itens_info_collapsed: info_collapsed,
		});
		const compra_to_show = compras_visiveis.find(compra => compra.id === parseInt(compra_id, 10)) || compras_visiveis[0];
		this.setState({ compra: compra_to_show, compras_visiveis });

		/**
		 * Alert não aceita compra parcial
		 */
		if (info_collapsed) {
			this.checkSeECompraParcial(compra_to_show);
		}

		setTimeout(() => this.showModalUpdateNomeComercial(fornecedor), 0);
	}

	showModalUpdateNomeComercial(fornecedor) {
		const { nome } = fornecedor;
		const { empresas_by_id } = this.props.cadastro;
		const nome_only_letters = nome.toUpperCase().replace(/[^a-zA-z\s]/g, '');
		const nome_splitted = nome_only_letters.split(' ').filter(word => word !== '');
		const last_word = nome_splitted.length > 0 ? nome_splitted[nome_splitted.length - 1] : '';

		if (isAuthenticated() && ['ME', 'SA', 'LTDA'].indexOf(last_word) !== -1 && empresas_by_id.indexOf(fornecedor.id) !== -1) {
			this.props.updateModals(['alerta_nome_comercial'], true);
			setLocalstorageData('fornecedor_id', fornecedor.id);
		}
	}

	checkSeECompraParcial(compra) {
		const compra_id = (compra || {}).id || 0;
		const status_compra = compra_id !== 0
			? defineStatusCompra(compra)
			: null;
		const aguardando_confirmacao = status_compra === 'aguardando_confirmacao';

		if (compra_id !== 0 && aguardando_confirmacao) {
			_post(`/conaz/v2/compras/${compra_id}/verifica_se_eh_parcial`).then(response => {
				const eh_parcial = response.data || false;
				if (eh_parcial) {
					this.checkNaoAceitaParcial(compra);
				}
			}).catch(error => {
				console.log(error); // eslint-disable-line
			});
		}
	}

	checkNaoAceitaParcial(compra) {
		const { valor_total } = (compra || {});
		const condicao_de_pagamento = (compra || {}).condicao_de_pagamento || {};
		const { aceita_parcial, compra_minima } = condicao_de_pagamento;

		/**
		 * Não aceita compra parcial
		 */
		if (aceita_parcial === 0) {
			this.setState({ modal_nao_aceita_parcial: true });
			return;
		}

		/**
		 * Aceita mas depende do valor
		 */
		if (aceita_parcial === 2 && compra_minima > valor_total) {
			this.setState({
				modal_nao_aceita_parcial: true,
				nao_aceita_parcial_compra_minima: compra_minima,
			});
		}
	}

	handleModalNaoAceitaParcial() {
		const modal_nao_aceita_parcial = !this.state.modal_nao_aceita_parcial;
		this.setState({ modal_nao_aceita_parcial });
	}

	expandInfo(field) {
		const value = !this.state[field];
		this.setState({ [field]: value });
	}

	confirmaEdicao(value) {
		if (isAuthenticated()) {
			this.setState({ confirma_edicao: value, cotacao_info_collapsed: false });
		} else {
			this.props.updateModals('reset_rc', true);
		}
	}

	resetaCotacao() {
		this.setState({ resetando: true, resetando_error: false });
		const rc_id = this.props.match.params.id;
		resetStatus(rc_id).then(() => {
			this.props.history.push(`/cotacao/${rc_id}`);
		}).catch(error => {
			this.setState({ resetando: false, resetando_error: true });
			setTimeout(() => this.setState({ resetando_error: false }), 5000);
			console.log(error); // eslint-disable-line
		});
	}

	confirmarOC(compra_id) {
		/**
		 * Verifica se usuário logado diferente do RC
		 */
		const { usuario } = this.state.rc;
		const vendedor_id = Number(getLocalstorageData('user', 'id'));
		if (usuario.id !== vendedor_id && isAuthenticated()) {
			this.setState({ usuario_diferente: true });
			return;
		}
		/**
		 * Confirma ordem de compra
		 */
		this.setState({ confirmando_oc: true, error_confirmando_oc: false });
		confirmarCompra(compra_id).then(() => {
			this.setState({ oc_confirmada: true, confirmando_oc: false });
			this.props.updateModals('confirmacao_venda', false);
		}).catch(error => {
			/**
			 * Compra já confirmada
			 */
			const message = (error.response && error.response.data && error.response.data.message)
				? error.response.data.message
				: null;
			if (message === 'Compra confirmada anteriormente') {
				this.setState({ oc_confirmada: true, confirmando_oc: false });
				this.props.updateModals('confirmacao_venda', false);
				return;
			}
			/**
			 * Erro
			 */
			console.log(error); // eslint-disable-line
			this.setState({ confirmando_oc: false, error_confirmando_oc: true });
			reportarErro(error);
		});
	}

	showTooltip() {
		this.setState({ tooltip_atualizar: true });
	}

	hideTooltip() {
		this.setState({ tooltip_atualizar: false });
	}

	openPerfilConstrutor(tab = 0) {
		const { updateModals } = this.props;
		this.setState({ tab_perfil_construtor: tab });
		updateModals('perfil_construtor', true);
	}

	reloadFullPage() {
		const rc = {
			...this.state.rc,
		};
		delete rc.uuid;
		this.setState({ rc });
	}

	render() {
		const { id } = this.props.match.params;
		const loaded = this.state.rc.uuid !== undefined;

		return (
			<Wrapper
				loaded={loaded}
				promises={[() => getRC(id)]}
				buildResponse={this.retornaSolicitacao}
				{...this.props}>

				<Review
					{...this.state}
					{...this.props}
					handleChangeUrl={this.handleChangeUrl}
					isAuthenticated={isAuthenticated}
					transporte_opcoes={this.transporte_opcoes}
					confirmaEdicao={this.confirmaEdicao}
					resetaCotacao={this.resetaCotacao}
					confirmarOC={this.confirmarOC}
					showTooltip={this.showTooltip}
					hideTooltip={this.hideTooltip}
					expandInfo={this.expandInfo}
					handleModalNaoAceitaParcial={this.handleModalNaoAceitaParcial}
					openPerfilConstrutor={this.openPerfilConstrutor}
					updateViewRecusada={this.updateViewRecusada} />

			</Wrapper>
		);
	}
}

ReviewContainer.propTypes = {
	ui: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	updateModals: PropTypes.func.isRequired,
	updateUi: PropTypes.func.isRequired,
	cadastro: PropTypes.object.isRequired,
};

const mapStateToProps = props => ({
	cadastro: props.cadastro,
	ui: props.ui,
});

const mapDispatchToProps = dispatch => ({
	resetCadastro: () => dispatch(actions.resetCadastro()),
	updateCadastro: (field, value) => dispatch(actions.updateCadastro(field, value)),
	updateUi: (field, value) => dispatch(actions.updateUi(field, value)),
	updateModals: (modal, value) => dispatch(actions.updateModals(modal, value)),
	reportErrors: (page, error, codigo_pedido, full_error, test) => dispatch(actions.reportErrors(page, error, codigo_pedido, full_error, test)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewContainer);
