import { _post } from '../../services/apis/requests';
import { getLocalstorageData, setLocalstorageData } from './_getLocalstorageData';
import { reportarErro } from '../../services/apis/http';
import * as actions from '../../actions/index';


export default (
	cotacao,
	setEnviando,
	enviandoBool,
	errorBool,
	handleModalPedidoEmEdicao,
	handleAlerts,
) => {
	setEnviando(enviandoBool); // funcao que seta estado enviando

	const { uuid } = cotacao.rc;
	_post(`/conaz/v2/rcs/${uuid}/finalizacao`, {}).then(() => {
		actions.updateUi(['alert_cotacao_enviada'], true);
		const { fornecedor_selecionado } = cotacao;

		const _vendedor_fornecedores = getLocalstorageData('user', 'fornecedores')
			.filter(x => x.fornecedor.status >= 0);
		const filtra_associados_pelo_construtor = _vendedor_fornecedores
			.filter(x => x.tipo_associacao !== 4);
		const vendedor_fornecedores = filtra_associados_pelo_construtor.length > 0
			? [...filtra_associados_pelo_construtor]
			: [..._vendedor_fornecedores];

		let fornecedor;
		if (vendedor_fornecedores.length > 1) {
			([{ fornecedor }] = vendedor_fornecedores.filter(f => f.fornecedor.id === fornecedor_selecionado.id));
		} else {
			([{ fornecedor }] = vendedor_fornecedores);
		}
		setLocalstorageData('fornecedor_id', fornecedor.id);
		const status_vendedor = (vendedor_fornecedores[0] || {}).status || 0;
		if (status_vendedor === 0) {
			// history.push(`/c/${uuid}`);
			window.location = `/c/${uuid}`;
		} else if (status_vendedor === 10) {
			// history.push(`/c/${uuid}/2`);
			window.location = `/c/${uuid}/2`;
		} else {
			// history.push('/solicitacoes');
			window.location = '/solicitacoes';
		}
	}).catch(error => {
		setEnviando(errorBool);
		const error_conaz = (((error || {}).response || {}).data || {}).message || '';
		if (error_conaz === 'RC incompleto: itens' || error_conaz === 'RC incompleto: informacoes gerais') {
			handleAlerts();
			return;
		}
		if (error_conaz === 'Pedido não está pronto') {
			handleModalPedidoEmEdicao();
			return;
		}
		console.log(error); // eslint-disable-line
		actions.updateModals('error', true);
		reportarErro(error);
	});
};
