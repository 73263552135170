export const maskPhone = value => {
	const only_numbers = value.replace(/[^\d]/g, '');
	if (only_numbers.substring(0, 3) === '400') {
		const x = only_numbers.match(/(\d{0,4})(\d{0,4})/);
		return `${x[1]}${x[2] ? `-${x[2]}` : ''}`;
	}
	if (['0300', '0500', '0800', '0900'].indexOf(only_numbers.substring(0, 4)) !== -1) {
		const x = only_numbers.match(/(\d{0,4})(\d{0,3})(\d{0,4})/);
		return !x[2] ? x[1] : `${x[1]} ${x[2]}${x[3] ? ` ${x[3]}` : ''}`;
	}
	if (only_numbers.length < 11) {
		const x = only_numbers.match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
		return !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? `-${x[3]}` : ''}`;
	}
	const x = only_numbers.match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
	return !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? `-${x[3]}` : ''}`;
};
