import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';


import BotaoCriaCotacao from './BotaoCriaCotacao';
import BotaoLinkCotacao from './BotaoLinkCotacao';

const SolicitacoesRow = ({
	children,
	titulo,
	rc_id,
	status,
	data_de_entrega_desejada,
	documento_comprador,
	codigo_pedido,
	nome_cliente,
	quantidade_itens,
	prazo_de_resposta,
	cidade_obra,
	uf_obra,
	data_fechamento_compra,
	criarRC,
}) => {
	const data_entrega_split = data_de_entrega_desejada.split('-');
	const data_entrega = `${data_entrega_split[2]}/${data_entrega_split[1]}`;
	const localizacao = `${cidade_obra || ''}/${uf_obra || ''}`;
	// documento_comprador
	const documento = (documento_comprador !== null && documento_comprador !== undefined) ? documento_comprador.replace(/[^0-9]/g, '') : '0';
	return (
		<div styleName="solicitacao-block expand">

			{
				React.Children.map(children, child => React
					.cloneElement(child, { data_fechamento_compra, prazo_de_resposta, status }))
			}
			<div styleName="infos">
				<h4>{titulo}</h4>
				<div styleName="infos-wrapper">
					{
						rc_id ? (
							<BotaoLinkCotacao rc_id={rc_id} status={status} />
						) : (
							<BotaoCriaCotacao criarRC={criarRC} codigo_pedido={codigo_pedido} />
						)
					}
					<div styleName="info">
						<span>Itens</span><br /><b>{quantidade_itens}</b>
					</div>
					<div styleName="info">
						<span>Código</span><br /><b>#{codigo_pedido}</b>
					</div>
					<div styleName="info entrega">
						<span>Entrega</span><br /><b>{data_entrega}</b>
					</div>
					<div styleName="cliente">
						<span>
							<i
								className={`fa fa-${documento.length === 11 ? 'user' : 'building'} fa-fw`}
								aria-hidden="true" />
							{nome_cliente}
						</span>
						{localizacao !== '/' && (
							<React.Fragment>
								<br />
								<span>
									<i
										className="fa fa-map-marker fa-fw"
										aria-hidden="true" />
									{localizacao}
								</span>
							</React.Fragment>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

SolicitacoesRow.propTypes = {
	codigo_pedido: PropTypes.number,
	rc_id: PropTypes.string,
	titulo: PropTypes.string,
	status: PropTypes.number,
	data_de_entrega_desejada: PropTypes.string,
	quantidade_itens: PropTypes.number,
	nome_cliente: PropTypes.string,
	documento_comprador: PropTypes.string,
	prazo_de_resposta: PropTypes.string,
	children: PropTypes.element,
	cidade_obra: PropTypes.string,
	uf_obra: PropTypes.string,
	criarRC: PropTypes.func,
	data_fechamento_compra: PropTypes.string,
};

SolicitacoesRow.defaultProps = {
	codigo_pedido: 0,
	status: 0,
	rc_id: '',
	titulo: '',
	data_de_entrega_desejada: '',
	quantidade_itens: 0,
	nome_cliente: '',
	documento_comprador: '',
	prazo_de_resposta: '',
	children: null,
	cidade_obra: '',
	uf_obra: '',
	data_fechamento_compra: '',
	criarRC: () => {},
};

export default CSSModules(SolicitacoesRow, styles, { allowMultiple: true });
