import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

import { maskPhone } from '../../../_functions/_maskPhone';

class InputFieldPhone extends Component {
	static bloqueiaEnter(e) {
		const key = e.charCode || e.keyCode || 0;
		if (key === 13) {
			e.preventDefault();
		}
	}

	render() {
		const { label, additional_class, obrigatorio, default_value } = this.props;
		const input_class = additional_class !== '' ? additional_class : '';
		const label_class = additional_class !== '' ? `label-${additional_class}` : '';
		const is_obrigatorio = obrigatorio ? (
			<span styleName="obrigatorio-label">obrigatório</span>
		) : (
			''
		);

		return (
			<div className="form-group" styleName="form-group">
				<label styleName={label_class}>
					{label} {is_obrigatorio}
				</label>
				<input
					type="text"
					className="form-control"
					styleName={input_class}
					maxLength="15"
					value={maskPhone(default_value)}
					onChange={e => this.props.auxiliarFunction(maskPhone(e.target.value))}
					onKeyPress={this.constructor.bloqueiaEnter} />
			</div>
		);
	}
}

InputFieldPhone.propTypes = {
	label: PropTypes.string.isRequired,
	additional_class: PropTypes.string,
	default_value: PropTypes.string,
	obrigatorio: PropTypes.bool,
	auxiliarFunction: PropTypes.func,
};

InputFieldPhone.defaultProps = {
	additional_class: '',
	obrigatorio: false,
	auxiliarFunction: () => {},
	default_value: '',
};

export default CSSModules(InputFieldPhone, styles, { allowMultiple: true });
