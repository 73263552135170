import { login as loginRequest, me } from './apis/auth';


export const getToken = () => localStorage.getItem('token');

export const isAuthenticated = () => getToken();

export const login = (email, senha) => loginRequest(email, senha)
	.then(response => {
		const { usuario } = response.data;
		localStorage.setItem('token', response.data.token);
		localStorage.setItem('user', JSON.stringify(usuario));

		const fornecedor_id = localStorage.getItem('fornecedor_id');
		let _fornecedor;
		if (!fornecedor_id) {
			_fornecedor = (usuario.fornecedores[0] || {}).fornecedor;
		} else {
			const find_fornecedor = usuario.fornecedores.filter(fu => fu.fornecedor.id === fornecedor_id);
			if (find_fornecedor.length === 0) {
				_fornecedor = usuario.fornecedores[0].fornecedor;
			} else {
				_fornecedor = find_fornecedor[0].fornecedor;
			}
		}
		localStorage.setItem('fornecedor_id', (_fornecedor || {}).id);
		localStorage.setItem('fornecedor', JSON.stringify(_fornecedor));

		return response;
	});

export const logarComoUsuario = (token) => {
	localStorage.setItem('token', token);
	return me()
		.then(response => {
			localStorage.setItem('user', JSON.stringify(response.data));
			return response;
		});
};

export const logout = () => {
	localStorage.removeItem('token');
	localStorage.removeItem('user');
	localStorage.removeItem('fornecedor');
	localStorage.removeItem('fornecedor_id');
};
