import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from '../style.module.scss';

// Functions
import { buildOpcaoPreenchida } from '../../../_functions/_buildOpcaoPreenchida';

class SelectOption extends Component {
	constructor() {
		super();
		this.handleOption = this.handleOption.bind(this);
	}

	handleOption(e) {
		e.preventDefault();
		const { type, option, handleEdition } = this.props;
		switch (type) {
		case 'cotacao': {
			const _option = buildOpcaoPreenchida(option.valor, option.unidade, option.id);
			handleEdition([_option]);
			break;
		}
		case 'item': {
			const _option = buildOpcaoPreenchida(option.valor, option.unidade, null);
			handleEdition([_option]);
			break;
		}
		case 'caracteristica': {
			handleEdition([option]);
			// const _option = buildOpcaoPreenchida(option.valor, option.unidade, option.id);
			// handleEdition([_option]);
			break;
		}
		default:
		}
	}

	render() {
		const { unidade, valor } = this.props.option;
		const option_view = `${valor} ${unidade}`;
		return (
			<li data-dropchild>
				<Link to="#" onClick={this.handleOption} styleName={option_view === this.props.option_view && 'selected'}>{option_view}</Link>
			</li>
		);
	}
}

SelectOption.propTypes = {
	type: PropTypes.string.isRequired,
	option: PropTypes.object.isRequired,
	handleEdition: PropTypes.func.isRequired,
	option_view: PropTypes.string,
};

SelectOption.defaultProps = {
	option_view: '',
};

export default CSSModules(SelectOption, styles, { allowMultiple: true });
