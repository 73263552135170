import axios from 'axios';

const territorios_api = axios.create({
	baseURL: process.env.TERRITORIO_BASEURL,
});

const viacep_api = axios.create({
	headers: '',
});

export const getLogradouro = (cep) => viacep_api.get(`https://viacep.com.br/ws/${cep}/json/`);

export const getMesorregioes = (uf) => territorios_api.get(`uf/${uf}/mesorregioes/`);

export const getMunicipios = (mesorregiao) => territorios_api.get(`/mesorregiao/${mesorregiao}/municipios/`);

export const getSubregioes = (municipio) => territorios_api.get(`/municipio/${municipio}/subregioes/`);

export const getMunicipio = (municipio) => territorios_api.get(`/municipio/${municipio}/`);
