/* eslint-disable no-unused-vars */
/* eslint class-methods-use-this: 0 */
import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';

// Functions
import { isAuthenticated } from '../../services/login_manager';

const withLoadCotacao = (Comp) => (
	class PP extends Component {
		constructor() {
			super();
			this.state = {};
		}

		handleResponsesHoc = (responses, history, updateCotacao) => {
			const cotacao = { ...responses[0].data };
			const { location: { pathname } } = history;
			const estah_em_revisao = (pathname || '').includes('/revisao');

			if ((
				cotacao.status === -20
				|| cotacao.status === 40
				|| cotacao.status === 50
				|| cotacao.status === 60
			) &&
				!cotacao.estah_resetado
			) {
				history.replace(`/proposta/${cotacao.uuid}/ver`);
				return;
			}

			const codigo = (cotacao.pedido || {}).codigo || '';
			document.title = `#${codigo} - Cotação - Conaz`;

			this.buildItensResposta(cotacao.itens_de_resposta, updateCotacao);
			const anexos = cotacao.anexos.map((a, key) => {
				let { url } = a;
				if (url.substring(0, 8) !== 'https://') url = `https://${a.url}`;
				return { ...a, key, url, file: null, progress: 0, success: true, error: false };
			});
			const validade = cotacao.validade !== null ? moment(cotacao.validade) : moment('2016-01-01');
			const condicoes_de_pagamento_by_id = cotacao.condicoes_de_pagamento.reduce((result, current) => ({
				...result,
				[current.id]: { ...current },
			}), {});
			const condicoes_de_pagamento = Object.keys(condicoes_de_pagamento_by_id).map(id => Number(id));
			const _cotacao = {
				...cotacao,
				anexos,
				validade,
				condicoes_de_pagamento_by_id,
				condicoes_de_pagamento,
			};
			updateCotacao(['rc'], _cotacao);

			if (!isAuthenticated() && estah_em_revisao) {
				history.replace(`/cotacao/${cotacao.uuid}`);
				return;
			}

			if (cotacao.status === 36) {
				history.replace(`/cotacao/${cotacao.uuid}/revisao`);
				return;
			}

			if (cotacao.status === 37) {
				history.replace(`/cotacao/${cotacao.uuid}`);
			}
		}

		buildItensResposta(itens_de_resposta, updateCotacao) {
			const itens_observacoes = [];
			const by_id = itens_de_resposta.reduce((result, current) => {
				// todas as características
				const { caracteristicas } = current.item_de_pedido.item_preenchido.item;
				const carac_preenchidas_by_id_1 = caracteristicas
					.filter(c => c.visao_fornecedor === 1 || c.visao_fornecedor === 2)
					.reduce((r, c) => ({
						...r,
						[c.id]: {
							caracteristica: { ...c },
							opcoes_preenchidas: [],
						},
					}), {});

				// características já preenchidas
				let carac_preenchidas_by_id_2 = {};
				if (current.item_preenchido.caracteristicas_preenchidas !== undefined) {
					const { caracteristicas_preenchidas } = current.item_preenchido;
					carac_preenchidas_by_id_2 = caracteristicas_preenchidas
						.reduce((r, c) => ({ ...r, [c.caracteristica.id]: c }), {});
				}

				// junta todas, colocando as já preenchidas em segundo para forçar os valores
				const carac_preenchidas_by_id = { ...carac_preenchidas_by_id_1, ...carac_preenchidas_by_id_2 };
				const carac_preenchidas_all_ids = Object.keys(carac_preenchidas_by_id).map(id => id);
				if (current.informacoes_adicionais !== '') itens_observacoes.push(current.id);
				const _current = {
					...current,
					item_preenchido: {
						item: current.item_de_pedido.item_preenchido.item,
						caracteristicas_preenchidas: carac_preenchidas_by_id,
						caracteristicas_preenchidas_all_ids: carac_preenchidas_all_ids,
					},
				};
				return { ...result, [current.id]: _current };
			}, {});
			const all_ids = Object.keys(by_id)
				.sort((a, b) => by_id[a].item_de_pedido.ordem - by_id[b].item_de_pedido.ordem)
				.map(id => id);
			updateCotacao(['itens_by_id'], by_id);
			updateCotacao(['itens_all_ids'], all_ids);
			updateCotacao(['itens_observacoes'], itens_observacoes);
		}

		render() {
			return (
				<Comp
					{...this.state}
					{...this.props}
					handleResponsesHoc={this.handleResponsesHoc} />
			);
		}
	}
);

export default withLoadCotacao;
