import React, { useState } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Components
import LoadingButton from '../_loadings/LoadingButton/LoadingButton';

// Functions
import { _post } from '../../../services/apis/requests';
import { getLocalstorageData, setLocalstorageData } from '../../_functions/_getLocalstorageData';
import { reportarErro } from '../../../services/apis/http';

const EnviaRCButton = (props) => {
	const [enviando, setEnviando] = useState(0);

	const postRC = () => {
		const {
			cotacao,
			updateUi,
			history,
			updateModals,
			handleModalPedidoEmEdicao,
			handleAlerts,
			validateSteps,
		} = props;

		const check = validateSteps(4);

		if (!check) {
			handleAlerts();
			return;
		}

		setEnviando(1);
		const { uuid } = cotacao.rc;
		_post(`/conaz/v2/rcs/${uuid}/finalizacao`, {}).then(() => {
			updateUi(['alert_cotacao_enviada'], true);
			const { fornecedor_selecionado } = cotacao;

			const _vendedor_fornecedores = getLocalstorageData('user', 'fornecedores')
				.filter(x => x.fornecedor.status >= 0);
			const filtra_associados_pelo_construtor = _vendedor_fornecedores
				.filter(x => x.tipo_associacao !== 4);
			const vendedor_fornecedores = filtra_associados_pelo_construtor.length > 0
				? [...filtra_associados_pelo_construtor]
				: [..._vendedor_fornecedores];

			let fornecedor;
			if (vendedor_fornecedores.length > 1) {
				([{ fornecedor }] = vendedor_fornecedores.filter(f => f.fornecedor.id === fornecedor_selecionado.id));
			} else {
				([{ fornecedor }] = vendedor_fornecedores);
			}
			setLocalstorageData('fornecedor_id', fornecedor.id);
			const status_vendedor = (vendedor_fornecedores[0] || {}).status || 0;
			if (status_vendedor === 0) {
				history.push(`/c/${uuid}`);
			} else if (status_vendedor === 10) {
				history.push(`/c/${uuid}/2`);
			} else {
				history.push('/solicitacoes');
			}
		}).catch(error => {
			setEnviando(3);
			const error_conaz = (((error || {}).response || {}).data || {}).message || '';
			if (error_conaz === 'RC incompleto: itens' || error_conaz === 'RC incompleto: informacoes gerais') {
				handleAlerts();
				return;
			}
			if (error_conaz === 'Pedido não está pronto') {
				handleModalPedidoEmEdicao();
				return;
			}
			console.log(error); // eslint-disable-line
			updateModals('error', true);
			reportarErro(error);
		});
	};

	function atualizaUsuarioFornecedor(fornecedor_id, usuario_id, send_rc, uuid) {
		const params = { fornecedor_id, usuario_id };
		const url = `/conaz/v2/rcs/${uuid}/alterar_fornecedor_vendedor`;
		_post(url, params).then(() => {
			postRC();
		}).catch(() => {
			postRC();
		});
	}

	const checkVendedor = () => {
		const { cotacao } = props;
		const { rc, fornecedor_selecionado } = cotacao;
		const { uuid } = rc;

		const _vendedor_fornecedores = getLocalstorageData('user', 'fornecedores')
			.filter(x => x.fornecedor.status >= 0);
		const filtra_associados_pelo_construtor = _vendedor_fornecedores
			.filter(x => x.tipo_associacao !== 4);
		const vendedor_fornecedores = filtra_associados_pelo_construtor.length > 0
			? [...filtra_associados_pelo_construtor]
			: [..._vendedor_fornecedores];

		if (vendedor_fornecedores.length > 1) {
			const vendedor_id = getLocalstorageData('user', 'id');
			atualizaUsuarioFornecedor(fornecedor_selecionado.id, vendedor_id, true, uuid);
			return;
		}

		const vendedor_id = getLocalstorageData('user', 'id');
		const fornecedor_id = (((vendedor_fornecedores || [])[0] || {}).fornecedor || {}).id || null;
		if (
			((cotacao.rc || {}).usuario || {}).id !== vendedor_id
			&& fornecedor_id !== null
		) {
			atualizaUsuarioFornecedor(fornecedor_id, vendedor_id, true, uuid);
			return;
		}

		postRC();
	};

	const initPostRC = () => {
		const { validateSteps, handleAlerts } = props;
		const check = validateSteps(4);
		if (!check) {
			handleAlerts();
			return;
		}

		setEnviando(1);
		checkVendedor();
	};

	const {
		current_page_key,
		isPreRefinamento,
		startSaving,
		waiting,
	} = props;

	const button_text = current_page_key === 3
		? 'Enviar cotação'
		: 'Continuar';

	const onClickFunc = current_page_key === 3
		? initPostRC
		: startSaving;

	if (waiting || enviando === 1) {
		return (
			<button
				type="button"
				className="btn btn-success btn-lg older-button"
				style={{ color: 'transparent' }}>
				<LoadingButton />{button_text} <i className="fa fa-angle-right fa-fw" aria-hidden="true" />
			</button>
		);
	}

	if (isPreRefinamento) return null;

	return (
		<button
			type="button"
			className="btn btn-success btn-lg older-button"
			onClick={onClickFunc}>
			{button_text} <i className="fa fa-angle-right fa-fw" aria-hidden="true" />
		</button>
	);
};

EnviaRCButton.propTypes = {
	isPreRefinamento: PropTypes.bool,
	current_page_key: PropTypes.number.isRequired,
	validateSteps: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	waiting: PropTypes.bool.isRequired,
	startSaving: PropTypes.func.isRequired,
	cotacao: PropTypes.object.isRequired,
	updateModals: PropTypes.func.isRequired,
	updateUi: PropTypes.func.isRequired,
	handleAlerts: PropTypes.func.isRequired,
	handleModalPedidoEmEdicao: PropTypes.func.isRequired,
};

EnviaRCButton.defaultProps = {
	isPreRefinamento: false,
};

export default CSSModules(EnviaRCButton, styles, { allowMultiple: true });
