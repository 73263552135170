import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { formatNumbers } from '../../../_functions/_formatNumber';
import { documentType } from '../../../_functions/_documentType';

class LeftModalConstrutorPerfil extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tab: props.tab,
		};
		this.changeTab = this.changeTab.bind(this);
	}

	changeTab(e) {
		const tab = Number(e.currentTarget.getAttribute('data-tab'));
		this.setState({ tab });
	}

	render() {
		const {
			tab,
		} = this.state;

		const {
			exibir_contatos_pedido,
			exibir_contatos_compra,
			obra,
			usuario_pedido,
			usuario_compra,
		} = this.props;

		const {
			nome,
			endereco,
			pessoas,
			metragem,
			cliente,
			usuario_financeiro,
			usuario_responsavel,
		} = obra;

		/**
		 * Cliente 1 = Responsável Obra
		 *
		 *  - filtra usuários por papel = 1
		 *  - pega nome
		 *  - tamanho do documento para verificação
		 */
		const c1 = (pessoas.filter(p1 => p1.papeis.filter(p2 => p2 === 1).length > 0)[0] || {});
		const c1_nome = (c1.pessoa || {}).nome || '';
		const c1_razao_social = (c1.pessoa || {}).razao_social || '';
		const c1_razao_social_view = c1_razao_social !== ''
			? <React.Fragment><span styleName="label">Razão Social:</span> {c1_razao_social}<br /></React.Fragment>
			: '';
		const c1_doc_type = documentType(c1.pessoa.documento) || '';
		const c1_documento = (
			c1.pessoa.documento !== '' &&
			c1.pessoa.documento !== undefined &&
			c1.pessoa.documento !== null &&
			c1_doc_type !== ''
		) ? <React.Fragment><span styleName="label">{c1_doc_type.toUpperCase()}:</span> {formatNumbers(c1.pessoa.documento, c1_doc_type)}</React.Fragment> : <em>Sem registro</em>;

		/**
		 * Cliente 2 = responsavel pagamento
		 *
		 *  - filtra usuários por papel = 2
		 *  - pega nome
		 *  - tamanho do documento para verificação
		 */
		const c2 = (pessoas.filter(p1 => p1.papeis.filter(p2 => p2 === 2).length > 0)[0] || {});
		const c2_nome = (c2.pessoa || {}).nome || '';
		const c2_razao_social = (c2.pessoa || {}).razao_social || '';
		const c2_razao_social_view = c2_razao_social !== ''
			? <React.Fragment><span styleName="label">Razão Social:</span> {c2_razao_social}<br /></React.Fragment>
			: '';
		const c2_doc_type = documentType(c2.pessoa.documento) || '';
		const c2_documento = (
			c2.pessoa.documento !== '' &&
			c2.pessoa.documento !== undefined &&
			c2.pessoa.documento !== null &&
			c2_doc_type !== ''
		) ? <React.Fragment><span styleName="label">{c2_doc_type.toUpperCase()}:</span> {formatNumbers(c2.pessoa.documento, c2_doc_type)} <br /></React.Fragment> : '';

		let c2_endereco = '';
		if (c2.pessoa.endereco !== undefined && c2.pessoa.endereco.id !== undefined) {
			const { bairro, cidade, estado, numero, logradouro, complemento } = c2.pessoa.endereco;
			c2_endereco = (
				<>
					<span styleName="label">Endereço da sede: </span>{`${logradouro}, nº ${numero}, ${bairro} - ${cidade}/${estado}`}
					{complemento !== '' && (
						<>
							<br />
							<span styleName="label">Complemento: </span>{complemento}
						</>
					)}
				</>
			);
		}

		const contatos_by_id = {};
		const contatos_all_ids = [];
		const contatos_faturamento_all_ids = [];
		const contatos_entrega_all_ids = [];

		const labels_map = {
			1: 'Solicitante da Cotação',
			2: 'Solicitante da Compra',
			3: 'Boletos | NFs',
			4: 'Responsável da Obra',
		};

		if (exibir_contatos_pedido && usuario_pedido !== null) {
			const { id } = usuario_pedido;
			contatos_by_id[id] = usuario_pedido;
			contatos_by_id[id].labels = [1];
			contatos_all_ids.push(id);
		}

		if (exibir_contatos_compra) {
			if (usuario_compra !== null && usuario_compra.id !== undefined) {
				const { id } = usuario_compra;
				if (contatos_all_ids.filter(x => x === id).length === 0) {
				// if (contatos_all_ids.indexOf(id) === -1) {
					contatos_by_id[id] = usuario_compra;
					contatos_by_id[id].labels = [2];
					contatos_all_ids.push(id);
				} else {
					contatos_by_id[id].labels.push(2);
				}
			}

			if (usuario_financeiro.id !== undefined) {
				const { id } = usuario_financeiro;
				if (contatos_all_ids.filter(x => x === id).length === 0) {
				// if (contatos_all_ids.indexOf(id) === -1) {
					contatos_by_id[id] = usuario_financeiro;
					contatos_by_id[id].labels = [3];
					contatos_all_ids.push(id);
					contatos_faturamento_all_ids.push(id);
				} else {
					contatos_by_id[id].labels.push(3);
					contatos_faturamento_all_ids.push(id);
				}
			}

			if (usuario_responsavel.id !== undefined) {
				const { id } = usuario_responsavel;
				if (contatos_all_ids.filter(x => x === id).length === 0) {
				// if (contatos_all_ids.indexOf(id) === -1) {
					contatos_by_id[id] = usuario_responsavel;
					contatos_by_id[id].labels = [4];
					contatos_all_ids.push(id);
					contatos_entrega_all_ids.push(id);
				} else {
					contatos_by_id[id].labels.push(4);
					contatos_entrega_all_ids.push(id);
				}
			}
		}

		const has_endereco = endereco !== null && endereco !== undefined && endereco.logradouro !== undefined;
		const _rua = (has_endereco && endereco.logradouro !== null) ? endereco.logradouro : '';
		const _numero = (has_endereco && endereco.numero !== null) ? endereco.numero : '';
		const _bairro = (has_endereco && endereco.bairro !== null) ? endereco.bairro : '';
		const _cidade = (has_endereco && endereco.cidade !== null) ? endereco.cidade : '';
		const _estado = (has_endereco && endereco.estado !== null) ? endereco.estado : '';
		const _complemento = (has_endereco && endereco.complemento !== null) ? endereco.complemento : '';
		const _link_gmaps = (has_endereco && endereco.link_gmaps !== null) ? endereco.link_gmaps : '';

		const quantidade_obras = cliente.quantidade_obras || 0;

		return (
			<div styleName="modal-content">
				<div styleName="content">
					<button
						type="button"
						className="close"
						styleName="close-button"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => this.props.updateModals('perfil_construtor', false)}>
						<span aria-hidden="true">&times;</span>
					</button>
					<div styleName="info">
						<img src={c1.pessoa.logo_url} width="180" alt="" />
					</div>
					<div styleName="info">
						<h3>{c1_nome}</h3>
						{quantidade_obras > 0 && (
							<React.Fragment>
								<span styleName="label">Obras ativas da construtora:</span> {quantidade_obras}
							</React.Fragment>
						)}
					</div>

					<div styleName="tabs-wrapper">
						<button
							styleName={tab === 1 ? 'tab-button open' : 'tab-button'}
							data-tab="1"
							onClick={this.changeTab}>
							<b>Faturamento</b>
							<p>Dados da Empresa e do responsável financeiro</p>
						</button>
						<button
							styleName={tab === 2 ? 'tab-button open' : 'tab-button'}
							data-tab="2"
							onClick={this.changeTab}>
							<b>Entrega</b>
							<p>Endereço da Obra e contato do responsável</p>
						</button>
						<button
							styleName={tab === 3 ? 'tab-button open' : 'tab-button'}
							data-tab="3"
							onClick={this.changeTab}>
							<b>Contatos</b>
							<p>Nomes, e-mails e telefones disponíveis</p>
						</button>
						{/**
						*
						* Faturamento
						*
						*/}
						{tab === 1 && (
							<div styleName="tab-content">
								<div styleName="info">
									<span styleName="label">Responsável pelo pagamento:</span>
									<h4>{c2_nome}</h4>
									{c2_razao_social_view}
									{c2_documento}
									{c2_doc_type === 'cpf' && ' (Pessoa física)'}
									{c2_endereco}
								</div>
								<hr />
								<div styleName="info">
									<span styleName="label">Contatos:</span><br />
									{contatos_faturamento_all_ids.length > 0 ? (contatos_faturamento_all_ids.map(cid => (
										<React.Fragment key={cid}>
											<b style={{ fontSize: '16px' }}>{contatos_by_id[cid].nome}</b><br />{contatos_by_id[cid].labels.map(l_id => <span key={l_id} styleName={`label-bs r-${l_id}`} className="label label-primary">{labels_map[l_id]}</span>)}<br />
											{contatos_by_id[cid].email}<br />
											{contatos_by_id[cid].telefones.length > 0 ? formatNumbers(contatos_by_id[cid].telefones[0].numero, 'telefone') : ''}
											<br /><br />
										</React.Fragment>
									))) : (
										<em>Construtor não disponibilizou contato nesta etapa</em>
									)}
								</div>
							</div>
						)}
						{/**
						*
						* Entrega
						*
						*/}
						{tab === 2 && (
							<div styleName="tab-content">
								<div styleName="info gmaps">
									<i className="fa fa-map-marker" aria-hidden="true" />
									<span styleName="label">Obra:</span><br />
									<b style={{ fontSize: '16px' }}>{nome}</b><br />
									{_rua !== '' && `${_rua}, `}
									{_numero !== '' && `nº ${_numero}, `}
									{_complemento !== '' && `${_complemento} - `}
									{_bairro !== '' && `${_bairro} - `}
									{_cidade !== '' && `${_cidade}`}
									{_estado !== '' && `/${_estado}`}
									{_complemento !== '' && `, complemento: ${_complemento}`}
									{metragem > 0 && (
										<React.Fragment>
											<br />
											<span styleName="label">Área da obra:</span> {metragem} m²
										</React.Fragment>
									)}
									{_link_gmaps !== '' &&
										<React.Fragment>
											<br /><a href={_link_gmaps} rel="noopener noreferrer" target="_blank">Ver mapa</a>
										</React.Fragment>
									}
								</div>
								<hr />
								<div styleName="info">
									<span styleName="label">Responsável pela obra:</span>
									<h4>{c1_nome}</h4>
									{c1_razao_social_view}
									{c1_documento}
									{c1_doc_type === 'cpf' && ' (Pessoa física)'}
								</div>
								<hr />
								<div styleName="info">
									<span styleName="label">Contatos:</span><br />
									{contatos_entrega_all_ids.length > 0 ? (contatos_entrega_all_ids.map(cid => (
										<React.Fragment key={cid}>
											<b style={{ fontSize: '16px' }}>{contatos_by_id[cid].nome}</b><br />{contatos_by_id[cid].labels.map(l_id => <span key={l_id} styleName={`label-bs r-${l_id}`} className="label label-primary">{labels_map[l_id]}</span>)}<br />
											{contatos_by_id[cid].email}<br />
											{contatos_by_id[cid].telefones.length > 0 ? formatNumbers(contatos_by_id[cid].telefones[0].numero, 'telefone') : ''}
											<br /><br />
										</React.Fragment>
									))) : (
										<em>Construtor não disponibilizou contato nesta etapa</em>
									)}
								</div>
							</div>
						)}
						{/**
						*
						* Contatos
						*
						*/}
						{tab === 3 && (
							<div styleName="tab-content">
								<div styleName="info">
									{contatos_all_ids.length > 0 ? (contatos_all_ids.map(cid => (
										<React.Fragment key={cid}>
											<b style={{ fontSize: '16px' }}>{contatos_by_id[cid].nome}</b><br />{contatos_by_id[cid].labels.map(l_id => <span key={l_id} styleName={`label-bs r-${l_id}`} className="label label-primary">{labels_map[l_id]}</span>)}<br />
											{contatos_by_id[cid].email}<br />
											{contatos_by_id[cid].telefones.length > 0 ? formatNumbers(contatos_by_id[cid].telefones[0].numero, 'telefone') : ''}
											<br /><br />
										</React.Fragment>
									))) : (
										<em>Construtor não disponibilizou contato nesta etapa</em>
									)}
								</div>
							</div>
						)}
					</div>
					{tab === 0 && (
						<div styleName="onboard-wrapper">
							<div styleName="onboard-1">
								<svg viewBox="0 0 100 125" x="0px" y="0px" fill="#dee3e8"><polygon points="61.18 49 9.66 49 27.77 30.91 26.36 29.49 5.82 50 26.36 70.51 27.77 69.09 9.66 51 61.18 51 61.18 49" /><rect x="89.18" y="49" width="4" height="2" /><rect x="65.18" y="49" width="4" height="2" /><rect x="81.18" y="49" width="4" height="2" /><rect x="73.18" y="49" width="4" height="2" /></svg>
								<p>Utilize os botões acima para encontrar mais informações sobre o construtor.</p>
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}

LeftModalConstrutorPerfil.propTypes = {
	obra: PropTypes.object.isRequired,
	updateModals: PropTypes.func,
	exibir_contatos_compra: PropTypes.bool,
	exibir_contatos_pedido: PropTypes.bool,
	usuario_compra: PropTypes.object,
	usuario_pedido: PropTypes.object,
	tab: PropTypes.number,
};

LeftModalConstrutorPerfil.defaultProps = {
	updateModals: () => {},
	exibir_contatos_compra: false,
	exibir_contatos_pedido: false,
	usuario_compra: null,
	usuario_pedido: null,
	tab: 0,
};

export default CSSModules(LeftModalConstrutorPerfil, styles, { allowMultiple: true });
