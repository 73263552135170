import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';


const ConfirmarCadastro = ({
	/* state */ send_state, confirmado,
	/* funcs */ reenviaEmail, redirecionaParaSolicitacoes,
}) => {
	const msgRedirSolicitacoes = 'Você está sendo redirecionado para suas solicitações em aberto';
	const msgRedirReviewCotacao = 'Você está sendo redirecionado para sua última cotação';

	const msgRedir = redirecionaParaSolicitacoes() ? msgRedirSolicitacoes : msgRedirReviewCotacao;

	return (
		<div>
			<div className="container" styleName="main-container">
				<div className="row">
					<div className="col-sm-12" style={{ textAlign: 'center' }}>
						<h2>{confirmado ? 'Email confirmado com sucesso' : 'Aguardando verificação de email'}</h2>
						<h5>{confirmado ? msgRedir : 'Por questões de segurança, verifique seu email e confirme seu cadastro clicando no link enviado.'}</h5>
					</div>
				</div>
				{send_state === 2 ? (
					<div className="row">
						<div className="col-sm-12" style={{ textAlign: 'center' }}>
							<div
								className="alert alert-success"
								role="alert"
								style={{ maxWidth: '460px', margin: '20px auto 20px auto' }}>
								<i className="fa fa-check fa-fw" aria-hidden="true" />
								Link enviado com sucesso!
							</div>
						</div>
					</div>
				) : (
					<div className="row">
						<div className="col-sm-12" style={{ textAlign: 'center' }}>
							<p><br /></p>
						</div>
					</div>
				)}
				{
					!confirmado &&
					<div className="row">
						<div
							className="col-sm-12"
							styleName="enviar-novamente"
							style={{ textAlign: 'center' }}>
							<p>Não recebeu?</p>
							{(send_state === 0 || send_state === 2) && (
								<button
									type="submit"
									className="btn btn-primary btn-lg"
									styleName="button-margin"
									onClick={reenviaEmail}>
									Enviar novamente
								</button>
							)}
							{send_state === 1 && (
								<button
									type="button"
									className="btn btn-primary btn-lg"
									styleName="button-margin disabled">
									Enviando...
								</button>
							)}
						</div>
					</div>
				}
			</div>
		</div>
	);
};

ConfirmarCadastro.propTypes = {
	send_state: PropTypes.number.isRequired,
	confirmado: PropTypes.bool.isRequired,
	reenviaEmail: PropTypes.func.isRequired,
	redirecionaParaSolicitacoes: PropTypes.func.isRequired,
};

export default CSSModules(ConfirmarCadastro, styles, { allowMultiple: true });
