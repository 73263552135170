import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

class Modal extends Component {
	constructor() {
		super();
		this.state = { show: false };
		this.closeModal = this.closeModal.bind(this);
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({ show: true });
		}, 60);
	}

	closeModal() {
		this.setState({ show: false });
		setTimeout(() => {
			this.props.handleModal(this.props.modal, false);
		}, 60);
	}

	render() {
		const { show } = this.state;
		const modal_class = show ? 'modal fade in' : 'modal fade';

		return (
			<div className={modal_class} styleName={modal_class} tabIndex="-1" role="dialog">
				<div className="modal-dialog" role="document">
					{React.cloneElement(this.props.children, { closeModal: this.closeModal })}
				</div>
			</div>
		);
	}
}

Modal.propTypes = {
	children: PropTypes.element.isRequired,
	modal: PropTypes.string.isRequired,
	handleModal: PropTypes.func.isRequired,
};

export default CSSModules(Modal, styles, { allowMultiple: true });
