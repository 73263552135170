import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

const ARAvaliacaoVendedor = ({
	avaliacao_vendedor,
}) => {
	const nota_vendedor = (avaliacao_vendedor.detalhe || {}).estrelas || 0;

	if (nota_vendedor === 0) {
		return (
			<div styleName="criterio">
				<span>Não avaliado</span>
				<p>Avaliação do atendimento</p>
			</div>
		);
	}

	return (
		<div styleName="criterio">
			{[1, 2, 3, 4, 5].map(n => {
				const start_class = n <= nota_vendedor
					? 'star active'
					: 'star';
				return (
					<i key={n} className="fa fa-star" styleName={start_class} aria-hidden="true" />
				);
			})}
			<p>Avaliação do atendimento</p>
		</div>
	);
};

ARAvaliacaoVendedor.propTypes = {
	avaliacao_vendedor: PropTypes.object.isRequired,
};

export default CSSModules(ARAvaliacaoVendedor, styles, { allowMultiple: true });
