import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const ModalGeral = ({ updateModals, icon, title, message }) => (
	<div className="modal-content" styleName="modal-content">
		<div className="modal-body" styleName="error-wrapper">
			<button
				type="button"
				className="close"
				styleName="close-button"
				data-dismiss="modal"
				aria-label="Close"
				onClick={() => updateModals('geral', false)}>
				<span aria-hidden="true">&times;</span>
			</button>
			<i className={`fa fa-${icon}`} aria-hidden="true" />
			<h3>{title}</h3>
			<p>{message}</p>
		</div>
	</div>
);

ModalGeral.propTypes = {
	updateModals: PropTypes.func,
	icon: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired,
};

ModalGeral.defaultProps = {
	updateModals: () => {},
};

export default CSSModules(ModalGeral, styles, { allowMultiple: true });
