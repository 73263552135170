import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const ModalError = ({ updateModals, title, message }) => (
	<div className="modal-content" styleName="modal-content">
		<div className="modal-body" styleName="error-wrapper">
			<button
				type="button"
				className="close"
				styleName="close-button"
				data-dismiss="modal"
				aria-label="Close"
				onClick={() => updateModals('error', false)}>
				<span aria-hidden="true">&times;</span>
			</button>
			<i className="fa fa-exclamation-circle" aria-hidden="true" />
			<h3>{title}</h3>
			<p>{message}</p>
			<div style={{ textAlign: 'center' }}>
				<div styleName="keyboards-wrapper">
					<div styleName="keyboards">
						<div styleName="type">Windows:</div>
						<div styleName="keys">
							<div styleName="key c89" style={{ width: '70px' }}><span>Ctrl</span></div>
							<div styleName="key-plus">+</div>
							<div styleName="key c89"><span>F5</span></div>
						</div>
					</div>
					<div styleName="keyboards center">
						<div styleName="type">Mac OS:</div>
						<div styleName="keys">
							<div styleName="key c89" style={{ width: '70px' }}><span>&#8984;</span></div>
							<div styleName="key-plus">+</div>
							<div styleName="key c89"><span>R</span></div>
						</div>
					</div>
					<div styleName="keyboards">
						<div styleName="type">Linux:</div>
						<div styleName="keys">
							<div styleName="key c89" style={{ width: '70px' }}><span>Ctrl</span></div>
							<div styleName="key-plus">+</div>
							<div styleName="key c89"><span>F5</span></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
);

ModalError.propTypes = {
	updateModals: PropTypes.func,
	title: PropTypes.string,
	message: PropTypes.string,
};

ModalError.defaultProps = {
	updateModals: () => {},
	title: 'Desculpe, tivemos um problema!',
	message: 'Atualize a página como descrito abaixo ou entre em contato com nossa equipe de suporte pelo chat.',
};

export default CSSModules(ModalError, styles, { allowMultiple: true });
