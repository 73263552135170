import axios from 'axios';
import { getLocalstorageData } from '../components/_functions/_getLocalstorageData';

import { UPDATE_USER, CHANGE_OBRA, SET_AUTH, UNSET_AUTH } from './action.types';

export const updateUser = (field, value) => ({ type: UPDATE_USER, field, value });

export const changeObra = (obra_id, tem_express) => ({ type: CHANGE_OBRA, obra_id, tem_express });

export const reportErrors = (page, error, codigo_pedido, full_error, test) => (dispatch, getState) => {
	const base_url = getLocalstorageData('environment', 'base_url');
	const { email, token } = getState().user;
	const error_text = `*Página:* ${page}
*Request:* ${error}
*E-mail do cliente:* ${email}
*Print do erro:* ${full_error}
----`;
	// if (page === 'Review de pedido' || page === 'Edição de pedido') {
		// error_text = `*Página:* ${page}\n
		// 	*Url:* ${error}\n
		// 	*Código do pedido:* ${codigo_pedido}\n
		// 	*E-mail do cliente:* ${email}\n
		// 	*Print do erro:* ${full_error}\n----`;
	// } else {
	// 	error_text = `*Página:* ${page}\n*Request:* ${error}\n*E-mail do cliente:* ${email}\n*Print do erro:* ${full_error}\n----`;
	// }
	axios.defaults.headers.common.email = email;
	axios.defaults.headers.common.token = token;
	const channel = test ? '#erros-dev' : '#erros';
	axios.post(`${base_url}/conaz/legacy/send_slack`, {
		channel,
		username: 'Clientes',
		text: error_text,
		icon_emoji: ':triangular_flag_on_post:',
	});
};

export const setAuth = (token, user, tem_express) => ({ type: SET_AUTH, token, user, tem_express });

export const unsetAuth = () => ({ type: UNSET_AUTH });
