import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import _ from 'lodash';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import InputText from '../_inputs/InputText/InputText';
import InputUnidadeItem from '../_inputs/InputUnidadeItem/InputUnidadeItem';

function ItemRowUnidade({
	item,
	updateItem,
	autoSaveTimeout,
}) {
	const [outra_unidade, setOutraUnidade] = useState(false);

	function handleOutraUnidade() {
		updateItem(item.id, 'unidade', '');
		autoSaveTimeout(item.id);
		setTimeout(() => {
			const field = `unidade_${item.id}`;
			if (document.getElementById(field)) {
				document.getElementById(field).focus();
			}
		}, 60);
		setOutraUnidade(!outra_unidade);
	}

	const { id, item_de_pedido, unidade } = item;
	const _unidade = unidade !== null ? unidade : '';
	const unidades = ((item_de_pedido.item_preenchido || {}).item || {}).unidades || [];

	return (
		<>
			<label>Unidade <span className="obrigatorio-label">*</span></label>
			{!outra_unidade && (
				<InputUnidadeItem
					field_id={`unidade_${id}`}
					item_id={id}
					field="unidade"
					default_value={_unidade}
					opcoes={unidades}
					updateFunction={updateItem}
					handleOutraUnidade={handleOutraUnidade}
					autoSave={autoSaveTimeout}
				/>
			)}
			{outra_unidade && (
				<InputText
					type="item"
					field_id={`unidade_${id}`}
					item_id={id}
					field="unidade"
					default_value={_unidade}
					updateFunction={updateItem}
					outro={outra_unidade}
					handleOutro={handleOutraUnidade}
					autoSaveTimeout={autoSaveTimeout}
				/>
			)}
		</>
	);
}

ItemRowUnidade.propTypes = {
	item: PropTypes.object.isRequired,
	updateItem: PropTypes.func.isRequired,
	autoSaveTimeout: PropTypes.func.isRequired,
};

export default CSSModules(ItemRowUnidade, styles, { allowMultiple: true });
