/*eslint-disable*/ 
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss'

class InputFieldMultiple extends Component {
	constructor() {
		super();
		this.state = {
			opcoes_local: [],
			selected_view: '',
			all_options_checked: false,
			search: ''
		};
		this.handleEditionStateAllOptions = this.handleEditionStateAllOptions.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.updateComponent(this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.opcoes_selecionadas !== nextProps.opcoes_selecionadas) {
			this.updateComponent(nextProps);
		}
	}

	updateComponent(props) {
		const { opcoes, opcoes_selecionadas } = props;
		const opcoes_selecionadas_string = `, ${opcoes_selecionadas.join(', ')},`;
		const opcoes_new = opcoes
			.map(opcao => {
				const checked = opcoes_selecionadas_string.indexOf(`, ${opcao.value},`) !== -1 ? true : false;
				return { view: opcao.view, value: opcao.value, order: 0, checked: checked }
			})
			.sort((a, b) => {
				if (a.view.toUpperCase() < b.view.toUpperCase()) { return -1 }
				if (a.view.toUpperCase() > b.view.toUpperCase()) { return 1 }
				return 0;
			});
		const selected_view = this.selectedViews(opcoes_new);
		const all_checked_check = this.allCheckedCheck(opcoes_new);
		// const selected_view = all_options_selected ? all_options_label : this.selectedViews(opcoes_new);
		this.setState({ opcoes_local: opcoes_new, selected_view: selected_view, all_options_checked: all_checked_check });
	}

	openDropdown() {
		const { dropdown_open } = this.props;
		if (dropdown_open && this.dropdown.className === 'dropdown clearfix') {
			const dropdowns = document.getElementsByClassName('dropdown clearfix open');
			for (let i = 0; i < dropdowns.length; i++) { dropdowns[i].className = 'dropdown clearfix'; }
		}
		if (this.dropdown.className === 'dropdown clearfix') {
			this.dropdown.className = 'dropdown clearfix open';
			this.props.updateUi('dropdown_open', true);
		} else {
			this.dropdown.className = 'dropdown clearfix';
			this.props.updateUi('dropdown_open', false);
		}
	}

	handleEditionState(opcao_value) {
		let opcoes_new = this.state.opcoes_local.slice();
		opcoes_new.map(opcao => {
			if (opcao.value === opcao_value) {
				opcao.checked = !opcao.checked;
			}
			return opcao;
		});
		const all_checked_check = this.allCheckedCheck(opcoes_new);
		const selected_view = this.selectedViews(opcoes_new);
		this.setState({ opcoes_local: opcoes_new, all_options_checked: all_checked_check, selected_view: selected_view });
		this.props.auxiliarFunction(this.props.list_keys[0], this.selectedValues(opcoes_new), this.props.list_keys[1], all_checked_check, this.props.list_keys[2]);
	}

	handleEditionStateAllOptions() {
		const all_checked = !this.state.all_options_checked ? true : false;
		let opcoes_new = this.state.opcoes_local.slice();
		opcoes_new.map(opcao => {
			opcao.checked = all_checked;
			return opcao;
		});
		const selected_view = this.selectedViews(opcoes_new);
		// const selected_view = all_checked ? this.props.all_options_label : this.selectedViews(opcoes_new);
		this.setState({ opcoes_local: opcoes_new, all_options_checked: all_checked, selected_view: selected_view });
		this.props.auxiliarFunction(this.props.list_keys[0], this.selectedValues(opcoes_new), this.props.list_keys[1], all_checked, this.props.list_keys[2]);
	}

	handleSearchState(typed) {
		this.setState({ search: typed });
	}

	searchedOptions() {
		const { opcoes_local, search } = this.state;
		return opcoes_local
			.filter(opcao => opcao.view.toLowerCase().indexOf(search.toLowerCase()) !== -1)
			.map((opcao, o) => (
				<li key={o} styleName="checkbox-wrapper" data-dropchild>
					{opcao.checked ? (
						<button styleName="checkbox-button checked" data-dropchild onClick={() => this.handleEditionState(opcao.value)}>
							<div styleName="checkbox-icon" data-dropchild><i className="fa fa-check" aria-hidden="true" data-dropchild></i></div>{opcao.view}
						</button>
					) : (
						<button data-dropchild styleName="checkbox-button" onClick={() => this.handleEditionState(opcao.value)}>
							<div styleName="checkbox-icon" data-dropchild></div>{opcao.view}
						</button>
					)}
				</li>
			));
	}

	allCheckedCheck(opcoes) {
		const opcoes_check = opcoes.filter(opcao => opcao.checked);
		if (opcoes.length === opcoes_check.length) { return true; }
		return false;
	}

	selectedValues(opcoes) {
		return opcoes
			.filter(opcao => opcao.checked)
			.map(opcao => opcao.value);
	}

	selectedViews(opcoes_local) {
		const opcoes_selecionadas = opcoes_local
			.filter(opcao => opcao.checked)
			.map(opcao => opcao.value);
		const opcoes_selecionadas_string = `, ${opcoes_selecionadas.join(', ')},`;
		return opcoes_local
			.filter(opcao => opcoes_selecionadas_string.indexOf(`, ${opcao.value},`) !== -1)
			.map(opcao => opcao.view)
			// .sort((a, b) => {
			// 	if (a.toUpperCase() < b.toUpperCase()) { return -1 }
			// 	if (a.toUpperCase() > b.toUpperCase()) { return 1 }
			// 	return 0;
			// })
			.join(', ')
			.replace(/, (?![\s\S]*, )/, ' e ');
	}

	render() {
		const { opcoes_local, all_options_checked, selected_view } = this.state;
		const { label, additional_class, all_options, all_options_label } = this.props;
		const dropdown_class = additional_class !== '' ? `dropdown ${additional_class}` : `dropdown`;

		return (
			<div className="form-group" styleName="form-group">
				<label>{label}</label>
				<div className="dropdown">
					<button
						className="btn btn-default dropdown-toggle"
						styleName={dropdown_class}
						type="button" aria-haspopup="true" aria-expanded="true"
						data-dropdown>
						{selected_view}<span className="caret" styleName="dropdown-caret" data-dropdown-2></span>
					</button>
					<ul className="dropdown-menu" styleName="dropdown-menu-multiple" data-dropchild>
						{opcoes_local.length > 20 && (
							<div styleName="dropdown-search" data-dropchild>
								<div styleName="top-border"></div>
								<li styleName="input" data-dropchild>
									<input type="text" className="form-control" placeholder="Busca..." data-dropchild
										onChange={e => this.handleSearchState(e.target.value)} />
								</li>
								<li role="separator" className="divider" data-dropchild />
							</div>
						)}
						<div styleName="dropdown-menu-list" data-dropchild>
							{all_options && (
								<li styleName="checkbox-wrapper" data-dropchild>
									{all_options_checked ? (
										<button styleName="checkbox-button checked" data-dropchild onClick={this.handleEditionStateAllOptions}>
											<div styleName="checkbox-icon" data-dropchild><i className="fa fa-check" aria-hidden="true" data-dropchild></i></div>{all_options_label}
										</button>
									) : (
										<button data-dropchild styleName="checkbox-button" onClick={this.handleEditionStateAllOptions}>
											<div styleName="checkbox-icon" data-dropchild></div>{all_options_label}
										</button>
									)}
								</li>
							)}
							{all_options && <li role="separator" className="divider"></li>}
							{this.searchedOptions()}
						</div>
					</ul>
				</div>
			</div>
		)
	}
}

InputFieldMultiple.propTypes = {
	label: PropTypes.string.isRequired,
	additional_class: PropTypes.string,
	opcoes: PropTypes.array.isRequired,
	opcoes_selecionadas: PropTypes.array.isRequired,
	all_options: PropTypes.bool,
	all_options_label: PropTypes.string,
	all_options_selected: PropTypes.bool,
	dropdown_open: PropTypes.bool.isRequired,
	updateUi: PropTypes.func.isRequired,
	auxiliarFunction: PropTypes.func
}

InputFieldMultiple.defaultProps = {
	additional_class: '',
	all_options: false,
	all_options_label: 'Todas as opções',
	all_options_selected: false,
	auxiliarFunction: function() {}
}

export default CSSModules(InputFieldMultiple, styles, { allowMultiple: true });
