import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/index';

// Components
import Auxlr from '../Auxlr/Auxlr';

// Functions
import { clearDropdowns } from '../../components/_functions/_clearDropdowns';
import { getLocalstorageData, setLocalstorageData } from '../../components/_functions/_getLocalstorageData';
import { attachHotJar } from '../../components/_functions/_attachHotJar';

class Layout extends Component {
	static defineBaseUrl() {
		// const origin = window.location.href;
		// // producao
		// const eh_portal = origin.indexOf('fornecedor.conazweb.com.br') !== -1;
		// if (eh_portal) {
		// 	return 'https://api-fornecedor.conazweb.com.br';
		// }
		// // dev
		// return 'https://dev.conazweb.com.br';
		return process.env.REACT_APP_BASEURL;
	}

	constructor() {
		super();
		this.handleMouseUp = this.handleMouseUp.bind(this);
		// this.handleClick = this.handleClick.bind(this);
	}

	UNSAFE_componentWillMount() {
		const base_url = this.constructor.defineBaseUrl();
		setLocalstorageData('environment', { base_url, environment: '' });
	}

	componentDidMount() {
		window.addEventListener('mouseup', this.handleMouseUp);
		// TawkTo & HotJar
		const user_obj = getLocalstorageData('user');
		if (!this.props.ui.tawkto && window.location.href.indexOf('fornecedor.conazweb.com.br') !== -1) {
			if (
				user_obj.email !== ''
				&& user_obj.email !== undefined
				&& (user_obj.email.indexOf('conazsolucoes.com.br') === -1
				|| user_obj.email.indexOf('ambar.tech') === -1)
			) {
				attachHotJar(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
			}
			this.props.updateUi('tawkto', true);
		}
	}

	handleMouseUp(e) {
		if (e.target.getAttribute('data-dropdown')) {
			const dropdown_class = e.target.parentNode.className === 'dropdown open' ? 'dropdown' : 'dropdown open';
			clearDropdowns();
			e.target.parentNode.className = dropdown_class;
		} else if (e.target.getAttribute('data-dropdown-2')) {
			const dropdown_class = e.target.parentNode.parentNode.className === 'dropdown open' ? 'dropdown' : 'dropdown open';
			clearDropdowns();
			e.target.parentNode.parentNode.className = dropdown_class;
		} else if (!e.target.getAttribute('data-dropchild')) {
			clearDropdowns();
		}

		if (this.props.ui.account_menu && !e.target.getAttribute('data-account-menu')) {
			this.props.updateUi(['account_menu'], false);
		} else if (this.props.ui.obras_menu && !e.target.getAttribute('data-obras-menu')) {
			this.props.updateUi(['obras_menu'], false);
		} else if (this.props.ui.actions_menu && !e.target.getAttribute('data-actions-menu')) {
			this.props.updateUi(['actions_menu'], false);
		}
	}

	render() {
		return <Auxlr>{this.props.children}</Auxlr>;
	}
}

Layout.propTypes = {
	ui: PropTypes.object.isRequired,
	updateUi: PropTypes.func.isRequired,
	children: PropTypes.object.isRequired,
};

const mapStateToProps = props => ({
	compras: props.compras,
	cotacao: props.cotacao,
	cotacoes: props.cotacoes,
	ui: props.ui,
	user: props.user,
});

const mapDispatchToProps = dispatch => ({
	updateUi: (field, value) => dispatch(actions.updateUi(field, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
