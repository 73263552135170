import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
const TagSugestao = ({ value, onClick }) => (
	<button styleName="tag-btn" onClick={onClick}>
		<p styleName="tag-value">{value.toString()}</p>
		<i className="fa fa-magic" aria-hidden="true" styleName="icon" />
	</button>
);

TagSugestao.propTypes = {
	onClick: PropTypes.func.isRequired,
	value: PropTypes.string,
};

TagSugestao.defaultProps = {
	value: '',
};

export default CSSModules(TagSugestao, styles, { allowMultiple: true });
