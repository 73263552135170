import { caracteristicaType } from './_caracteristicaType';

const loopItens = cotacao => {
	const { itens_by_id, itens_all_ids } = cotacao;
	for (let i = 0; i < itens_all_ids.length; i++) {
		const id = itens_all_ids[i];
		const item = itens_by_id[id];

		// Status do item
		if (item.status === null) {
			return {
				type: 'item_status',
				item_id: id,
				carac_id: null,
				unidade: null,
			};
		}

		if (item.status !== -1) {
			const { caracteristicas_preenchidas, caracteristicas_preenchidas_all_ids } = item.item_preenchido;
			for (let c = 0; c < caracteristicas_preenchidas_all_ids.length; c++) {
				const c_id = caracteristicas_preenchidas_all_ids[c];
				const caracteristica = caracteristicas_preenchidas[c_id];

				const { opcoes, selecao, tipo_de_valor } = caracteristica.caracteristica;
				const caracteristica_type = caracteristicaType(opcoes, selecao, tipo_de_valor);
				const has_unidade = (opcoes.length > 0 && caracteristica_type !== 'selecao' && caracteristica_type !== 'multipla_escolha') ||
					(caracteristica_type === 'selecao' && opcoes.length > 0 && opcoes[0].unidade !== '');

				// caracteristica
				if (
					caracteristica.caracteristica.visao_fornecedor === 2 &&
					(
						caracteristica.opcoes_preenchidas.length === 0 ||
						caracteristica.opcoes_preenchidas[0].valor === ''
					)
				) {
					// const unidade = has_unidade && caracteristica.opcoes_preenchidas.length > 0 && caracteristica.opcoes_preenchidas[0].unidade === '';
					// return [id, c_id, false];
					return {
						type: 'caracteristica',
						item_id: id,
						carac_id: c_id,
						unidade: false,
					};
				}

				// unidade
				if (
					caracteristica.caracteristica.visao_fornecedor === 2 &&
					(has_unidade && caracteristica.opcoes_preenchidas[0].unidade === '')
				) {
					// const unidade = has_unidade && caracteristica.opcoes_preenchidas.length > 0 && caracteristica.opcoes_preenchidas[0].unidade === '';
					// return [id, c_id, true];
					return {
						type: 'caracteristica',
						item_id: id,
						carac_id: c_id,
						unidade: true,
					};
				}
			}

			// Quantidade
			if ((item.quantidade === 0 || item.quantidade === '' || item.quantidade === null) && item.status === 1) {
				// return [id, null, null];
				return {
					type: 'item_quantidade',
					item_id: id,
					carac_id: null,
					unidade: null,
				};
			}

			// Unidade
			if ((item.unidade === '' || item.unidade === null) && item.status === 1) {
				// return [id, null, null];
				return {
					type: 'item_unidade',
					item_id: id,
					carac_id: null,
					unidade: null,
				};
			}

			// preco_unitario
			if ((item.preco_unitario === 0 || item.preco_unitario === '' || item.preco_unitario === null) && item.status === 1) {
				// return [id, null, null];
				return {
					type: 'preco_unitario',
					item_id: id,
					carac_id: null,
					unidade: null,
				};
			}
		}
	}
	// return [null, null, null];
	return {
		type: null,
		item_id: null,
		carac_id: null,
		unidade: null,
	};
};

export const validaItens = (cotacao, page) => {
	const item_em_branco = loopItens(cotacao);
	const { type, item_id, carac_id, unidade } = item_em_branco;

	/**
	 * Status do item
	 */
	if (type === 'item_status' && item_id !== null) {
		if (document.getElementById(`tooltip_possui_item_${item_id}`)) {
			document.getElementById(`tooltip_possui_item_${item_id}`).className = 'tooltip right in';
		}
		return 0;
	}

	/**
	 * Caracteristicas
	 */
	if (type === 'caracteristica' && carac_id !== null) {
	// if (item_em_branco[0] !== null && item_em_branco[1] !== null) {
		const field_id = !unidade
			? `caracteristica_${item_id}_${carac_id}`
			: `unidade_caracteristica_${item_id}_${carac_id}`;
		const wrapper_id = !unidade
			? `caracteristica_wrapper_${item_id}_${carac_id}`
			: `unidade_caracteristica_wrapper_${item_id}_${carac_id}`;
		let wrapper_class = !unidade
			? 'caracteristica-wrapper has-error'
			: 'unidade-wrapper has-error';

		if (
			document.getElementById(wrapper_id) &&
			document.getElementById(wrapper_id).className === 'caracteristica-wrapper has-unidade' &&
			wrapper_class === 'caracteristica-wrapper has-error'
		) {
			wrapper_class = 'caracteristica-wrapper has-unidade has-error';
		}

		if (document.getElementById(field_id)) {
			document.getElementById(field_id).focus();
		}
		if (document.getElementById(wrapper_id)) {
			document.getElementById(wrapper_id).className = wrapper_class;
		}
		if (document.getElementById(`tooltip_caracteristica_${item_id}_${carac_id}`)) {
			document.getElementById(`tooltip_caracteristica_${item_id}_${carac_id}`).className = 'tooltip top in';
		}
		const space_from_top = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
		if (page === 'itens') {
			window.scroll(0, space_from_top - 85);
		}
		return 0;
	}

	/**
	 * Quantidade
	 */
	if (type === 'item_quantidade' && item_id !== null) {
		const field_id = `quantidade_${item_id}`;
		if (document.getElementById(field_id)) {
			document.getElementById(field_id).focus();
		}
		if (document.getElementById(`tooltip_quantidade_${item_id}`)) {
			document.getElementById(`tooltip_quantidade_${item_id}`).className = 'tooltip top in';
		}
		const space_from_top = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
		if (page === 'itens') {
			window.scroll(0, space_from_top - 85);
		}
		return 0;
	}

	/**
	 * Unidade
	 */
	if (type === 'item_unidade' && item_id !== null) {
		const field_id = `unidade_${item_id}`;
		if (document.getElementById(field_id)) {
			document.getElementById(field_id).focus();
		}
		if (document.getElementById(`tooltip_unidade_${item_id}`)) {
			document.getElementById(`tooltip_unidade_${item_id}`).className = 'tooltip top in';
		}
		const space_from_top = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
		if (page === 'itens') {
			window.scroll(0, space_from_top - 85);
		}
		return 0;
	}

	/**
	 * Valor unitario
	 */
	if (type === 'preco_unitario' && item_id !== null) {
	// if (item_em_branco[0] !== null && item_em_branco[1] === null) {
		const field_id = `preco_unitario_${item_id}`;
		const wrapper_id = `caracteristica_wrapper_${item_id}`;

		if (document.getElementById(field_id)) {
			document.getElementById(field_id).focus();
		}
		if (document.getElementById(wrapper_id)) {
			document.getElementById(wrapper_id).className = 'caracteristica-wrapper has-error';
		}
		if (document.getElementById(`tooltip_valor_unitario_${item_id}`)) {
			document.getElementById(`tooltip_valor_unitario_${item_id}`).className = 'tooltip top in';
		}
		const space_from_top = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
		if (page === 'itens') {
			window.scroll(0, space_from_top - 85);
		}
		return 0;
	}

	/**
	 * Todos os itens estão como "não tenho esse item"
	 */
	const { itens_by_id, itens_all_ids } = cotacao;
	const tenho_esses_itens = itens_all_ids.filter(id => itens_by_id[id].status !== -1);
	if (tenho_esses_itens.length === 0) {
		return -1;
	}

	/**
	 * Todos os itens estão como "incluso"
	 */
	const itens_inclusos = itens_all_ids.filter(id => itens_by_id[id].status === 0);
	const itens_nao_tenho = itens_all_ids.filter(id => itens_by_id[id].status === -1);
	const soma_dos_itens = [...itens_inclusos, ...itens_nao_tenho];
	if (
		soma_dos_itens.length === itens_all_ids.length &&
		cotacao.rc.custos_adicionais === 0
	) {
		return -2;
	}

	/**
	 * Ok
	 */
	return 1;
};
