
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../../actions/index';

// Components
import AlterarSenha from './AlterarSenha';

// Functions
import Wrapper from '../../Wrapper/Wrapper';
import { alterarSenha } from '../../../services/apis/auth';

import { lockBody } from '../../_functions/_lockBody';

class AlterarSenhaContainer extends Component {
	static setValidationErrors() {
		return { senha_antiga: false, nova_senha: false, confirma_nova_senha: false, senhas_nao_correspondem: false };
	}

	constructor() {
		super();
		this.state = {
			senha_antiga: '',
			nova_senha: '',
			confirma_nova_senha: '',
			enviando: false,
			success: false,
			modal_error: false,
			page_locked: false,
			validation_errors: {
				senha_antiga: false,
				nova_senha: false,
				confirma_nova_senha: false,
				senhas_nao_correspondem: false,
			},
			errorMsg: '',
			errorTitle: '',
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleEdition = this.handleEdition.bind(this);
		this.handleModal = this.handleModal.bind(this);
	}

	handleModal(modal, value) {
		this.setState({ [modal]: value, page_locked: value });
		lockBody(value);
		if (this.state.success) {
			this.props.history.push('/solicitacoes');
		}
	}

	handleSubmit(e) {
		e.preventDefault();
		document.getElementById('send_btn').focus();
		this.setState({ enviando: true, success: false, errorMsg: '', errorTitle: '' });

		const { senha_antiga, nova_senha, confirma_nova_senha } = this.state;

		if (senha_antiga === '') {
			const validation_new = this.constructor.setValidationErrors();
			validation_new.senha_antiga = true;
			this.setState({ validation_errors: validation_new, enviando: false });
			return;
		}

		if (nova_senha === '') {
			const validation_new = this.constructor.setValidationErrors();
			validation_new.nova_senha = true;
			this.setState({ validation_errors: validation_new, enviando: false });
			return;
		}

		if (confirma_nova_senha === '') {
			const validation_new = this.constructor.setValidationErrors();
			validation_new.confirma_nova_senha = true;
			this.setState({ validation_errors: validation_new, enviando: false });
			return;
		}

		if (nova_senha !== confirma_nova_senha) {
			const validation_new = this.constructor.setValidationErrors();
			validation_new.senhas_nao_correspondem = true;
			this.setState({ validation_errors: validation_new, enviando: false });
			return;
		}

		alterarSenha(nova_senha, senha_antiga)
			.then(() => {
				this.setState({
					modal_error: true,
					enviando: false,
					success: true,
					errorMsg: 'Senha atualizada com sucesso!',
					errorTitle: 'Sucesso',
				});
			})
			.catch(error => {
				if (error.response && error.response.status !== 500) {
					this.setState({
						modal_error: true,
						enviando: false,
						errorMsg: error.response.data.message,
						errorTitle: 'Erro ao alterar senha',
					});
				} else {
					this.setState({
						modal_error: true,
						enviando: false,
					});
				}
			});
	}

	handleEdition(fields, value) {
		this.setState({ [fields]: value });
		this.setState({ validation_errors: this.constructor.setValidationErrors() });
	}

	render() {
		return (
			<Wrapper
				{...this.props}>
				<AlterarSenha
					{...this.state}
					{...this.props}
					handleSubmit={this.handleSubmit}
					handleEdition={this.handleEdition}
					handleModal={this.handleModal} />
			</Wrapper>
		);
	}
}

const mapStateToProps = props => ({
	cadastro: props.cadastro,
	ui: props.ui,
});

const mapDispatchToProps = dispatch => ({
	resetCadastro: () => dispatch(actions.resetCadastro()),
	updateCadastro: (field, value) => dispatch(actions.updateCadastro(field, value)),
	updateUi: (field, value) => dispatch(actions.updateUi(field, value)),
	updateModals: (modal, value) => dispatch(actions.updateModals(modal, value)),
	reportErrors: (page, error, codigo_pedido, full_error, test) => dispatch(actions.reportErrors(page, error, codigo_pedido, full_error, test)),
});


AlterarSenhaContainer.propTypes = {
	cadastro: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	updateCadastro: PropTypes.func.isRequired,
	resetCadastro: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlterarSenhaContainer);
