import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import LoadingButton from '../../_loadings/LoadingButton/LoadingButton';

// Functions
import { _post } from '../../../../services/apis/requests';
import { handleRequestErrors } from '../../../_functions/_handleRequestErrors';

class ModalTermosDeUso extends Component {
	constructor() {
		super();
		this.state = {
			enviando: 0,
			conaz: '',
		};
		this.closeModalConaz = this.closeModalConaz.bind(this);
		this.enviaConfirmacao = this.enviaConfirmacao.bind(this);
	}

	componentDidMount() {
		window.addEventListener('keyup', this.closeModalConaz);
	}

	componentWillUnmount() {
		window.removeEventListener('keyup', this.closeModalConaz);
	}

	closeModalConaz(e) {
		const { conaz } = this.state;
		let typed = '';

		switch (e.keyCode) {
		case 67:
			typed = `${conaz}c`;
			this.setState({ conaz: typed });
			break;
		case 79:
			typed = `${conaz}o`;
			this.setState({ conaz: typed });
			break;
		case 78:
			typed = `${conaz}n`;
			this.setState({ conaz: typed });
			break;
		case 65:
			typed = `${conaz}a`;
			this.setState({ conaz: typed });
			break;
		case 90:
			typed = `${conaz}z`;
			this.setState({ conaz: typed });
			break;
		case 27:
			this.setState({ conaz: '' });
			break;
		default:
		}

		if (typed === 'conaz') {
			this.props.updateModals('termos_de_uso', false);
		}
	}

	enviaConfirmacao() {
		this.setState({ enviando: 1 });
		const params = { aceitou_termos: true };
		_post('/conaz/v2/auth/fornecedor/editar_conta', params).then(() => {
			this.props.updateModals('termos_de_uso', false);
		}).catch(error => {
			this.setState({ enviando: 3 });
			handleRequestErrors(error);
		});
	}

	render() {
		const { enviando } = this.state;

		return (
			<div className="modal-content" styleName="modal-content">
				<div className="modal-body" styleName="modal-body">
					<div styleName="content">
						<h3>Termos de Uso</h3>
						<p>Pensando no melhor uso e segurança da plataforma, atualizamos nossos termos de uso e política de privacidade. Você pode acessá-los <b><a href={process.env.URL_TERMOS_DE_USO} target="_blank" rel="noopener noreferrer">clicando aqui</a></b></p>
						{enviando === 1 ? (
							<button
								type="button"
								className="btn btn-secondary-conaz round-border loading">
								<LoadingButton />Li e Concordo com os termos
							</button>
						) : (
							<button
								type="button"
								className="btn btn-secondary-conaz round-border"
								onClick={this.enviaConfirmacao}>
								Li e Concordo com os termos
							</button>
						)}
						{enviando === 3 && (
							<div styleName="error">Ops... Aconteceu algo errado. Tente novamente.</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

ModalTermosDeUso.propTypes = {
	updateModals: PropTypes.func,
};

ModalTermosDeUso.defaultProps = {
	updateModals: () => {},
};

export default CSSModules(ModalTermosDeUso, styles, { allowMultiple: true });
