/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { login } from '../../../services/login_manager';
import { urlSearchParamsPolyfill } from '../../_functions/_urlSearchParamsPolyfill';

class Login extends Component {
	constructor() {
		super();
		this.state = {
			request_state: 0,
			email: '',
			senha: '',
			errorMsg: '',
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleEdition = this.handleEdition.bind(this);
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	handleEdition(e) {
		const campo = e.target.name;

		this.setState({ [campo]: e.target.value });
	}

	handleSubmit(e) {
		e.preventDefault();
		const { email, senha } = this.state;
		this.setState({ request_state: 1 });

		login(email, senha).then(() => {
			const { search } = this.props.location;
			const all_params = urlSearchParamsPolyfill(search);
			const redirect_to = all_params.redirect_to || '/solicitacoes';
			// const params = new URLSearchParams(search);
			// const redirect_to = params.get('redirect_to') || '/solicitacoes';
			this.props.history.push(redirect_to);
		}).catch(error => {
			this.setState({ request_state: 0 });

			if (!error.response || error.response.status === 500) {
				this.setState({ errorMsg: 'Ops... aconteceu algo errado.' });
			} else {
				this.setState({ errorMsg: error.response.data.message });
			}
		});
	}

	resetErrors() {
		this.setState({ request_state: 0 });
	}

	render() {
		const { request_state, email, errorMsg } = this.state;
		const error_class = errorMsg ? 'form-group has-error' : 'form-group';

		return (
			<div styleName="login-page-wrapper">
				<div className="container">
					<div className="row">
						<div className="col-xs-12 col-sm-7" style={{ textAlign: 'center' }}>
							<div styleName="logo-lateral-wrapper">
								<img
									src="/images/logotipo_original.png"
									width="340"
									alt="" />
							</div>
							<h3 styleName="h3-lateral">FORNECEDOR</h3>
						</div>
						<div className="col-xs-12 col-sm-5" style={{ textAlign: 'center' }}>
							<div styleName="logo-wrapper">
								<img
									src="/images/logotipo_original.png"
									width="230"
									alt="" />
								<h5>FORNECEDOR</h5>
							</div>
							<div styleName="form-wrapper">
								<h3 styleName="h3-margin">Faça seu login</h3>
								<form onSubmit={this.handleSubmit}>
									<div className={error_class}>
										<label htmlFor="email">E-mail</label>
										<input
											type="email"
											className="form-control"
											id="email"
											value={email}
											onChange={this.handleEdition}
											name="email"
											onKeyDown={() => this.resetErrors()} />
									</div>
									<div className={error_class}>
										<label htmlFor="senha">Senha</label>
										<input
											type="password"
											className="form-control"
											id="senha"
											name="senha"
											onChange={this.handleEdition}
											onKeyDown={() => this.resetErrors()} />
									</div>
									{errorMsg && (
										<div className="alert alert-danger" role="alert">
											<i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" /> {errorMsg}
										</div>
									)}
									{request_state === 1 ? (
										<button
											type="submit"
											className="btn btn-primary btn-lg btn-block"
											styleName="button-margin"
											disabled="disabled">
											Entrando
										</button>
									) : (
										<button
											type="submit"
											className="btn btn-primary btn-lg btn-block"
											styleName="button-margin">
											Entrar
										</button>
									)}
								</form>
								<p
									className="help-block"
									style={{ textAlign: 'center', paddingTop: '36px', maxWidth: '380px' }}>
									<Link to="/esqueci-minha-senha">Esqueceu sua senha?</Link>
								</p>
							</div>
							<div styleName="cadastre-wrapper">
								<p>Ainda não tem cadastro?</p>
								<p><Link to="/cadastro">Criar cadastro</Link></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

Login.propTypes = {
	history: PropTypes.object.isRequired,
};

export default CSSModules(Login, styles, { allowMultiple: true });
