import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import Modal from '../../_parts/Modal/Modal';
import MessageModal from '../../_parts/MessageModal/MessageModal';
import ModalWrapper from '../../_parts/_modals/ModalWrapper/ModalWrapper';
import BasicModal from '../../_parts/_modals/BasicModal/BasicModal';
import Loading from '../../_parts/_loadings/Loading/Loading';
import InputSelectGeral from '../../_parts/_inputs/InputSelectGeral/InputSelectGeral';

const ListarEmpresas = ({
	/* state */ modal_error, fornecedor_selecionado,
	/* props */ cadastro, ui,
	/* fprops */ updateModals,
	/* funcs */ handleModal, alteraFornecedor, onClickToEdit, desvincularFornecedor,
}) => {
	const { modals } = ui;
	const { empresas, empresas_by_id, loaded } = cadastro;

	const fornecedores_opcoes = empresas_by_id.map(f => ({
		id: empresas[f].fornecedor.id,
		valor: empresas[f].fornecedor.nome,
	}));
	if (modal_error) {
		return (
			<Modal modal="modal_error" handleModal={handleModal}>
				<MessageModal
					type="alert"
					title="Ops... aconteceu algo errado."
					message="Desculpe, não conseguimos identificar o que aconteceu. Espere alguns segundos e tente novamente." />
			</Modal>
		);
	}
	if (!loaded) {
		return (
			<div className="container" styleName="main-container">
				<div className="row">
					<div className="col-xs-12" style={{ textAlign: 'center' }}>
						<Loading />
					</div>
				</div>
			</div>
		);
	}

	if (loaded && fornecedores_opcoes.length === 0) {
		return (
			<div className="container" styleName="main-container">
				<div className="row">
					<div className="col-xs-12" style={{ textAlign: 'center', paddingTop: '50px', paddingBottom: '50px' }}>
						Você não possui empresas cadastradas. Cadastre uma nova empresa para começar a receber cotações.
					</div>
					<div className="col-xs-12" style={{ textAlign: 'center' }}>
						<Link
							to="/cadastro">
							<button
								type="button"
								className="btn btn-primary">
								<i className="fa fa-plus" aria-hidden="true" /> Cadastrar empresa
							</button>
						</Link>
					</div>
				</div>
			</div>
		);
	}
	return (
		<div className="container" styleName="main-container">
			<ModalWrapper visible={modals.confirmar_desvinculacao_fornecedor} updateModals={updateModals}>
				<BasicModal
					title="Atenção"
					message="Ao se desvincular da empresa, você não receberá mais cotações da referida empresa. Deseja prosseguir?"
					modalName="confirmar_desvinculacao_fornecedor"
					onClickConfirmar={() => desvincularFornecedor()}
					showConfirmarButton
					showCancelarButton />
			</ModalWrapper>
			<div className="row">
				<div className="col-xs-12">
					<h2 style={{ textAlign: 'center', marginBottom: '28px' }}>Edição de empresa</h2>
					{fornecedores_opcoes.length > 1 && (
						<InputSelectGeral
							field_id="fornecedor_select"
							field="fornecedor"
							default_value={!fornecedor_selecionado.id ? '' : fornecedor_selecionado.nome}
							opcoes={fornecedores_opcoes}
							updateFunction={alteraFornecedor}
							has_outro={false} />
					)}

					{fornecedores_opcoes.length === 1 && (
						<p>{fornecedores_opcoes[0].valor}</p>
					)}
					<div className="btn-group" style={{ marginTop: '20px' }}>
						<button
							style={{ margin: '0 5px 6px 0' }}
							type="button"
							className="btn btn-primary"
							onClick={() => onClickToEdit('empresa')}>
							<i className="fa fa-building" aria-hidden="true" /> Editar empresa
						</button>
						<button
							style={{ margin: '0 5px 6px 0' }}
							type="button"
							className="btn btn-primary"
							onClick={() => onClickToEdit('entrega')}>
							<i className="fa fa-truck fa-fw" aria-hidden="true" /> Editar locais de entrega
						</button>
						<button
							style={{ margin: '0 5px 6px 0' }}
							type="button"
							className="btn btn-primary"
							onClick={() => onClickToEdit('familias')}>
							<i className="fa fa-shopping-basket" aria-hidden="true" /> Editar famílias de mercado
						</button>
						<button
							style={{ margin: '0 5px 6px 0' }}
							type="button"
							className="btn btn-danger"
							onClick={() => updateModals('confirmar_desvinculacao_fornecedor', true)}>
							<i className="fa fa-times-circle" aria-hidden="true" /> Desvincular da empresa
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

ListarEmpresas.propTypes = {
	handleModal: PropTypes.func.isRequired,
	cadastro: PropTypes.object.isRequired,
	modal_error: PropTypes.bool.isRequired,
	alteraFornecedor: PropTypes.func.isRequired,
	onClickToEdit: PropTypes.func.isRequired,
	ui: PropTypes.object.isRequired,
	updateModals: PropTypes.func.isRequired,
	desvincularFornecedor: PropTypes.func.isRequired,
	fornecedor_selecionado: PropTypes.object.isRequired,
};

export default CSSModules(ListarEmpresas, styles, { allowMultiple: true });
