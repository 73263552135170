import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import StatusBar from '../../_parts/StatusBar/StatusBar';

// Functions
import { formatCurrency } from '../../_functions/_formatNumber';
import { defineStatusCompra } from '../../_functions/_defineStatusCompra';

// Content
import { formas_pagamento, prazos_pagamento_full } from '../../_content/_condicoes_pagamento';

const ReviewCompra = ({
	compra,
	confirmada,
	recusada,
	confirmarOC,
	recusarOC,
	retirada,
	pedido_id,
}) => {
	const {
		id,
		data_envio,
		data_de_entrega,
		valor_total,
		observacoes,
		condicao_de_pagamento,
		link_detalhamento_itens,
		mudou_do_rc,
		// status,
		confirmada_por,
		avaliacao_fornecedor,
		avaliacao_vendedor,
	} = compra;

	let _status = defineStatusCompra(compra);
	if (recusada) _status = 'recusada_fornecedor';

	const _confirmada = (
		confirmada
		|| _status === 'confirmada'
		|| _status === 'entrega_confirmada'
		|| _status === 'finalizada'
	);

	const forma = (condicao_de_pagamento.forma !== -1 && condicao_de_pagamento.forma !== null)
		? (formas_pagamento[condicao_de_pagamento.forma] || {}).view || ''
		: condicao_de_pagamento.outra_forma;
	const prazo = (condicao_de_pagamento.prazo !== -1 && condicao_de_pagamento.prazo !== null)
		? (prazos_pagamento_full[condicao_de_pagamento.prazo] || {}).view || ''
		: condicao_de_pagamento.outro_prazo;
	const desconto = condicao_de_pagamento.desconto || 0;

	const criado_em_local_moment = moment.utc(data_envio)._d;
	const criado_em_local = moment(criado_em_local_moment).format();
	const data_hora_criado_em_split = criado_em_local.split('T');
	const criado_em_split = data_hora_criado_em_split[0].split('-');
	const criado_em_view = criado_em_split[2] !== undefined ? `${criado_em_split[2]}/${criado_em_split[1]}/${criado_em_split[0]}` : '-';
	const hora_criado_em_split = typeof data_hora_criado_em_split[1] === 'string' ? data_hora_criado_em_split[1].split(':') : '';
	const hora_criado_em_view = hora_criado_em_split[0] !== undefined ? `${hora_criado_em_split[0]}:${hora_criado_em_split[1]}` : '';

	const entrega_desejada_split = (data_de_entrega || '').split('-');
	const entrega_desejada_view = entrega_desejada_split[2] !== undefined ? `${entrega_desejada_split[2]}/${entrega_desejada_split[1]}/${entrega_desejada_split[0]}` : '-';

	let venda_confirmada_label = 'Venda confirmada';
	if (_confirmada && (confirmada_por === null || confirmada_por !== 1)) {
		venda_confirmada_label = 'Venda confirmada por você';
	} else if (_confirmada && confirmada_por === 1) {
		venda_confirmada_label = 'Venda confirmada pelo construtor';
	}

	const anexos = (compra.anexos || [])
		.filter(a => a.visivel_pro_fornecedor);

	return (
		<React.Fragment>
			<div className="row">
				<div className="col-sm-12">
					<div styleName="review-block no-pad2">
						<div styleName="content">
							<div className="row">
								<div className="col-xs-12 col-md-4" styleName="content-block">
									<i className="fa fa-paper-plane-o" aria-hidden="true" />
									<p styleName="labels">Solicitada em</p>
									<h4 styleName="info" style={{ color: '#0e4f7b' }}><b>{criado_em_view}<br />{hora_criado_em_view}</b></h4>
								</div>
								<div className="col-xs-12 col-md-4" styleName="content-block">
									<i className="fa fa-calendar-check-o" aria-hidden="true" />
									<p styleName="labels">{` Data de ${retirada ? 'retirada' : 'entrega'}`}</p>
									<h3 styleName="info" style={{ marginTop: '9px', color: '#0e4f7b' }}><b>{entrega_desejada_view}</b></h3>
								</div>
								<div className="col-xs-12 col-md-4" styleName="content-block">
									<i className="fa fa-files-o" aria-hidden="true" />
									<p styleName="labels">Anexos</p>
									{anexos.map((anexo, a) => ( // eslint-disable-next-line react/no-array-index-key
										<div key={a} styleName="anexo-row">
											<span>{a + 1}</span> <a href={anexo.url} target="_blank" rel="noopener noreferrer" download>{anexo.nome}</a>
										</div>
									))}
									{anexos.length === 0 && <em style={{ color: '#c3c6c9', fontSize: '14px' }}>0 arquivos</em>}
									{avaliacao_fornecedor.detalhe && (
										<p>
											<b>Avaliação da entrega</b>
											<Link
												to={`/avaliacoes/${id}`}
												style={{
													color: '#FF9D28',
													background: 'inherit',
													border: 'none',
												}}>
												{` ${avaliacao_fornecedor.detalhe.estrelas}.0/5.0`}
												{' '}<i className="fa fa-caret-down" />
											</Link>
										</p>
									)}
									{avaliacao_vendedor.detalhe && (
										<p>
											<b>Sua avaliação</b>
											<span style={{ color: '#FF9D28' }}>
												{` ${avaliacao_vendedor.detalhe.estrelas}.0/5.0`}
											</span>
										</p>
									)}
								</div>
							</div>
							<div className="row">
								<div className="col-xs-12 col-md-4" styleName="content-block">
									<i className="fa fa-credit-card" aria-hidden="true" />
									<p styleName="labels">Condição de pagamento</p>
									<p style={{ color: '#0e4f7b' }}>
										<b>{`${forma} - ${prazo}`}</b>
										<br />
										{desconto > 0 && (
											<span style={{ fontSize: '14px', color: '#696b6d' }}>{desconto}% de desconto já aplicado</span>
										)}
									</p>
								</div>
								<div className="col-xs-12 col-md-8" styleName="content-block">
									<i className="fa fa-money" aria-hidden="true" />
									<p styleName="labels">Valor da venda</p>
									<h3 style={{ marginTop: '9px', color: '#0e4f7b' }}><b>{formatCurrency(valor_total, 'R$')}</b></h3>
								</div>

								<div className="col-xs-12">
									<hr styleName="hr" />
								</div>
							</div>
							{_status === 'em_edicao' ? (
								<div className="row">
									<div className="col-sm-12">
										<p style={{ marginTop: '8px', color: '#66a3c1', fontSize: '18px', textAlign: 'center' }}>
											Esta compra está sendo editada pelo construtor neste momento.
										</p>
									</div>
								</div>
							) : (
								<div className="row">
									<div className="col-sm-12">
										<div styleName="review-block h-142 no-pad" style={{ boxShadow: 'none' }}>
											<div styleName="inside w-33 left-form-top">
												<div styleName="label"><i className="fa fa-comment fa-fw" aria-hidden="true" /> Observações gerais</div>
												<p styleName="info">{observacoes || 'Nenhuma observação'}</p>
											</div>
											<div styleName="inside w-33 left-form-top">
												<div styleName="label"><i className="fa fa-paperclip fa-fw" aria-hidden="true" /> Detalhamento dos itens</div>
												{link_detalhamento_itens
													? (<a target="_blank" rel="noopener noreferrer" href={link_detalhamento_itens}><b style={{ fontSize: '18px' }}>Visualizar Ordem de compra detalhada</b></a>)
													: ('Indisponível')
												}
											</div>
											<div styleName="inside w-33 left-form-top last" style={{ border: 'none' }}>
												<StatusBar>
													{pedido_id !== null && (
														<StatusBar.NormalStage label={`Cotação #${pedido_id}`} done connection />
													)}
													{compra.tipo === 4 ?
														<React.Fragment>
															<StatusBar.NormalStage
																label="Ordem de compra"
																active={!_confirmada}
																done={_confirmada}
																connection />
															{_status === 'recusada_fornecedor' && (
																<StatusBar.CanceledStage
																	label="Recusada por você"
																	active />
															)}
															{_status === 'cancelada' && (
																<StatusBar.CanceledStage
																	label="Venda cancelada"
																	active />
															)}
															{(_status !== 'recusada_fornecedor' && _status !== 'cancelada') && (
																<StatusBar.NormalStage
																	label={venda_confirmada_label}
																	active={_confirmada} />
															)}
														</React.Fragment>
														:
														<StatusBar.NormalStage label="Compra registrada pelo construtor" active />
													}
												</StatusBar>
											</div>
											{(_status === 'aguardando_confirmacao' || _confirmada) && (
												<div styleName="inside w-100 left-form-top last" style={{ border: 'none', marginTop: '30px' }}>
													<a
														target="_blank"
														rel="noopener noreferrer"
														href={`/impressao/${id}`}>
														<b style={{ fontSize: '18px' }}>
															Gerar impressão da ordem de compra
														</b>
													</a>
												</div>
											)}
											{(!_confirmada && _status === 'aguardando_confirmacao') && (
												<div styleName="inside w-100 left-form-top last" style={{ border: 'none' }}>
													<p style={{ marginTop: '50px' }}>
														<button
															type="button"
															className="btn btn-danger"
															style={{ marginRight: '20px' }}
															onClick={recusarOC}>
															Recusar Compra
														</button>
														<button
															type="button"
															className="btn btn-success"
															styleName="btn-confirmar-oc"
															onClick={confirmarOC}>
															Confirmar Compra
														</button>
													</p>
													{mudou_do_rc && (
														<p>
															<i
																style={{ color: 'red' }}
																className="fa fa-exclamation-circle"
																aria-hidden="true"
															/>
															&nbsp;Houve alterações em relação a proposta inicial
														</p>
													)}
												</div>
											)}
											{_status === 'cancelada' && (
												<div styleName="inside w-100 left-form-top last" style={{ border: 'none' }}>
													<p>
														<i style={{ color: 'red' }} className="fa fa-ban fa-fw" aria-hidden="true" /> Venda cancelada
													</p>
												</div>
											)}
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

ReviewCompra.propTypes = {
	compra: PropTypes.object.isRequired,
	confirmada: PropTypes.bool.isRequired,
	recusada: PropTypes.bool.isRequired,
	confirmarOC: PropTypes.func.isRequired,
	recusarOC: PropTypes.func.isRequired,
	retirada: PropTypes.bool.isRequired,
	pedido_id: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
};

ReviewCompra.defaultProps = {
	pedido_id: null,
};

export default CSSModules(ReviewCompra, styles, { allowMultiple: true });
