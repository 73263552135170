import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as actions from '../../../actions/index';

// Components
import Enviadas from './Enviadas';
import Wrapper from '../../Wrapper/Wrapper';

// Functions
// import { defineStatusCompra } from '../../_functions/_defineStatusCompra';
import {
	getRCEA,
	getRCAC,
	getRCCE,
	getRCSS,
	getRCCC,
	getRCRecusado,
} from '../../../services/apis/rcs';

const EnviadasContainer = props => {
	const [loadingEA, setLoadingEA] = useState(true);
	const [loadingAC, setLoadingAC] = useState(true);
	const [loadingCE, setLoadingCE] = useState(true);
	const [loadingSS, setLoadingSS] = useState(true);
	const [loadingCC, setLoadingCC] = useState(true);
	const [loadingRecusado, setLoadingRecusado] = useState(true);
	const [notification, setNotification] = useState(false);
	const [list, setList] = useState([]);
	// const [avaliacaoDropdown, setAvaliacaoDropdown] = useState(false);

	const setLoadingObject = {
		em_andamento: setLoadingEA,
		aguardando_confirmacao: setLoadingAC,
		vendas: setLoadingCE,
		sem_sucesso: setLoadingSS,
		canceladas: setLoadingCC,
		recusadas: setLoadingRecusado,
	};

	const loadingObject = {
		em_andamento: loadingEA,
		aguardando_confirmacao: loadingAC,
		vendas: loadingCE,
		sem_sucesso: loadingSS,
		canceladas: loadingCC,
		recusadas: loadingRecusado,
	};

	const { ui: { tabs }, updateUi } = props;
	const requestsArray = [
		getRCEA,
		getRCAC,
		getRCCE,
		getRCSS,
		getRCCC,
		getRCRecusado,
	];


	useEffect(() => {
		document.title = 'Cotações enviadas - Conaz';
	}, []);

	const requestFunc = (requestData, tabs_index, objTabs = null) => {
		const { result, page } = requestData;
		const _tabs = objTabs || tabs;
		_tabs[tabs_index].current_page_rcs = result || [];
		_tabs[tabs_index].pagination_count = Math.ceil(page.total / page.per_page);
		updateUi('tabs', tabs);
		setLoadingObject[tabs_index](false);
	};

	const initialBuildResponseRcs = results => {
		const lens = results.map(({ data }) => data.page.total);
		const values = _.orderBy(_.values(tabs), 'number');
		const keys = _.orderBy(_.keys(tabs), 'number');

		for (let i = 0; i <= 5; i++) {
			values[i].rcs_length = lens[i];
		}
		const _tabs = _.zipObject(keys, values);
		requestFunc(results[1].data, 'aguardando_confirmacao', _tabs);

		const tabsindex = [
			'em_andamento',
			'vendas',
			'sem_sucesso',
			'canceladas',
			'recusadas',
		];

		const _requestsArray = [requestsArray[0], ...requestsArray.slice(2, 6)];
		const requestPromises = _requestsArray.map(promise => promise());

		requestPromises.forEach((request, i) => {
			_tabs[tabsindex[i]].called = true;
			request.then(({ data }) => requestFunc(data, tabsindex[i], _tabs));
		});
	};

	const hideAlert = () => {
		setNotification(false);
		props.history.push('/propostas/enviadas');
	};

	const filterRCs = funcfilter => list.filter(funcfilter);

	const requestByTab = (index, tabs_index, called) => {
		const is_empity = tabs[tabs_index].current_page_rcs.length === 0;
		if (is_empity && !called) {
			setLoadingObject[tabs_index](true);
			const request = requestsArray[index]();
			const _tabs = tabs;
			_tabs[tabs_index].called = true;
			request.then(({ data }) => {
				requestFunc(data, tabs_index, _tabs);
			});
		}
	};

	const handleAvaliacao = compra_id => {
		// setAvaliacaoDropdown(!avaliacaoDropdown);
		props.history.push(`/avaliacoes/${compra_id}`);
	};

	return (
		<Wrapper
			checa_fornecedor_vendedor
			{...props}
			initial
			buildResponse={initialBuildResponseRcs}
			promises={requestsArray}
		>
			<Enviadas
				{...props}
				requestByTab={requestByTab}
				loading={loadingObject}
				setLoading={setLoadingObject}
				setList={setList}
				notification={notification}
				hideAlert={hideAlert}
				filterRCs={filterRCs}
				handleAvaliacao={handleAvaliacao}
				// avaliacaoDropdown={avaliacaoDropdown}
			/>
		</Wrapper>
	);
};

EnviadasContainer.propTypes = {
	history: PropTypes.object.isRequired,
	ui: PropTypes.object.isRequired,
	updateUi: PropTypes.func.isRequired,
};

const mapStateToProps = ({ cadastro, ui }) => ({
	cadastro,
	ui,
});

const mapDispatchToProps = dispatch => ({
	resetCadastro: () => dispatch(actions.resetCadastro()),
	updateCadastro: (field, value) => dispatch(actions.updateCadastro(field, value)),
	updateUi: (field, value) => dispatch(actions.updateUi(field, value)),
	updateModals: (modal, value) => dispatch(actions.updateModals(modal, value)),
	reportErrors: (page, error, codigo_pedido, full_error, test) => dispatch(actions.reportErrors(page, error, codigo_pedido, full_error, test)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(EnviadasContainer);
