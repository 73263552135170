/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import GroupsMultiLevel from '../../_parts/_inputs/InputCadastro/GroupsMultiLevel/GroupsMultiLevel';
import GroupCheckbox from '../../_parts/_inputs/InputCadastro/GroupsMultiLevel/GroupCheckbox';
import Modal from '../../_parts/Modal/Modal';
import MessageModal from '../../_parts/MessageModal/MessageModal';

const SelecionaGrupos = ({
	/* state */ success, message, enviando, modal_error, handleSearchType, search_type, search_result, errorTitle,
	/* props */ cadastro,
	/* funcs */ submitGrupos, editFamilias, handleModal, limpaResultadosBusca, onClickVoltarButton, limparGrupos,
}) => {
	const { nome, grupos } = cadastro;
	if (modal_error) {
		return (
			<Modal modal="modal_error" handleModal={handleModal}>
				<MessageModal
					type={success ? 'success' : 'alert'}
					title={errorTitle || 'Ops... aconteceu algo errado.'}
					message={message || 'Desculpe, não conseguimos identificar o que aconteceu. Espere alguns segundos e tente novamente.'} />
			</Modal>
		);
	}
	return (
		<div className="container" styleName="main-container">
			<div className="row" styleName="search-familias">
				<div className="col-sm-12" style={{ textAlign: 'center' }}>
					<h3>Você quer receber pedidos de cotações de quais insumos?</h3>
					<p styleName="h5">Atendo pela {nome} os seguintes insumos:</p>
					<input
						type="text"
						className="form-control input-lg"
						placeholder="Buscar produtos e serviços"
						name="search_type"
						value={search_type}
						onChange={handleSearchType} />
					<div styleName="list-group-search-familias" className="list-group">
						{search_result.map(result => (
							<button
								key={result.id}
								type="button"
								className={`list-group-item ${result.selected ? 'disabled' : ''}`}
								onClick={() => { editFamilias(result.keys, true); limpaResultadosBusca(result.keys); }}
								dangerouslySetInnerHTML={{ __html: result.label_front }} />
						))}
					</div>
				</div>
			</div>

			<div className="row" style={{ paddingTop: '160px' }}>
				<div className="col-xs-12">
					{grupos.map(grupo => (
						<div key={grupo.id}>
							<GroupsMultiLevel
								class_name="grupo-element"
								keys={[grupo.key]}
								view={grupo.nome}
								checked={grupo.selected}
								auxiliarFunction={editFamilias} />
							{grupo.selected && grupo.subgrupos.map(subgrupo => (
								<div key={subgrupo.id}>
									<GroupsMultiLevel
										class_name="subgrupo-element"
										keys={[grupo.key, subgrupo.key]}
										view={subgrupo.nome}
										checked={subgrupo.selected}
										auxiliarFunction={editFamilias} />
									{subgrupo.selected && subgrupo.familias.map(familia => (
										<GroupCheckbox
											id={[grupo.key, subgrupo.key, familia.key].join('_')}
											key={familia.id}
											keys={[grupo.key, subgrupo.key, familia.key]}
											view={familia.nome}
											checked={familia.selected}
											auxiliarFunction={editFamilias} />
									))}
								</div>
							))}
						</div>
					))}
				</div>
			</div>

			<div className="row">
				<div className="col-xs-12" style={{ textAlign: 'center' }}>
					<p><br /></p><p><br /></p>
					{message && (
						<div className={`alert alert-${success ? 'success' : 'danger'}`} role="alert">
							<i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" /> {message}
						</div>
					)}
					<p><br /></p>
					{enviando ? (
						<button
							className="btn btn-success btn-lg"
							style={{ padding: '10px 36px 11px' }}
							disabled="disabled">
							Enviando
						</button>
					) : (
						<div className="btn-group">
							<button
								className="btn btn-default btn-lg"
								style={{ padding: '10px 36px 11px', marginRight: '10px' }}
								onClick={onClickVoltarButton}>
								Voltar
							</button>
							<button
								className="btn btn-success btn-lg"
								style={{ padding: '10px 36px 11px' }}
								onClick={() => submitGrupos()}
								disabled={grupos.length === 0}>
								Enviar
							</button>
						</div>
					)}
				</div>
				<div className="col-xs-12" style={{ textAlign: 'center', marginTop: '44px' }}>
					<button
						type="button"
						className="btn btn-link"
						style={{ color: '#6c7174' }}
						onClick={limparGrupos}>
						Limpar todos os insumos
					</button>
				</div>
			</div>
		</div>
	);
};

SelecionaGrupos.propTypes = {
	modal_error: PropTypes.bool.isRequired,
	enviando: PropTypes.bool.isRequired,
	cadastro: PropTypes.object.isRequired,
	message: PropTypes.string.isRequired,
	success: PropTypes.bool.isRequired,
	submitGrupos: PropTypes.func.isRequired,
	editFamilias: PropTypes.func.isRequired,
	limpaResultadosBusca: PropTypes.func.isRequired,
	handleSearchType: PropTypes.func.isRequired,
	search_type: PropTypes.string.isRequired,
	search_result: PropTypes.array.isRequired,
	handleModal: PropTypes.func.isRequired,
	onClickVoltarButton: PropTypes.func.isRequired,
	errorTitle: PropTypes.string.isRequired,
	limparGrupos: PropTypes.func.isRequired,
};

export default CSSModules(SelecionaGrupos, styles, { allowMultiple: true });
