import React, { Component } from 'react';
import PropTypes from 'prop-types';

class InputTextArea extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: props.default_value,
		};
		this.handleEdition = this.handleEdition.bind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { default_value } = nextProps;
		if (default_value !== this.state.value) this.setState({ value: default_value });
	}

	handleEdition(e) {
		const { type, item_id, field, updateFunction, autoSaveTimeout } = this.props;
		const { value } = e.target;
		this.setState({ value });

		switch (type) {
		case 'cotacao': {
			updateFunction(field, value);
			break;
		}
		case 'item': {
			updateFunction(item_id, field, value);
			break;
		}
		default:
		}

		autoSaveTimeout(item_id);
	}

	render() {
		const { value } = this.state;
		const { field_id } = this.props;

		return (
			<textarea
				id={field_id}
				className="form-control"
				rows="4"
				value={value}
				onChange={this.handleEdition} />
		);
	}
}

InputTextArea.propTypes = {
	type: PropTypes.string.isRequired,
	field_id: PropTypes.string,
	item_id: PropTypes.number,
	field: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.array,
	]).isRequired,
	default_value: PropTypes.string,
	updateFunction: PropTypes.func,
	autoSaveTimeout: PropTypes.func,
};

InputTextArea.defaultProps = {
	field_id: '',
	item_id: -1,
	default_value: '',
	updateFunction: () => {},
	autoSaveTimeout: () => {},
};

export default InputTextArea;
