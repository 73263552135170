/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from '../style.module.scss';

import { formatCurrency, formatCurrencyDecimalsFree } from '../../../_functions/_formatNumber';
import { printStatus } from '../../../_functions/_printStatus';

function setPrazoEntregaView(
	prazo_de_entrega,
	transporte,
	transporte_proprio,
) {
	if (
		(transporte === 1 && transporte_proprio === 2)
		|| prazo_de_entrega === null
	) {
		return 'A Combinar';
	}

	return prazo_de_entrega === 1
		? `${prazo_de_entrega} dia útil`
		: `${prazo_de_entrega} dias úteis`;
}

const CotacaoRespondida = ({
	condicoes_de_pagamento,
	status,
	custos_adicionais,
	// transporte_opcoes,
	transporte,
	transporte_proprio,
	prazo_de_entrega,
	codigo_do_fornecedor,
	data_resposta_view,
	hora_resposta_view,
	validade_view,
	anexos_cotacao,
	observacoes,
	formas_pagamento,
	prazos_pagamento_full,
	sub_total,
	// preco_total,
	valor_transporte,
	pedido_cancelado,
	compra,
}) => {
	const status_view = compra.status !== 25
		? printStatus(status, pedido_cancelado)
		: 'Sem sucesso';

	const prazo_entrega_label = transporte === 2
		? 'Prazo para retirada'
		: 'Prazo de entrega';
	const prazo_de_entrega_view = setPrazoEntregaView(
		prazo_de_entrega,
		transporte,
		transporte_proprio,
	);

	// transporte_proprio
	const opcoes_tp = {
		0: 'Próprio',
		1: 'Terceirizado',
		2: 'A Combinar',
	};
	const transporte_proprio_view = opcoes_tp[transporte_proprio] || 'A Combinar';

	return (
		<React.Fragment>
			<div className="row">
				<div className="col-xs-12 col-md-8" styleName="content-block">
					<i className="fa fa-credit-card-alt" aria-hidden="true" />
					<p styleName="labels">Valores</p>
					{condicoes_de_pagamento.map((condicao, c) => {
						const forma = (condicao.forma !== -1 && condicao.forma !== null)
							? (formas_pagamento[condicao.forma] || {}).view || ''
							: condicao.outra_forma;
						const prazo = (condicao.prazo !== -1 && condicao.prazo !== null)
							? (prazos_pagamento_full[condicao.prazo] || {}).view || ''
							: condicao.outro_prazo;
						const valor_desconto = (sub_total / 100) * condicao.desconto;
						const preco_total_desconto = (sub_total - valor_desconto) + valor_transporte + custos_adicionais;

						return (
							<div key={c} styleName="pagamentos">
								<div styleName="dot">•</div>
								<h4 styleName={c === 0 ? 'first' : ''}>
									<b>{formatCurrency(preco_total_desconto, 'R$')}</b>
								</h4>
								{forma} - {prazo} {condicao.desconto !== 0 && <span style={{ color: '#a29f9f' }}>- ({condicao.desconto}% desconto)</span>}
								{condicao.aceita_parcial === 0 && (
									<p>Não aceito compras parciais</p>
								)}
								{condicao.aceita_parcial === 1 && (
									<p>Aceito compras parciais</p>
								)}
								{(condicao.aceita_parcial === 2 && condicao.compra_minima > 0) && (
									<p>Aceito compras parciais acima de {formatCurrency(condicao.compra_minima, 'R$')}</p>
								)}
							</div>
						);
					})}
				</div>
				<div className="col-xs-12 col-md-4" styleName="content-block">
					<i className="fa fa-check-circle" aria-hidden="true" />
					<p styleName="labels">Status da cotação</p>
					<span styleName="price-color"><b>{status_view}</b></span>
				</div>
				<div className="col-xs-12">
					<hr styleName="hr" />
				</div>
			</div>
			<div className="row">
				<div className="col-xs-12 col-md-3" styleName="content-block">
					<i className="fa fa-usd" aria-hidden="true" />
					<p styleName="labels">Custos adicionais</p>
					<span styleName="price-color">{formatCurrencyDecimalsFree(custos_adicionais, 'R$')}</span>
				</div>
				<div className="col-xs-12 col-md-3" styleName="content-block">
					<i className="fa fa-truck" aria-hidden="true" />
					<p styleName="labels">Transporte / Frete</p>
					{transporte === 0 && (
						<p>Frete incluso no preço (CIF)</p>
					)}
					{transporte === 1 && ( // a pagar
						<>
							{transporte_proprio === 2 ? (
								<p>{transporte_proprio_view}</p>
							) : (
								<p>
									Frete a pagar
									<span styleName="price-color"><br />{formatCurrencyDecimalsFree(valor_transporte, 2, 'R$')} - {transporte_proprio_view}</span>
								</p>
							)}
						</>
					)}
					{transporte === 2 && ( // não realiza entrega
						<p>Frete por conta do cliente</p>
					)}
					{/* transporte_opcoes
						.filter(t => t.value === transporte)
						.map((transporte2, t2) => (
							<p key={t2}>
								{(transporte2 || {}).view}
								{Number(transporte) === 1 && (
									<span styleName="price-color"><br />{formatCurrencyDecimalsFree(valor_transporte, 'R$')}</span>
								)}
							</p>
						))
					*/}
				</div>
				<div className="col-xs-12 col-md-3" styleName="content-block">
					<i className="fa fa-calendar-check-o" aria-hidden="true" />
					<p styleName="labels">{prazo_entrega_label}</p>
					{prazo_de_entrega === null || prazo_de_entrega === undefined ? (
						<p>A Combinar</p>
					) : (
						<p>{prazo_de_entrega_view}</p>
					)}
				</div>
				<div className="col-xs-12 col-md-3" styleName="content-block">
					<i className="fa fa-hashtag" aria-hidden="true" />
					<p styleName="labels">Código da sua proposta</p>
					{codigo_do_fornecedor !== '' ? codigo_do_fornecedor : '-'}
				</div>
			</div>

			<div className="row">
				<div className="col-xs-12 col-md-3" styleName="content-block margin-top">
					<i className="fa fa-paper-plane" aria-hidden="true" />
					<p styleName="labels">Enviada em</p>
					{data_resposta_view} - {hora_resposta_view}
				</div>
				<div className="col-xs-12 col-md-3" styleName="content-block margin-top">
					<i className="fa fa-clock-o" aria-hidden="true" />
					<p styleName="labels">Validade da proposta</p>
					{validade_view}
				</div>
			</div>

			<div className="row">
				<div className="col-xs-12 col-md-6" styleName="content-block margin-top">
					<i className="fa fa-files-o" aria-hidden="true" />
					<p styleName="labels">Anexos</p>
					{anexos_cotacao.map((anexo, a) => ( // eslint-disable-next-line react/no-array-index-key
						<div key={a} styleName="anexo-row">
							<span>{a + 1}</span> <a href={anexo.url} target="_blank" rel="noopener noreferrer" download>{anexo.nome}</a>
						</div>
					))}
					{anexos_cotacao.length === 0 && <em style={{ color: '#c3c6c9', fontSize: '14px' }}>0 arquivos</em>}
				</div>
				<div className="col-xs-12 col-md-6" styleName="content-block margin-top">
					<i className="fa fa-comment" aria-hidden="true" />
					<p styleName="labels">Observações</p>
					{observacoes !== '' ? <p style={{ fontSize: '14px' }}>&#34;{observacoes}&#34;</p> : <em style={{ color: '#c3c6c9', fontSize: '14px' }}>Nenhuma observação.</em>}
				</div>
			</div>
		</React.Fragment>
	);
};

CotacaoRespondida.propTypes = {
	// transporte_opcoes: PropTypes.array.isRequired,
	status: PropTypes.number.isRequired,
	transporte: PropTypes.number,
	transporte_proprio: PropTypes.number,
	anexos_cotacao: PropTypes.array.isRequired,
	validade_view: PropTypes.string.isRequired,
	hora_resposta_view: PropTypes.string.isRequired,
	data_resposta_view: PropTypes.string.isRequired,
	custos_adicionais: PropTypes.number.isRequired,
	prazo_de_entrega: PropTypes.number,
	valor_transporte: PropTypes.number.isRequired,
	codigo_do_fornecedor: PropTypes.string.isRequired,
	condicoes_de_pagamento: PropTypes.array.isRequired,
	sub_total: PropTypes.number.isRequired,
	// preco_total: PropTypes.number.isRequired,
	observacoes: PropTypes.string.isRequired,
	prazos_pagamento_full: PropTypes.object.isRequired,
	formas_pagamento: PropTypes.object.isRequired,
	pedido_cancelado: PropTypes.bool.isRequired,
	compra: PropTypes.object.isRequired,
};

CotacaoRespondida.defaultProps = {
	transporte: null,
	transporte_proprio: null,
	prazo_de_entrega: null,
};

export default CSSModules(CotacaoRespondida, styles, { allowMultiple: true });
