import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shallowequal from 'shallowequal';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

class ItemRow extends Component {
	shouldComponentUpdate(nextProps) {
		if (!shallowequal(this.props, nextProps)) return true;
		return false;
	}

	render() {
		const { number, item } = this.props;
		const { item_preenchido } = item;
		const caracteristicas_pedido = item_preenchido.caracteristicas_preenchidas
			.filter(c => c.caracteristica.visao_construtor !== 0 && c.opcoes_preenchidas.length > 0)
			.sort((a, b) => a.caracteristica.ordem - b.caracteristica.ordem);

		return (
			<div className="row">
				<div className="col-xs-12">
					<div styleName="item-row-top">
						<div styleName="number">{number}</div>
						<div styleName="nome">{item_preenchido.item.nome}</div>
						<div styleName="quantidade-top">
							Quantidade solicitada:
							<b> {item.quantidade.toString().replace('.', ',')} {item.unidade}</b>
						</div>
						{caracteristicas_pedido.map(c => {
							// if (c.caracteristica.visao_construtor !== 0 && c.opcoes_preenchidas.length > 0) {
							const valor = c.opcoes_preenchidas.map(op => ((op.unidade !== '' && op.unidade !== null && op.unidade !== undefined) ? `${op.valor} ${op.unidade}` : op.valor)).join(', ');
							return valor !== '' ? (
								<div key={c.caracteristica.id} styleName="carac-wrapper">
									{c.caracteristica.nome}<p><b>{valor}</b></p>
								</div>
							) : false;
							// }
							// return false;
						})}
						{item.observacoes !== '' && (
							<div styleName="carac-wrapper">
								Observações
								<p><b>{item.observacoes}</b></p>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

ItemRow.propTypes = {
	number: PropTypes.number.isRequired,
	item: PropTypes.object.isRequired,
};

export default CSSModules(ItemRow, styles, { allowMultiple: true });
