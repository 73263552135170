import React from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
// import LoadingButton from '../../_parts/_loadings/LoadingButton/LoadingButton';
import ModalWrapper from '../../_parts/_modals/ModalWrapper/ModalWrapper';
import ModalUpload from '../../_parts/_modals/ModalUpload/ModalUpload';
import HeaderCotacao from '../../_parts/HeaderCotacao/HeaderCotacao';
import InfoClienteCotacao from '../../_parts/InfoClienteCotacao/InfoClienteCotacao';
import ItemRowRevisao from '../../_parts/ItemRowRevisao/ItemRowRevisao';
// import InputSelectGeral from '../../_parts/_inputs/InputSelectGeral/InputSelectGeral';
import LeftModalWrapper from '../../_parts/_modals/LeftModalWrapper/LeftModalWrapper';
import LeftModalConstrutorPerfil from '../../_parts/_modals/LeftModalConstrutorPerfil/LeftModalConstrutorPerfil';
import ModalPortal from '../../_parts/_modals/ModalPortal/ModalPortal';
import ModalWrapperPortal from '../../_parts/_modals/ModalPortal/ModalWrapperPortal';
import ModalConfirm from '../../_parts/_modals/ModalConfirm/ModalConfirm';
import Refinamento from '../Refinamento/Refinamento';
import Stepbar from '../../_parts/Stepbar';
import StepbarMobile from '../../_parts/Stepbar/StepbarMobile';
import EnviarRCmobile from '../../_parts/Stepbar/EnviarRCmobile';

// Functions
import { formatCurrency, formatCurrencyDecimalsFree } from '../../_functions/_formatNumber';
// import { getLocalstorageData } from '../../_functions/_getLocalstorageData';

// Content
import { formas_pagamento, prazos_pagamento_full } from '../../_content/_condicoes_pagamento';
// TODO refatorar aviso para revisao no responsivo
const Revisao = ({
	// =========== local
	tab_perfil_construtor,
	modal_pedido_em_edicao,
	width,
	new_saving,
	// =========== local funcs
	openPerfilConstrutor,
	handleModalPedidoEmEdicao,
	// =========== store
	ui,
	cotacao,
	history,
	// =========== funcs
	updateCotacao,
	updateModals,
	updateUi,
}) => {
	const { modals } = ui;
	const { rc, itens_all_ids, itens_by_id } = cotacao;
	const valor_final_itens = itens_all_ids.reduce((r, c) => {
		const item = itens_by_id[c];
		const quantidade = item.quantidade || 0;
		const preco_unitario = item.preco_unitario || 0;
		// sub_total
		const sub_total = quantidade * preco_unitario;
		// desconto
		const desconto = item.desconto || 0;
		const valor_desconto = (sub_total / 100) * desconto;
		// total
		const total = (sub_total - valor_desconto) || 0;
		return r + total;
	}, 0);

	const transporte_views = ['Incluso no preço', 'A pagar', 'Não realizo entrega'];
	const is_mobile = width <= 1020 || width <= 768;


	if (
		rc.status === -30 ||
		rc.pedido.status === -30 ||
		rc.pedido.status === -20 ||
		rc.pedido.status === -10
	) {
		return (
			<div className="container" style={{ marginTop: '36px' }}>
				<div className="row">
					<div className="col-sm-12 error-wrapper">
						<i className="fa fa-exclamation-circle" aria-hidden="true" />
						<h3>Pedido cancelado!</h3>
						<p>Este pedido foi cancelado pelo construtor e não está mais disponível.</p>
					</div>
				</div>
			</div>
		);
	}

	if (rc.pedido.status === 10 || rc.pedido.status === 15) {
		return (
			<div className="container" style={{ marginTop: '36px' }}>
				<div className="row">
					<div className="col-sm-12 error-wrapper">
						<i className="fa fa-pencil" aria-hidden="true" />
						<h3>Pedido em edição!</h3>
						<p style={{ fontSize: '16px' }}>Este pedido está sendo editado pelo construtor neste momento.<br />Você será notificado por e-mail assim que ele concluir.</p>
					</div>
				</div>
			</div>
		);
	}

	return (
		<>
			{modal_pedido_em_edicao && (
				<ModalPortal>
					<ModalWrapperPortal closeModal={handleModalPedidoEmEdicao}>
						<ModalConfirm
							title="Pedido em edição"
							message={<p>Este pedido está sendo editado pelo construtor neste momento. Você será notificado por e-mail assim que ele concluir.</p>}
							button_type="secondary"
							button_text="Entendi!" />
					</ModalWrapperPortal>
				</ModalPortal>
			)}
			<ModalWrapper visible={modals.anexos} updateModals={updateModals}>
				<ModalUpload
					rc_id={rc.uuid}
					anexos={rc.anexos}
					updateCotacao={updateCotacao}
					edit={false} />
			</ModalWrapper>
			<ModalWrapper visible={modals.anexos_cliente} updateModals={updateModals}>
				<ModalUpload
					rc_id={rc.uuid}
					anexos={rc.anexos_pedido}
					updateCotacao={updateCotacao}
					modal_to_close="anexos_cliente"
					edit={false} />
			</ModalWrapper>
			<LeftModalWrapper visible={modals.perfil_construtor} updateModals={updateModals} modal="perfil_construtor">
				<LeftModalConstrutorPerfil
					exibir_contatos_pedido={rc.pedido.exibir_contato}
					usuario_pedido={rc.pedido.aprovacao_pedida_por.id !== undefined ? rc.pedido.aprovacao_pedida_por : rc.pedido.usuario}
					obra={rc.pedido.obra}
					tab={tab_perfil_construtor} />
			</LeftModalWrapper>
			{is_mobile && (
				<StepbarMobile
					cotacao={cotacao}
					history={history}
					title="4. Revisão da proposta"
					new_saving={new_saving}
					updateModals={updateModals}
					modals={modals}
				/>
			)}
			<HeaderCotacao
				pedido_id={rc.pedido.id}
				data_envio={rc.data_envio}
				prazo_de_resposta={rc.pedido.prazo_de_resposta}
				data_de_entrega_desejada={rc.pedido.data_de_entrega_desejada} />

			<InfoClienteCotacao
				obra={rc.pedido.obra}
				data_de_entrega_desejada={rc.pedido.data_de_entrega_desejada}
				observacoes={rc.pedido.observacoes_conaz}
				anexos={rc.anexos_pedido}
				updateModals={updateModals}
				openPerfilConstrutor={openPerfilConstrutor} />

			<div className="container">
				{!is_mobile && (
					<Stepbar
						isPreRefinamento={rc.status === 36}
						cotacao={cotacao}
						history={history}
						updateUi={updateUi}
						updateModals={updateModals}
						handleModalPedidoEmEdicao={handleModalPedidoEmEdicao} />
				)}
				<div styleName="revisao-proposta">
					<p>Revisão de proposta</p>
					<div>
						<i className="fa fa-exclamation-circle" />
						<p>Revise a sua proposta antes de enviar para a construtora</p>
					</div>
				</div>
				<div className="row">
					{rc.anexos.length > 0 && (
						<div className="col-xs-12 col-md-6">
							<button
								type="button"
								styleName="anexos-button"
								onClick={() => updateModals('anexos', true)}>
								<i className="fa fa-paperclip fa-rotate-90" /> Anexos <span>{rc.anexos.length}</span>
							</button>
						</div>
					)}
				</div>
				{rc.status === 36
					&& <Refinamento {...{ history, cotacao, handleModalPedidoEmEdicao, updateCotacao }} /> }
				<p style={{ marginBottom: '10px', fontSize: '18px' }}><b>Informações dos itens cotados:</b></p>
				{itens_all_ids.map((id, i) => (
					<ItemRowRevisao
						key={id}
						number={i + 1}
						item={itens_by_id[id]} />
				))}
				{rc.observacoes !== '' && (
					<div className="row">
						<div className="col-xs-12" style={{ paddingTop: '30px' }}>
							<label>Observações gerais</label>
							<p>{rc.observacoes}</p>
						</div>
					</div>
				)}
				<div className="row">
					<div className="col-xs-6 col-md-4" style={{ paddingTop: '30px' }}>
						<label>Opções de transporte/Frete</label>
						<p>
							{transporte_views[rc.transporte]}
							{rc.valor_transporte > 0 && (
								<span> ({formatCurrencyDecimalsFree(rc.valor_transporte, 'R$')}) </span>
							)}
						</p>
					</div>
					<div className="col-xs-6 col-md-8" style={{ paddingTop: '30px' }}>
						<label>Impostos / Adicionais</label>
						<p>{formatCurrencyDecimalsFree(rc.custos_adicionais, 'R$')}</p>
					</div>
				</div>
				<div className="row">
					<div className="col-xs-12" style={{ paddingTop: '55px' }}>
						<label>Condições de pagamento</label>
						{rc.condicoes_de_pagamento.map((id, i) => {
							const condicao = rc.condicoes_de_pagamento_by_id[id];
							const forma_selecionada = condicao.forma > 0 ? formas_pagamento[condicao.forma].view : condicao.outra_forma;
							const prazo_selecionado = condicao.prazo > 0 ? prazos_pagamento_full[condicao.prazo].view : condicao.outro_prazo;
							const desconto = condicao.desconto || 0;
							// valor final
							const valor_desconto = (valor_final_itens / 100) * desconto;
							const valor_final_desconto = (valor_final_itens - valor_desconto) + rc.valor_transporte + rc.custos_adicionais;

							return (
								<div key={id} styleName="condicoes-pagamento">
									<div styleName="condicao">
										{i + 1}. {forma_selecionada} - {prazo_selecionado} {condicao.desconto > 0 && <span> - {condicao.desconto}% de desconto</span>}
										{condicao.aceita_parcial === 0 && (
											<p>Não aceito compras parciais</p>
										)}
										{condicao.aceita_parcial === 1 && (
											<p>Aceito compras parciais</p>
										)}
										{(condicao.aceita_parcial === 2 && condicao.compra_minima > 0) && (
											<p>Aceito compras parciais acima de {formatCurrency(condicao.compra_minima, 'R$')}</p>
										)}
									</div>
									{!is_mobile && (
										<div styleName="valor">
											{formatCurrency(valor_final_desconto, 'R$')}
										</div>
									)}
								</div>
							);
						})}
					</div>
				</div>
				<div className="row">
					<div className="col-xs-12">
						<p><br /></p><p><br /></p><p><br /></p>
						<p><br /></p><p><br /></p><p><br /></p>
					</div>
				</div>
			</div>
			{is_mobile && <EnviarRCmobile cotacao={cotacao} history={history} />}
		</>
	);
};

Revisao.propTypes = {
	// =========== local
	tab_perfil_construtor: PropTypes.number.isRequired,
	modal_pedido_em_edicao: PropTypes.bool.isRequired,
	new_saving: PropTypes.number.isRequired,
	// =========== local funcs
	openPerfilConstrutor: PropTypes.func.isRequired,
	handleModalPedidoEmEdicao: PropTypes.func.isRequired,
	// =========== store
	cotacao: PropTypes.object.isRequired,
	ui: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	width: PropTypes.number.isRequired,

	// =========== funcs
	updateModals: PropTypes.func.isRequired,
	updateCotacao: PropTypes.func.isRequired,
	updateUi: PropTypes.func.isRequired,
};

export default CSSModules(Revisao, styles, { allowMultiple: true });
