import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

class ModalResetRC extends Component {
	resetRC(where) {
		this.props.updateModals('reset_rc', false);
		this.props.history.push(`/${where}`);
	}

	render() {
		return (
			<div className="modal-content" styleName="modal-content">
				<div className="modal-header" styleName="modal-header">
					<button
						type="button"
						className="close"
						styleName="close-button"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => this.props.updateModals('reset_rc', false)}>
						<span aria-hidden="true">&times;</span>
					</button>
					<h4 className="modal-title">Atualizar proposta</h4>
				</div>
				<div className="modal-body" styleName="modal-body">
					<p>É necessário estar logado para atualizar a proposta. Faça login ou cadastre-se:</p>
					<div className="row" style={{ textAlign: 'center', paddingTop: '20px', paddingBottom: '20px' }}>
						<div className="col-xs-12 col-md-6">
							<button type="button" className="btn btn-primary btn-lg" onClick={() => this.resetRC('login')}>Entrar</button>
						</div>
						<div className="col-xs-12 col-md-6">
							<button type="button" className="btn btn-success btn-lg" onClick={() => this.resetRC('cadastro')}>Cadastrar</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

ModalResetRC.propTypes = {
	updateModals: PropTypes.func,
	history: PropTypes.object.isRequired,
};

ModalResetRC.defaultProps = {
	updateModals: () => {},
};

export default CSSModules(ModalResetRC, styles, { allowMultiple: true });
