export const formatCurrency = (value, cur, dec) => {
	const currency = (
		cur === '' ||
		cur === undefined ||
		cur === null ||
		typeof cur !== 'string'
	) ? 'R$' : cur;
	const pre_value = (
		value !== null &&
		value !== undefined &&
		value !== ''
	) ? value : 0;
	const decimals = (
		dec !== null &&
		dec !== undefined &&
		dec !== '' &&
		typeof dec === 'number'
	) ? dec : 2;
	const number = typeof pre_value === 'number'
		? pre_value.toFixed(decimals).toString().replace(/\./g, ',')
		: parseFloat(pre_value).toFixed(decimals).toString().replace(/\./g, ',');
	return `${currency} ${number.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
};

export const formatCurrencyDecimalsFree = (value, cur) => {
	const currency = (
		cur === '' ||
		cur === undefined ||
		cur === null ||
		typeof cur !== 'string'
	) ? 'R$' : cur;
	const pre_value = (
		value !== null &&
		value !== undefined &&
		value !== ''
	) ? value : 0;
	const number = typeof pre_value === 'number'
		? pre_value.toString().replace(/\./g, ',')
		: parseFloat(pre_value).toString().replace(/\./g, ',');
	const inteiros = number.split(',')[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
	const pre_decimals = number.split(',')[1] || '00';
	const decimals = pre_decimals.length === 1 ? `${pre_decimals}0` : pre_decimals;
	return `${currency} ${inteiros},${decimals}`;
};

export const formatDecimals = value => {
	const pre_value = (
		value !== null &&
		value !== undefined &&
		value !== '' &&
		typeof value === 'number'
	) ? value : 0;
	const number = typeof pre_value === 'number'
		? pre_value.toString().replace(/\./g, ',')
		: parseFloat(pre_value).toString().replace(/\./g, ',');
	const inteiros = number.split(',')[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
	const pre_decimals = number.split(',')[1] || '';
	const decimals = pre_decimals === '' ? '' : `,${pre_decimals}`;
	return `${inteiros}${decimals}`;
};

export const formatNumbers = (value, mask) => {
	const only_numbers = value.replace(/\D/g, '');

	switch (mask) {
	case 'cpf': {
		if (only_numbers.length === 11) {
			const x1 = only_numbers.match(/(\d{3})(\d{3})(\d{3})(\d{2})/);
			return `${x1[1]}.${x1[2]}.${x1[3]}-${x1[4]}`;
		}
		return only_numbers;
	}
	case 'cnpj': {
		if (only_numbers.length === 14) {
			const x1 = only_numbers.match(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/);
			return `${x1[1]}.${x1[2]}.${x1[3]}/${x1[4]}-${x1[5]}`;
		}
		return only_numbers;
	}

	case 'cep': {
		if (only_numbers.length === 8) {
			const x1 = only_numbers.match(/(\d{5})(\d{3})/);
			return `${x1[1]}-${x1[2]}`;
		}
		return only_numbers;
	}

	case 'telefone': {
		switch (only_numbers.length) {
		case 8: {
			const x2 = only_numbers.match(/(\d{4})(\d{4})/);
			return `${x2[1]}-${x2[2]}`;
		}
		case 9: {
			const x3 = only_numbers.match(/(\d{5})(\d{4})/);
			return `${x3[1]}-${x3[2]}`;
		}
		case 10: {
			const x4 = only_numbers.match(/(\d{2})(\d{4})(\d{4})/);
			return `(${x4[1]}) ${x4[2]}-${x4[3]}`;
		}
		case 11: {
			if (['0300', '0500', '0800', '0900'].indexOf(only_numbers.substring(0, 4)) !== -1) {
				const x5 = only_numbers.match(/(\d{0,4})(\d{0,3})(\d{0,4})/);
				return `${x5[1]} ${x5[2]} ${x5[3]}`;
			}
			const x5 = only_numbers.match(/(\d{2})(\d{5})(\d{4})/);
			return `(${x5[1]}) ${x5[2]}-${x5[3]}`;
		}
		default:
			return only_numbers;
		}
	}

	default:
		return value;
	}
};
