import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './ReactotronConfig';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

// styles
import './styles/bootstrap/bootstrap.scss';
import './styles/app.scss';

import App from './App';
import * as serviceWorker from './serviceWorker';

// reducers
import cadastroReducer from './reducers/cadastro.reducer';
import cotacoesReducer from './reducers/cotacoes.reducer';
import cotacaoReducer from './reducers/cotacao.reducer';
import uiReducer from './reducers/ui.reducer';
import userReducer from './reducers/user.reducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
	cadastro: cadastroReducer,
	cotacoes: cotacoesReducer,
	cotacao: cotacaoReducer,
	ui: uiReducer,
	user: userReducer,
});

// const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
const store = process.env.NODE_ENV === 'development'
	? createStore(rootReducer, composeEnhancers(applyMiddleware(thunk), console.tron.createEnhancer())) // eslint-disable-line
	: createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const app = (
	<Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
