import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { compose } from 'redux';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { getLocalstorageData } from '../../_functions/_getLocalstorageData';
import { isAuthenticated, logout } from '../../../services/login_manager';
import withDocumentWidth from '../../../hoc/DocumentWidh/withDocumentWidht';

class NavigationBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			account_menu: false,
		};
		this.tooltip_timeout = window.setTimeout(() => {}, 1);
		this.handleAccountMenu = this.handleAccountMenu.bind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.account_menu !== this.props.account_menu) {
			this.setState({ account_menu: nextProps.account_menu });
		}
	}

	handleAccountMenu() {
		const account_menu = !this.state.account_menu;
		this.setState({ account_menu });
		this.props.updateUi('account_menu', account_menu);
	}

	signOut(e) {
		e.preventDefault();
		this.props.resetCadastro();
		logout();
		this.props.history.push('/login');
	}

	showTooltip(id) {
		window.clearTimeout(this.tooltip_timeout);
		this.tooltip_timeout = window.setTimeout(() => {
			document.getElementById(id).className = 'tooltip bottom in';
		}, 400);
	}

	hideTooltip(id) {
		window.clearTimeout(this.tooltip_timeout);
		document.getElementById(id).className = 'tooltip bottom';
	}

	render() {
		const { account_menu } = this.state;
		const { menu, salvando_cotacao, width: _width } = this.props;
		const account_menu_button_class = account_menu ? 'account-menu-button active' : 'account-menu-button';
		const nome_usuario = getLocalstorageData('user', 'nome');
		const nome_usuario_view = nome_usuario.charAt(0).toUpperCase() + nome_usuario.slice(1);

		const li_solicitacoes_cls = window.location.href.indexOf('solicitacoes') !== -1 ? 'active' : '';
		const li_enviadas_cls_name = window.location.href.indexOf('enviadas') !== -1 ? 'active' : '';
		const li_avaliacoes_cls_name = window.location.href.indexOf('avaliacoes') !== -1 ? 'active' : '';

		return (
			<>
				<div styleName="navbar-backspace" />
				<nav className="navbar navbar-default navbar-fixed-top" styleName="navbar-local">
					<div className="container" styleName="navbar-container">
						<div className="navbar-header" style={{ width: '100%' }}>
							{isAuthenticated() ? (
								<Link to="/solicitacoes" className="navbar-brand" styleName="navbar-logo">
									<img src="/images/conaz-simbolo_branco_small.png" width="54" alt="" />
								</Link>
							) : (
								<Link to="#" className="navbar-brand" styleName="navbar-logo">
									<img src="/images/conaz-simbolo_branco_small.png" width="54" alt="" />
								</Link>
							)}
							{menu && (
								<>
									{isAuthenticated() && (
										<>
											<ul styleName="navbar-mobile only-mobile">
												<li className={li_solicitacoes_cls} styleName={li_solicitacoes_cls}><Link to="/solicitacoes">Oportunidades</Link></li>
												<li className={li_enviadas_cls_name} styleName={li_enviadas_cls_name}><Link to="/propostas/enviadas">Resultados</Link></li>
												<li className={li_avaliacoes_cls_name} styleName={li_avaliacoes_cls_name}><Link to="/avaliacoes">Avaliações</Link></li>
											</ul>
											<ul className="nav navbar-nav" styleName="only-desktop">
												<li className={li_solicitacoes_cls} styleName={li_solicitacoes_cls}><Link to="/solicitacoes">Oportunidades</Link></li>
												<li className={li_enviadas_cls_name} styleName={li_enviadas_cls_name}><Link to="/propostas/enviadas">Resultados</Link></li>
												<li className={li_avaliacoes_cls_name} styleName={li_avaliacoes_cls_name}><Link to="/avaliacoes">Avaliações</Link></li>
											</ul>
										</>
									)}
								</>
							)}
							{salvando_cotacao === 1 && (
								<div styleName="salvando-message">
									<i className="fa fa-circle-o-notch fa-spin fa-fw" /> Salvando...
								</div>
							)}
							{salvando_cotacao === 2 && (
								<div styleName="salvando-message">
									<i className="fa fa-check fa-fw" /> Cotação salva!
								</div>
							)}
							{isAuthenticated() && (
								<button styleName={account_menu_button_class} onClick={this.handleAccountMenu} data-account-menu>
									<span styleName="only-desktop" data-account-menu>{nome_usuario_view}</span> <span className="caret" data-account-menu />
								</button>
							)}
							{_width >= 768 && (
								<div styleName="help-button">
									<a
										href="https://conaz.zendesk.com/hc/pt-br/categories/115000062933-Fornecedor"
										target="_blank"
										rel="noopener noreferrer"
										onMouseEnter={() => this.showTooltip('tooltip_help')}
										onMouseLeave={() => this.hideTooltip('tooltip_help')}>
										<i className="fa fa-question-circle" aria-hidden="true" />
									</a>
									<div id="tooltip_help" className="tooltip bottom" styleName="tooltip-help" role="tooltip" style={{ marginTop: '-1px', right: '-4px' }}>
										<div className="tooltip-arrow" style={{ borderBottomColor: '#fff' }} />
										<div className="tooltip-inner" style={{ backgroundColor: '#fff', color: '#000', paddingBottom: '4px' }}>Ajuda</div>
									</div>
								</div>
							)}
						</div>

						{account_menu && (
							<div styleName="account-menu" data-account-menu>
								<ul aria-labelledby="dropdownMenu1" style={{ display: 'block' }} data-account-menu>
									{_width < 768 && <li><a href="https://conaz.zendesk.com/hc/pt-br/categories/115000062933-Fornecedor" target="_blank" rel="noopener noreferrer"><i className="fa fa-question-circle fa-fw" aria-hidden="true" data-account-menu /> Ajuda</a></li>}
									{_width < 768 && <li role="separator" styleName="divider" />}
									<li><Link to="/minha-conta" data-account-menu><i className="fa fa-user-circle" aria-hidden="true" data-account-menu /> Minha conta</Link></li>
									<li><Link to="/alterar-senha" data-account-menu><i className="fa fa-key" aria-hidden="true" data-account-menu /> Alterar senha</Link></li>
									<li><Link to="/empresas" data-account-menu><i className="fa fa-building fa-fw" aria-hidden="true" data-account-menu /> Editar empresas</Link></li>
									<li><Link to="/cadastro" data-account-menu><i className="fa fa-plus-circle" aria-hidden="true" data-account-menu /> Cadastrar outra empresa</Link></li>
									<li><Link to="#" onClick={e => this.signOut(e)} data-account-menu><i className="fa fa-sign-out fa-fw" aria-hidden="true" data-account-menu /> Sair</Link></li>
								</ul>
							</div>
						)}
					</div>
				</nav>
			</>
		);
	}
}

NavigationBar.propTypes = {
	account_menu: PropTypes.bool,
	updateUi: PropTypes.func.isRequired,
	// unsetAuth: PropTypes.func.isRequired,
	menu: PropTypes.bool,
	history: PropTypes.object.isRequired,
	salvando_cotacao: PropTypes.number,
	resetCadastro: PropTypes.func.isRequired,
	width: PropTypes.number.isRequired,
};

NavigationBar.defaultProps = {
	account_menu: false,
	menu: true,
	salvando_cotacao: 0,
};
const _NavigationBar = CSSModules(NavigationBar, styles, { allowMultiple: true });
export default compose(withDocumentWidth)(_NavigationBar);
