import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

const ARComprador = ({ avaliacao }) => {
	const compra = (avaliacao.compra || [])[0] || {};
	const compra_id = compra.id || 0;
	const compra_titulo = compra.titulo || '';
	const compra_cliente = ((
		avaliacao || {})
		.cliente || {})
		.apelido_interno || '';
	const cliente = `#${compra_id} - ${compra_titulo}`;

	const rc_id = compra.rc_id || '';
	const compra_link = rc_id !== ''
		? `/proposta/${rc_id}/ver/${compra_id}`
		: `/venda/${compra_id}/ver`;

	return (
		<div styleName="comprador">
			{compra_cliente} - <Link to={compra_link}>{cliente}</Link>
		</div>
	);
};

ARComprador.propTypes = {
	avaliacao: PropTypes.object.isRequired,
};

export default CSSModules(ARComprador, styles, { allowMultiple: true });
