
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../../actions/index';

// Components
import EditarConta from './EditarConta';

// Functions
import Wrapper from '../../Wrapper/Wrapper';
import { editarConta } from '../../../services/apis/auth';
import { setLocalstorageData } from '../../_functions/_getLocalstorageData';

import { lockBody } from '../../_functions/_lockBody';
import { validaTelefone } from '../../_functions/_validaTelefone';

class EditarContaContainer extends Component {
	static setValidationErrors() {
		return { telefones_vendedor: [] };
	}

	constructor() {
		super();
		this.state = {
			enviando: false,
			success: false,
			modal_error: false,
			page_locked: false,
			validation_errors: {
				telefones_vendedor: [],
			},
			errorMsg: '',
			errorTitle: '',
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleEdition = this.handleEdition.bind(this);
		this.handleModal = this.handleModal.bind(this);
		this.handleEditionTelefones = this.handleEditionTelefones.bind(this);
		this.removerTelefone = this.removerTelefone.bind(this);
		this.adicionarTelefone = this.adicionarTelefone.bind(this);
	}

	handleModal(modal, value) {
		this.setState({ [modal]: value, page_locked: value });
		lockBody(value);
		if (this.state.success) {
			this.props.history.push('/solicitacoes');
		}
	}

	handleSubmit(e) {
		e.preventDefault();
		document.getElementById('send_btn').focus();
		this.setState({ enviando: true, success: false, errorMsg: '', errorTitle: '' });

		const { nome_vendedor, telefones_vendedor } = this.props.cadastro;

		if (nome_vendedor === '') {
			const validation_new = this.constructor.setValidationErrors();
			validation_new.nome_vendedor = true;
			this.setState({ validation_errors: validation_new, enviando: false });
			window.scrollTo(0, 100);
			return;
		}

		let telefones_validos = true;
		const validation_new = this.constructor.setValidationErrors();
		telefones_vendedor.forEach((telefone, idx) => {
			if (telefone.numero === '' || !validaTelefone(telefone.numero)) {
				validation_new.telefones_vendedor[idx] = true;
				this.setState({ validation_errors: validation_new, enviando: false });
				window.scrollTo(0, 100);
				telefones_validos = false;
			} else {
				telefones_vendedor[idx].numero = telefone.numero.replace(/[^\d]/g, '');
			}
		});

		if (!telefones_validos) {
			return;
		}

		const usuario = {
			nome: nome_vendedor,
			telefones: telefones_vendedor,
		};

		editarConta(usuario)
			.then(response => {
				setLocalstorageData('user', response.data);
				this.setState({
					success: true,
					modal_error: true,
					enviando: false,
					errorTitle: 'Sucesso',
					errorMsg: 'Conta atualizada com sucesso!',
				});
			})
			.catch(error => {
				if (error.response && error.response.status !== 500) {
					this.setState({
						modal_error: true,
						enviando: false,
						errorMsg: error.response.data.message,
						errorTitle: 'Erro ao cadastrar usuário',
					});
				} else {
					this.setState({
						modal_error: true,
						enviando: false,
					});
				}
			});
	}

	handleEdition(fields, value) {
		this.props.updateCadastro(fields, value);
		const validation_new = this.constructor.setValidationErrors();
		this.setState({ enviando: false, validation_errors: validation_new });
	}

	handleEditionTelefones(idx, value) {
		const { telefones_vendedor } = this.props.cadastro;
		telefones_vendedor[idx].numero = value;
		this.props.updateCadastro(['telefones_vendedor'], telefones_vendedor);
		const validation_new = this.constructor.setValidationErrors();
		this.setState({ enviando: false, validation_errors: validation_new });
	}

	removerTelefone(idx) {
		const { telefones_vendedor } = this.props.cadastro;
		telefones_vendedor.splice(idx, 1);
		this.props.updateCadastro(['telefones_vendedor'], telefones_vendedor);
		const validation_new = this.constructor.setValidationErrors();
		this.setState({ enviando: false, validation_errors: validation_new });
	}

	adicionarTelefone() {
		const { telefones_vendedor } = this.props.cadastro;
		telefones_vendedor.push({ numero: '', tipo: 1 });
		this.props.updateCadastro(['telefones_vendedor'], telefones_vendedor);
		const validation_new = this.constructor.setValidationErrors();
		this.setState({ enviando: false, validation_errors: validation_new });
	}

	render() {
		return (
			<Wrapper
				{...this.props}>
				<EditarConta
					{...this.state}
					{...this.props}
					handleSubmit={this.handleSubmit}
					handleEdition={this.handleEdition}
					handleModal={this.handleModal}
					handleEditionTelefones={this.handleEditionTelefones}
					removerTelefone={this.removerTelefone}
					adicionarTelefone={this.adicionarTelefone} />
			</Wrapper>
		);
	}
}

const mapStateToProps = props => ({
	cadastro: props.cadastro,
	ui: props.ui,
});

const mapDispatchToProps = dispatch => ({
	resetCadastro: () => dispatch(actions.resetCadastro()),
	updateCadastro: (field, value) => dispatch(actions.updateCadastro(field, value)),
	updateUi: (field, value) => dispatch(actions.updateUi(field, value)),
	updateModals: (modal, value) => dispatch(actions.updateModals(modal, value)),
	reportErrors: (page, error, codigo_pedido, full_error, test) => dispatch(actions.reportErrors(page, error, codigo_pedido, full_error, test)),
});


EditarContaContainer.propTypes = {
	cadastro: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	updateCadastro: PropTypes.func.isRequired,
	resetCadastro: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditarContaContainer);
