import React from 'react';
import Proptypes from 'prop-types';
// import { Link } from 'react-router-dom';

// Style
import './Impressao.style.scss';

const Impressao = ({ compra }) => {
	const {
		compra_id,
		cotacao_id,
		condicao_de_pagamento,
		desconto,
		dt_emitida,
		dt_entrega,
		items,
		transporte,
		titulo,
		valor_transporte,
		custos_adicionais,
		total_item,
		subtotal,
		total,
		observacoes,
		// observacoes_internas,
		fornecedor,
		faturamento,
		obra_endereco,
	} = compra;

	return (
		<main className="container-impressao">
			<header className="clearfix">
				<div className="title-impressao">{titulo}</div>
				<section className="header-right">
					<p>Data de emissão:</p>
					<span>{dt_emitida}</span>
				</section>
				<section className="header-left">
					<p>Compra #{compra_id} {cotacao_id && (
						<>
							<span>|</span>
							{`${' '}Pedido de cotação #${cotacao_id}`}
						</>
					)}
					</p>
				</section>
			</header>
			<section className="faturamento">
				<p>Empresa para faturamento:</p>
				<span>{faturamento.nome}</span>
				<hr className="normal" />
				<p>CNPJ:</p>
				<span>{faturamento.doc}</span>
				<hr className="normal" />
				<p>Endereço da obra:</p>
				<span>{obra_endereco}</span>
			</section>
			<section className="resumo">
				<span>Resumo da compra</span>
				<div>
					<p>Subtotal dos itens:<b> {subtotal}</b></p>
					<p>Desconto geral: <b>{desconto}%</b></p>
					<p>Total dos itens: <b>{total_item}</b></p>
					<hr className="large" />
					<p>Transporte: <b>{valor_transporte}</b></p>
					<p>Custos adicionais: <b>{custos_adicionais}</b></p>
				</div>
				<p>Total: <span>{total}</span></p>
			</section>
			<section className="fornecedor">
				<p>Empresa fornecedora:</p>
				<span>{fornecedor.nome}</span>
				<hr className="normal" />
				<p>CNPJ:</p>
				<span>{fornecedor.doc}</span>
				<hr className="normal" />
				<p>Condição de pagamento:</p>
				<span>{condicao_de_pagamento}</span>
				<hr className="normal" />
				<p>Data de entrega:</p>
				<span>{dt_entrega}</span>
				<hr className="normal" />
				<p>Transporte:</p>
				<span>{transporte}</span>
			</section>
			{observacoes && (
				<>
					<hr className="normal" />
					<section className="observacoes">
						{observacoes && (
							<>
								<p>Observações do cliente:</p>
								<span>{observacoes}</span>
								<hr />
							</>
						)}
					</section>
				</>
			)}
			<div className="hr-text">Itens ({items.length}):</div>
			<hr className="full" />
			{items.map((item, i) => (
				<section className="item-section" key={item.id}>
					<section className={`item-left ${item.status === -1 || item.excluido ? 'strikethrough' : ''}`}>
						<div>{i + 1}. {item.nome}</div>
						{item.status === -1 && <div className="retirado">Fornecedor não possui este item</div>}
						{item.excluido && <div className="retirado">Item excluído da compra</div>}
						{item.caracteristicas.map(c => (
							<p key={c.valor}><span>{c.caracteristica}:</span> {c.valor}</p>
						))}
						{item.observacoes && <p><span>Observações:</span> {item.observacoes}</p>}
						{/* {item.observacoes_internas && <p>Observações internas: {item.observacoes_internas}</p>} */}
					</section>
					<section className={`item-right ${item.status === -1 ? 'strikethrough' : ''}`}>
						<div>{item.qtd} {item.un}</div>
						<p>Valor unitário <b>{item.preco}</b></p>
						<p>Desconto unitário <b>{item.desconto}%</b></p>
						<hr />
						<p>Total <span>{item.total}</span></p>
					</section>
				</section>
			))}
		</main>
	);
};

Impressao.propTypes = {
	compra: Proptypes.object.isRequired,
};

export default Impressao;
