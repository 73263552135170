import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../../actions/index';

import Wrapper from '../../Wrapper/Wrapper';

// Components
import SelecionaLocaisEntrega from './SelecionaLocaisEntrega';

// Services
import { getMesorregioes, getMunicipios, getMunicipio } from '../../../services/apis/territorios';

// Functions
import { lockBody } from '../../_functions/_lockBody';
import { isEmpty } from '../../_functions/_objectUtil';
import { editarEntrega } from '../../../services/apis/fornecedores_vendedores';

// Vars
import { ufs as all_ufs } from './ufs';

class SelecionaLocaisEntregaContainer extends Component {
	constructor() {
		super();

		this.state = {
			estrutura_montada: false,
			message: '',
			enviando: false,
			regioesLimpas: false,
			ufs: {},
			ufs_by_id: [],
			liberaEnvio: false,
			modal_error: false,
			page_locked: false,
			success: false,
			errorTitle: '',
		};
		this.addUF = this.addUF.bind(this);
		this.deleteUF = this.deleteUF.bind(this);
		this.obterMesorregioes = this.obterMesorregioes.bind(this);
		this.obterMunicipios = this.obterMunicipios.bind(this);
		this.selecionarMunicipio = this.selecionarMunicipio.bind(this);
		this.salvarAreasAtendimento = this.salvarAreasAtendimento.bind(this);
		this.handleModal = this.handleModal.bind(this);
		this.limparLocais = this.limparLocais.bind(this);
	}

	UNSAFE_componentWillMount() {
		const { cadastro } = this.props;
		const { id, empresas } = cadastro;
		const { status } = (empresas[id] || {});
		if (status !== 0) this.setState({ liberaEnvio: true });
	}

	componentDidMount() {
		this.validaMontagemEstrutura();
		if (!this.props.cadastro.id && this.props.cadastro.loaded) {
			this.props.history.replace('/empresas');
		}
	}

	UNSAFE_componentWillReceiveProps(newProps) {
		if (!newProps.cadastro.id && newProps.cadastro.loaded) {
			this.props.history.replace('/empresas');
		}
	}

	componentDidUpdate() {
		this.validaMontagemEstrutura();
	}

	handleModal(modal, value) {
		this.setState({ [modal]: value, page_locked: value });
		lockBody(value);
		const { status } = this.props.cadastro;
		const cadastro_incompleto = status !== 20;
		if (cadastro_incompleto && this.state.success) {
			this.props.history.push('/editar-familias');
		} else if (this.state.success) {
			this.props.history.push('/empresas');
		}
	}

	validaMontagemEstrutura() {
		const { loaded, id, empresas } = this.props.cadastro;
		const { ufs_by_id } = this.state;
		const fornecedor_selecionado = (empresas[id] || {});
		const locais_de_entrega = fornecedor_selecionado.locais_de_entrega || {};
		if (loaded && ufs_by_id.length === 0) {
			locais_de_entrega.estados = locais_de_entrega.estados.filter(e => e !== 1);
			this.montarEstrutura(locais_de_entrega);
		}
	}

	limparLocais() {
		this.setState({ regioesLimpas: true });
		this.montarEstrutura({});
	}

	montarEstrutura(locais) {
		const ufs = {};
		const ufs_by_id = [];
		const estados = locais.estados !== undefined ? locais.estados : [1];

		if (
			isEmpty(locais)
			|| (locais.estados.length === 0 && locais.mesorregioes.length === 0 && locais.municipios.length === 0)
			|| (estados.length === 1 && estados[0] === 1)
		) {
			ufs[1] = { loading: false, nome: 'Todo o Brasil', mesorregioes: {}, mesorregioes_by_id: [] };
			ufs_by_id.push(1);
			this.setState({ ufs, ufs_by_id, estrutura_montada: true });
			return;
		}

		const estados_todas_meso = locais.estados;
		const estados_sem_meso = [];
		const municipios_to_load = [];

		for (let i = 0; i < locais.mesorregioes.length; i++) {
			const mesorregiao = locais.mesorregioes[i] || '';
			const codigo_uf = parseInt(mesorregiao.toString().substr(0, 2), 10);
			if (
				estados_todas_meso.indexOf(codigo_uf) === -1
				&& estados_sem_meso.indexOf(codigo_uf) === -1
				&& mesorregiao !== ''
			) {
				estados_sem_meso.push(codigo_uf);
			}
		}

		for (let i = 0; i < locais.municipios.length; i++) {
			const municipio = locais.municipios[i] || '';
			const codigo_uf = parseInt(municipio.toString().substr(0, 2), 10);
			if (estados_todas_meso.indexOf(codigo_uf) === -1 && municipio !== '') {
				municipios_to_load.push(locais.municipios[i]);
				if (estados_sem_meso.indexOf(codigo_uf) === -1) {
					estados_sem_meso.push(codigo_uf);
				}
			}
		}

		// carrega estados
		const promises = estados_todas_meso.concat(estados_sem_meso).map(uf => getMesorregioes(uf));
		Promise.all(promises)
			.then(responses => {
				for (let i = 0; i < responses.length; i++) {
					const mesorregioes = {};
					const mesorregioes_by_id = [];
					const { codigo_uf } = responses[i].data.mesorregioes[0];
					const select_all = estados_todas_meso.indexOf(codigo_uf) !== -1;
					responses[i].data.mesorregioes.forEach(meso => {
						const selected = select_all || locais.mesorregioes.indexOf(meso.codigo) !== -1;
						mesorregioes_by_id.push(meso.codigo);
						mesorregioes[meso.codigo] = { all: select_all, loading: !select_all, nome: meso.nome, selected, municipios: {}, municipios_by_id: [] };
					});
					ufs_by_id.push(codigo_uf);
					ufs[codigo_uf] = { all: select_all, loading: false, nome: all_ufs[codigo_uf], mesorregioes, mesorregioes_by_id };
				}
				this.setState({ ufs, ufs_by_id, estrutura_montada: true });

				// carrega mesorregioes
				const promises_meso = estados_sem_meso.map(uf => ufs[uf].mesorregioes_by_id).reduce((total, current) => total.concat(current), []).map(cod_meso => getMunicipios(cod_meso));
				Promise.all(promises_meso)
					.then(responses_meso => {
						for (let i = 0; i < responses_meso.length; i++) {
							const municipios_by_id = [];
							const municipios = [];
							const { codigo_uf, codigo_mesorregiao } = responses_meso[i].data.municipios[0];
							responses_meso[i].data.municipios.forEach(muni => {
								municipios_by_id.push(muni.codigo);
								municipios[muni.codigo] = { nome: muni.nome, selected: locais.mesorregioes.indexOf(codigo_mesorregiao) !== -1 };
							});
							const mesorregiao = ufs[codigo_uf].mesorregioes[codigo_mesorregiao];
							mesorregiao.all = locais.mesorregioes.indexOf(codigo_mesorregiao) !== -1;
							mesorregiao.loading = false;
							mesorregiao.municipios_by_id = municipios_by_id;
							mesorregiao.municipios = municipios;
							ufs[codigo_uf].mesorregioes[codigo_mesorregiao] = mesorregiao;
						}
						this.setState({ ufs, ufs_by_id, estrutura_montada: true });

						// carrega municipios
						const promise_muni = municipios_to_load.map(cod_muni => getMunicipio(cod_muni));
						Promise.all(promise_muni)
							.then(responses_muni => {
								for (let i = 0; i < responses_muni.length; i++) {
									const { codigo_uf, codigo_mesorregiao, codigo } = responses_muni[i].data.municipio;
									const mesorregiao = ufs[codigo_uf].mesorregioes[codigo_mesorregiao];
									mesorregiao.all = false;
									mesorregiao.selected = true;
									mesorregiao.municipios[codigo].selected = true;
									ufs[codigo_uf].mesorregioes[codigo_mesorregiao] = mesorregiao;
								}
								this.setState({ ufs, ufs_by_id, estrutura_montada: true });
							});
					});
			});
	}

	obterMesorregioes(key, uf_nome, uf_codigo) {
		let { ufs_by_id } = this.state;
		const { ufs } = this.state;

		if (uf_codigo === 'all') {
			ufs[1] = { loading: false, nome: 'Todo o Brasil', mesorregioes: {}, mesorregioes_by_id: [] };
			ufs_by_id = [1];
			this.setState({ ufs, ufs_by_id });
			return;
		}

		ufs_by_id.splice(ufs_by_id.indexOf(key), 1, parseInt(uf_codigo, 10));
		delete ufs[key];
		ufs[uf_codigo] = { loading: true, nome: all_ufs[uf_codigo], mesorregioes: {}, mesorregioes_by_id: [] };
		this.setState({ ufs, ufs_by_id });
		getMesorregioes(uf_codigo)
			.then(response => {
				const mesorregioes = {};
				const mesorregioes_by_id = [];
				response.data.mesorregioes.forEach(meso => {
					mesorregioes_by_id.push(meso.codigo);
					mesorregioes[meso.codigo] = { all: true, loading: false, nome: meso.nome, selected: true, municipios: {}, municipios_by_id: [] };
				});
				ufs[uf_codigo] = { all: true, loading: false, nome: all_ufs[uf_codigo], mesorregioes, mesorregioes_by_id };
				this.setState({ ufs, ufs_by_id });
			});
	}

	obterMunicipios(uf_codigo, mesorregioes, mesorregiao, all_options = false) {
		const { ufs } = this.state;
		ufs[uf_codigo].mesorregioes_by_id.forEach(cod_meso => {
			const is_selected = mesorregioes.indexOf(cod_meso.toString()) !== -1;
			ufs[uf_codigo].mesorregioes[cod_meso].selected = is_selected;

			if (!is_selected) {
				ufs[uf_codigo].mesorregioes[cod_meso].municipios_by_id.forEach(mun => {
					ufs[uf_codigo].mesorregioes[cod_meso].municipios[mun].selected = false;
				});
			}
		});
		const carregar_municipios = [];
		mesorregioes.forEach(cod_meso => {
			ufs[uf_codigo].all = all_options;

			const count_non_selected = ufs[uf_codigo].mesorregioes[cod_meso].municipios_by_id
				.filter(mun => !ufs[uf_codigo].mesorregioes[cod_meso].municipios[mun].selected).length;
			const all_mun = count_non_selected === 0 || count_non_selected === ufs[uf_codigo].mesorregioes[cod_meso].municipios_by_id.length;

			ufs[uf_codigo].mesorregioes[cod_meso].all = all_mun;

			if (ufs[uf_codigo].mesorregioes[cod_meso].municipios_by_id.length === 0) {
				carregar_municipios.push(cod_meso);
				ufs[uf_codigo].mesorregioes[cod_meso].loading = true;
			}

			if (all_mun) {
				ufs[uf_codigo].mesorregioes[cod_meso].municipios_by_id.forEach(mun => {
					ufs[uf_codigo].mesorregioes[cod_meso].municipios[mun].selected = true;
				});
			}
		});
		this.setState({ ufs });
		const promises_meso = carregar_municipios.map(cod_meso => getMunicipios(cod_meso));
		if (promises_meso.length > 0) {
			Promise.all(promises_meso)
				.then(responses_meso => {
					for (let i = 0; i < responses_meso.length; i++) {
						const municipios_by_id = [];
						const municipios = [];
						const { codigo_uf, codigo_mesorregiao } = responses_meso[i].data.municipios[0];
						responses_meso[i].data.municipios.forEach(muni => {
							municipios_by_id.push(muni.codigo);
							municipios[muni.codigo] = { nome: muni.nome, selected: true };
						});
						const _mesorregiao = ufs[codigo_uf].mesorregioes[codigo_mesorregiao];
						_mesorregiao.loading = false;
						_mesorregiao.municipios_by_id = municipios_by_id;
						_mesorregiao.municipios = municipios;
						ufs[codigo_uf].mesorregioes[codigo_mesorregiao] = _mesorregiao;
					}
					this.setState({ ufs });
				});
		}
	}

	selecionarMunicipio(uf_codigo, municipios, mesorregiao, all_options = false) {
		const { ufs } = this.state;
		ufs[uf_codigo].mesorregioes[mesorregiao].all = all_options;

		ufs[uf_codigo].mesorregioes[mesorregiao].municipios_by_id.forEach(cod_mun => {
			ufs[uf_codigo].mesorregioes[mesorregiao].municipios[cod_mun].selected = municipios.indexOf(cod_mun.toString()) !== -1;
		});

		this.setState({ ufs });
	}

	addUF() {
		const { ufs, ufs_by_id } = this.state;
		if (ufs_by_id.filter(uf => uf === -1).length === 0) {
			const nova_uf_vazia = { all: false, loading: false, nome: '', mesorregioes: {}, mesorregioes_by_id: [] };
			ufs[-1] = nova_uf_vazia;
			ufs_by_id.push(-1);
			this.setState({ ufs, ufs_by_id });
		}
	}

	deleteUF(cod_uf) {
		let { ufs_by_id } = this.state;
		const { ufs } = this.state;
		delete ufs[cod_uf];
		ufs_by_id = ufs_by_id.filter(uf => uf !== cod_uf);
		if (ufs_by_id.length === 1 && ufs_by_id[0] === -1) {
			ufs[1] = { all: false, loading: false, nome: 'Todo o Brasil', mesorregioes: {}, mesorregioes_by_id: [] };
			ufs_by_id = [1];
		}
		this.setState({ ufs, ufs_by_id });
	}

	allowSendState = () => {
		this.setState({ liberaEnvio: true, regioesLimpas: false });
	}

	salvarAreasAtendimento() {
		const { ufs_by_id, ufs, liberaEnvio, regioesLimpas } = this.state;
		if (!liberaEnvio || regioesLimpas) {
			// eslint-disable-next-line no-alert
			alert('Por favor selecione uma região');
			return;
		}
		this.setState({ enviando: true, message: '', success: false, errorTitle: '' });
		const ufs_validas = ufs_by_id.filter(uf => uf > 0);
		const codigos = [];
		if (ufs_validas.length === 1 && ufs_validas[0] === 1) {
			codigos.push(1);
		} else {
			ufs_validas.forEach(cod_uf => {
				const uf = ufs[cod_uf];
				if (uf.all) {
					codigos.push(cod_uf);
				} else {
					const meso_selected = uf.mesorregioes_by_id.filter(cod_meso => uf.mesorregioes[cod_meso].selected);
					meso_selected.forEach(cod_meso => {
						const mesorregiao = uf.mesorregioes[cod_meso];
						if (mesorregiao.all) {
							codigos.push(cod_meso);
						} else {
							const muni_selected = mesorregiao.municipios_by_id.filter(cod_muni => mesorregiao.municipios[cod_muni].selected);
							codigos.push(...muni_selected);
						}
					});
				}
			});
		}

		const boas_vindas = this.props.match.path === '/boas-vindas';
		const { id, empresas } = this.props.cadastro;
		const data = codigos.map(codigo_ibge => ({ codigo_ibge }));
		const fornecedor_selecionado = (empresas[id] || {});
		const relacao_id = fornecedor_selecionado.id;

		editarEntrega(relacao_id, 1, data)
			.then(response => {
				this.props.updateCadastro(['empresas'], { ...this.props.cadastro.empresas, [response.data.fornecedor.id]: response.data });
				const message = `Locais de entrega salvos com sucesso!${boas_vindas ? ' Redirecionando para cadastro de famílias de mercado...' : ''}`;

				if (boas_vindas) {
					this.setState({ enviando: false, message, success: true });
					setTimeout(() => {
						this.setState({ message: '' });
						this.props.history.push('/boas-vindas-2');
					}, 3000);
				} else {
					this.setState({
						success: true,
						modal_error: true,
						enviando: false,
						errorTitle: 'Sucesso',
						message,
					});
				}
			})
			.catch(() => {
				this.setState({
					enviando: false,
					modal_error: true,
					success: false,
					message: '',
					errorTitle: '',
				});
			});
	}

	render() {
		const { regioesLimpas } = this.state;

		return (
			<Wrapper
				{...this.props}>

				<SelecionaLocaisEntrega
					{...this.state}
					{...this.props}
					handleModal={this.handleModal}
					regioesLimpas={regioesLimpas}
					addUF={this.addUF}
					allowSendState={this.allowSendState}
					deleteUF={this.deleteUF}
					selecionarMunicipio={this.selecionarMunicipio}
					obterMesorregioes={this.obterMesorregioes}
					obterMunicipios={this.obterMunicipios}
					salvarAreasAtendimento={this.salvarAreasAtendimento}
					limparLocais={this.limparLocais} />

			</Wrapper>
		);
	}
}

const mapStateToProps = props => ({
	cadastro: props.cadastro,
	ui: props.ui,
});

const mapDispatchToProps = dispatch => ({
	resetCadastro: () => dispatch(actions.resetCadastro()),
	updateCadastro: (field, value) => dispatch(actions.updateCadastro(field, value)),
	updateUi: (field, value) => dispatch(actions.updateUi(field, value)),
	updateModals: (modal, value) => dispatch(actions.updateModals(modal, value)),
	reportErrors: (page, error, codigo_pedido, full_error, test) => dispatch(actions.reportErrors(page, error, codigo_pedido, full_error, test)),
});

SelecionaLocaisEntregaContainer.propTypes = {
	match: PropTypes.object.isRequired,
	cadastro: PropTypes.object.isRequired,
	updateCadastro: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelecionaLocaisEntregaContainer);
