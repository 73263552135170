import moment from 'moment';
import 'moment/locale/pt-br';

const cotacao = {
	rc: {},
	itens_by_id: {},
	itens_all_ids: [],
	itens_selecionados: [],
	itens_nao_possui: [],
	itens_observacoes: [],
	prazo_entrega_qc: moment(),
	data_entrega_obra: moment(),
	observacoes: '',
	status: 0,
	rc_to_update: false,
	fornecedor_selecionado: {},
};

export default cotacao;
