import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const CanceledStage = ({ label }) => (
	<div styleName="icons canceled">
		<div styleName="icon"><i className="fa fa-ban" aria-hidden="true" /></div>
		<div styleName="text canceled">
			{label}
		</div>
	</div>
);

CanceledStage.propTypes = {
	label: PropTypes.string.isRequired,
};

export default CSSModules(CanceledStage, styles, { allowMultiple: true });
