import { formas_pagamento, prazos_pagamento_full } from '../_content/_condicoes_pagamento';

export const transporteText = opt => {
	const options = [
		'Incluso no preço',
		'A pagar',
		'Não incluso',
	];

	return options[opt];
};

export const pagamentoText = (condicoes, outras_condicoes = null) => {
	const formas = formas_pagamento;
	const prazos = prazos_pagamento_full;

	if (condicoes.forma === -1) formas['-1'].view = outras_condicoes.outra_forma;
	if (condicoes.prazo === -1) prazos['-1'].view = outras_condicoes.outro_prazo;

	return `${formas[condicoes.forma].view} - ${prazos[condicoes.prazo].view}`;
};

export const validation = compra => {
	const {
		titulo,
		itens_de_compra,
		fornecedor,
		usuario_fornecedor,
		condicao_de_pagamento,
		transporte,
		tipo,
		data_de_entrega,
	} = compra;

	if (titulo === '' || titulo === null || titulo === undefined) return false;
	if (itens_de_compra.length === 0) return false;

	const check_items = itens_de_compra.map(item => {
		const {
			quantidade,
			unidade,
			preco_unitario,
			item_preenchido,
			item_de_resposta,
			excluido,
		} = item;

		if (!item_preenchido.item) return 'Ocorreu um problema com os items, favor verifica-los!';

		const { nome: item_nome } = item_preenchido.item;

		if ((item_de_resposta || {}).status !== -1 || !excluido) {
			if (quantidade === 0) return `Quantidade inválida no item ${item_nome}`;
			if (unidade === '') return `Unidade inválida no item ${item_nome}`;
			if (!preco_unitario) return `Preço inválido no item ${item_nome}`;

			const opcoes = item_preenchido.caracteristicas_preenchidas
				.map(dados => {
					const {
						caracteristica,
						opcoes_preenchidas,
					} = dados;

					if (
						(caracteristica.visao_fornecedor === 2 && opcoes_preenchidas.length > 0)
						|| caracteristica.visao_fornecedor !== 2
					) return true;
					return null;
				});

			if (opcoes.find(opcao => opcao === null) === null) return `Caracteristicas inválidas no item ${item_nome}`;
		}
		return '';
	});

	const item_err = check_items.find(item => item !== '');
	if (item_err) return false;

	if (!fornecedor.id) return false;
	if (!usuario_fornecedor.id) return false;
	if (!condicao_de_pagamento.forma || condicao_de_pagamento.forma === 0) return false;
	if (!condicao_de_pagamento.prazo || condicao_de_pagamento.prazo === 0) return false;
	if (transporte === null || transporte === '' || transporte === undefined) return false;
	if (!tipo || (tipo < 4 && tipo > 6)) return false;
	if (!data_de_entrega) return false;

	return true;
};

export const docType = doc => {
	if (doc === undefined || doc === null || doc === '') return null;
	if (doc.length === 11) return 'cpf';
	if (doc.length === 14) return 'cnpj';
	return null;
};
