import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import LoadingButton from '../../_loadings/LoadingButton/LoadingButton';

// Functions
import { _post } from '../../../../services/apis/requests';
import { handleRequestErrors } from '../../../_functions/_handleRequestErrors';

class ModalConfirm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			confirmando: 0,
		};
		this.confirmFuncLocal = this.confirmFuncLocal.bind(this);
	}

	confirmFuncLocal() {
		const { type, url_to_send, params_to_send, closeModal, successFunc } = this.props;

		/**
		 * Não existe request a ser feita
		 * nem success func
		 */
		if (url_to_send === '' && successFunc === null) {
			closeModal();
			return;
		}
		/**
		 * Não existe request a ser feita
		 * mas existe função de envio
		 */
		if (url_to_send === '') {
			successFunc();
			return;
		}

		/**
		 * Request
		 */
		this.setState({ confirmando: 1 });
		switch (type) {
		default:
		case 'post':
			_post(url_to_send, params_to_send).then(() => {
				if (successFunc === null) {
					this.setState({ confirmando: 2 });
					setTimeout(() => closeModal(), 3000);
					return;
				}
				successFunc();
			}).catch(error => {
				this.setState({ confirmando: 3 });
				handleRequestErrors(error);
			});
		}
	}

	render() {
		const {
			confirmando,
		} = this.state;
		const {
			closeModal,
			title,
			message,
			icon,
			button_type,
			button_text,
			url_to_send,
		} = this.props;

		return (
			<div className="modal-content" styleName="modal-content">
				<div className="modal-body" styleName="modal-body">
					{confirmando !== 1 && (
						<button
							type="button"
							className="close"
							styleName="close-button"
							data-dismiss="modal"
							aria-label="Close"
							onClick={closeModal}>
							<span aria-hidden="true">&times;</span>
						</button>
					)}
					{icon !== '' && (
						<div styleName="icon">
							<i className={`fa fa-${icon}`} aria-hidden="true" />
						</div>
					)}
					<div styleName="content">
						<h3>{title}</h3>
						{message}
						{confirmando === 1 ? (
							<React.Fragment>
								{url_to_send !== '' && (
									<button
										type="button"
										className="btn btn-clear-conaz round-border"
										style={{ opacity: '0.3', marginRight: '15px' }}>
										Cancelar
									</button>
								)}
								<button
									type="button"
									className={`btn btn-${button_type}-conaz round-border loading`}>
									<LoadingButton />{button_text}
								</button>
							</React.Fragment>
						) : (
							<React.Fragment>
								{url_to_send !== '' && (
									<button
										type="button"
										className="btn btn-clear-conaz round-border"
										style={{ marginRight: '15px' }}
										onClick={closeModal}>
										Cancelar
									</button>
								)}
								<button
									type="button"
									className={`btn btn-${button_type}-conaz round-border`}
									onClick={this.confirmFuncLocal}>
									{button_text}
								</button>
							</React.Fragment>
						)}
					</div>
				</div>
			</div>
		);
	}
}

ModalConfirm.propTypes = {
	closeModal: PropTypes.func,
	title: PropTypes.string.isRequired,
	message: PropTypes.object.isRequired,
	url_to_send: PropTypes.string,
	params_to_send: PropTypes.object,
	type: PropTypes.string,
	successFunc: PropTypes.func,
	icon: PropTypes.string,
	button_type: PropTypes.string,
	button_text: PropTypes.string,
};

ModalConfirm.defaultProps = {
	closeModal: () => {},
	url_to_send: '',
	params_to_send: {},
	type: 'post',
	successFunc: null,
	icon: '',
	button_type: 'danger', // secondary
	button_text: 'Confirmar',
};

export default CSSModules(ModalConfirm, styles, { allowMultiple: true });
