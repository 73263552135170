import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import InputFieldText from '../../_parts/_inputs/InputCadastro/InputFieldText';
import Modal from '../../_parts/Modal/Modal';
import MessageModal from '../../_parts/MessageModal/MessageModal';
import Loading from '../../_parts/_loadings/Loading/Loading';

const CadastroNormal = ({
	/* state */ searching_cep, loading_cep, request_state, modal_error, cep_state, validation_errors, titulo_state,
	/* props */ cadastro,
	/* funcs */ getCEP, submitFornecedor, handleEdition, handleModal,
}) => {
	const { nome, documento, endereco, site, razao_social } = cadastro;

	const chars_blocked = '& / # , + @ $ ~ ^ % \' " : | * ? < > { } \\';

	return (
		<div styleName="teste">
			{modal_error && (
				<Modal modal="modal_error" handleModal={handleModal}>
					<MessageModal
						type="alert"
						title="Ops... aconteceu algo errado."
						message="Desculpe, não conseguimos identificar o que aconteceu. Espere alguns segundos e tente novamente." />
				</Modal>
			)}

			<div className="container" styleName="main-container">
				<div className="row">
					<div className="col-xs-12" style={{ textAlign: 'center' }}>
						<h2>Cadastro de fornecedor</h2>
					</div>
				</div>
				{/* EMPRESA */}
				<div className="row">
					<div className="col-xs-12">
						<h5 styleName="section-title"><i className="fa fa-building fa-fw" aria-hidden="true" /> Empresa</h5>
					</div>
				</div>
				<form onSubmit={submitFornecedor}>
					<div className="row">
						<div className="col-xs-12 col-sm-7">
							{validation_errors.nome && (
								<div className="tooltip top in" styleName="tooltip" role="tooltip" style={{ marginLeft: '-19px', marginTop: '-25px' }}>
									<div className="tooltip-arrow" />
									<div className="tooltip-inner" styleName="tooltip-inner">Preencha o campo nome da empresa!</div>
								</div>
							)}
							{validation_errors.nome_repetido && (
								<div className="tooltip top in" styleName="tooltip" role="tooltip" style={{ marginLeft: '-19px', marginTop: '-25px' }}>
									<div className="tooltip-arrow" />
									<div className="tooltip-inner" styleName="tooltip-inner">Já existe uma empresa cadastrada com esse nome. Escolha outro.</div>
								</div>
							)}
							<InputFieldText
								label="Apelido da empresa"
								sublabel="(Nome que as construtoras vão identificar a sua empresa)"
								fields={['nome']}
								obrigatorio
								maxLength={100}
								default_value={nome}
								auxiliarFunction={handleEdition} />
						</div>

						{titulo_state === 1 && (
							<div id="tooltip-titulo-chars" className="tooltip top" styleName="titulo-tooltip" role="tooltip" style={{ marginTop: '-40px' }}>
								<div className="tooltip-arrow" style={{ borderBottomColor: '#ee2622' }} />
								<div className="tooltip-inner" style={{ backgroundColor: '#ee2622', maxWidth: '400px', padding: '3px 18px 6px', fontSize: '14px' }}>O nome não pode conter os caracteres a seguir: <br />{chars_blocked}</div>
							</div>
						)}

						{titulo_state === 2 && (
							<div id="tooltip-titulo-chars" className="tooltip top" styleName="titulo-tooltip" role="tooltip" style={{ marginTop: '-40px' }}>
								<div className="tooltip-arrow" style={{ borderBottomColor: '#ee2622' }} />
								<div className="tooltip-inner" style={{ backgroundColor: '#ee2622', maxWidth: '400px', padding: '3px 18px 6px', fontSize: '14px' }}>Lembrete: siglas como LTDA, SA e ME remetem à razão social, utilize um nome mais comercial</div>
							</div>
						)}

						<div className="col-xs-12 col-sm-5">
							<InputFieldText
								label="Site"
								fields={['site']}
								default_value={site}
								maxLength="100"
								auxiliarFunction={handleEdition} />
						</div>

						<div className="col-xs-12 col-sm-8">
							<InputFieldText
								label="Razão social"
								fields={['razao_social']}
								maxLength="100"
								default_value={razao_social}
								auxiliarFunction={handleEdition} />
						</div>

						<div className="col-xs-12 col-sm-4">
							{documento !== '' && <div styleName="field-over-block" />}
							{validation_errors.documento && (
								<div className="tooltip top in" styleName="tooltip" role="tooltip" style={{ marginLeft: '-19px', marginTop: '-34px' }}>
									<div className="tooltip-arrow" />
									<div className="tooltip-inner">Preencha o campo Documento (somente números)</div>
								</div>
							)}
							{validation_errors.documento_invalido && (
								<div className="tooltip top in" styleName="tooltip" role="tooltip" style={{ marginLeft: '-19px', marginTop: '-22px' }}>
									<div className="tooltip-arrow" />
									<div className="tooltip-inner">Documento não existente!</div>
								</div>
							)}
							<div className="form-group" style={{ marginTop: '16px', marginBottom: '0' }}>
								<label>CNPJ <span styleName="obrigatorio-label">obrigatório</span></label>
								<input
									type="text"
									className="form-control input-md"
									defaultValue={documento}
									disabled />
							</div>
						</div>
					</div>

					{/* ENDEREÇO */}
					<div className="row">
						<div className="col-xs-12">
							<h5 styleName="section-title"><i className="fa fa-map-marker fa-fw" aria-hidden="true" /> Localização</h5>
						</div>
					</div>

					<div className="row">
						<div className="col-xs-12 col-sm-3">
							{validation_errors.cep && (
								<div className="tooltip top in" styleName="tooltip" role="tooltip" style={{ marginLeft: '-19px', marginTop: '-22px' }}>
									<div className="tooltip-arrow" />
									<div className="tooltip-inner">Preencha o campo cep</div>
								</div>
							)}
							{cep_state === 1 && (
								<div className="tooltip top in" styleName="tooltip" role="tooltip" style={{ marginLeft: '-19px', marginTop: '-22px' }}>
									<div className="tooltip-arrow" />
									<div className="tooltip-inner">Cep inválido!</div>
								</div>
							)}
							<InputFieldText
								label="CEP"
								fields={['endereco', 'cep']}
								obrigatorio
								default_value={endereco.cep || ''}
								getCEP={getCEP}
								auxiliarFunction={handleEdition} />
						</div>

						<div className="col-xs-12 col-sm-7">
							{searching_cep && <div styleName="field-over-block" />}
							{loading_cep && <div style={{ position: 'absolute', top: '43px', left: '6px' }}><Loading /></div>}
							{validation_errors.logradouro && (
								<div className="tooltip top in" styleName="tooltip" role="tooltip" style={{ marginLeft: '-19px', marginTop: '-22px' }}>
									<div className="tooltip-arrow" />
									<div className="tooltip-inner">Preencha o campo logradouro!</div>
								</div>
							)}
							<InputFieldText
								label="Logradouro"
								fields={['endereco', 'logradouro']}
								obrigatorio
								maxLength={100}
								default_value={endereco.logradouro}
								auxiliarFunction={handleEdition} />
						</div>
						<div className="col-xs-12 col-sm-2">
							{searching_cep && <div styleName="field-over-block" />}
							{validation_errors.numero && (
								<div className="tooltip top in" styleName="tooltip" role="tooltip" style={{ marginLeft: '-19px', marginTop: '-22px' }}>
									<div className="tooltip-arrow" />
									<div className="tooltip-inner">Preencha o campo nº!</div>
								</div>
							)}
							<InputFieldText
								label="nº"
								fields={['endereco', 'numero']}
								obrigatorio
								maxLength={20}
								default_value={endereco.numero}
								auxiliarFunction={handleEdition} />
						</div>
					</div>

					<div className="row">
						<div className="col-xs-12 col-sm-3">
							{searching_cep && <div styleName="field-over-block" />}
							{validation_errors.bairro && (
								<div className="tooltip top in" styleName="tooltip" role="tooltip" style={{ marginLeft: '-19px', marginTop: '-22px' }}>
									<div className="tooltip-arrow" />
									<div className="tooltip-inner">Preencha o campo bairro!</div>
								</div>
							)}
							<InputFieldText
								label="Bairro"
								fields={['endereco', 'bairro']}
								obrigatorio
								maxLength={100}
								default_value={endereco.bairro}
								auxiliarFunction={handleEdition} />
						</div>
						<div className="col-xs-12 col-sm-7">
							<div styleName="field-over-block" />
							{validation_errors.cidade && (
								<div className="tooltip top in" styleName="tooltip" role="tooltip" style={{ marginLeft: '-19px', marginTop: '-22px' }}>
									<div className="tooltip-arrow" />
									<div className="tooltip-inner">Preencha o campo cidade!</div>
								</div>
							)}
							<InputFieldText
								label="Cidade"
								fields={['endereco', 'cidade']}
								obrigatorio
								maxLength={100}
								default_value={endereco.cidade}
								auxiliarFunction={handleEdition} />
						</div>
						<div className="col-xs-12 col-sm-2">
							<div styleName="field-over-block" />
							{validation_errors.estado && (
								<div className="tooltip top in" styleName="tooltip" role="tooltip" style={{ marginLeft: '-19px', marginTop: '-22px' }}>
									<div className="tooltip-arrow" />
									<div className="tooltip-inner">Preencha o campo UF!</div>
								</div>
							)}
							<InputFieldText
								label="UF"
								fields={['endereco', 'estado']}
								obrigatorio
								maxLength={100}
								default_value={endereco.estado}
								auxiliarFunction={handleEdition} />
						</div>
						<div className="col-xs-12 col-sm-12">
							{searching_cep && <div styleName="field-over-block" />}
							<InputFieldText
								label="Complemento"
								maxLength={100}
								fields={['endereco', 'complemento']}
								default_value={endereco.complemento}
								auxiliarFunction={handleEdition} />
						</div>
					</div>

					{(request_state === 1) ? (
						<div className="row">
							<div className="col-xs-12" style={{ textAlign: 'center' }}>
								<p><br /></p><p><br /></p>
								<button className="btn btn-success btn-lg" disabled="disabled">Validando</button>
							</div>
						</div>
					) : (
						<div className="row">
							<div className="col-xs-12" style={{ textAlign: 'center' }}>
								<p><br /></p><p><br /></p>
								<Link to="/cadastro"><button id="" className="btn btn-default btn-lg" style={{ marginRight: '10px' }}>Voltar</button></Link>
								<button type="submit" id="send_btn" className="btn btn-success btn-lg">Criar conta</button>
								{validation_errors.numero && (
									<div className="tooltip right in" styleName="tooltip" role="tooltip" style={{ marginLeft: '495px', marginTop: '-42px' }}>
										<div className="tooltip-arrow" />
										<div className="tooltip-inner">Preencha o campo nº!</div>
									</div>
								)}
								{validation_errors.documento && (
									<div className="tooltip right in" styleName="tooltip" role="tooltip" style={{ marginLeft: '495px', marginTop: '-42px' }}>
										<div className="tooltip-arrow" />
										<div className="tooltip-inner">Preencha o campo documento (somente números)</div>
									</div>
								)}
								{validation_errors.documento_invalido && (
									<div className="tooltip right in" styleName="tooltip" role="tooltip" style={{ marginLeft: '495px', marginTop: '-42px' }}>
										<div className="tooltip-arrow" />
										<div className="tooltip-inner">documento não existente!</div>
									</div>
								)}
								{validation_errors.cep && (
									<div className="tooltip right in" styleName="tooltip" role="tooltip" style={{ marginLeft: '495px', marginTop: '-42px' }}>
										<div className="tooltip-arrow" />
										<div className="tooltip-inner">Preencha o campo cep</div>
									</div>
								)}
								{cep_state === 1 && (
									<div className="tooltip right in" styleName="tooltip" role="tooltip" style={{ marginLeft: '495px', marginTop: '-42px' }}>
										<div className="tooltip-arrow" />
										<div className="tooltip-inner">Cep inválido!</div>
									</div>
								)}
								{validation_errors.logradouro && (
									<div className="tooltip right in" styleName="tooltip" role="tooltip" style={{ marginLeft: '495px', marginTop: '-42px' }}>
										<div className="tooltip-arrow" />
										<div className="tooltip-inner">Preencha o campo logradouro!</div>
									</div>
								)}

							</div>
						</div>
					)}

				</form>
			</div>
		</div>
	);
};

CadastroNormal.propTypes = {
	getCEP: PropTypes.func.isRequired,
	submitFornecedor: PropTypes.func.isRequired,
	handleEdition: PropTypes.func.isRequired,
	handleModal: PropTypes.func.isRequired,
	cadastro: PropTypes.object.isRequired,
	searching_cep: PropTypes.bool.isRequired,
	loading_cep: PropTypes.bool.isRequired,
	request_state: PropTypes.number.isRequired,
	modal_error: PropTypes.bool.isRequired,
	cep_state: PropTypes.number.isRequired,
	validation_errors: PropTypes.object.isRequired,
	titulo_state: PropTypes.number.isRequired,
};

export default CSSModules(CadastroNormal, styles, { allowMultiple: true });
