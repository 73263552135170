import moment from 'moment';
import 'moment/locale/pt-br';
import business from 'moment-business';


import { createRequest } from './http';


export const get = (id) => createRequest({ method: 'get', url: `/conaz/v2/rcs/${id}` });

export const getCompra = (id) => createRequest({ method: 'get', url: `/conaz/v2/compras/${id}` });

export const getList = (params) => createRequest({ method: 'get', url: '/conaz/v2/rcs', params });

export const getRCsAtivos = () => {
	const params = {
		status: '0,10,20,30,35',
		em_aberta: true,
	};
	return createRequest({ method: 'get', url: '/conaz/v2/rcs/resumidos', params })
		.then(response => {
			const today = moment();
			const prazo_com_atraso = business.subtractWeekDays(today, 3);
			return response.data
				.filter(rc => moment(rc.prazo_de_resposta).isSameOrAfter(prazo_com_atraso, 'day') && !rc.pedido_cancelado && rc.qtd_compras_pedido === 0)
				.sort((a, b) => new Date(b.prazo_de_resposta) - new Date(a.prazo_de_resposta));
		});
};

export const getRCSEmRefinamento = () => {
	const params = { status: '36,37' };
	return createRequest({ method: 'get', url: '/conaz/v2/rcs/resumidos', params })
		.then(response => response.data);
};

export const getRCsExpirados = () => {
	const params = {
		status: '0,10,20,30,35',
	};
	return createRequest({ method: 'get', url: '/conaz/v2/rcs/resumidos', params })
		.then(response => {
			const today = moment();
			const prazo_com_atraso = business.subtractWeekDays(today, 3);
			return response.data
				.filter(rc => (moment(rc.prazo_de_resposta).isBefore(prazo_com_atraso, 'day') && !rc.pedido_cancelado && rc.tipo_selecao_fornecedor < 4) || (rc.qtd_compras_pedido > 0 && !rc.pedido_cancelado && rc.tipo_selecao_fornecedor < 4))
				.sort((a, b) => new Date(b.prazo_de_resposta) - new Date(a.prazo_de_resposta));
		});
};

export const getRCsCancelados = () => {
	const params = {
		status: '-30,0,10,20,30,35',
	};
	return createRequest({ method: 'get', url: '/conaz/v2/rcs/resumidos', params })
		.then(response => response.data
			.filter(rc => rc.pedido_cancelado && rc.tipo_selecao_fornecedor < 4)
			.sort((a, b) => new Date(b.prazo_de_resposta) - new Date(a.prazo_de_resposta)));
};

export const getRCsRecusados = () => {
	const params = {
		status: '-20',
	};
	return createRequest({ method: 'get', url: '/conaz/v2/rcs/resumidos', params })
		.then(response => (response.data
			.filter(rc => !rc.pedido_cancelado)
			.sort((a, b) => new Date(b.prazo_de_resposta) - new Date(a.prazo_de_resposta))
		));
};

export const getRCsEnviados = () => {
	// const params = {
	// 	status: '40,50,60',
	// };
	const params = {};

	return createRequest({ method: 'get', url: '/conaz/v2/rcs/resumidos?status=40,50,60', params })
		.then(response => {
			const rcs = response.data;
			return rcs.sort((a, b) => new Date(b.data_de_resposta) - new Date(a.data_de_resposta));
		});
};

export const getComprasEnviadas = () => createRequest({ method: 'get', url: '/conaz/v2/compras?rc=None&status=-20&status=0&status=10&status=30&status=40' })
	.then(response => {
		const rcs = response.data;
		return rcs.sort((a, b) => new Date(b.data_de_resposta) - new Date(a.data_de_resposta));
	});

export const resetStatus = id => createRequest({ method: 'post', url: `/conaz/v2/rcs/${id}/reset_status?options=alerta` });

export const create = (data) => createRequest({ method: 'post', url: '/conaz/v2/rcs/', data });

export const getRCAC = (page = '', per_page = 20) => createRequest({
	method: 'get',
	url: `/conaz/v2/rcs/aguardando_confirmacao?page=${page}&per_page=${per_page}`,
});

export const getRCSS = (page = '', per_page = 20) => createRequest({
	method: 'get',
	url: `/conaz/v2/rcs/sem_sucesso?page=${page}&per_page=${per_page}`,
});

export const getRCRecusado = (page = '', per_page = 20) => createRequest({
	method: 'get',
	url: `/conaz/v2/rcs/recusadas_pelo_fornecedor?page=${page}&per_page=${per_page}`,
});

export const getRCCC = (page = '', per_page = 20) => createRequest({
	method: 'get',
	url: `/conaz/v2/rcs/compras_canceladas?page=${page}&per_page=${per_page}`,
});

export const getRCCE = (page = '', per_page = 20) => createRequest({
	method: 'get',
	url: `/conaz/v2/rcs/compras_efetuadas?page=${page}&per_page=${per_page}`,
});

export const getRCEA = (page = '', per_page = 20) => createRequest({
	method: 'get',
	url: `/conaz/v2/rcs/em_andamento?page=${page}&per_page=${per_page}`,
});
