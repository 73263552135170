import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import InputFieldText from '../../_parts/_inputs/InputCadastro/InputFieldText';
import InputFieldPhone from '../../_parts/_inputs/InputCadastro/InputFieldPhone';
import Modal from '../../_parts/Modal/Modal';
import MessageModal from '../../_parts/MessageModal/MessageModal';

const EditarConta = ({
	/* state */ enviando,
	success,
	modal_error,
	validation_errors,
	errorTitle,
	errorMsg,
	/* props */ cadastro,
	/* funcs */ handleSubmit,
	handleEdition,
	handleModal,
	handleEditionTelefones,
	removerTelefone,
	adicionarTelefone,
}) => {
	const { nome_vendedor, telefones_vendedor } = cadastro;

	if (modal_error) {
		return (
			<Modal modal="modal_error" handleModal={handleModal}>
				<MessageModal
					type={success ? 'success' : 'alert'}
					title={errorTitle || 'Ops... aconteceu algo errado.'}
					message={
						errorMsg ||
						'Desculpe, não conseguimos identificar o que aconteceu. Espere alguns segundos e tente novamente.'
					} />
			</Modal>
		);
	}

	return (
		<div className="container" styleName="main-container">
			<div className="row">
				<div className="col-xs-12" style={{ textAlign: 'center' }}>
					<h2>Editar conta</h2>
				</div>
			</div>
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-xs-12 col-sm-6">
						{validation_errors.nome_vendedor && (
							<div
								className="tooltip top in"
								styleName="tooltip"
								role="tooltip"
								style={{ marginLeft: '-19px', marginTop: '-25px' }}>
								<div className="tooltip-arrow" />
								<div className="tooltip-inner" styleName="tooltip-inner">
									Preencha o nome do vendedor
								</div>
							</div>
						)}
						<InputFieldText
							label="Nome do(a) vendedor(a)"
							fields={['nome_vendedor']}
							obrigatorio
							maxLength={60}
							default_value={nome_vendedor}
							auxiliarFunction={handleEdition} />
					</div>
				</div>
				<div className="row">
					<div className="col-xs-12 col-sm-4">
						{
							/* eslint-disable react/no-array-index-key */
							telefones_vendedor.map((tel, idx) => (
								<div key={idx} style={{ position: 'relative' }}>
									{validation_errors.telefones_vendedor[idx] && (
										<div
											className="tooltip top in"
											styleName="tooltip"
											role="tooltip"
											style={{ marginLeft: '-19px', marginTop: '-25px' }}>
											<div className="tooltip-arrow" />
											<div className="tooltip-inner" styleName="tooltip-inner">
												Preencha o telefone do vendedor
											</div>
										</div>
									)}
									<InputFieldPhone
										label="Telefone"
										obrigatorio
										default_value={tel.numero}
										auxiliarFunction={(value) => handleEditionTelefones(idx, value)}
										additional_class="input-max-width" />
									<button
										type="button"
										className="btn btn-link"
										styleName="delete-phone-button"
										onClick={() => removerTelefone(idx)}>
										<i className="fa fa-trash" aria-hidden="true" />
									</button>
								</div>
							))
						}
					</div>
					<div className="col-xs-12">
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => adicionarTelefone()}>
							<i className="fa fa-plus" aria-hidden="true" /> telefone
						</button>
					</div>
				</div>
				<div className="row">
					<div className="col-xs-12" style={{ textAlign: 'center' }}>
						<p>
							<br />
						</p>
						<p>
							<br />
						</p>
						<button
							type="submit"
							id="send_btn"
							className="btn btn-success btn-lg"
							disabled={enviando === 1}>
							{enviando === 1 ? 'Validando' : 'Salvar'}
						</button>
					</div>
				</div>
			</form>
		</div>
	);
};

EditarConta.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	handleEdition: PropTypes.func.isRequired,
	handleModal: PropTypes.func.isRequired,
	cadastro: PropTypes.object.isRequired,
	enviando: PropTypes.bool.isRequired,
	success: PropTypes.bool.isRequired,
	modal_error: PropTypes.bool.isRequired,
	validation_errors: PropTypes.object.isRequired,
	errorMsg: PropTypes.string.isRequired,
	errorTitle: PropTypes.string.isRequired,
	handleEditionTelefones: PropTypes.func.isRequired,
	removerTelefone: PropTypes.func.isRequired,
	adicionarTelefone: PropTypes.func.isRequired,
};

export default CSSModules(EditarConta, styles, { allowMultiple: true });
