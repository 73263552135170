
import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import InputFieldText from '../../_parts/_inputs/InputCadastro/InputFieldText';
import Modal from '../../_parts/Modal/Modal';
import MessageModal from '../../_parts/MessageModal/MessageModal';

const AlterarSenha = ({
	/* state */ enviando, success, modal_error, validation_errors, errorTitle, errorMsg, senha_antiga, nova_senha, confirma_nova_senha,
	/* funcs */ handleSubmit, handleEdition, handleModal,
}) => {
	if (modal_error) {
		return (
			<Modal modal="modal_error" handleModal={handleModal}>
				<MessageModal
					type={success ? 'success' : 'alert'}
					title={errorTitle || 'Ops... aconteceu algo errado.'}
					message={errorMsg || 'Desculpe, não conseguimos identificar o que aconteceu. Espere alguns segundos e tente novamente.'} />
			</Modal>
		);
	}
	return (
		<div className="container" styleName="main-container">
			<div className="row">
				<div className="col-xs-12" style={{ textAlign: 'center' }}>
					<h2>Alterar senha</h2>
				</div>
			</div>
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-xs-12 col-sm-7">
						{validation_errors.senha_antiga && (
							<div className="tooltip top in" styleName="tooltip" role="tooltip" style={{ marginLeft: '-19px', marginTop: '-22px' }}>
								<div className="tooltip-arrow" />
								<div className="tooltip-inner">Preencha a senha atual!</div>
							</div>
						)}
						<InputFieldText
							label="Senha atual"
							fields={['senha_antiga']}
							obrigatorio
							maxLength={100}
							default_value={senha_antiga}
							auxiliarFunction={handleEdition} />
					</div>
				</div>
				<div className="row">
					<div className="col-xs-12 col-sm-7">
						{validation_errors.nova_senha && (
							<div className="tooltip top in" styleName="tooltip" role="tooltip" style={{ marginLeft: '-19px', marginTop: '-22px' }}>
								<div className="tooltip-arrow" />
								<div className="tooltip-inner">Preencha a nova senha!</div>
							</div>
						)}
						<InputFieldText
							label="Senha"
							fields={['nova_senha']}
							obrigatorio
							maxLength={100}
							default_value={nova_senha}
							auxiliarFunction={handleEdition} />
					</div>
				</div>
				<div className="row">
					<div className="col-xs-12 col-sm-7">
						{validation_errors.confirma_nova_senha && (
							<div className="tooltip top in" styleName="tooltip" role="tooltip" style={{ marginLeft: '-19px', marginTop: '-22px' }}>
								<div className="tooltip-arrow" />
								<div className="tooltip-inner">Preencha a confirmação da senha!</div>
							</div>
						)}
						{validation_errors.senhas_nao_correspondem && (
							<div className="tooltip top in" styleName="tooltip" role="tooltip" style={{ marginLeft: '-19px', marginTop: '-22px' }}>
								<div className="tooltip-arrow" />
								<div className="tooltip-inner">As senhas fornecidas não correspondem!</div>
							</div>
						)}
						<InputFieldText
							label="Confirmar senha"
							fields={['confirma_nova_senha']}
							obrigatorio
							maxLength={100}
							default_value={confirma_nova_senha}
							auxiliarFunction={handleEdition} />
					</div>
				</div>
				<div className="row">
					<div className="col-xs-12" style={{ textAlign: 'center' }}>
						<p><br /></p><p><br /></p>
						<button
							type="submit"
							id="send_btn"
							className="btn btn-success btn-lg"
							disabled={enviando}>
							{enviando ? 'Validando' : 'Salvar'}
						</button>
					</div>
				</div>
			</form>
		</div>
	);
};

AlterarSenha.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	handleEdition: PropTypes.func.isRequired,
	handleModal: PropTypes.func.isRequired,
	enviando: PropTypes.bool.isRequired,
	success: PropTypes.bool.isRequired,
	modal_error: PropTypes.bool.isRequired,
	validation_errors: PropTypes.object.isRequired,
	errorMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
	errorTitle: PropTypes.string.isRequired,
	nova_senha: PropTypes.string.isRequired,
	confirma_nova_senha: PropTypes.string.isRequired,
	senha_antiga: PropTypes.string.isRequired,
};


export default CSSModules(AlterarSenha, styles, { allowMultiple: true });
