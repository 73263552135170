import { createRequest } from './http';

export const create = (data) => createRequest({ method: 'post', url: '/conaz/v2/grupos/', data });

export const update = (id, data) => {
	const url = `/conaz/v2/grupos/${id}`;
	return createRequest({ method: 'put', url, data });
};

export const get = (id) => createRequest({ method: 'get', url: `/conaz/v2/grupos/${id}` });

export const getList = (params = {}) => createRequest({ method: 'get', url: '/conaz/v2/grupos/', params });
