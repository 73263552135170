import update from 'immutability-helper';
import user from '../store/user';
import { UPDATE_USER, CHANGE_OBRA, SET_AUTH, UNSET_AUTH } from '../actions/action.types';

const reducer = (state = user, action) => {
	switch (action.type) {
	case UPDATE_USER:
		return update(state, {
			[action.field]: { $set: action.value },
		});

	case CHANGE_OBRA:
		return update(state, {
			obra_selected: { $set: action.obra_id },
			tem_express: { $set: action.tem_express },
		});

	case UNSET_AUTH:
		localStorage.setItem('user', JSON.stringify({ email: '' }));
		return update(state, {
			logged: { $set: false },
			token: { $set: '' },
			email: { $set: '' },
			id: { $set: 0 },
			nome: { $set: '' },
			tem_express: { $set: false },
			obras_by_id: { $set: {} },
			obras_all_ids: { $set: [] },
			obra_selected: { $set: 0 },
		});

	case SET_AUTH:
		return update(state, {
			logged: { $set: true },
			token: { $set: action.token },
			email: { $set: action.user.email },
			id: { $set: Number(action.user.id) },
			nome: { $set: action.user.nome },
			tem_express: { $set: action.tem_express },
		});

	default:
		return state;
	}
};

export default reducer;
