import axios from 'axios';
import qs from 'qs';

import { getLocalstorageData } from '../../components/_functions/_getLocalstorageData';


export const createClient = () => axios.create({
	baseURL: getLocalstorageData('environment', 'base_url'),
	headers: { apiToken: localStorage.getItem('token') },
	paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),

});

const formatarMensagemErro = (error) => {
	const { response, message } = error;
	const { responseURL } = ((response || {}).request || {});
	const { method } = ((response || {}).config || {});
	const { status } = (response || {});
	const { responseText } = ((response || {}).request || {});

	return [
		`Página de origem: ${window.location.href}`,
		`URL: ${responseURL}`,
		`Método: ${method}`,
		`Código de status: ${status}`,
		`Response data: ${responseText}`,
		`Message: ${message}`,
	].join('\n');
};

export const reportarErro = (error) => {
	const is_test = (window.location.href.indexOf('fornecedor.conazweb.com.br/') === -1);
	const channel = is_test ? '#erros-dev' : '#erros';
	const { status } = ((error || {}).response || {});
	if (status === 500) {
		const data = {
			icon_emoji: ':triangular_flag_on_post:',
			username: 'Portal Fornecedores',
			channel,
			text: formatarMensagemErro(error),
		};
		createClient().post('/conaz/legacy/send_slack', data);
	}
};

export const createRequest = (config) => createClient().request(config)
	.catch(error => {
		reportarErro(error);
		throw error;
	});
