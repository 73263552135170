import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const GroupCheckbox = ({ id, checked, view, auxiliarFunction, keys }) => {
	if (checked) {
		return (
			<button id={id} styleName="checkbox-button checked" onClick={() => auxiliarFunction(keys)}>
				<div
					styleName="checkbox-icon">
					<i className="fa fa-check" aria-hidden="true" />
				</div>
				<b>{view}</b>
			</button>
		);
	}
	return (
		<button id={id} styleName="checkbox-button" onClick={() => auxiliarFunction(keys)}>
			<div styleName="checkbox-icon" />
			{view}
		</button>
	);
};


GroupCheckbox.propTypes = {
	id: PropTypes.string,
	keys: PropTypes.array.isRequired,
	view: PropTypes.string,
	checked: PropTypes.bool,
	auxiliarFunction: PropTypes.func,
};

GroupCheckbox.defaultProps = {
	id: null,
	view: '',
	checked: false,
	auxiliarFunction: () => {},
};

export default CSSModules(GroupCheckbox, styles, { allowMultiple: true });
