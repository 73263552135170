
import { getLocalstorageData } from './_getLocalstorageData';
import { isAuthenticated } from '../../services/login_manager';

export const validaEmpresa = (fornecedor_selecionado = {}) => {
	if (!isAuthenticated()) return 0;

	const _vendedor_fornecedores = getLocalstorageData('user', 'fornecedores')
		.filter(x => x.fornecedor.status >= 0);
	const filtra_associados_pelo_construtor = _vendedor_fornecedores
		.filter(x => x.tipo_associacao !== 4);
	const vendedor_fornecedores = filtra_associados_pelo_construtor.length > 0
		? [...filtra_associados_pelo_construtor]
		: [..._vendedor_fornecedores];

	if (vendedor_fornecedores.length > 1 && (fornecedor_selecionado.id === 0 || fornecedor_selecionado.id === undefined)) {
		if (document.getElementById('fornecedor_select')) {
			document.getElementById('fornecedor_select').focus();
		}
		if (document.getElementById('tooltip_fornecedor')) {
			document.getElementById('tooltip_fornecedor').className = 'tooltip top in';
		}
		return 0;
	}

	return 1;
};
