import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/pt-br';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../actions/index';

// Components
import Wrapper from '../../Wrapper/Wrapper';
import DadosFinais from './DadosFinais';

// Functions
import { _put } from '../../../services/apis/requests';
import { editDateFormat } from '../../_functions/_editDateFormat';
import { clearItensAlerts } from '../../_functions/_clearItensAlerts';
import { reportarErro } from '../../../services/apis/http';
import withDocumentWidth from '../../../hoc/DocumentWidh/withDocumentWidht';

class DadosFinaisContainer extends Component {
	constructor() {
		super();
		this.state = {
			errors: false,
			salvando: false,
			salva: true,
			new_saving: 2,
			tab_perfil_construtor: 0,
		};
		this.handleValidade = this.handleValidade.bind(this);
		this.handleTransporte = this.handleTransporte.bind(this);
		this.autoSaveRcTimeout = this.autoSaveRcTimeout.bind(this);
		this.openPerfilConstrutor = this.openPerfilConstrutor.bind(this);
		this.forceAutoSave = this.forceAutoSave.bind(this);
	}

	UNSAFE_componentWillMount() {
		window.scroll(0, 0);
		// redirect page 1
		const { match, cotacao, history } = this.props;
		if (cotacao.rc.uuid === undefined) {
			history.replace(`/cotacao/${match.params.id}`);
		}
	}

	componentWillUnmount() {
		const { updateUi } = this.props;
		updateUi(['salvando_cotacao'], 0);
	}

	handleInputChange = (field, value) => {
		this.setState({ [field]: value });
	}

	handleChange = ({ target }) => {
		const value = parseInt(target.value, 10) || parseInt(target.value, 10) === 0
			? parseInt(target.value, 10)
			: target.value;
		const { updateCotacao } = this.props;
		updateCotacao(['rc', target.name], value);
		if (target.name === 'transporte_proprio' && value === 2) {
			updateCotacao(['rc', 'valor_transporte'], 0);
			updateCotacao(['rc', 'prazo_de_entrega'], null);
		}
		this.autoSaveRcTimeout();
		clearItensAlerts();
	}

	handleValidade(_date) {
		if ((_date || {}).target === undefined) {
			const validade = (_date === '' || _date === null) ? moment() : _date;
			this.props.updateCotacao(['rc', 'validade'], validade);
			this.autoSaveRcTimeout();
			clearItensAlerts();
		}
	}

	handleTransporte(field, option) {
		const { id: value } = option;
		const { updateCotacao } = this.props;
		updateCotacao(['rc', field], value);
		if (value !== 1) {
			updateCotacao(['rc', 'valor_transporte'], 0);
			updateCotacao(['rc', 'transporte_proprio'], null);
		}
		if (value === 2) {
			updateCotacao(['rc', 'prazo_de_entrega'], null);
		}
		this.autoSaveRcTimeout();
		clearItensAlerts();
	}

	checkErrors(error) {
		const { cotacao, updateUi, updateModals } = this.props;
		const message_conaz = (((error || {}).response || {}).data || {}).message || '';
		if (message_conaz === 'Pedido esta sendo editado') {
			window.location.replace(`${window.location.origin}/cotacao/${cotacao.rc.uuid}`);
			return;
		}

		console.log(error); // eslint-disable-line
		updateUi(['salvando_cotacao'], 0);
		updateModals('error', true);
		reportarErro(error);
	}

	autoSaveRcTimeout() {
		const timeout = 120 * 1000;
		this.setState({ new_saving: 0, salva: false, errors: false });
		this.props.updateUi(['salvando_cotacao'], 0);
		window.clearTimeout(this.save_timeout);
		this.save_timeout = window.setTimeout(() => {
			this.autoSaveRC();
		}, timeout);
	}

	forceAutoSave() {
		window.clearTimeout(this.save_timeout);
		this.autoSaveRC();
	}

	autoSaveRC() {
		this.setState({ new_saving: 1 });
		this.props.updateUi(['salvando_cotacao'], 1);
		const { uuid, condicoes_de_pagamento, custos_adicionais, codigo_do_fornecedor, prazo_de_entrega, transporte,
			valor_transporte, transporte_proprio, validade, observacoes } = this.props.cotacao.rc;
		const _validade = typeof validade === 'string' ? validade : editDateFormat(validade);
		const condicoes_de_pagamento_ids = [...condicoes_de_pagamento];
		const rc_data = {
			condicoes_de_pagamento_ids,
			custos_adicionais,
			codigo_do_fornecedor,
			prazo_de_entrega,
			transporte,
			valor_transporte,
			validade: _validade,
			observacoes,
		};
		if (transporte_proprio !== null) rc_data.transporte_proprio = transporte_proprio;
		_put(`/conaz/v2/rcs/${uuid}`, rc_data).then(() => {
			this.setState({ new_saving: 2, salva: true });
			this.props.updateUi(['salvando_cotacao'], 2);
		}).catch(error => {
			this.setState({ new_saving: 3 });
			this.props.updateUi(['salvando_cotacao'], 0);
			this.checkErrors(error);
		});
	}

	validaCondicoes() {
		const { condicoes_de_pagamento, condicoes_de_pagamento_by_id } = this.props.cotacao.rc;
		for (let c = 0; c < condicoes_de_pagamento.length; c++) {
			const condicao = condicoes_de_pagamento_by_id[condicoes_de_pagamento[c]];
			if (
				condicao.forma === 0 ||
				condicao.prazo === 0 ||
				(condicao.prazo === -1 && condicao.outro_prazo === '') ||
				(condicao.forma === -1 && condicao.outra_forma === '')
			) {
				this.setState({ errors: true });
				document.getElementById('condicao_wrapper').focus();
				document.getElementById('tooltip_condicoes_pagamento').className = 'tooltip top in';
				return false;
			}
		}
		return true;
	}

	validaCondicoesNovas() {
		const { condicoes_de_pagamento, condicoes_de_pagamento_by_id } = this.props.cotacao.rc;
		for (let c = 0; c < condicoes_de_pagamento.length; c++) {
			const condicao = condicoes_de_pagamento_by_id[condicoes_de_pagamento[c]];
			if (
				condicao.forma === 0 ||
				condicao.prazo === 0 ||
				(condicao.prazo === -1 && condicao.outro_prazo === '') ||
				(condicao.forma === -1 && condicao.outra_forma === '')
			) {
				this.setState({ errors: true });
				document.getElementById('condicao_wrapper').focus();
				document.getElementById(`tooltip_condicao_pagamento_${condicao.id}`).className = 'tooltip top in';
				return false;
			}

			const aceita_parcial = condicao.aceita_parcial !== null
				? condicao.aceita_parcial
				: -1;
			const compra_minima = condicao.compra_minima || 0;
			if (aceita_parcial === -1) {
				this.setState({ errors: true });
				document.getElementById('condicao_wrapper').focus();
				document.getElementById(`tooltip_compra_parcial_${condicao.id}`).className = 'tooltip top in';
				return false;
			}
			if (aceita_parcial === 2 && compra_minima === 0) {
				this.setState({ errors: true });
				document.getElementById('condicao_wrapper').focus();
				document.getElementById(`tooltip_valor_minimo_${condicao.id}`).className = 'tooltip top in';
				return false;
			}
		}
		return true;
	}

	openPerfilConstrutor(tab = 0) {
		const { updateModals } = this.props;
		this.setState({ tab_perfil_construtor: tab });
		updateModals('perfil_construtor', true);
	}

	render() {
		const { cotacao } = this.props;
		const loaded = cotacao.rc.uuid !== undefined;

		if (cotacao.rc.uuid === undefined) {
			return null;
		}

		return (
			<Wrapper
				type="cotacao"
				loaded={loaded}
				{...this.props}
			>
				<DadosFinais
					{...this.state}
					{...this.props}
					handleValidade={this.handleValidade}
					handleTransporte={this.handleTransporte}
					handleChange={this.handleChange}
					autoSaveRcTimeout={this.autoSaveRcTimeout}
					openPerfilConstrutor={this.openPerfilConstrutor}
					forceAutoSave={this.forceAutoSave}
				/>
			</Wrapper>
		);
	}
}

DadosFinaisContainer.propTypes = {
	// =========== store
	cotacao: PropTypes.object.isRequired,
	ui: PropTypes.object.isRequired,
	cadastro: PropTypes.object.isRequired,
	// =========== funcs
	updateCadastro: PropTypes.func.isRequired,
	updateCotacao: PropTypes.func.isRequired,
	updateUi: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	reportErrors: PropTypes.func.isRequired,
	// =========== router
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
};

const mapStateToProps = props => ({
	cadastro: props.cadastro,
	cotacao: props.cotacao,
	ui: props.ui,
});

const mapDispatchToProps = dispatch => ({
	resetCadastro: () => dispatch(actions.resetCadastro()),
	updateCadastro: (field, value) => dispatch(actions.updateCadastro(field, value)),
	updateCotacao: (field, value) => dispatch(actions.updateCotacao(field, value)),
	updateUi: (field, value) => dispatch(actions.updateUi(field, value)),
	updateItem: (item_id, field, value) => dispatch(actions.updateItem(item_id, field, value)),
	updateItemCaracteristica: (item_id, key, value) => dispatch(actions.updateItemCaracteristica(item_id, key, value)),
	updateItemCaracteristicaUnidade: (item_id, key, value) => dispatch(actions.updateItemCaracteristicaUnidade(item_id, key, value)),
	updateModals: (modal, value) => dispatch(actions.updateModals(modal, value)),
	reportErrors: (page, error, codigo_pedido, full_error, test) => dispatch(actions.reportErrors(page, error, codigo_pedido, full_error, test)),
});

export default compose(
	withDocumentWidth,
	connect(mapStateToProps, mapDispatchToProps),
)(DadosFinaisContainer);
