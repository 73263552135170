
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../actions/index';

// Components
import CadastroComFornecedor from './CadastroComFornecedor';

// Functions
import { enviarConfirmacao } from '../../../services/apis/auth';
import { create as createUsuario } from '../../../services/apis/usuarios';
import { addUsuarioToFornecedor } from '../../../services/apis/fornecedores';
import { isAuthenticated, login } from '../../../services/login_manager';
import { urlSearchParamsPolyfill } from '../../_functions/_urlSearchParamsPolyfill';

import {
	getLocalstorageData,
	setLocalstorageData,
} from '../../_functions/_getLocalstorageData';

import { lockBody } from '../../_functions/_lockBody';
import { validaTelefone } from '../../_functions/_validaTelefone';

class CadastroComFornecedorContainer extends Component {
	static setValidationErrors() {
		return { telefone_vendedor: false, email: false, email_cadastrado: false, senha: false, senha_repeat: false };
	}

	constructor() {
		super();
		this.state = {
			associado: false,
			request_state: 0,
			modal_error: false,
			page_locked: false,
			validation_errors: {
				telefone_vendedor: false,
				email: false,
				email_cadastrado: false,
				nome_cadastrado: false,
				senha: false,
				senha_repeat: false,
			},
			errorMsg: '',
			errorTitle: '',
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleEdition = this.handleEdition.bind(this);
		this.handleModal = this.handleModal.bind(this);
		this.handleClickVoltar = this.handleClickVoltar.bind(this);
		this.handleClickAssociar = this.handleClickAssociar.bind(this);
		this.handleEditionTelefone = this.handleEditionTelefone.bind(this);
	}

	componentDidMount() {
		const { documento } = this.props.cadastro;
		if (!documento) {
			this.props.history.push({
				pathname: '/cadastro',
				search: this.props.location.search,
			});
		}
		window.scrollTo(0, 0);
	}

	handleModal(modal, value) {
		this.setState({ [modal]: value, page_locked: value });
		lockBody(value);
	}

	handleClickVoltar() {
		this.props.history.push({
			pathname: '/cadastro',
			search: this.props.location.search,
		});
	}

	handleClickAssociar() {
		const { id, empresas, empresas_by_id } = this.props.cadastro;
		setLocalstorageData('fornecedor_id', id);
		const { search } = this.props.location;

		const user = getLocalstorageData('user');
		addUsuarioToFornecedor(id, user.id, { tipo_associacao: 2 })
			.then(response => {
				const all_params = urlSearchParamsPolyfill(search);
				const _from = all_params.from || '';
				const _redirect_to = all_params.redirect_to || '';

				user.fornecedores.push(response.data);
				setLocalstorageData('user', user);
				this.setState({ associado: true });
				this.props.updateCadastro(['empresas_by_id'], [...empresas_by_id, response.data.fornecedor.id]);
				this.props.updateCadastro(['empresas'], { ...empresas, [response.data.fornecedor.id]: response.data });

				if (_from !== 'identificacao_vendedor') {
					setTimeout(() => this.props.history.push('/boas-vindas'), 5000);
				} else {
					setTimeout(() => this.props.history.push(_redirect_to), 5000);
				}
			})
			.catch(err => {
				console.log(err); // eslint-disable-line
			});
	}

	handleSubmit(e) {
		e.preventDefault();
		document.getElementById('send_btn').focus();
		this.setState({ request_state: 1 });

		const { titulo_state } = this.state;

		const { id, nome_vendedor, telefones_vendedor, email, senha, senha_repeat, empresas, empresas_by_id } = this.props.cadastro;

		if (titulo_state === 1) {
			window.scrollTo(0, 100);
		} else {
			// console.log(email);
			setTimeout(() => {
				if (email === '') {
					// this.setState({ request_state: 9 });
					const validation_new = this.constructor.setValidationErrors();
					validation_new.email = true;
					this.setState({ validation_errors: validation_new, request_state: 0 });
					window.scrollTo(0, 800);
					return;
				}

				if (senha === '') {
					// this.setState({ request_state: 10 });
					const validation_new = this.constructor.setValidationErrors();
					validation_new.senha = true;
					this.setState({ validation_errors: validation_new, request_state: 0 });
					return;
				}

				if (senha !== senha_repeat) {
					// this.setState({ request_state: 12 });
					const validation_new = this.constructor.setValidationErrors();
					validation_new.senha_repeat = true;
					this.setState({ validation_errors: validation_new, request_state: 0 });
					return;
				}

				if (nome_vendedor === '') {
					const validation_new = this.constructor.setValidationErrors();
					validation_new.nome_vendedor = true;
					this.setState({ validation_errors: validation_new, request_state: 0 });
					window.scrollTo(0, 100);
					return;
				}

				if (telefones_vendedor[0].numero === '' || !validaTelefone(telefones_vendedor[0].numero)) {
					const validation_new = this.constructor.setValidationErrors();
					validation_new.telefone_vendedor = true;
					this.setState({ validation_errors: validation_new, request_state: 0 });
					window.scrollTo(0, 100);
					return;
				}
				telefones_vendedor[0].numero = telefones_vendedor[0].numero.replace(/[^\d]/g, '');

				const usuario = {
					nome: nome_vendedor,
					email,
					senha,
					origem_cadastro: 1,
					status: 0,
					cadastrado_por: null,
					telefones: telefones_vendedor,
					aceitou_termos: true,
				};

				this.setState({ errorMsg: '', errorTitle: '' });

				createUsuario(usuario)
					.then(response => {
						const usuario_id = response.data.id;
						addUsuarioToFornecedor(id, usuario_id, { tipo_associacao: 1 })
							.then(resp => {
								this.setState({ request_state: 0 });
								this.props.updateCadastro(['empresas_by_id'], [...empresas_by_id, resp.data.fornecedor.id]);
								this.props.updateCadastro(['empresas'], { ...empresas, [resp.data.fornecedor.id]: resp.data });

								this.handleLogin(usuario.email, usuario.senha);
							})
							.catch(() => {
								this.setState({ modal_error: true, request_state: 0 });
								window.scrollTo(0, 1000);
							});
					})
					.catch(err => {
						this.setState({ modal_error: true, request_state: 0 });

						const msg_email_ja_existente_back_end = `Usuário com email ${email} já possui cadastro`;

						if (err.response.status === 400) {
							if (msg_email_ja_existente_back_end === err.response.data.message) {
								this.setState({
									errorMsg: <p>Email já cadastrado. Se já possui conta, <Link to="/login">clique para acessar</Link></p>,
									errorTitle: 'Erro ao cadastrar usuário',
								});
							} else {
								this.setState({
									errorMsg: err.response.data.message,
									errorTitle: 'Erro ao cadastrar usuário',
								});
							}
						} else if (err.response.status !== 500) {
							this.setState({
								errorMsg: err.response.data.message,
								errorTitle: 'Erro ao cadastrar usuário',
							});
						} else {
							this.setState({
								errorTitle: '',
								errorMsg: '',
							});
						}
						window.scrollTo(0, 1000);
					});
			}, 60);
		}
	}

	handleLogin(email, password) {
		login(email, password)
			.then(response => {
				enviarConfirmacao()
					.catch(err => {
						console.log(err); // eslint-disable-line
					});

				const { search } = this.props.location;
				const all_params = urlSearchParamsPolyfill(search);
				const _from = all_params.from || '';
				const _redirect_to = all_params.redirect_to || '';

				if (_from !== 'identificacao_vendedor') {
					this.props.history.push('/boas-vindas');
				} else {
					this.props.history.push(_redirect_to);
				}
				return response;
			});
	}

	handleEdition(fields, value) {
		this.props.updateCadastro(fields, value);
		const validation_new = this.constructor.setValidationErrors();
		this.setState({ request_state: 0, validation_errors: validation_new });
	}

	handleEditionTelefone(value) {
		const { telefones_vendedor } = this.props.cadastro;
		telefones_vendedor[0].numero = value;
		this.props.updateCadastro(['telefones_vendedor'], telefones_vendedor);
		const validation_new = this.constructor.setValidationErrors();
		this.setState({ request_state: 0, validation_errors: validation_new });
	}

	render() {
		return (
			<CadastroComFornecedor
				{...this.state}
				{...this.props}
				handleSubmit={this.handleSubmit}
				handleEdition={this.handleEdition}
				handleLogin={this.handleLogin}
				handleModal={this.handleModal}
				handleLoginVendedor={this.handleLoginVendedor}
				isAuthenticated={isAuthenticated}
				handleClickVoltar={this.handleClickVoltar}
				handleClickAssociar={this.handleClickAssociar}
				handleEditionTelefone={this.handleEditionTelefone} />
		);
	}
}

const mapStateToProps = props => ({
	cadastro: props.cadastro,
});

const mapDispatchToProps = dispatch => ({
	updateCadastro: (field, value) => dispatch(actions.updateCadastro(field, value)),
	resetCadastro: () => dispatch(actions.resetCadastro()),
	updateModals: (modal, value) => dispatch(actions.updateModals(modal, value)),
	reportErrors: (page, error, codigo_pedido, full_error, test) => dispatch(actions.reportErrors(page, error, codigo_pedido, full_error, test)),
});


CadastroComFornecedorContainer.propTypes = {
	cadastro: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	updateCadastro: PropTypes.func.isRequired,
	resetCadastro: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CadastroComFornecedorContainer);
