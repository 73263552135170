import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const MessageModal = ({ type, title, message, closeModal }) => (
	<div className="modal-content">
		<div className="modal-header" styleName="modal-header">
			<button
				type="button"
				className="close"
				data-dismiss="modal"
				aria-label="Close"
				onClick={closeModal}>
				<span aria-hidden="true">&times;</span>
			</button>
			<i className="fa fa-exclamation-circle" styleName={type} aria-hidden="true" />
			<h4 className="modal-title">{title}</h4>
		</div>
		<div className="modal-body" styleName="modal-body">
			{message}
		</div>
		<div className="modal-footer" styleName="modal-footer">
			<button className="btn btn-default" type="button" onClick={closeModal}>Fechar</button>
		</div>
	</div>
);

MessageModal.propTypes = {
	title: PropTypes.string.isRequired,
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
	closeModal: PropTypes.func,
	type: PropTypes.string,
};

MessageModal.defaultProps = {
	closeModal: () => {},
	type: 'alert',
};

export default CSSModules(MessageModal, styles, { allowMultiple: true });
