import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from '../style.module.scss';

class BotaoAtualizaRC extends Component {
	static propTypes = {
		status_rc: PropTypes.number.isRequired,
		status_pedido: PropTypes.number.isRequired,
		pedido_cancelado: PropTypes.bool.isRequired,
		confirma_edicao: PropTypes.bool.isRequired,
		tooltip_atualizar: PropTypes.bool.isRequired,
		compra: PropTypes.object.isRequired,
		showTooltip: PropTypes.func.isRequired,
		hideTooltip: PropTypes.func.isRequired,
		confirmaEdicao: PropTypes.func.isRequired,
	};

	confirmaEdicaoLocal = () => {
		const { confirmaEdicao } = this.props;
		confirmaEdicao(true);
	}

	render() {
		const {
			status_rc,
			status_pedido,
			pedido_cancelado,
			confirma_edicao,
			tooltip_atualizar,
			compra,
			showTooltip,
			hideTooltip,
		} = this.props;

		const libera_botao_atualizar = (
			!confirma_edicao
			&& (status_rc === 40 || status_rc === -20)
			&& (status_pedido === 40 || status_pedido === 50)
			&& !pedido_cancelado
		);

		// motivo botão desabilitado
		let motivo_desativado = 'O construtor já realizou a compra desta cotação com outro fornecedor';
		if (pedido_cancelado) motivo_desativado = 'O construtor cancelou a cotação';
		if (compra.em_edicao) motivo_desativado = 'O construtor está editando esta compra';
		if (status_pedido === 60 && compra.id === undefined) motivo_desativado = 'O construtor já está criando uma compra com a sua proposta';
		if (status_pedido === 60 && compra.id !== undefined) motivo_desativado = 'O construtor já realizou a compra desta cotação';

		if (libera_botao_atualizar) {
			return (
				<button
					type="button"
					className="btn btn-primary btn-sm"
					styleName="btn-atualizar"
					onClick={this.confirmaEdicaoLocal}>
					Atualizar proposta
				</button>
			);
		}

		return (
			<React.Fragment>
				{(!confirma_edicao && tooltip_atualizar && status_rc !== 50) && (
					<div className="tooltip left in" role="tooltip" style={{ top: '9px', right: '150px' }}>
						<div className="tooltip-arrow" />
						<div className="tooltip-inner" style={{ maxWidth: '250px' }}>{motivo_desativado}</div>
					</div>
				)}
				<button
					type="button"
					className="btn btn-primary btn-sm"
					styleName="btn-atualizar"
					style={{ opacity: '0.35' }}
					onMouseEnter={showTooltip}
					onMouseLeave={hideTooltip}>
					Atualizar proposta
				</button>
			</React.Fragment>
		);
	}
}

export default CSSModules(BotaoAtualizaRC, styles, { allowMultiple: true });
