export const urlSearchParamsPolyfill = url => {
	const params_as_string = url.replace('?', '');
	const params_array = params_as_string.split('&');
	const params = params_array.reduce((result, current) => {
		const values = current.split('=');
		return {
			...result,
			[values[0]]: current.split('=').slice(1).join('='),
		};
	}, {});
	return params;
};
