import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Components
import HeaderCotacao from '../../_parts/HeaderCotacao/HeaderCotacao';
import InfoClienteCotacao from '../../_parts/InfoClienteCotacao/InfoClienteCotacao';
import Stepbar from '../../_parts/Stepbar';
import InputFieldText from '../../_parts/_inputs/InputCadastro/InputFieldText';
import LeftModalWrapper from '../../_parts/_modals/LeftModalWrapper/LeftModalWrapper';
import LeftModalConstrutorPerfil from '../../_parts/_modals/LeftModalConstrutorPerfil/LeftModalConstrutorPerfil';

const IdentificacaoVendedor = ({
	// =========== local
	tab_perfil_construtor,
	request_state,
	request_state_login,
	validation_errors,
	errorMsg,
	cnpj,
	email,
	// =========== local funcs
	handleSubmit,
	handleSubmitLogin,
	handleEditionLogin,
	handleEditionDocumento,
	openPerfilConstrutor,
	// =========== store
	cotacao,
	ui,
	history,
	// =========== funcs
	updateModals,
}) => {
	const { modals } = ui;
	const { rc } = cotacao;
	const error_class = errorMsg
		? 'form-group has-error'
		: 'form-group';

	return (
		<>
			<LeftModalWrapper
				visible={modals.perfil_construtor}
				updateModals={updateModals}
				modal="perfil_construtor"
			>
				<LeftModalConstrutorPerfil
					exibir_contatos_pedido={rc.pedido.exibir_contato}
					usuario_pedido={rc.pedido.aprovacao_pedida_por.id !== undefined ? rc.pedido.aprovacao_pedida_por : rc.pedido.usuario}
					obra={rc.pedido.obra}
					tab={tab_perfil_construtor}
				/>
			</LeftModalWrapper>

			<HeaderCotacao
				pedido_id={rc.pedido.id}
				data_envio={rc.data_envio}
				prazo_de_resposta={rc.pedido.prazo_de_resposta}
				data_de_entrega_desejada={rc.pedido.data_de_entrega_desejada}
			/>

			<InfoClienteCotacao
				obra={rc.pedido.obra}
				data_de_entrega_desejada={rc.pedido.data_de_entrega_desejada}
				observacoes={rc.pedido.observacoes_conaz}
				anexos={rc.anexos_pedido}
				updateModals={updateModals}
				openPerfilConstrutor={openPerfilConstrutor}
			/>

			<div className="container">
				<Stepbar
					cotacao={cotacao}
					history={history}
					updateModals={updateModals}
				/>

				<div className="row">
					<div className="col-xs-12">
						<div styleName="ident-wrapper">
							<div styleName="left-area">
								<p styleName="first-title">Para concluir a proposta, <span styleName="green-title">acesse sua conta ou faça seu cadastro!</span></p>
								<p>Sua proposta está quase pronta para ser enviada. Mas antes, é necessário informar para o construtor qual é a sua empresa e quem é o vendedor que está respondendo.</p>
							</div>
							<div styleName="right-area">
								<div styleName="login">
									<h4>Acessar conta</h4>
									<form onSubmit={handleSubmitLogin}>
										<div className={error_class}>
											<label htmlFor="email">E-mail</label>
											<input
												type="email"
												className="form-control input-md"
												id="email"
												name="email"
												value={email}
												onChange={handleEditionLogin}
												required
											/>
										</div>
										<div className="form-group">
											<label htmlFor="senha">Senha</label>
											<a
												href="/esqueci-minha-senha"
												target="_blank"
												rel="noopener noreferrer"
												style={{ float: 'right' }}>
												Esqueceu sua senha?
											</a>
											<input
												type="password"
												className="form-control input-md"
												id="senha"
												name="senha"
												onChange={handleEditionLogin}
												required
											/>
										</div>
										{errorMsg !== '' && (
											<div className="alert alert-danger" role="alert">
												<i className="fa fa-exclamation-triangle fa-fw" /> {errorMsg}
											</div>
										)}
										{request_state_login === 1 ? (
											<button
												type="button"
												className="btn btn-success btn-lg"
												styleName="green-button"
											>
												Entrando
											</button>
										) : (
											<button
												type="submit"
												className="btn btn-success btn-lg"
												styleName="green-button"
											>
												Entrar
											</button>
										)}
									</form>
								</div>
								<div styleName="cadastro">
									<h4>Não tenho cadastro</h4>
									<form onSubmit={handleSubmit}>
										{validation_errors.cnpj && (
											<div className="tooltip top in" role="tooltip" style={{ marginLeft: '-19px', marginTop: '-34px' }}>
												<div className="tooltip-arrow" />
												<div className="tooltip-inner">Preencha o campo CNPJ</div>
											</div>
										)}
										{validation_errors.cnpj_invalido && (
											<div className="tooltip top in" role="tooltip" style={{ marginLeft: '-19px', marginTop: '-22px' }}>
												<div className="tooltip-arrow" />
												<div className="tooltip-inner">CNPJ inválido!</div>
											</div>
										)}
										<InputFieldText
											label="CNPJ"
											fields={['documento']}
											obrigatorio
											default_value={cnpj}
											auxiliarFunction={handleEditionDocumento}
										/>

										{(request_state === 1) ? (
											<button
												type="button"
												style={{ marginTop: '19px' }}
												className="btn btn-primary btn-lg older-button small"
												disabled="disabled"
											>
												Validando
											</button>
										) : (
											<button
												type="submit"
												style={{ marginTop: '19px' }}
												className="btn btn-primary btn-lg older-button small"
											>
												Continuar
											</button>
										)}
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-xs-12">
						<p><br /></p><p><br /></p><p><br /></p>
						<p><br /></p><p><br /></p><p><br /></p>
					</div>
				</div>
			</div>
		</>
	);
};

IdentificacaoVendedor.propTypes = {
	// =========== local
	tab_perfil_construtor: PropTypes.number.isRequired,
	request_state: PropTypes.number.isRequired,
	request_state_login: PropTypes.number.isRequired,
	validation_errors: PropTypes.object.isRequired,
	errorMsg: PropTypes.string.isRequired,
	cnpj: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
	// =========== local funcs
	handleEditionLogin: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	handleSubmitLogin: PropTypes.func.isRequired,
	handleEditionDocumento: PropTypes.func.isRequired,
	openPerfilConstrutor: PropTypes.func.isRequired,
	// =========== store
	cotacao: PropTypes.object.isRequired,
	ui: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	// =========== funcs
	updateModals: PropTypes.func.isRequired,
};

export default CSSModules(IdentificacaoVendedor, styles, { allowMultiple: true });
