import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

class Alerts extends Component {
	constructor() {
		super();
		this.state = {
			success: false,
		};
		this.closeSuccess = this.closeSuccess.bind(this);
	}

	componentDidMount() {
		const { alerts } = this.props;
		if (alerts.success.visible) {
			setTimeout(() => this.setState({ success: true }), 350);
			setTimeout(() => this.closeSuccess(), 14000);
		}
	}

	closeSuccess() {
		this.setState({ success: false });
		const alerts = { ...this.props.alerts, success: { visible: false, message: '' } };
		setTimeout(() => this.props.updateUi(['alerts'], alerts), 500);
	}

	render() {
		const { alerts } = this.props;
		const { success } = this.state;
		const success_class = success ? 'alert success in' : 'alert success';

		return (
			<div>
				<div styleName={success_class}>
					<button styleName="close" onClick={this.closeSuccess}><span aria-hidden="true">&times;</span></button>
					{alerts.success.message}
				</div>
			</div>
		);
	}
}

Alerts.propTypes = {
	alerts: PropTypes.object.isRequired,
	updateUi: PropTypes.func.isRequired,
};

export default CSSModules(Alerts, styles, { allowMultiple: true });
