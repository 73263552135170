import React from 'react';
import PropTypes from 'prop-types';
// import moment from 'moment';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import Header from '../../_parts/Header/Header';
import AnexosModal from '../../_parts/AnexosModal/AnexosModal';
import BasicModal from '../../_parts/_modals/BasicModal/BasicModal';
import ModalWrapper from '../../_parts/_modals/ModalWrapper/ModalWrapper';
import LeftModalWrapper from '../../_parts/_modals/LeftModalWrapper/LeftModalWrapper';
import LeftModalConstrutorPerfil from '../../_parts/_modals/LeftModalConstrutorPerfil/LeftModalConstrutorPerfil';
import InfoClienteCotacao from '../../_parts/InfoClienteCotacao/InfoClienteCotacao';
import ReviewCompra from './ReviewCompra';
import ModalConfirmacaoVenda from '../../_parts/_modals/ModalConfirmacaoVenda/ModalConfirmacaoVenda';
import ModalRecusaCompra from '../../_parts/_modals/ModalSemInteresse/ModalRecusaCompra';

// Content
// import { formas_pagamento, prazos_pagamento_full } from '../../_content/_condicoes_pagamento';

// Functions
import { defineStatusCompra } from '../../_functions/_defineStatusCompra';

const ReviewSemRC = ({
	/* state */
	compra,
	oc_confirmada,
	oc_recusada,
	usuario_diferente,
	confirmando_oc,
	error_confirmando_oc,
	error_msg,
	cotacao_info_collapsed,
	pedido_info_collapsed,
	tab_perfil_construtor,
	/* local */
	// transporte_opcoes,
	/* props */
	ui,
	history,
	/* pfunc */
	updateModals,
	/* funcs */
	confirmarOC,
	expandInfo,
	openPerfilConstrutor,
	updateViewRecusada,
}) => {
	const { modals } = ui;
	const {
		pedido_id,
		titulo,
		data_de_entrega,
		anexos,
		obra,
		observacoes,
		transporte,
		usuario_construtor,
		usuario_fornecedor,
	} = compra;

	const status_compra = defineStatusCompra(compra);

	const compras_visiveis = [{ ...compra }];
	const compra_to_show = compras_visiveis[compras_visiveis.length - 1] || {};
	const retirada = transporte === 2;

	const titulo_pedido = pedido_id !== null
		? `#${pedido_id} - ${titulo}`
		: titulo;

	const anexos_cotacao = anexos;

	// const data_resposta_local_moment = moment.utc(data_de_resposta)._d;
	// const data_resposta_local = moment(data_resposta_local_moment).format();
	// const data_hora_resposta_split = data_resposta_local.split('T');
	// const data_resposta_split = data_hora_resposta_split[0].split('-');
	// const data_resposta_view = data_resposta_split[2] !== undefined ? `${data_resposta_split[2]}/${data_resposta_split[1]}/${data_resposta_split[0]}` : '-';
	// const hora_resposta_split = typeof data_hora_resposta_split[1] === 'string' ? data_hora_resposta_split[1].split(':') : '';
	// const hora_resposta_view = hora_resposta_split[0] !== undefined ? `${hora_resposta_split[0]}:${hora_resposta_split[1]}` : '';
	// data_entrega_desejada
	// validade
	// const validade_split = validade !== null ? validade.split('-') : '';
	// const validade_view = validade_split[2] !== undefined ? `${validade_split[2]}/${validade_split[1]}/${validade_split[0]}` : '-';

	if (status_compra === 'cancelada') {
		return (
			<div className="container" style={{ marginTop: '36px' }}>
				<div className="row">
					<div className="col-sm-12 error-wrapper">
						<i className="fa fa-exclamation-circle" aria-hidden="true" />
						<h3>Compra cancelada!</h3>
						<p>Esta compra foi cancelada e não está mais disponível.</p>
					</div>
				</div>
			</div>
		);
	}

	if (
		status_compra === 'em_edicao'
		|| status_compra === 'em_analise'
		|| status_compra === 'em_refinamento'
	) {
		return (
			<div className="container" style={{ marginTop: '36px' }}>
				<div className="row">
					<div className="col-sm-12 error-wrapper">
						<i className="fa fa-pencil" aria-hidden="true" />
						<h3>Compra em edição!</h3>
						<p style={{ fontSize: '16px' }}>Esta compra está sendo editada pelo construtor neste momento.<br />Você será notificado por e-mail assim que ele concluir.</p>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div>
			<ModalWrapper visible={modals.anexos_cliente} updateModals={updateModals}>
				<AnexosModal
					anexos={anexos}
					tipo_anexo="cliente"
					nome_modal="anexos_cliente"
					updateModals={updateModals} />
			</ModalWrapper>
			<ModalWrapper visible={modals.anexos} updateModals={updateModals}>
				<AnexosModal
					anexos={anexos_cotacao}
					tipo_anexo="fornecedor"
					nome_modal="anexos"
					updateModals={updateModals} />
			</ModalWrapper>
			<LeftModalWrapper visible={modals.perfil_construtor} updateModals={updateModals} modal="perfil_construtor">
				<LeftModalConstrutorPerfil
					exibir_contatos_compra
					exibir_contatos_pedido
					usuario_compra={usuario_construtor}
					obra={obra}
					tab={tab_perfil_construtor} />
			</LeftModalWrapper>
			<ModalWrapper visible={modals.confirmacao_venda} updateModals={updateModals}>
				<ModalConfirmacaoVenda
					confirmacaoEnviada={confirmando_oc}
					confirmOC={confirmarOC}
					updateModals={updateModals}
					compra_id={compras_visiveis.length > 0 ? compra_to_show.id : -1}
					error_confirmando_oc={error_confirmando_oc}
					error_msg={error_msg}
					usuario_diferente={usuario_diferente}
					usuario={usuario_fornecedor}
					history={history} />
			</ModalWrapper>
			<ModalWrapper visible={modals.recusa_venda} updateModals={updateModals}>
				<ModalRecusaCompra
					updateModals={updateModals}
					compra_id={compras_visiveis.length > 0 ? compra_to_show.id : -1}
					compra={compras_visiveis.length > 0 ? compra_to_show : {}}
					updateViewRecusada={updateViewRecusada} />
			</ModalWrapper>
			<ModalWrapper visible={modals.alerta_nome_comercial} updateModals={updateModals}>
				<BasicModal
					title="Atenção"
					message={<p>Identificamos que você utilizou palavras como <b>&quot;LTDA&quot;, &quot;MEI&quot; ou &quot;S.A&quot;</b> no cadastro do nome comercial da sua empresa, o que nos parece ser o nome da Razão Social. Recomendamos que <b>altere seu cadastro</b> para algo mais reconhecível aos construtores, assim conseguirem identificar a sua empresa mais facilmente.</p>}
					modalName="alerta_nome_comercial"
					onClickConfirmar={() => { history.push('/editar-empresa'); }}
					showConfirmarButton
					showCancelarButton
					textoConfirmar="Editar cadastro"
					textoCancelar="Não quero alterar" />
			</ModalWrapper>

			<Header
				title={titulo_pedido} />

			<InfoClienteCotacao
				obra={obra}
				data_de_entrega_desejada={data_de_entrega}
				observacoes={observacoes}
				anexos={[]}
				updateModals={updateModals}
				show_obs_anexos={false}
				openPerfilConstrutor={openPerfilConstrutor}
				white_buttons />

			<div className="container" styleName="main-container">
				{compras_visiveis.length > 0 &&
					<ReviewCompra
						retirada={retirada}
						compra={compra_to_show}
						confirmada={oc_confirmada}
						recusada={oc_recusada}
						confirmarOC={() => updateModals('confirmacao_venda', true)}
						recusarOC={() => updateModals('recusa_venda', true)} />
				}

				<div className="row">
					<div className="col-sm-12">
						<div styleName="review-block no-pad2" style={{ minHeight: '40px' }}>
							<div id="info_cotacao_title_onboarding" styleName="info-title">
								Informações gerais da Cotação
							</div>
							{!cotacao_info_collapsed ? (
								<React.Fragment>
									<div className="row">
										<div className="col-xs-12 col-md-8" styleName="content-block">
											<p styleName="labels" style={{ margin: '23px 0 5px 15px' }}>Esta compra não possui cotação.</p>
										</div>
									</div>
									<button
										type="button"
										styleName="content-to-expand to-collapse"
										onClick={() => expandInfo('cotacao_info_collapsed')}>
										<i className="fa fa-angle-up fa-fw" aria-hidden="true" />
									</button>
								</React.Fragment>
							) : (
								<button
									type="button"
									styleName="content-to-expand"
									onClick={() => expandInfo('cotacao_info_collapsed')}>
									<i className="fa fa-angle-down fa-fw" aria-hidden="true" /> Expandir informações <i className="fa fa-angle-down fa-fw" aria-hidden="true" />
								</button>
							)}
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-sm-12">
						<hr styleName="hr-blocks" />
					</div>
				</div>
				<div className="row">
					<div className="col-sm-12">
						<div styleName="review-block no-pad2" style={{ minHeight: '40px' }}>
							<div styleName="info-title">Informações gerais do Pedido</div>
							{!pedido_info_collapsed ? (
								<React.Fragment>
									<div className="row">
										<div className="col-xs-12 col-md-8" styleName="content-block">
											<p styleName="labels" style={{ margin: '23px 0 5px 15px' }}>Esta compra não possui pedido.</p>
										</div>
									</div>
									<button
										type="button"
										styleName="content-to-expand to-collapse"
										onClick={() => expandInfo('pedido_info_collapsed')}>
										<i className="fa fa-angle-up fa-fw" aria-hidden="true" />
									</button>
								</React.Fragment>
							) : (
								<button
									type="button"
									styleName="content-to-expand"
									onClick={() => expandInfo('pedido_info_collapsed')}>
									<i className="fa fa-angle-down fa-fw" aria-hidden="true" /> Expandir informações <i className="fa fa-angle-down fa-fw" aria-hidden="true" />
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

ReviewSemRC.propTypes = {
	// =========== local
	compra: PropTypes.object.isRequired,
	oc_confirmada: PropTypes.bool.isRequired,
	oc_recusada: PropTypes.bool.isRequired,
	confirmando_oc: PropTypes.bool.isRequired,
	error_confirmando_oc: PropTypes.bool.isRequired,
	error_msg: PropTypes.object,
	usuario_diferente: PropTypes.bool.isRequired,
	// tooltip_atualizar: PropTypes.bool.isRequired,
	cotacao_info_collapsed: PropTypes.bool.isRequired,
	pedido_info_collapsed: PropTypes.bool.isRequired,
	tab_perfil_construtor: PropTypes.number.isRequired,
	// =========== local funcs
	confirmarOC: PropTypes.func.isRequired,
	expandInfo: PropTypes.func.isRequired,
	openPerfilConstrutor: PropTypes.func.isRequired,
	updateViewRecusada: PropTypes.func.isRequired,
	// =========== store
	ui: PropTypes.object.isRequired,
	// =========== funcs
	updateModals: PropTypes.func.isRequired,
	// =========== router
	history: PropTypes.object.isRequired,
};

ReviewSemRC.defaultProps = {
	error_msg: null,
};

export default CSSModules(ReviewSemRC, styles, { allowMultiple: true });
