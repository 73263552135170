import React from 'react';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const LoadingPage = () => (
	<div styleName="page-wrapper">
		<div styleName="loading-wrapper">
			<div styleName="logo">
				<img src="/images/logo-loading.png" width="100" alt="" />
			</div>
			<div styleName="progress">
				<div styleName="indeterminate" />
			</div>
		</div>
	</div>
);

export default CSSModules(LoadingPage, styles, { allowMultiple: true });
