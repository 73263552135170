import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { editMomentDateFormat } from '../../_functions/_editDateFormat';
import withDocumentWidth from '../../../hoc/DocumentWidh/withDocumentWidht';

const HeaderCotacao = ({
	pedido_id,
	data_envio,
	prazo_de_resposta,
	data_de_entrega_desejada,
	isPreview,
	width,
}) => {
	const is_mobile = width <= 768;
	const [isVisible, setAsVisible] = useState(false);
	useEffect(() => {
		if (is_mobile) {
			setAsVisible(false);
		}
	}, []);

	useEffect(() => {
		if (is_mobile) {
			setAsVisible(false);
			return;
		}
		setAsVisible(true);
	}, [is_mobile]);

	return (
		<div styleName={isPreview ? 'full-wrapper top' : 'full-wrapper'}>
			<div className="container">
				<div className="row">
					<div className="col-xs-12 col-md-6">
						<div styleName="container-toggle">
							<p style={{ margin: '0 0 5px', fontSize: '18px' }}><b>Solicitação de cotação</b> #{pedido_id}</p>
							<button onClick={() => setAsVisible(!isVisible)} styleName="toggle">
								<i
									className={isVisible ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}
									styleName={isVisible ? 'icon-toggle' : 'icon-toggle hidden'} />
							</button>
						</div>
						<p styleName={isVisible ? 'enviado-em' : 'enviado-em hidden'}><span style={{ color: '#b2cada' }}>Enviado em:</span> <b>{editMomentDateFormat(data_envio)}</b></p>
					</div>
					<div className="col-xs-12 col-md-6">
						<div styleName={isVisible ? 'resposta-ate' : 'resposta-ate hidden'}>
							<p style={{ margin: '0 0 5px' }}>Entrega estimada: <b styleName="data-1">{editMomentDateFormat(data_de_entrega_desejada)}</b></p>
							<p style={{ margin: '0' }}>Resposta até: <b styleName="data-2">{editMomentDateFormat(prazo_de_resposta)}</b></p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

HeaderCotacao.propTypes = {
	pedido_id: PropTypes.number.isRequired,
	data_envio: PropTypes.string.isRequired,
	isPreview: PropTypes.bool,
	prazo_de_resposta: PropTypes.string.isRequired,
	data_de_entrega_desejada: PropTypes.string.isRequired,
	width: PropTypes.number.isRequired,
};

HeaderCotacao.defaultProps = {
	isPreview: false,
};

const _styledHeaderCotacao = CSSModules(HeaderCotacao, styles, { allowMultiple: true });
export default compose(withDocumentWidth)(_styledHeaderCotacao);
