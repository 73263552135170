import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Functions
import { formatCurrency } from '../../_functions/_formatNumber';

const StepbarTotal = ({ cotacao }) => {
	// valor dos itens
	const { itens_all_ids, itens_by_id, rc } = cotacao;
	const valor_final_itens = itens_all_ids.reduce((r, c) => {
		const item = itens_by_id[c];
		const quantidade = item.quantidade || 0;
		const preco_unitario = item.preco_unitario || 0;
		// sub_total
		const sub_total = quantidade * preco_unitario;
		// desconto
		const desconto = item.desconto || 0;
		const valor_desconto = (sub_total / 100) * desconto;
		// total
		const total = (sub_total - valor_desconto) || 0;
		return r + total;
	}, 0);

	// desconto da condição de pagamento
	const condicoes = (cotacao.rc || {}).condicoes_de_pagamento || [];
	const condicoes_by_id = (cotacao.rc || {}).condicoes_de_pagamento_by_id || {};
	const maior_desconto = condicoes
		.reduce((max, condicao_id) => {
			const condicao = condicoes_by_id[condicao_id];
			const desconto = condicao.desconto || 0;

			return desconto > max
				? desconto
				: max;
		}, 0);

	// valor final
	const valor_desconto = (valor_final_itens / 100) * maior_desconto;
	const valor_final = (valor_final_itens - valor_desconto) + rc.valor_transporte + rc.custos_adicionais;

	return (
		<div styleName="totalVenda">
			<label>
				<p>Valor total</p>
				<p>da proposta</p>
			</label>
			<span>{formatCurrency(valor_final, 'R$', 2)}</span>
		</div>
	);
};

StepbarTotal.propTypes = {
	cotacao: PropTypes.object.isRequired,
};

export default CSSModules(StepbarTotal, styles, { allowMultiple: true });
