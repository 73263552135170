import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { _post } from '../../../../services/apis/requests';

class ModalSemInteresse extends Component {
	constructor() {
		super();
		this.state = {
			opcoes_sem_interesse: [
				{ id: 0, mensagem: 'Não possuo a maioria dos itens ou não trabalho com este material' },
				{ id: 1, mensagem: 'Não trabalho com pessoa física' },
				{ id: 2, mensagem: 'Valor da compra abaixo do faturamento mínimo' },
				{ id: 4, mensagem: 'Não entrego nesse local' },
				{ id: 5, mensagem: 'Não atendo a este volume de compra' },
				{ id: 6, mensagem: 'Preciso de um tempo maior para responder' },
				{ id: 7, mensagem: 'Não consigo atender o prazo de entrega em obra solicitado' },
				{ id: 8, mensagem: 'Estou sem estoque' },
			],
			opcoes_selecionadas: [],
			outro_motivo_selecionado: false,
			outro_motivo: '',
			enviando_sem_interesse: 0,
			confirm_nao_possui_todos_itens: false,
		};
		this.handleOutroMotivo = this.handleOutroMotivo.bind(this);
		this.enviaNaoTenhoInteresse = this.enviaNaoTenhoInteresse.bind(this);
		this.preEnvioSemInteresse = this.preEnvioSemInteresse.bind(this);
	}

	UNSAFE_componentWillMount() {
		if (this.props.nao_tem_itens) this.setState({ opcoes_selecionadas: [0] });
	}

	selectOpcao(opcao_id) {
		const { opcoes_selecionadas } = this.state;
		const _opcoes_selecionadas = opcoes_selecionadas.filter(o => o === opcao_id).length === 0
			? [...opcoes_selecionadas, opcao_id]
			: opcoes_selecionadas.filter(o => o !== opcao_id);
		this.setState({ opcoes_selecionadas: _opcoes_selecionadas });
	}

	handleOutroMotivo(e) {
		const outro_motivo = e.target.value;
		this.setState({ outro_motivo });
	}

	enviaNaoTenhoInteresse() {
		this.setState({
			enviando_sem_interesse: 1,
			confirm_nao_possui_todos_itens: false,
		});
		const { rc_id, history } = this.props;
		const { opcoes_selecionadas, outro_motivo } = this.state;
		const params = {
			codigos_recusa: opcoes_selecionadas,
			outro_motivo_recusa: outro_motivo,
		};
		_post(`/conaz/v2/rcs/${rc_id}/sem_interesse`, params).then(() => {
			this.setState({ enviando_sem_interesse: 2 });
			setTimeout(() => history.push('/solicitacoes'), 5000);
		}).catch(() => {
			this.setState({ enviando_sem_interesse: 3 });
		});
	}

	preEnvioSemInteresse() {
		const { nao_tem_itens } = this.props;
		const { opcoes_selecionadas } = this.state;
		if (!nao_tem_itens && opcoes_selecionadas.length === 1 && opcoes_selecionadas.filter(op => op === 0).length === 1) {
			this.setState({ confirm_nao_possui_todos_itens: true });
		} else {
			this.enviaNaoTenhoInteresse();
		}
	}

	render() {
		const {
			outro_motivo_selecionado,
			opcoes_sem_interesse,
			opcoes_selecionadas,
			outro_motivo,
			enviando_sem_interesse,
			confirm_nao_possui_todos_itens,
		} = this.state;
		const { updateModals } = this.props;

		const habilita_botao = (opcoes_selecionadas.length > 0 && !outro_motivo_selecionado) || (outro_motivo_selecionado && outro_motivo !== '');

		if (enviando_sem_interesse === 2) {
			return (
				<div className="modal-content">
					<div className="modal-header" styleName="modal-header">
						<h4 className="modal-title">Resposta enviada com sucesso!</h4>
					</div>
					<div className="modal-body" styleName="modal-body">
						<div styleName="success-wrapper">
							<i className="fa fa-check" aria-hidden="true" />
							Você será redirecionado para sua lista de cotações em poucos segundos...
						</div>
					</div>
				</div>
			);
		}

		if (confirm_nao_possui_todos_itens) {
			return (
				<div className="modal-content">
					<div className="modal-header" styleName="modal-header">
						<h4 className="modal-title">Aviso</h4>
					</div>
					<div className="modal-body" styleName="modal-body">
						<p>Você sabia que muitas vezes os construtores compram com fornecedores que não possuem todos os itens? Tem certeza que prefere não participar da cotação?</p>
						<button
							type="button"
							className="btn btn-primary older-button small"
							style={{ marginRight: '20px', marginBottom: '15px' }}
							onClick={this.enviaNaoTenhoInteresse}>
							Prefiro não participar
						</button>
						<button
							type="button"
							className="btn btn-default small"
							style={{ marginBottom: '15px' }}
							onClick={() => updateModals('sem_interesse', false)}>
							Quero participar
						</button>
					</div>
				</div>
			);
		}

		return (
			<div className="modal-content">
				<div className="modal-header" styleName="modal-header">
					<button
						type="button"
						className="close"
						styleName="close-button"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => updateModals('sem_interesse', false)}>
						<span aria-hidden="true">&times;</span>
					</button>
					<h4 className="modal-title">Informe ao construtor o motivo de não participar</h4>
				</div>
				<div className="modal-body" styleName="modal-body">
					{opcoes_sem_interesse.map(opcao => (
						<button
							key={opcao.id}
							type="button"
							styleName={opcoes_selecionadas.filter(o => o === opcao.id).length > 0 ? 'checkbox-button selected' : 'checkbox-button'}
							onClick={() => this.selectOpcao(opcao.id)}>
							<i className="fa fa-check-circle" styleName="check" />
							<i className="fa fa-circle" styleName="circle" />
							{opcao.mensagem}
						</button>
					))}
					<button
						type="button"
						styleName={outro_motivo_selecionado ? 'checkbox-button selected' : 'checkbox-button'}
						onClick={() => this.setState({ outro_motivo_selecionado: !outro_motivo_selecionado })}>
						<i className="fa fa-check-circle" styleName="check" />
						<i className="fa fa-circle" styleName="circle" />
						Outro
					</button>
					{outro_motivo_selecionado && (
						<textarea
							className="form-control"
							rows="2"
							value={outro_motivo}
							onChange={this.handleOutroMotivo} />
					)}
					<div styleName="button-wrapper">
						{enviando_sem_interesse === 3 && (
							<div className="alert alert-danger" role="alert" style={{ margin: '0 auto 20px', textAlign: 'center' }}>
								<i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" /> Erro ao enviar resposta. Clique em enviar e tente novamente.
							</div>
						)}
						<button
							type="button"
							className="btn btn-primary btn-block older-button small"
							disabled={enviando_sem_interesse === 1 || !habilita_botao}
							style={{ opacity: enviando_sem_interesse === 1 || !habilita_botao ? '0.5' : '1' }}
							onClick={this.preEnvioSemInteresse}>
							{enviando_sem_interesse === 1 ? 'Enviando' : 'Enviar'}
						</button>
					</div>
				</div>
			</div>
		);
	}
}

ModalSemInteresse.propTypes = {
	updateModals: PropTypes.func,
	rc_id: PropTypes.string.isRequired,
	history: PropTypes.object.isRequired,
	nao_tem_itens: PropTypes.bool,
};

ModalSemInteresse.defaultProps = {
	updateModals: () => {},
	nao_tem_itens: false,
};

export default CSSModules(ModalSemInteresse, styles, { allowMultiple: true });
