import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from '../style.module.scss';

import CotacaoRespondida from './CotacaoRespondida';
import CotacaoSemInteresse from './CotacaoSemInteresse';
import BotaoAtualizaRC from './BotaoAtualizaRC';

const InfoCotacao = ({
	formas_pagamento,
	prazos_pagamento_full,
	confirma_edicao,
	resetando,
	resetando_error,
	tooltip_atualizar,
	transporte_opcoes,
	confirmaEdicao,
	resetaCotacao,
	showTooltip,
	hideTooltip,
	observacoes,
	sub_total,
	preco_total,
	codigo_do_fornecedor,
	condicoes_de_pagamento,
	valor_transporte,
	prazo_de_entrega,
	custos_adicionais,
	data_resposta_view,
	hora_resposta_view,
	validade_view,
	anexos_cotacao,
	status_pedido,
	status,
	transporte,
	transporte_proprio,
	motivos_recusa,
	cotacao_info_collapsed,
	expandInfo,
	pedido_cancelado,
	compra,
}) => (
	<div className="row">
		<div className="col-sm-12">
			<div styleName="review-block no-pad2" style={{ minHeight: '40px' }}>
				<div id="info_cotacao_title_onboarding" styleName="info-title">
					Informações gerais da Cotação
					<BotaoAtualizaRC
						status_rc={status}
						status_pedido={status_pedido}
						pedido_cancelado={pedido_cancelado}
						confirma_edicao={confirma_edicao}
						tooltip_atualizar={tooltip_atualizar}
						compra={compra}
						showTooltip={showTooltip}
						hideTooltip={hideTooltip}
						confirmaEdicao={confirmaEdicao} />
				</div>
				{!cotacao_info_collapsed ? (
					<React.Fragment>
						<div styleName="content">
							{(confirma_edicao && (status === 40 || status === -20) && (status_pedido === 40 || status_pedido === 45 || status_pedido === 50)) && (
								<div className="row">
									<div className="col-xs-12" styleName="content-block">
										<p style={{ fontSize: '14px' }}><i className="fa fa-exclamation-circle" style={{ fontSize: '16px', marginRight: '3px', color: '#afc1cc' }} aria-hidden="true" /> Sua cotação poderá ser editada novamente e não perderá nenhuma informação já preenchida, mas ela ficará indisponível para o construtor até ser reenviada novamente. Tem certeza que deseja realizar esta ação?</p>
										{resetando_error && <div className="alert alert-danger" role="alert">Ops... Aconteceu algo errado. Tente novamente.</div>}
										{!resetando ? (
											<p>
												<button type="button" className="btn btn-primary" style={{ padding: '9px 12px 9px', marginRight: '10px' }} onClick={resetaCotacao}>Sim</button>
												<button type="button" className="btn btn-default" style={{ padding: '9px 12px 9px' }} onClick={() => confirmaEdicao(false)}>Cancelar</button>
											</p>
										) : (
											<button type="button" className="btn btn-default" style={{ padding: '9px 12px 9px' }}>Aguarde...</button>
										)}
									</div>
									<div className="col-xs-12">
										<hr styleName="hr" style={{ marginTop: '30px', marginBottom: '60px' }} />
									</div>
								</div>
							)}
							{status === -20 ? (
								<CotacaoSemInteresse
									motivos_recusa={motivos_recusa} />
							) : (
								<CotacaoRespondida
									formas_pagamento={formas_pagamento}
									prazos_pagamento_full={prazos_pagamento_full}
									transporte_opcoes={transporte_opcoes}
									observacoes={observacoes}
									sub_total={sub_total}
									preco_total={preco_total}
									codigo_do_fornecedor={codigo_do_fornecedor}
									condicoes_de_pagamento={condicoes_de_pagamento}
									valor_transporte={valor_transporte}
									prazo_de_entrega={prazo_de_entrega}
									custos_adicionais={custos_adicionais}
									data_resposta_view={data_resposta_view}
									hora_resposta_view={hora_resposta_view}
									validade_view={validade_view}
									anexos_cotacao={anexos_cotacao}
									status={status}
									transporte={transporte}
									transporte_proprio={transporte_proprio}
									pedido_cancelado={pedido_cancelado}
									compra={compra} />
							)}
						</div>
						<button
							type="button"
							styleName="content-to-expand to-collapse"
							onClick={() => expandInfo('cotacao_info_collapsed')}>
							<i className="fa fa-angle-up fa-fw" aria-hidden="true" />
						</button>
					</React.Fragment>
				) : (
					<button
						type="button"
						styleName="content-to-expand"
						onClick={() => expandInfo('cotacao_info_collapsed')}>
						<i className="fa fa-angle-down fa-fw" aria-hidden="true" /> Expandir informações <i className="fa fa-angle-down fa-fw" aria-hidden="true" />
					</button>
				)}
			</div>
		</div>
	</div>
);

InfoCotacao.propTypes = {
	// =========== local
	confirma_edicao: PropTypes.bool.isRequired,
	resetando: PropTypes.bool.isRequired,
	resetando_error: PropTypes.bool.isRequired,
	transporte_opcoes: PropTypes.array.isRequired,
	tooltip_atualizar: PropTypes.bool.isRequired,
	// =========== local funcs
	confirmaEdicao: PropTypes.func.isRequired,
	resetaCotacao: PropTypes.func.isRequired,
	showTooltip: PropTypes.func.isRequired,
	hideTooltip: PropTypes.func.isRequired,
	motivos_recusa: PropTypes.array.isRequired,
	status: PropTypes.number.isRequired,
	transporte: PropTypes.number,
	transporte_proprio: PropTypes.number,
	status_pedido: PropTypes.number.isRequired,
	anexos_cotacao: PropTypes.array.isRequired,
	validade_view: PropTypes.string.isRequired,
	hora_resposta_view: PropTypes.string.isRequired,
	data_resposta_view: PropTypes.string.isRequired,
	custos_adicionais: PropTypes.number.isRequired,
	prazo_de_entrega: PropTypes.number,
	valor_transporte: PropTypes.number.isRequired,
	codigo_do_fornecedor: PropTypes.string.isRequired,
	condicoes_de_pagamento: PropTypes.array.isRequired,
	sub_total: PropTypes.number.isRequired,
	preco_total: PropTypes.number.isRequired,
	observacoes: PropTypes.string.isRequired,
	prazos_pagamento_full: PropTypes.object.isRequired,
	formas_pagamento: PropTypes.object.isRequired,
	cotacao_info_collapsed: PropTypes.bool.isRequired,
	expandInfo: PropTypes.func.isRequired,
	pedido_cancelado: PropTypes.bool.isRequired,
	compra: PropTypes.object.isRequired,
};

InfoCotacao.defaultProps = {
	transporte: null,
	transporte_proprio: null,
	prazo_de_entrega: null,
};

export default CSSModules(InfoCotacao, styles, { allowMultiple: true });
