import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const AnexosModal = ({ anexos, updateModals, tipo_anexo, nome_modal }) => (
	<div className="modal-content">
		<div className="modal-header" styleName="modal-header">
			<button
				type="button"
				className="close"
				data-dismiss="modal"
				aria-label="Close"
				onClick={() => updateModals(nome_modal, false)}>
				<span aria-hidden="true">&times;</span>
			</button>
			<h4 className="modal-title">{tipo_anexo === 'cliente' ? 'Anexos do Cliente' : 'Seus anexos'}</h4>
		</div>
		<div className="modal-body" styleName="modal-body">
			{anexos.map(anexo => (
				<div key={anexo.nome} styleName="file-wrapper">
					<i className="fa fa-file-text-o" aria-hidden="true" />
					<div styleName="file-name">{anexo.nome}</div>
				</div>
			))}
		</div>
	</div>
);

AnexosModal.propTypes = {
	anexos: PropTypes.array.isRequired,
	updateModals: PropTypes.func,
	tipo_anexo: PropTypes.string.isRequired,
	nome_modal: PropTypes.string.isRequired,
};

AnexosModal.defaultProps = {
	updateModals: () => {},
};

export default CSSModules(AnexosModal, styles, { allowMultiple: true });
