import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable */
// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const ModalObservacoesNormal = ({ closeModalObservacoes, observacoes }) => (
	<div className="modal-content" styleName="modal-content">
		<div className="modal-body" styleName="modal-body">
			<h3>Observações importantes</h3>
			<p>{observacoes}</p>
			<button
				type="button"
				className="btn btn-primary older-button small"
				styleName="entendi-button"
				onClick={closeModalObservacoes}>
				Entendi!
			</button>
		</div>
	</div>
);

ModalObservacoesNormal.propTypes = {
	updateModals: PropTypes.func,
	closeModalObservacoes: PropTypes.func.isRequired,
	observacoes: PropTypes.string.isRequired,
};

ModalObservacoesNormal.defaultProps = {
	updateModals: () => {},
};

export default CSSModules(ModalObservacoesNormal, styles, { allowMultiple: true });
