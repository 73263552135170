import update from 'immutability-helper';
import cadastro from '../store/cadastro';
import { UPDATE_CADASTRO, RESET_CADASTRO } from '../actions/action.types';

const reducer = (state = cadastro, action) => {
	switch (action.type) {
	case UPDATE_CADASTRO:
		switch (action.field.length) {
		case 1:
			return update(state, {
				[action.field[0]]: { $set: action.value },
			});
		case 2:
			return update(state, {
				[action.field[0]]: {
					[action.field[1]]: { $set: action.value },
				},
			});
		default:
			return state;
		}

	case RESET_CADASTRO:
		return cadastro;

	default:
		return state;
	}
};

export default reducer;
