import React from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'moment/locale/pt-br';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import ModalWrapper from '../../_parts/_modals/ModalWrapper/ModalWrapper';
import ModalUpload from '../../_parts/_modals/ModalUpload/ModalUpload';
import HeaderCotacao from '../../_parts/HeaderCotacao/HeaderCotacao';
import InfoClienteCotacao from '../../_parts/InfoClienteCotacao/InfoClienteCotacao';
import InputText from '../../_parts/_inputs/InputText/InputText';
import InputNumber from '../../_parts/_inputs/InputNumber/InputNumber';
import InputNumberDisabled from '../../_parts/_inputs/InputNumber/InputNumberDisabled';
import InputTextArea from '../../_parts/_inputs/InputTextArea/InputTextArea';
import InputSelectGeral from '../../_parts/_inputs/InputSelectGeral/InputSelectGeral';
// import LoadingButton from '../../_parts/_loadings/LoadingButton/LoadingButton';
import LeftModalWrapper from '../../_parts/_modals/LeftModalWrapper/LeftModalWrapper';
import LeftModalConstrutorPerfil from '../../_parts/_modals/LeftModalConstrutorPerfil/LeftModalConstrutorPerfil';
import CondicoesPagamento2 from '../../_parts/CondicoesPagamento2/CondicoesPagamento2';
import Stepbar from '../../_parts/Stepbar';
import StepbarMobile from '../../_parts/Stepbar/StepbarMobile';
import EnviarRCmobile from '../../_parts/Stepbar/EnviarRCmobile';

// Functions
import { isWeekday } from '../../_functions/_isWeekday';

const DadosFinais = ({
	/* state */
	tab_perfil_construtor,
	new_saving,
	/* props */
	ui,
	cotacao,
	history,
	width,
	/* propf */
	updateModals,
	updateCotacao,
	/* funcs */
	handleValidade,
	autoSaveRcTimeout,
	openPerfilConstrutor,
	handleChange,
	handleTransporte,
	forceAutoSave,
}) => {
	const { modals } = ui;
	const { rc, itens_all_ids, itens_by_id } = cotacao;

	const valor_final_itens = itens_all_ids.reduce((r, c) => {
		const item = itens_by_id[c];
		const quantidade = item.quantidade || 0;
		const preco_unitario = item.preco_unitario || 0;
		// sub_total
		const sub_total = quantidade * preco_unitario;
		// desconto
		const desconto = item.desconto || 0;
		const valor_desconto = (sub_total / 100) * desconto;
		// total
		const total = (sub_total - valor_desconto) || 0;
		return r + total;
	}, 0);
	const valor_final = valor_final_itens + rc.custos_adicionais;
	const blank_validade = rc.validade.format('YYYY-MM-DD') === '2016-01-01';

	const options_view = [
		{ id: 0, valor: 'Frete incluso no preço (CIF)' },
		{ id: 1, valor: 'Frete a pagar' },
		{ id: 2, valor: 'Frete por conta do cliente' },
	];

	const default_option = options_view.find(option => option.id === rc.transporte) || { valor: '' };
	const is_mobile = width <= 1020;

	return (
		<>
			<ModalWrapper visible={modals.anexos_cliente} updateModals={updateModals}>
				<ModalUpload
					rc_id={rc.uuid}
					anexos={rc.anexos_pedido}
					updateCotacao={updateCotacao}
					modal_to_close="anexos_cliente"
					edit={false}
				/>
			</ModalWrapper>
			<LeftModalWrapper
				visible={modals.perfil_construtor}
				updateModals={updateModals}
				modal="perfil_construtor"
			>
				<LeftModalConstrutorPerfil
					exibir_contatos_pedido={rc.pedido.exibir_contato}
					usuario_pedido={
						rc.pedido.aprovacao_pedida_por.id !== undefined
							? rc.pedido.aprovacao_pedida_por
							: rc.pedido.usuario
					}
					obra={rc.pedido.obra}
					tab={tab_perfil_construtor}
				/>
			</LeftModalWrapper>
			{is_mobile && (
				<StepbarMobile
					cotacao={cotacao}
					history={history}
					title="2. Condições de proposta"
					new_saving={new_saving}
					updateModals={updateModals}
					modals={modals}
				/>
			)}
			<HeaderCotacao
				pedido_id={rc.pedido.id}
				data_envio={rc.data_envio}
				prazo_de_resposta={rc.pedido.prazo_de_resposta}
				data_de_entrega_desejada={rc.pedido.data_de_entrega_desejada}
			/>

			<InfoClienteCotacao
				obra={rc.pedido.obra}
				data_de_entrega_desejada={rc.pedido.data_de_entrega_desejada}
				observacoes={rc.pedido.observacoes_conaz}
				anexos={rc.anexos_pedido}
				updateModals={updateModals}
				openPerfilConstrutor={openPerfilConstrutor}
			/>
			<div className="container">
				{!is_mobile && (
					<Stepbar
						cotacao={cotacao}
						history={history}
						new_saving={new_saving}
						updateModals={updateModals}
						forceAutoSave={forceAutoSave}
					/>
				)}
				<div className="row">
					<div className="col-xs-12">
						<div styleName="item-row-top">
							<div styleName="nome">Dados da proposta</div>
						</div>
						<div styleName="item-row-bottom">
							<div className="row" styleName="row-space">
								<div className="col-xs-12 col-lg-6">
									<div
										id="tooltip_validade"
										className="tooltip top"
										role="tooltip"
										style={{ marginTop: '-34px' }}
									>
										<div
											className="tooltip-arrow"
											style={{ borderTopColor: '#ee2622', left: '10%' }}
										/>
										<div className="tooltip-inner" style={{ backgroundColor: '#ee2622' }}>
											Este campo é obrigatório
										</div>
									</div>
									<label>
										Validade da proposta <span className="obrigatorio-label">*</span>
									</label>
									<div className="form-control-date-wrapper">
										<i className="fa fa-calendar" aria-hidden="true" />
										<DatePicker
											id="validade"
											className="form-control-date"
											selected={blank_validade ? null : rc.validade}
											onChange={handleValidade}
											onBlur={handleValidade}
											filterDate={isWeekday}
											minDate={moment()}
											peekNextMonth
											showMonthDropdown
											showYearDropdown
											dropdownMode="select"
											locale="pt-br"
											popperPlacement="top-start"
											popperModifiers={{
												offset: { enabled: true, offset: '0, 0' },
												preventOverflow: {
													enabled: true,
													escapeWithReference: false,
													boundariesElement: 'viewport',
												},
											}}
										/>
									</div>
								</div>
								<div className="col-xs-12 col-lg-6">
									<label>
										Código da sua proposta <span styleName="label-optional">(opcional)</span>
									</label>
									<InputText
										type="cotacao"
										field={['rc', 'codigo_do_fornecedor']}
										default_value={rc.codigo_do_fornecedor}
										updateFunction={updateCotacao}
										autoSaveTimeout={autoSaveRcTimeout}
									/>
								</div>
							</div>

							<div className="row" styleName="row-space">
								<div className="col-xs-12 col-md-3">
									<div
										id="tooltip_transporte"
										className="tooltip top"
										role="tooltip"
										style={{ marginTop: '-34px' }}
									>
										<div
											className="tooltip-arrow"
											style={{ borderTopColor: '#ee2622', left: '10%' }}
										/>
										<div className="tooltip-inner" style={{ backgroundColor: '#ee2622' }}>
											Este campo é obrigatório
										</div>
									</div>
									<label>
										Opções de frete e transporte <span className="obrigatorio-label">*</span>
									</label>
									<InputSelectGeral
										field="transporte"
										default_value={default_option.valor}
										opcoes={options_view}
										updateFunction={handleTransporte}
										has_outro={false}
									/>
								</div>

								<div className="col-xs-12 col-md-9" styleName="transporte-options">
									{(rc.transporte === 0 || rc.transporte === 1) && (
										<label styleName="t-label">
											<div
												id="tooltip_prazo_de_entrega"
												className="tooltip top"
												role="tooltip"
												style={{ margin: '-46px 0 0 129px' }}
											>
												<div
													className="tooltip-arrow"
													style={{ borderTopColor: '#ee2622', left: '10%' }}
												/>
												<div className="tooltip-inner" style={{ backgroundColor: '#ee2622' }}>
													Este campo é obrigatório
												</div>
											</div>
											<span>Prazo de entrega</span>
											<span className="obrigatorio-label">*</span>{' '}
											{rc.transporte_proprio === 2 ? (
												<input type="text" className="form-control input-md" disabled />
											) : (
												<InputNumber
													type="cotacao"
													field_id="prazo_de_entrega"
													field={['rc', 'prazo_de_entrega']}
													default_value={rc.prazo_de_entrega}
													updateFunction={updateCotacao}
													autoSaveTimeout={autoSaveRcTimeout}
												/>
											)}{' '}
											<span>dias úteis</span>
										</label>
									)}
									{rc.transporte === 2 && (
										<label styleName="t-label">
											<div
												id="tooltip_prazo_de_entrega"
												className="tooltip top"
												role="tooltip"
												style={{ margin: '-46px 0 0 209px' }}
											>
												<div
													className="tooltip-arrow"
													style={{ borderTopColor: '#ee2622', left: '10%' }}
												/>
												<div className="tooltip-inner" style={{ backgroundColor: '#ee2622' }}>
													Este campo é obrigatório
												</div>
											</div>
											<span>Prazo disponível para retirada</span>
											<span className="obrigatorio-label">*</span>{' '}
											<InputNumber
												type="cotacao"
												field_id="prazo_de_entrega"
												field={['rc', 'prazo_de_entrega']}
												default_value={rc.prazo_de_entrega}
												updateFunction={updateCotacao}
												autoSaveTimeout={autoSaveRcTimeout}
											/>{' '}
											<span>dias úteis</span>
										</label>
									)}
								</div>
							</div>
							{rc.transporte === 1 && (
								<div className="row" styleName="row-space">
									<div className="col-xs-12 col-md-4">
										<div
											id="tooltip_valor_transporte"
											className="tooltip top"
											role="tooltip"
											style={{ margin: '-35px 0 0 0' }}
										>
											<div
												className="tooltip-arrow"
												style={{ borderTopColor: '#ee2622', left: '10%' }}
											/>
											<div className="tooltip-inner" style={{ backgroundColor: '#ee2622' }}>
												Este campo é obrigatório
											</div>
										</div>
										<label>Valor</label> <span className="obrigatorio-label">*</span>
										{rc.transporte_proprio === 2 ? (
											<InputNumberDisabled currency />
										) : (
											<InputNumber
												type="cotacao"
												field_id="valor_transporte"
												field={['rc', 'valor_transporte']}
												default_value={rc.valor_transporte}
												updateFunction={updateCotacao}
												currency
												autoSaveTimeout={autoSaveRcTimeout}
											/>
										)}{' '}
									</div>
									<div styleName="radio-row" className="col-xs-12 col-md-8">
										<div
											id="tooltip_transporte_proprio"
											className="tooltip top"
											role="tooltip"
											style={{ margin: '-45px 0 0 0' }}
										>
											<div
												className="tooltip-arrow"
												style={{ borderTopColor: '#ee2622', left: '10%' }}
											/>
											<div className="tooltip-inner" style={{ backgroundColor: '#ee2622' }}>
												Este campo é obrigatório
											</div>
										</div>
										<label styleName="container">
											Próprio
											<input
												className="form-check-input"
												type="radio"
												styleName="frete_opcao"
												name="transporte_proprio"
												checked={rc.transporte_proprio === 0}
												onChange={handleChange}
												value={0}
											/>
											<span styleName="checkmark" />
										</label>
										<label styleName="container">
											Terceirizado
											<input
												className="form-check-input"
												type="radio"
												styleName="frete_opcao"
												name="transporte_proprio"
												checked={rc.transporte_proprio === 1}
												onChange={handleChange}
												value={1}
											/>
											<span styleName="checkmark" />
										</label>
										<label styleName="container">
											A Combinar
											<input
												className="form-check-input"
												type="radio"
												name="transporte_proprio"
												styleName="frete_opcao"
												checked={rc.transporte_proprio === 2}
												onChange={handleChange}
												value={2}
											/>
											<span styleName="checkmark" />
										</label>
									</div>
								</div>
							)}
							<div className="row" styleName="row-space">
								<div className="col-xs-12">
									<label>
										Observações gerais <span styleName="label-optional">(opcional)</span>
									</label>
									<InputTextArea
										type="cotacao"
										field={['rc', 'observacoes']}
										default_value={rc.observacoes}
										updateFunction={updateCotacao}
										autoSaveTimeout={autoSaveRcTimeout}
									/>
								</div>
							</div>

							<div className="row" styleName="row-space">
								<div className="col-xs-12 col-lg-5">
									<label>
										Impostos / Adicionais <span styleName="label-optional">(opcional)</span>
									</label>
									<InputNumber
										type="cotacao"
										field={['rc', 'custos_adicionais']}
										default_value={rc.custos_adicionais}
										updateFunction={updateCotacao}
										autoSaveTimeout={autoSaveRcTimeout}
										currency
									/>
								</div>
							</div>

							<CondicoesPagamento2
								condicoes_de_pagamento={rc.condicoes_de_pagamento}
								condicoes_de_pagamento_by_id={rc.condicoes_de_pagamento_by_id}
								updateCotacao={updateCotacao}
								autoSaveRcTimeout={autoSaveRcTimeout}
								valor_final_itens={valor_final_itens}
								custos_adicionais={rc.custos_adicionais}
								valor_final={valor_final}
								valor_transporte={rc.valor_transporte}
								condicao_de_preferencia={rc.pedido.condicao_de_preferencia}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-xs-12">
							<p>
								<br />
							</p>
							<p>
								<br />
							</p>
							<p>
								<br />
							</p>
							<p>
								<br />
							</p>
							<p>
								<br />
							</p>
							<p>
								<br />
							</p>
						</div>
					</div>
				</div>
			</div>
			{is_mobile && (
				<EnviarRCmobile
					cotacao={cotacao}
					history={history}
					forceAutoSave={forceAutoSave}
				/>
			)}
		</>
	);
};

DadosFinais.propTypes = {
	// =========== local
	tab_perfil_construtor: PropTypes.number.isRequired,
	new_saving: PropTypes.number.isRequired,
	// =========== local funcs
	handleValidade: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleTransporte: PropTypes.func.isRequired,
	autoSaveRcTimeout: PropTypes.func.isRequired,
	openPerfilConstrutor: PropTypes.func.isRequired,
	forceAutoSave: PropTypes.func.isRequired,
	// =========== store
	ui: PropTypes.object.isRequired,
	width: PropTypes.number.isRequired,
	cotacao: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	// =========== funcs
	updateModals: PropTypes.func.isRequired,
	updateCotacao: PropTypes.func.isRequired,
};

export default CSSModules(DadosFinais, styles, { allowMultiple: true });
