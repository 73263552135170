import React from 'react';
import PropTypes from 'prop-types';

import Loading from '../../_parts/_loadings/Loading/Loading';
import { logarComoUsuario } from '../../../services/login_manager';


class LogarComoUsuario extends React.Component {
	constructor(props) {
		super(props);
		this.state = { request_state: 1 };
	}

	componentDidMount() {
		const { token } = this.props.match.params;
		logarComoUsuario(token)
			.then(() => {
				this.props.history.push('/solicitacoes');
			})
			.catch(error => {
				console.log(error); // eslint-disable-line
				this.setState({ request_state: 3 });
				window.alert('Não foi possível completar sua solicitação'); // eslint-disable-line
			});
	}

	render() {
		return this.state.request_state === 1 ? <Loading /> : null;
	}
}

LogarComoUsuario.propTypes = {
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};

export default LogarComoUsuario;
