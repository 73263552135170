import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from '../style.module.scss';

// Functions
import { setDecimalsToNumber, setDecimalsToString } from '../../../_functions/_numbers';
import { clearItensAlerts } from '../../../_functions/_clearItensAlerts';

class InputNumber extends Component {
	state = {
		value: '',
	};

	UNSAFE_componentWillMount() {
		this.handleFirstValue(this.props.default_value);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { value } = this.state;
		if (
			(
				value === ''
				&& nextProps.default_value > 0
			) || (
				(value === '0' || value === '0,')
				&& nextProps.default_value > 0
			)
		) {
			this.handleFirstValue(nextProps.default_value);
		}
	}

	handleFirstValue(default_value) {
		const value = default_value !== 0
			? setDecimalsToString(default_value)
			: '';
		this.setState({ value });
	}

	handleEdition = (e) => {
		const number_values = setDecimalsToNumber(e.target.value); // numeros
		const props_value = number_values[0]; // numero
		const state_value = setDecimalsToString(number_values[1]); // string
		this.setState(
			{ value: state_value },
			this.updateRedux(props_value),
		);
	}

	updateRedux(props_value) {
		const {
			type,
			item_id,
			field,
			opcao_preenchida,
			updateFunction,
			autoSaveTimeout,
		} = this.props;

		switch (type) {
		case 'cotacao': {
			updateFunction(field, props_value);
			break;
		}
		case 'item': {
			updateFunction(item_id, field, props_value);
			break;
		}
		case 'caracteristica': {
			const _opcao_preenchida = { ...opcao_preenchida, valor: props_value };
			updateFunction(item_id, field, [_opcao_preenchida]);
			break;
		}
		default:
		}

		autoSaveTimeout(item_id);
		clearItensAlerts();
	}

	checkOutro = () => {
		if (this.props.outro && this.state.value === '') {
			this.props.handleOutro();
		}
	}

	render() {
		const { value } = this.state;
		const { field_id, disabled, currency, currency_icon } = this.props;

		return (
			<>
				{!currency ? (
					<input
						id={field_id}
						type="text"
						className="form-control input-md"
						disabled={disabled}
						value={value}
						onChange={this.handleEdition}
						onBlur={this.checkOutro} />
				) : (
					<div styleName="currency-wrapper">
						<input
							id={field_id}
							type="text"
							className="form-control input-md"
							disabled={disabled}
							value={value}
							onChange={this.handleEdition} />
						<div styleName="placeholder">{currency_icon}</div>
					</div>
				)}
			</>
		);
	}
}

InputNumber.propTypes = {
	type: PropTypes.string.isRequired,
	field_id: PropTypes.string,
	item_id: PropTypes.number,
	field: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.array,
	]).isRequired,
	default_value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	opcao_preenchida: PropTypes.object,
	updateFunction: PropTypes.func,
	autoSaveTimeout: PropTypes.func,
	outro: PropTypes.bool,
	handleOutro: PropTypes.func,
	disabled: PropTypes.bool,
	currency: PropTypes.bool,
	currency_icon: PropTypes.string,
};

InputNumber.defaultProps = {
	field_id: '',
	item_id: -1,
	default_value: 0,
	opcao_preenchida: {},
	updateFunction: () => {},
	autoSaveTimeout: () => {},
	outro: false,
	handleOutro: () => { },
	disabled: false,
	currency: false,
	currency_icon: 'R$',
};

export default CSSModules(InputNumber, styles, { allowMultiple: true });
