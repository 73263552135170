import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const Header = ({ title }) => (
	<div styleName="header-bar-wrapper">
		<div className="container">
			<div className="row">
				<div className="col-xs-12">
					<h3 styleName="header-position">{title}</h3>
				</div>
			</div>
		</div>
	</div>
);


Header.propTypes = {
	title: PropTypes.string.isRequired,
};

export default CSSModules(Header, styles, { allowMultiple: true });
