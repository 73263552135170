import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as actions from '../../../actions/index';

// Components
import withLoadCotacao from '../withLoadCotacao';
import Wrapper from '../../Wrapper/Wrapper';
import IdentificacaoVendedor from './IdentificacaoVendedor';

// Functions
import { get as getRC } from '../../../services/apis/rcs';
import { verifyCnpjFornecedor as verifyCnpj } from '../../../services/apis/fornecedores';
import { isAuthenticated, login } from '../../../services/login_manager';
import { _post } from '../../../services/apis/requests';
import { validaItens } from '../../_functions/_validaItens';
import { validaInfo } from '../../_functions/_validaInfo';

class IdentificacaoVendedorContainer extends Component {
	static validadeItens(cotacao) {
		const validacao_itens = validaItens(cotacao, 'itens');
		if (validacao_itens === 0 || validacao_itens === -1) {
			return false;
		}
		return true;
	}

	static validadeInfo(cotacao) {
		const validacao_info = validaInfo(cotacao.rc);
		if (validacao_info !== 1) {
			return false;
		}
		return true;
	}

	state = {
		tab_perfil_construtor: 0,
		request_state: 0,
		request_state_login: 0,
		email: '',
		senha: '',
		cnpj: '',
		errorMsg: '',
		validation_errors: {
			cnpj: false,
			cnpj_cadastrado: false,
			cnpj_invalido: false,
		},
	};

	UNSAFE_componentWillMount() {
		window.scroll(0, 0);
		const { match, history, cotacao } = this.props;

		const { id } = match.params;
		if (isAuthenticated()) {
			history.push(`/cotacao/${id}/revisao`);
		}

		if (cotacao.rc.uuid !== undefined) {
			this.finalizaPreenchimento(cotacao);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { cotacao } = nextProps;
		if (cotacao.rc.uuid !== undefined) {
			this.finalizaPreenchimento(cotacao);
		}
	}

	buildResponse = (responses) => {
		const { handleResponsesHoc, history, updateCotacao } = this.props;
		handleResponsesHoc(responses, history, updateCotacao);
	}

	finalizaPreenchimento(cotacao) {
		const { updateCotacao } = this.props;
		const status = (cotacao.rc || {}).status || 0;
		const uuid = (cotacao.rc || {}).uuid || null;
		const validade_itens = this.constructor.validadeItens(cotacao);
		const validade_info = this.constructor.validadeInfo(cotacao);

		if (
			validade_itens
			&& validade_info
			&& status <= 10
			&& uuid !== null
		) {
			_post(`/conaz/v2/rcs/${uuid}/finalizacao_preenchimento`, {})
				.then(() => {
					updateCotacao(['rc', 'status'], 30);
				});
		}
	}

	handleEditionDocumento = (field, value) => {
		this.setState({ cnpj: value });
		const validation_new = { cnpj: false, cnpj_cadastrado: false, cnpj_invalido: false };
		this.setState({ request_state: 0, validation_errors: validation_new });
	}

	handleEditionLogin = (e) => {
		const campo = e.target.name;
		this.setState({ [campo]: e.target.value, request_state_login: 0 });
	}

	handleSubmitLogin = (e) => {
		e.preventDefault();
		const { email, senha } = this.state;
		this.setState({ request_state: 1 });

		login(email, senha)
			.then(() => {
				const { id } = this.props.match.params;
				this.props.history.push(`/cotacao/${id}/empresa?auto_confirm=true`);
			})
			.catch(error => {
				this.setState({ request_state: 0 });

				if (!error.response || error.response.status === 500) {
					this.setState({ errorMsg: 'Ops... aconteceu algo errado.' });
				} else {
					this.setState({ errorMsg: error.response.data.message });
				}
			});
	}

	handleSubmit = (e) => {
		e.preventDefault();
		this.setState({ request_state: 1 });
		const {
			resetCadastro,
			updateCadastro,
			match,
			history,
		} = this.props;

		let { cnpj } = this.state;
		cnpj = cnpj || '';

		if (cnpj === '' || cnpj.substring(0, 18).replace(/[^\d]+/g, '').length < 14) {
			const validation_new = { cnpj: false, cnpj_cadastrado: false, cnpj_invalido: true };
			this.setState({ validation_errors: validation_new, request_state: 0 });
			window.scrollTo(0, 100);
			return;
		}

		resetCadastro();
		updateCadastro(['documento'], cnpj);

		setTimeout(() => {
			const limpaCNPJ = cnpj.substring(0, 18).replace(/[^\d]+/g, '');
			verifyCnpj({ cnpj: limpaCNPJ })
				.then(response => {
					this.setState({ request_state: 2 });

					let pathname;
					if (!_.isEmpty(response.data)) {
						const fornecedor = response.data;
						updateCadastro(['id'], fornecedor.id);
						updateCadastro(['nome'], fornecedor.nome);
						updateCadastro(['documento'], fornecedor.documento);
						updateCadastro(['site'], fornecedor.site);
						updateCadastro(['endereco'], fornecedor.endereco);
						updateCadastro(['usuarios'], fornecedor.usuarios);
						updateCadastro(['status'], fornecedor.status);
						updateCadastro(['site'], fornecedor.site);
						pathname = fornecedor.status >= 0
							? '/cadastro/3'
							: '/cadastro/2';
					} else {
						updateCadastro(['documento'], limpaCNPJ);
						pathname = '/cadastro/2';
					}

					const { id } = match.params;
					history.push({
						pathname,
						search: `from=identificacao_vendedor&redirect_to=/cotacao/${id}/empresa?auto_confirm=true`,
					});
				})
				.catch(error => {
					console.log(error); // eslint-disable-line
					this.setState({ modal_error: true });
					window.scrollTo(0, 1000);
				});
		}, 60);
	}

	openPerfilConstrutor = (tab = 0) => {
		const { updateModals } = this.props;
		this.setState({ tab_perfil_construtor: tab });
		updateModals('perfil_construtor', true);
	}

	render() {
		const { match, cotacao } = this.props;
		const { id } = match.params;
		const loaded = (cotacao.rc || {}).uuid === id;

		return (
			<Wrapper
				type="cotacao"
				promises={[() => getRC(id)]}
				loaded={loaded}
				buildResponse={this.buildResponse}
				{...this.props}>

				<IdentificacaoVendedor
					{...this.state}
					{...this.props}
					handleSubmit={this.handleSubmit}
					handleSubmitLogin={this.handleSubmitLogin}
					handleEditionLogin={this.handleEditionLogin}
					handleEditionDocumento={this.handleEditionDocumento}
					openPerfilConstrutor={this.openPerfilConstrutor} />

			</Wrapper>
		);
	}
}

IdentificacaoVendedorContainer.propTypes = {
	// =========== store
	cadastro: PropTypes.object.isRequired,
	cotacao: PropTypes.object.isRequired,
	ui: PropTypes.object.isRequired,
	// =========== funcs
	updateCadastro: PropTypes.func.isRequired,
	updateCotacao: PropTypes.func.isRequired,
	updateUi: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	reportErrors: PropTypes.func.isRequired,
	resetCadastro: PropTypes.func.isRequired,
	// =========== router
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	// =========== hoc
	handleResponsesHoc: PropTypes.func.isRequired,
};

const mapStateToProps = props => ({
	cadastro: props.cadastro,
	cotacao: props.cotacao,
	ui: props.ui,
});

const mapDispatchToProps = dispatch => ({
	resetCadastro: () => dispatch(actions.resetCadastro()),
	updateCadastro: (field, value) => dispatch(actions.updateCadastro(field, value)),
	updateCotacao: (field, value) => dispatch(actions.updateCotacao(field, value)),
	updateUi: (field, value) => dispatch(actions.updateUi(field, value)),
	updateItem: (item_id, field, value) => dispatch(actions.updateItem(item_id, field, value)),
	updateItemCaracteristica: (item_id, key, value) => dispatch(actions.updateItemCaracteristica(item_id, key, value)),
	updateItemCaracteristicaUnidade: (item_id, key, value) => dispatch(actions.updateItemCaracteristicaUnidade(item_id, key, value)),
	updateModals: (modal, value) => dispatch(actions.updateModals(modal, value)),
	reportErrors: (page, error, codigo_pedido, full_error, test) => dispatch(actions.reportErrors(page, error, codigo_pedido, full_error, test)),
});

const _IdentificacaoVendedor = connect(mapStateToProps, mapDispatchToProps)(IdentificacaoVendedorContainer);
export default withLoadCotacao(_IdentificacaoVendedor);
