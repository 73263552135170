import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from '../style.module.scss';

// Components
import MultipleOption from './MultipleOption';
import LoadingButton from '../../_loadings/LoadingButton/LoadingButton';

// Functions
import { clearItensAlerts } from '../../../_functions/_clearItensAlerts';
import { setDecimalsToNumber, setDecimalsToString } from '../../../_functions/_numbers';
import { _post } from '../../../../services/apis/requests';

class InputMultiple extends Component {
	constructor() {
		super();
		this.state = {
			opcoes_by_id: {},
			opcoes_all_ids: [],
			opcoes_selecionadas: [],
			opcoes_selecionadas_view: '',
			outro_form: false,
			outro_value: '',
			outra_unidade_value: '',
			outra_unidade_error: false,
			enviando_outra_opcao: false,
		};
		this.handleEdition = this.handleEdition.bind(this);
		this.showOutroForm = this.showOutroForm.bind(this);
		this.hideOutroForm = this.hideOutroForm.bind(this);
		this.addOutro = this.addOutro.bind(this);
		this.handleOutroEdition = this.handleOutroEdition.bind(this);
		this.handleOutraUnidadeEdition = this.handleOutraUnidadeEdition.bind(this);
		this.saveToRedux = this.saveToRedux.bind(this);
	}

	UNSAFE_componentWillMount() {
		const { opcoes, opcoes_selecionadas } = this.props;
		this.setInitialValues(opcoes, opcoes_selecionadas);
	}

	setInitialValues(_opcoes, _opcoes_selecionadas) {
		const _opcoes_selecionadas_by_id = _opcoes_selecionadas.reduce((result, current) => ({ ...result, [current.id]: current }), {});
		const _opcoes_by_id = _opcoes.reduce((result, current) => ({ ...result, [current.id]: current }), {});
		const opcoes_by_id = { ..._opcoes_selecionadas_by_id, ..._opcoes_by_id };
		const opcoes_all_ids = Object.keys(opcoes_by_id).map(id => Number(id));
		const opcoes_selecionadas = Object.keys(_opcoes_selecionadas_by_id).map(id => Number(id));
		const opcoes_selecionadas_view = opcoes_selecionadas.map(id => `${opcoes_by_id[id].valor} ${opcoes_by_id[id].unidade}`).join(', ');
		this.setState({ opcoes_by_id, opcoes_all_ids, opcoes_selecionadas, opcoes_selecionadas_view });
	}

	handleEdition(opcao_id) {
		const { opcoes_by_id } = this.state;
		const opcoes_selecionadas = this.state.opcoes_selecionadas.filter(id => id !== opcao_id);
		if (this.state.opcoes_selecionadas.filter(id => id === opcao_id).length === 0) opcoes_selecionadas.push(opcao_id);
		// if (this.state.opcoes_selecionadas.findIndex(id => id === opcao_id) === -1) opcoes_selecionadas.push(opcao_id);
		const opcoes_selecionadas_view = opcoes_selecionadas.map(id => `${opcoes_by_id[id].valor} ${opcoes_by_id[id].unidade}`).join(', ');
		this.setState({ opcoes_selecionadas, opcoes_selecionadas_view }, this.saveToRedux);
	}

	saveToRedux() {
		const { opcoes_by_id, opcoes_selecionadas } = this.state;
		const { type, item_id, field, updateFunction, autoSaveTimeout } = this.props;
		const opcoes_preenchidas = opcoes_selecionadas.map(id => opcoes_by_id[id]);

		switch (type) {
		case 'caracteristica': {
			updateFunction(item_id, field, opcoes_preenchidas);
			break;
		}
		default:
		}

		autoSaveTimeout(item_id);
		clearItensAlerts();
	}

	showOutroForm() {
		this.setState({ outro_form: true });
		const { item_id, field } = this.props;
		setTimeout(() => document.getElementById(`outro_multiple_input_${item_id}_${field}`).focus(), 60);
	}

	hideOutroForm() {
		const { outro_value } = this.state;
		if (outro_value === '') this.setState({ outro_form: false });
	}

	// addOutro() {
	// 	const { outro_value } = this.state;
	// 	const opcoes_novas = this.state.opcoes_all_ids.filter(id => Number(id) < 100);
	// 	const next_id = Number(opcoes_novas.reduce((r, c) => (c > r ? c : r))) + 1;
	// 	const opcao_preenchida = buildOpcaoPreenchida(outro_value, '', next_id);
	// 	const opcoes_by_id = { ...this.state.opcoes_by_id, [next_id]: opcao_preenchida };
	// 	const opcoes_all_ids = [...this.state.opcoes_all_ids, next_id];
	// 	const opcoes_selecionadas = [...this.state.opcoes_selecionadas, next_id];
	// 	const opcoes_selecionadas_view = opcoes_selecionadas.map(id => `${opcoes_by_id[id].valor} ${opcoes_by_id[id].unidade}`).join(', ');
	// 	this.setState({ opcoes_by_id, opcoes_all_ids, opcoes_selecionadas, opcoes_selecionadas_view, outro_value: '', outro_form: false }, this.saveToRedux);
	// }

	addOutro() {
		const { outro_value, outra_unidade_value, opcoes_by_id, opcoes_all_ids } = this.state;
		const { field } = this.props;

		if (outro_value === '') {
			this.setState({ outro_value: '', outra_unidade_value: '', outro_form: false, outra_unidade_error: false });
			return;
		}
		if (opcoes_by_id[opcoes_all_ids[0]].unidade !== '' && outra_unidade_value === '') {
			this.setState({ outra_unidade_error: true });
			return;
		}

		this.setState({ enviando_outra_opcao: true });
		let _outro_value = outro_value;
		const { tipo_de_valor } = this.props;
		if (tipo_de_valor === 'numero') {
			const number_values = setDecimalsToNumber(outro_value); // numeros
			_outro_value = setDecimalsToString(number_values[1]); // string
		}
		const nova_opcao = { unidade: outra_unidade_value, validada: false, valor: _outro_value };
		_post(`/conaz/v2/caracteristicas/${field}/opcoes`, nova_opcao).then(response => {
			const next_id = response.data.id;
			const opcao_preenchida = { ...response.data };
			const _opcoes_by_id = { ...opcoes_by_id, [next_id]: opcao_preenchida };
			const _opcoes_all_ids = [...opcoes_all_ids, next_id];
			const opcoes_selecionadas = [...this.state.opcoes_selecionadas, next_id];
			const opcoes_selecionadas_view = opcoes_selecionadas.map(id => `${_opcoes_by_id[id].valor} ${_opcoes_by_id[id].unidade}`).join(', ');
			this.setState(
				{
					opcoes_by_id: _opcoes_by_id,
					opcoes_all_ids: _opcoes_all_ids,
					opcoes_selecionadas,
					opcoes_selecionadas_view,
					outro_value: '',
					outra_unidade_value: '',
					outro_form: false,
					outra_unidade_error: false,
					enviando_outra_opcao: false,
				},
				this.saveToRedux,
			);
		}).catch(error => {
			console.log(error); // eslint-disable-line
			this.setState({ enviando_outra_opcao: false });
		});
	}

	handleOutroEdition(e) {
		const { tipo_de_valor } = this.props;
		if (tipo_de_valor === 'numero') {
			const number_values = setDecimalsToNumber(e.target.value); // numeros
			// const props_value = number_values[0]; // numero
			const outro_value = setDecimalsToString(number_values[1]); // string
			this.setState({ outro_value, outra_unidade_error: false });
		} else {
			const outro_value = e.target.value.toString();
			this.setState({ outro_value, outra_unidade_error: false });
		}
	}

	handleOutraUnidadeEdition(e) {
		const outra_unidade_value = e.target.value.toString();
		this.setState({ outra_unidade_value, outra_unidade_error: false });
	}

	render() {
		const { opcoes_by_id, opcoes_all_ids, opcoes_selecionadas, opcoes_selecionadas_view,
			outro_form, outro_value, outra_unidade_value, outra_unidade_error, enviando_outra_opcao } = this.state;
		const { field_id, item_id, field } = this.props;

		return (
			<div className="dropdown">
				<button
					id={field_id}
					className="btn btn-default input-md dropdown-toggle"
					styleName="dropdown"
					type="button"
					aria-haspopup="true"
					aria-expanded="true"
					data-dropdown>
					{opcoes_selecionadas_view} <span className="caret" styleName="dropdown-caret" data-dropdown-2 />
				</button>
				<div className="dropdown-menu" styleName="dropdown-menu-local" data-dropchild>
					{opcoes_all_ids.map(opcao_id => {
						const selected = opcoes_selecionadas.filter(id => id === opcao_id).length > 0;
						return (
							<MultipleOption
								key={opcao_id}
								handleEdition={this.handleEdition}
								option={opcoes_by_id[opcao_id]}
								selected={selected} />
						);
					})}
					<li role="separator" className="divider" styleName="divider" data-dropchild />
					{outro_form ? (
						<li data-dropchild>
							<div styleName="salvar-outro-wrapper" data-dropchild>
								{outra_unidade_error && (
									<div className="tooltip top in" styleName="tooltip-unidade" role="tooltip">
										<div className="tooltip-arrow" style={{ borderTopColor: '#ee2622', left: '10%' }} />
										<div className="tooltip-inner" style={{ backgroundColor: '#ee2622' }}>Preencha também a unidade</div>
									</div>
								)}
								<input
									id={`outro_multiple_input_${item_id}_${field}`}
									type="text"
									className="form-control input-md"
									styleName={opcoes_by_id[opcoes_all_ids[0]].unidade !== '' ? 'input-outro unidade' : 'input-outro'}
									data-dropchild
									value={outro_value}
									onChange={this.handleOutroEdition} />

								{opcoes_by_id[opcoes_all_ids[0]].unidade !== '' && (
									<input
										type="text"
										className="form-control input-md"
										styleName="input-outra-unidade"
										data-dropchild
										value={outra_unidade_value}
										onChange={this.handleOutraUnidadeEdition}
										placeholder="un" />
								)}

								{!enviando_outra_opcao ? (
									<button
										className="btn btn-primary"
										type="button"
										data-dropchild
										onClick={this.addOutro}>
										Salvar
									</button>
								) : (
									<button
										className="btn btn-primary"
										type="button"
										style={{ width: '50px' }}
										data-dropchild>
										<LoadingButton />
									</button>
								)}
							</div>
						</li>
					) : (
						<li data-dropchild><button type="button" data-dropchild onClick={this.showOutroForm}>Outro</button></li>
					)}
				</div>
			</div>
		);
	}
}

InputMultiple.propTypes = {
	type: PropTypes.string.isRequired,
	field_id: PropTypes.string,
	item_id: PropTypes.number.isRequired,
	field: PropTypes.string.isRequired,
	opcoes: PropTypes.array,
	opcoes_selecionadas: PropTypes.array,
	updateFunction: PropTypes.func,
	autoSaveTimeout: PropTypes.func.isRequired,
	tipo_de_valor: PropTypes.string,
};

InputMultiple.defaultProps = {
	field_id: '',
	opcoes: [],
	opcoes_selecionadas: [],
	updateFunction: () => {},
	tipo_de_valor: 'texto',
};

export default CSSModules(InputMultiple, styles, { allowMultiple: true });
