import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../../actions/index';

// Components
import ConfirmarCadastro from './ConfirmarCadastro';
import Wrapper from '../../Wrapper/Wrapper';

import { enviarConfirmacao, me as getMe } from '../../../services/apis/auth';
import { getLocalstorageData, setLocalstorageData } from '../../_functions/_getLocalstorageData';
import { urlSearchParamsPolyfill } from '../../_functions/_urlSearchParamsPolyfill';

class ConfirmarCadastroContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			send_state: 0,
			confirmado: false,
		};
		this.reenviaEmail = this.reenviaEmail.bind(this);
		this.redirecionaParaSolicitacoes = this.redirecionaParaSolicitacoes.bind(this);
	}

	UNSAFE_componentWillMount() {
		const is_confirmado = getLocalstorageData('user', 'estah_confirmado');
		if (is_confirmado) {
			this.setState({ confirmado: true });
		}
	}

	componentDidMount() {
		if (this.state.confirmado) {
			setTimeout(() => this.redirect(), 5000);
		} else {
			this.checkIsConfirmado();
		}
	}

	redirect() {
		const { search } = this.props.location;
		const all_params = urlSearchParamsPolyfill(search);
		const redirect_to = all_params.redirect_to || '/solicitacoes';
		// const params = new URLSearchParams(search);
		// const redirect_to = params.get('redirect_to') || '/solicitacoes';
		this.props.history.push(redirect_to);
	}

	redirecionaParaSolicitacoes() {
		const { search } = this.props.location;
		const all_params = urlSearchParamsPolyfill(search);
		const redirect_to = all_params.redirect_to || '';
		// const params = new URLSearchParams(search);
		// const redirect_to = params.get('redirect_to') || '';
		return redirect_to.indexOf('revisao') === -1;
	}

	checkIsConfirmado() {
		getMe()
			.then(response => {
				const usuario = response.data;

				if (usuario.estah_confirmado) {
					setLocalstorageData('user', usuario);
					this.setState({ confirmado: true });
					setTimeout(() => this.redirect(), 5000);
				}
			})
			.catch(error => {
				console.log(error); // eslint-disable-line
			});
	}

	reenviaEmail() {
		this.setState({ send_state: 1 });

		enviarConfirmacao()
			.then(resp => {
				console.log(resp); // eslint-disable-line
				this.setState({ send_state: 2 });
			})
			.catch(err => {
				console.log(err); // eslint-disable-line
				this.setState({ send_state: 3 });
			});
	}

	render() {
		return (
			<Wrapper
				loaded
				{...this.props}>
				<ConfirmarCadastro
					{...this.state}
					{...this.props}
					reenviaEmail={this.reenviaEmail}
					redirecionaParaSolicitacoes={this.redirecionaParaSolicitacoes} />
			</Wrapper>
		);
	}
}

const mapStateToProps = props => ({
	cadastro: props.cadastro,
	ui: props.ui,
});

const mapDispatchToProps = dispatch => ({
	resetCadastro: () => dispatch(actions.resetCadastro()),
	updateCadastro: (field, value) => dispatch(actions.updateCadastro(field, value)),
	updateUi: (field, value) => dispatch(actions.updateUi(field, value)),
	updateModals: (modal, value) => dispatch(actions.updateModals(modal, value)),
	reportErrors: (page, error, codigo_pedido, full_error, test) => dispatch(actions.reportErrors(page, error, codigo_pedido, full_error, test)),
});

ConfirmarCadastroContainer.propTypes = {
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmarCadastroContainer);
