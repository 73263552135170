import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/pt-br';
import { connect } from 'react-redux';
import * as actions from '../../../actions/index';

// Components
import Wrapper from '../../Wrapper/Wrapper';
import Preview from './Preview';

// Functions
import { _post } from '../../../services/apis/requests';
import { get as getRC } from '../../../services/apis/rcs';

class PreviewContainer extends Component {
	constructor() {
		super();
		this.state = {
			criando_itens: false,
			observacoes_vista: true,
			tab_perfil_construtor: 0,
		};
		this.closeModalObservacoes = this.closeModalObservacoes.bind(this);
		this.buildResponse = this.buildResponse.bind(this);
		this.criaItensResposta = this.criaItensResposta.bind(this);
		this.openNaoTenhoInteresse = this.openNaoTenhoInteresse.bind(this);
		this.openPerfilConstrutor = this.openPerfilConstrutor.bind(this);
	}

	UNSAFE_componentWillMount() {
		window.scroll(0, 0);
	}

	componentDidMount() {
		this._mounted = true;
		if (this.props.cotacao.rc.status === 35) {
			setTimeout(() => {
				if (this._mounted) this.props.updateModals('reset_confirm', true);
			}, 1000);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.cotacao.rc.status !== nextProps.cotacao.rc.status) {
			if (nextProps.cotacao.rc.status === 35) {
				setTimeout(() => {
					if (this._mounted) this.props.updateModals('reset_confirm', true);
				}, 1000);
			}
		}
	}

	componentWillUnmount() {
		this._mounted = false;
		const { updateModals } = this.props;
		updateModals('sem_interesse', false);
		updateModals('anexos_cliente', false);
		updateModals('reset_confirm', false);
		updateModals('aviso_prazo_cotacao', false);
		updateModals('geral', false);
	}

	closeModalObservacoes() {
		this.setState({ observacoes_vista: true });
	}

	atualizaRcVisto(rc) {
		if (rc.status === 0) {
			const observacoes_vista = (rc.pedido.observacoes_conaz !== '' ? rc.pedido.observacoes_conaz === '' : '') && rc.pedido.observacoes === '';
			this.setState({ observacoes_vista });
			_post(`/conaz/v2/rcs/${rc.uuid}/registrar_visualizacao`, {});
		}
	}

	buildResponse(responses) {
		const cotacao = { ...responses[0].data };

		if ((
			cotacao.status === -20 ||
			cotacao.status === 40 ||
			cotacao.status === 50 ||
			cotacao.status === 60
		) &&
			!cotacao.estah_resetado
		) {
			this.props.history.replace(`/proposta/${cotacao.uuid}/ver`);
			return;
		}

		const codigo = (cotacao.pedido || {}).codigo || '';
		document.title = `#${codigo} - Cotação - Conaz`;

		if (cotacao.status === 36) {
			this.props.history.replace(`/cotacao/${cotacao.uuid}/revisao`);
			return;
		}

		const anexos = cotacao.anexos.map((a, key) => {
			let { url } = a;
			if (url.substring(0, 8) !== 'https://') url = `https://${a.url}`;
			return { ...a, key, url, file: null, progress: 0, success: 1, error: false };
		});
		const validade = cotacao.validade !== null ? moment(cotacao.validade) : moment('2016-01-01');
		const condicoes_de_pagamento_by_id = cotacao.condicoes_de_pagamento.reduce((result, current) => ({
			...result,
			[current.id]: { ...current },
		}), {});
		const condicoes_de_pagamento = Object.keys(condicoes_de_pagamento_by_id).map(id => Number(id));
		/**
		 * Ordena itens de pedido e atualiza objeto pedido
		 */
		const itens_de_pedido = cotacao.pedido.itens_de_pedido.sort((a, b) => a.ordem - b.ordem);
		const pedido = {
			...cotacao.pedido,
			itens_de_pedido,
		};
		const _cotacao = {
			...cotacao,
			pedido,
			anexos,
			validade,
			condicoes_de_pagamento_by_id,
			condicoes_de_pagamento,
		};

		this.props.updateCotacao(['rc'], _cotacao);
		this.atualizaRcVisto(_cotacao);

		if (cotacao.status === 37) {
			this.props.history.replace(`/cotacao/${cotacao.uuid}`);
			return;
		}

		const status_pedido = cotacao.pedido.status;
		const passou_do_prazo = moment(moment().format('YYYY-MM-DD')).isAfter(cotacao.pedido.prazo_de_resposta);

		if (cotacao.status !== 35 && status_pedido < 60 && passou_do_prazo) {
			setTimeout(() => {
				if (this._mounted) this.props.updateModals('aviso_prazo_cotacao', true);
			}, 1000);
		}
	}

	buildItensResposta(itens_de_resposta) {
		if (this.props.cotacao.itens_all_ids.length === 0) {
			const itens_observacoes = [];
			const by_id = itens_de_resposta.reduce((result, current) => {
				// todas as características
				const { caracteristicas } = current.item_de_pedido.item_preenchido.item;
				const carac_preenchidas_by_id_1 = caracteristicas
					.filter(c => c.visao_fornecedor === 1 || c.visao_fornecedor === 2)
					.reduce((r, c) => {
						if (c.id !== null) {
							return {
								...r,
								[c.id]: {
									caracteristica: { ...c },
									opcoes_preenchidas: [],
								},
							};
						}
						return { ...r };
					}, {});

				// características já preenchidas
				let carac_preenchidas_by_id_2 = {};
				if (current.item_preenchido.caracteristicas_preenchidas !== undefined) {
					const { caracteristicas_preenchidas } = current.item_preenchido;
					carac_preenchidas_by_id_2 = caracteristicas_preenchidas.reduce((r, c) => {
						if (c.caracteristica.id !== null) {
							return { ...r, [c.caracteristica.id]: c };
						}
						return { ...r };
					}, {});
				}

				// junta todas, colocando as já preenchidas em segundo para forçar os valores
				const carac_preenchidas_by_id = { ...carac_preenchidas_by_id_1, ...carac_preenchidas_by_id_2 };
				const carac_preenchidas_all_ids = Object.keys(carac_preenchidas_by_id).map(id => id);
				if (current.informacoes_adicionais !== '') itens_observacoes.push(current.id);
				const _current = {
					...current,
					item_preenchido: {
						item: current.item_de_pedido.item_preenchido.item,
						caracteristicas_preenchidas: carac_preenchidas_by_id,
						caracteristicas_preenchidas_all_ids: carac_preenchidas_all_ids,
					},
				};
				return { ...result, [current.id]: _current };
			}, {});
			const all_ids = Object.keys(by_id)
				.sort((a, b) => by_id[a].item_de_pedido.ordem - by_id[b].item_de_pedido.ordem)
				.map(id => id);
			this.props.updateCotacao(['itens_by_id'], by_id);
			this.props.updateCotacao(['itens_all_ids'], all_ids);
			this.props.updateCotacao(['itens_observacoes'], itens_observacoes);
		}

		const { id } = this.props.match.params;
		this.props.history.push(`/cotacao/${id}/itens`);
	}

	criaItensResposta() {
		const { cotacao, updateModals } = this.props;
		if (cotacao.rc.pedido.status >= 60) {
			updateModals('geral', true);
		} else {
			this.setState({ criando_itens: true });
			if (cotacao.rc.status === 0 || cotacao.rc.status === 10 || cotacao.rc.status === 35) {
				// const { id } = match.params;
				_post(`/conaz/v2/rcs/${cotacao.rc.uuid}/iniciar`, {}).then(response => {
					this.buildItensResposta(response.data.itens_de_resposta);
				}).catch(() => {
					this.setState({ criando_itens: false });
				});
			} else {
				this.buildItensResposta(cotacao.rc.itens_de_resposta);
			}
		}
	}

	openNaoTenhoInteresse() {
		const { cotacao, updateModals } = this.props;
		if (cotacao.rc.pedido.status >= 60) {
			updateModals('geral', true);
		} else {
			updateModals('sem_interesse', true);
		}
	}

	openPerfilConstrutor(tab = 0) {
		const { updateModals } = this.props;
		this.setState({ tab_perfil_construtor: tab });
		updateModals('perfil_construtor', true);
	}

	render() {
		const { id } = this.props.match.params;
		const loaded = this.props.cotacao.rc.uuid === id;
		return (
			<Wrapper
				type="cotacao"
				promises={[() => getRC(id)]}
				loaded={loaded}
				buildResponse={this.buildResponse}
				{...this.props}>

				<Preview
					{...this.state}
					{...this.props}
					closeModalObservacoes={this.closeModalObservacoes}
					criaItensResposta={this.criaItensResposta}
					openNaoTenhoInteresse={this.openNaoTenhoInteresse}
					openPerfilConstrutor={this.openPerfilConstrutor} />

			</Wrapper>
		);
	}
}

PreviewContainer.propTypes = {
	// =========== store
	cadastro: PropTypes.object.isRequired,
	cotacao: PropTypes.object.isRequired,
	// =========== redux funcs
	updateCotacao: PropTypes.func.isRequired,
	updateCadastro: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	// =========== router
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};

const mapStateToProps = props => ({
	cadastro: props.cadastro,
	cotacao: props.cotacao,
	ui: props.ui,
});

const mapDispatchToProps = dispatch => ({
	resetCadastro: () => dispatch(actions.resetCadastro()),
	updateCadastro: (field, value) => dispatch(actions.updateCadastro(field, value)),
	updateCotacao: (field, value) => dispatch(actions.updateCotacao(field, value)),
	updateUi: (field, value) => dispatch(actions.updateUi(field, value)),
	updateItem: (item_id, field, value) => dispatch(actions.updateItem(item_id, field, value)),
	updateItemCaracteristica: (item_id, key, value) => dispatch(actions.updateItemCaracteristica(item_id, key, value)),
	updateItemCaracteristicaUnidade: (item_id, key, value) => dispatch(actions.updateItemCaracteristicaUnidade(item_id, key, value)),
	updateModals: (modal, value) => dispatch(actions.updateModals(modal, value)),
	reportErrors: (page, error, codigo_pedido, full_error, test) => dispatch(actions.reportErrors(page, error, codigo_pedido, full_error, test)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PreviewContainer);
