import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Components
import NavigationBar from '../_parts/NavigationBar/NavigationBar';
import Alert2 from '../_parts/Alerts/Alert2';
import UnsubscribeCheckbox from './UnsubscribeCheckbox';

// Functions
import { handleRequestErrors } from '../_functions/_handleRequestErrors';
import { _post } from '../../services/apis/requests';

class Unsubscribe extends Component {
	constructor() {
		super();
		// const email = (JSON.parse(localStorage.getItem('user')) || {}).email || '';
		this.state = {
			outro_motivo_bool: false,
			outro_motivo: '',
			motivos: [
				{
					id: '1',
					label: 'Não tenho autorização para responder cotações via sistema Conaz.',
				},
				{
					id: '2',
					label: 'Estou recebendo muitos e-mails e prefiro fazer a gestão diretamente pelo portal do fornecedor.',
				},
				{
					id: '3',
					label: 'Não trabalho mais nesta empresa ou não trabalho mais com construção civil.',
				},
				{
					id: '4',
					label: 'Não estou satisfeito com minha experiência no sistema Conaz.',
				},
				{
					id: '5',
					label: 'Não sou o responsável por responder cotações.',
				},
				{
					id: '6',
					label: 'Não entendo o que é sistema Conaz e estou recebendo muito e-mails deste lugar.',
				},
			],
			motivos_selecionados: [],
			error: 0,
			enviando: false,
			canceled: false,
		};
		this.error_message = 'Algo deu errado, tente novamente mais tarde.';
		this.option_less_message = 'Por favor, selecione pelo menos um motivo para cancelar a assinatura de e-mail.';
		this.canceled_message = 'Pronto! Você não receberá mais e-mails da Conaz.';
	}

	handleTarget = e => {
		const { motivos } = this.state;
		motivos.push(e.target.value);
		this.setState({ motivos });
	}

	handleVal = id => {
		const {
			enviando,
			motivos,
			motivos_selecionados,
		} = this.state;
		if (enviando) return;
		const option = _.find(motivos, { id });
		const _id = option.id;
		if (this.selected(_id)) {
			_.pull(motivos_selecionados, _id);
			this.setState({ motivos_selecionados });
			return;
		}
		motivos_selecionados.push(_id);
		this.setState({ motivos_selecionados });
	}

	handleOtherOption = () => {
		const { enviando, outro_motivo_bool } = this.state;
		if (enviando) return;
		this.setState({ outro_motivo_bool: !outro_motivo_bool });
	}

	handleOtherMotivation = e => {
		this.setState({ outro_motivo: e.target.value });
	}

	handleAfterUsubscribe = () => {
		const { history } = this.props;
		history.push('/');
	}

	handleMistakeClick = () => {
		const { history } = this.props;
		history.push('/');
	}

	selected = id => {
		const { motivos_selecionados } = this.state;
		return _.includes(motivos_selecionados, id);
	}

	sendMotivations = e => {
		e.preventDefault();
		// get url params
		const { location } = this.props;
		const search = (location.search || '').substr(1);
		const email_location = search
			.split('&')
			.filter(x => x.indexOf('email=') !== -1)[0] || '';
		const email = email_location
			.replace('email=', '');
		const uuid_location = search
			.split('&')
			.filter(x => x.indexOf('uuid=') !== -1)[0] || '';
		const uuid = uuid_location
			.replace('uuid=', '');
		// motivos
		const { outro_motivo, motivos_selecionados } = this.state;
		const _outro_motivo = outro_motivo !== '' && !_.includes(motivos_selecionados, outro_motivo);
		if (_outro_motivo) motivos_selecionados.push(outro_motivo);
		// post
		this.setState({ enviando: true });
		const url = '/conaz/v2/usuarios_fornecedor/desinscrever';
		const params = {
			motivos: motivos_selecionados,
			email,
			uuid,
		};
		_post(url, params)
			.then(() => {
				this.setState({ canceled: true, enviando: false });
			})
			.catch(err => {
				const _data = (((err || {}).response || {}).data);
				const _message_conaz = (_data || {}).message || '';
				handleRequestErrors(err);
				if (_message_conaz === 'Usuário já desinscrito') {
					this.setState({ canceled: true, enviando: false });
					return;
				}
				this.setState({ canceled: true, enviando: false });
			});
	}

	render() {
		const { history, location } = this.props;
		const {
			outro_motivo_bool,
			canceled,
			error,
			outro_motivo,
			motivos,
			enviando,
		} = this.state;

		const search = (location.search || '').substr(1);
		const email_location = search
			.split('&')
			.filter(x => x.indexOf('email=') !== -1)[0] || '';
		const email = email_location
			.replace('email=', '');
		const email_view = email !== ''
			? <>de <span>{email}</span></>
			: <></>;

		return (
			<>
				<NavigationBar
					menu={false}
					updateUi={() => null}
					history={history}
					resetCadastro={() => null}
				/>

				<div className="container">
					<div className="row">
						<div className="col-xs-12">
							<div styleName="header">Cancelando sua inscrição {email_view} no Sistema Conaz, você não será mais notificado por e-mail sobre oportunidades de venda para as construtoras.</div>
							<hr styleName="divider" />
							<h2 styleName="titulo">Ajude-nos a melhorar!</h2>
							<p>Por que você deseja se descadastrar dessa lista?<br /><br /></p>
							<div styleName="motivo-cancelmento">
								<ul>
									{_.map(motivos, ({ label, id }) => (
										<UnsubscribeCheckbox
											key={id}
											option_id={id}
											label={label}
											handleVal={this.handleVal}
											selected={this.selected(id)}
										/>
									))}
									<UnsubscribeCheckbox
										label="Outro motivo"
										handleVal={this.handleOtherOption}
										selected={outro_motivo_bool}
									/>
								</ul>
								{outro_motivo_bool && (
									<textarea
										className="form-control"
										style={{ marginBottom: '18px' }}
										rows="4"
										cols="50"
										maxLength="240"
										value={outro_motivo}
										onChange={this.handleOtherMotivation}
									/>
								)}
								{!enviando ? (
									<div styleName="buttons">
										<button
											type="button"
											className="btn btn-clear btn-lg"
											onClick={this.sendMotivations}>
											Cancelar recebimento de e-mails
										</button>
										<button
											type="button"
											className="btn btn-clear btn-lg"
											onClick={this.handleMistakeClick}>
											Cliquei por engano
										</button>
									</div>
								) : (
									<div styleName="buttons">
										<button
											type="button"
											className="btn btn-clear btn-lg">
											Enviando...
										</button>
										<button
											type="button"
											className="btn btn-clear btn-lg"
											style={{ opacity: '0.3' }}>
											Cliquei por engano
										</button>
									</div>
								)}
							</div>
						</div>

						{error === 1 && (
							<Alert2
								color="red"
								mensagem={this.error_message}
							/>
						)}
						{error === 2 && (
							<Alert2
								color="warning"
								externalFunction={() => this.setState({ error: 0 })}
								mensagem={this.option_less_message}
							/>
						)}
						{canceled && (
							<Alert2
								color="green"
								externalFunction={this.handleAfterUsubscribe}
								mensagem={this.canceled_message}
							/>
						)}
					</div>
				</div>
			</>
		);
	}
}

Unsubscribe.propTypes = {
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};

export default CSSModules(Unsubscribe, styles, { allowMultiple: true });
