import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';


// Components
import Alerts from '../../_parts/Alerts/Alerts';
import ModalWrapper from '../../_parts/_modals/ModalWrapper/ModalWrapper';
import BasicModal from '../../_parts/_modals/BasicModal/BasicModal';

const ConfirmaCotacaoNovo = ({
	/* props */	ui, updateUi, updateModals, link_completar_cadastro, history,
}) => (
	<React.Fragment>
		<ModalWrapper visible={ui.modals.alerta_nome_comercial} updateModals={updateModals}>
			<BasicModal
				title="Atenção"
				message={<p>Identificamos que você utilizou palavras como <b>&quot;LTDA&quot;, &quot;MEI&quot; ou &quot;S.A&quot;</b> no cadastro do nome comercial da sua empresa, o que nos parece ser o nome da Razão Social. Recomendamos que <b>altere seu cadastro</b> para algo mais reconhecível aos construtores, assim conseguirem identificar a sua empresa mais facilmente.</p>}
				modalName="alerta_nome_comercial"
				onClickConfirmar={() => { history.push('/editar-empresa'); }}
				showConfirmarButton
				showCancelarButton
				textoConfirmar="Editar cadastro"
				textoCancelar="Não quero alterar" />
		</ModalWrapper>
		<Alerts
			alerts={ui.alerts}
			updateUi={updateUi} />
		<div className="container">
			<div className="row" styleName="row-space">
				<div className="col-sm-12" style={{ textAlign: 'center' }}>
					<h1>Ganhe mais oportunidades!</h1>
					<p
						style={{ fontSize: '16px' }}>
						Cadastre todas as áreas que você atende e quais produtos/serviço você trabalha, ficando visível para mais construtoras e ganhando acesso ao nosso portal.
					</p>
					<Link
						to={link_completar_cadastro}>
						<button
							type="button"
							className="btn btn-primary btn-lg"
							styleName="button-cadastro">
							Cadastro avançado
						</button>
					</Link>
				</div>
			</div>
		</div>
	</React.Fragment>
);

ConfirmaCotacaoNovo.propTypes = {
	ui: PropTypes.object.isRequired,
	updateUi: PropTypes.func.isRequired,
	link_completar_cadastro: PropTypes.string.isRequired,
	history: PropTypes.object.isRequired,
	updateModals: PropTypes.func.isRequired,
};

export default CSSModules(ConfirmaCotacaoNovo, styles, { allowMultiple: true });
