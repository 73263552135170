import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from '../style.module.scss';

function InputNumberDisabled({ currency, currency_icon }) {
	return (
		<>
			{!currency ? (
				<input
					type="text"
					className="form-control input-md"
					disabled />
			) : (
				<div styleName="currency-wrapper">
					<input
						type="text"
						className="form-control input-md"
						disabled />
					<div styleName="placeholder">{currency_icon}</div>
				</div>
			)}
		</>
	);
}

InputNumberDisabled.propTypes = {
	currency: PropTypes.bool,
	currency_icon: PropTypes.string,
};

InputNumberDisabled.defaultProps = {
	currency: false,
	currency_icon: 'R$',
};

export default CSSModules(InputNumberDisabled, styles, { allowMultiple: true });
