
import React from 'react';
import PropTypes from 'prop-types';

import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

import LoadingSmall from '../../_parts/_loadings/LoadingSmall/LoadingSmall';

function AbaStatus({
	active,
	status_name,
	loading,
	rcs_length,
	tab_number,
	fetchRcs,
	updateUi,
}) {
	const handleOnClick = e => {
		e.preventDefault();
		updateUi(['tab_number_selected'], tab_number);
		fetchRcs();
	};

	return (
		<li role="presentation">
			<a href="#" styleName={active ? 'active' : ''} onClick={handleOnClick}>
				{status_name} ({loading && !rcs_length ? <LoadingSmall /> : rcs_length})
			</a>
		</li>
	);
}

AbaStatus.propTypes = {
	tab_number: PropTypes.number.isRequired,
	active: PropTypes.bool.isRequired,
	fetchRcs: PropTypes.func.isRequired,
	updateUi: PropTypes.func.isRequired,
	status_name: PropTypes.string.isRequired,
	loading: PropTypes.bool.isRequired,
	rcs_length: PropTypes.number,
};

AbaStatus.defaultProps = {
	rcs_length: 0,
};

export default CSSModules(AbaStatus, styles, { allowMultiple: true });
