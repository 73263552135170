import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components

import InputFieldText from '../../_parts/_inputs/InputCadastro/InputFieldText';
import Modal from '../../_parts/Modal/Modal';
import MessageModal from '../../_parts/MessageModal/MessageModal';
//
const Cadastro = ({
	/* state */ request_state, modal_error, validation_errors,
	/* props */ cadastro,
	/* funcs */ handleSubmit, handleEdition, handleModal,
}) => {
	const { documento } = cadastro;

	return (
		<div>
			{modal_error && (
				<Modal modal="modal_error" handleModal={handleModal}>
					<MessageModal
						type="alert"
						title="Ops... aconteceu algo errado."
						message="Desculpe, não conseguimos identificar o que aconteceu. Espere alguns segundos e tente novamente." />
				</Modal>
			)}

			<div className="container" styleName="main-container">
				<div className="row">
					<div className="col-xs-12" style={{ textAlign: 'center' }}>
						<h2>Cadastro de fornecedor</h2>
					</div>
				</div>
				{/* EMPRESA */}
				<div className="row">
					<div className="col-xs-12">
						<h5 styleName="section-title centralizar-conteudo"><i className="fa fa-building fa-fw" aria-hidden="true" /> Empresa</h5>
					</div>
				</div>
				<form onSubmit={handleSubmit}>
					<div className="row">
						<div className="col-xs-12 col-sm-4" styleName="centralizar-conteudo">
							{validation_errors.cnpj && (
								<div className="tooltip top in" styleName="tooltip" role="tooltip" style={{ marginLeft: '-19px', marginTop: '-34px' }}>
									<div className="tooltip-arrow" />
									<div className="tooltip-inner">Preencha o campo CNPJ</div>
								</div>
							)}
							{validation_errors.cnpj_invalido && (
								<div className="tooltip top in" styleName="tooltip" role="tooltip" style={{ marginLeft: '-19px', marginTop: '-22px' }}>
									<div className="tooltip-arrow" />
									<div className="tooltip-inner">CNPJ inválido!</div>
								</div>
							)}
							<InputFieldText
								label="CNPJ"
								fields={['documento']}
								obrigatorio
								default_value={documento}
								auxiliarFunction={handleEdition} />
						</div>
					</div>

					{(request_state === 1) ? (
						<div className="row">
							<div className="col-xs-12" style={{ textAlign: 'center', marginLeft: '-10px' }}>
								<button className="btn btn-success btn-lg" style={{ marginTop: '50px' }} disabled="disabled">Validando</button>
							</div>
						</div>
					) : (
						<div className="row">
							<div className="col-xs-12" style={{ textAlign: 'center', marginLeft: '-10px' }}>
								<button type="submit" id="send_btn" style={{ marginTop: '50px' }} className="btn btn-success btn-lg">Continuar</button>
							</div>
						</div>
					)}
				</form>

			</div>
		</div>
	);
};

Cadastro.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	request_state: PropTypes.number.isRequired,
	modal_error: PropTypes.bool.isRequired,
	validation_errors: PropTypes.object.isRequired,
	cadastro: PropTypes.object.isRequired,
	handleEdition: PropTypes.func.isRequired,
	handleModal: PropTypes.func.isRequired,
};

export default CSSModules(Cadastro, styles, { allowMultiple: true });
