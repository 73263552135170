import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const BotaoLinkCotacao = ({ rc_id, status }) => (
	<a
		href={(status === 36 || status === 37)
			? `/cotacao/${rc_id}/revisao`
			: `/cotacao/${rc_id}`}
		target="_blank"
		rel="noopener noreferrer">
		<button
			className="btn btn-success"
			styleName="button-responder">
			Ver cotação
		</button>
	</a>
);

BotaoLinkCotacao.propTypes = {
	rc_id: PropTypes.string.isRequired,
	status: PropTypes.number.isRequired,
};

export default CSSModules(BotaoLinkCotacao, styles, { allowMultiple: true });
