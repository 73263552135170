
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../../actions/index';

// Components
import ListarEmpresas from './ListarEmpresas';
import Wrapper from '../../Wrapper/Wrapper';

// Functions
import { setLocalstorageData } from '../../_functions/_getLocalstorageData';
import { removeUsuarioFromFornecedor } from '../../../services/apis/fornecedores';
import { lockBody } from '../../_functions/_lockBody';

class ListarEmpresasContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modal_error: false,
			page_locked: false,
			fornecedor_selecionado: {},
		};
		this.alteraFornecedor = this.alteraFornecedor.bind(this);
		this.onClickToEdit = this.onClickToEdit.bind(this);
		this.desvincularFornecedor = this.desvincularFornecedor.bind(this);
	}

	UNSAFE_componentWillMount() {
		const { id, nome } = this.props.cadastro;
		this.setState({ fornecedor_selecionado: { id, nome } });
	}

	componentDidMount() {
		document.title = 'Listar Empresas - Conaz';
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.cadastro.loaded && this.props.cadastro.loaded !== nextProps.cadastro.loaded) {
			const { id, nome } = this.props.cadastro;
			this.setState({ fornecedor_selecionado: { id, nome } });
		}
	}

	onClickToEdit(type) {
		this.props.history.push(`/editar-${type}`);
	}

	desvincularFornecedor() {
		const { id, id_vendedor } = this.props.cadastro;
		removeUsuarioFromFornecedor(id, id_vendedor)
			.then(() => {
				setLocalstorageData('fornecedor_id', null);
				this.props.resetCadastro();
			});
	}

	alteraFornecedor(field, opcao) {
		setLocalstorageData('fornecedor_id', opcao.id);
		this.props.updateCadastro(['loaded'], false);
	}

	handleModal(modal, value) {
		this.setState({ [modal]: value, page_locked: value });
		lockBody(value);
	}

	render() {
		const { loaded } = this.props.cadastro;
		return (
			<Wrapper
				loaded={loaded}
				{...this.props}>
				<ListarEmpresas
					{...this.state}
					{...this.props}
					handleModal={this.handleModal}
					alteraFornecedor={this.alteraFornecedor}
					onClickToEdit={this.onClickToEdit}
					desvincularFornecedor={this.desvincularFornecedor} />
			</Wrapper>
		);
	}
}

const mapStateToProps = props => ({
	cadastro: props.cadastro,
	ui: props.ui,
});

const mapDispatchToProps = dispatch => ({
	resetCadastro: () => dispatch(actions.resetCadastro()),
	updateCadastro: (field, value) => dispatch(actions.updateCadastro(field, value)),
	updateUi: (field, value) => dispatch(actions.updateUi(field, value)),
	updateModals: (modal, value) => dispatch(actions.updateModals(modal, value)),
	reportErrors: (page, error, codigo_pedido, full_error, test) => dispatch(actions.reportErrors(page, error, codigo_pedido, full_error, test)),
});


ListarEmpresasContainer.propTypes = {
	cadastro: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	updateCadastro: PropTypes.func.isRequired,
	resetCadastro: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListarEmpresasContainer);
