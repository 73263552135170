import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { getLocalstorageData } from '../../../_functions/_getLocalstorageData';
import { isAuthenticated, logout } from '../../../../services/login_manager';

const ModalConfirmacaoVenda = ({
	updateModals,
	confirmOC,
	confirmacaoEnviada,
	compra_id,
	error_confirmando_oc,
	error_msg,
	usuario_diferente,
	usuario,
	history,
}) => {
	const desloga = () => {
		logout();
		history.push('/entrar');
	};

	const rc_email = (usuario || {}).email || '';
	const login_email = getLocalstorageData('user', 'email') || '';

	return (
		<div className="modal-content" styleName="modal-content">
			<div className="modal-body" styleName="modal-body">
				<button
					type="button"
					className="close"
					styleName="close-button"
					data-dismiss="modal"
					aria-label="Close"
					onClick={() => updateModals('confirmacao_venda', false)}>
					<span aria-hidden="true">&times;</span>
				</button>

				{isAuthenticated() && (
					<React.Fragment>
						<h3>Confirma a venda, entrega no prazo e as condições estipuladas?</h3>
						<br />
						<p>O construtor receberá a resposta assim que a compra for confirmada.</p>
						<p>Dúvidas ou problemas podem ser resolvidos entrando em contato com a Conaz através do chat online, ou com o próprio construtor através do seu contato disponibilizado no Perfil do Construtor.</p>

						{usuario_diferente && (
							<div className="alert alert-warning" role="alert" style={{ fontSize: '14px' }}>
								<p>Você está logado na conta <b style={{ color: '#212121' }}>{login_email}</b>, porém a cotação foi enviada através de <b style={{ color: '#212121' }}>{rc_email}</b>. Entre na conta correta para confirmar a compra.</p>
								<button
									type="button"
									className="btn btn-link"
									style={{ fontWeight: 'bold', marginTop: '4px' }}
									onClick={desloga}>
									Trocar conta
								</button>
							</div>
						)}

						{error_confirmando_oc && (
							<div className="alert alert-danger" role="alert" style={{ fontSize: '14px' }}>
								{error_msg === null ? (
									<p>Desculpe, tivemos um problema! Espere alguns segundos e tente novamente.</p>
								) : (
									<React.Fragment>{error_msg}</React.Fragment>
								)}
							</div>
						)}

						{confirmacaoEnviada ? (
							<button
								type="button"
								className="btn btn-primary btn-lg">
								Confirmando...
							</button>
						) : (
							<div styleName="buttons">
								<button
									type="button"
									className="btn btn-default btn-lg"
									style={{ marginRight: '25px' }}
									onClick={() => updateModals('confirmacao_venda', false)}>
									Voltar
								</button>
								{!usuario_diferente ? (
									<button
										type="button"
										className="btn btn-primary btn-lg"
										onClick={() => confirmOC(compra_id)}>
										Confirmar
									</button>
								) : (
									<button
										type="button"
										className="btn btn-primary btn-lg"
										style={{ opacity: '0.3' }}>
										Confirmar
									</button>
								)}
							</div>
						)}
					</React.Fragment>
				)}

				{!isAuthenticated() && (
					<React.Fragment>
						<h3>Atenção</h3>
						<br />
						<p>Por questões de segurança, é necessário logar na sua conta para confirmar a venda.</p>
						<br />
						<a href="/entrar" target="_blank" rel="noopener noreferrer">
							<button
								type="button"
								className="btn btn-primary btn-lg">
								Ir para Login
							</button>
						</a>
					</React.Fragment>
				)}
			</div>
		</div>
	);
};

ModalConfirmacaoVenda.propTypes = {
	updateModals: PropTypes.func,
	confirmOC: PropTypes.func.isRequired,
	confirmacaoEnviada: PropTypes.bool.isRequired,
	compra_id: PropTypes.number.isRequired,
	error_confirmando_oc: PropTypes.bool.isRequired,
	error_msg: PropTypes.object,
	usuario_diferente: PropTypes.bool.isRequired,
	usuario: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};

ModalConfirmacaoVenda.defaultProps = {
	updateModals: () => {},
	error_msg: <p>Desculpe, tivemos um problema! Espere alguns segundos e tente novamente.</p>,
};

export default CSSModules(ModalConfirmacaoVenda, styles, { allowMultiple: true });
