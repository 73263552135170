import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CSSModules from 'react-css-modules';
import * as actions from '../../actions/index';

// CSS
import styles from './style.module.sass';

// Components
import Wrapper from '../Wrapper/Wrapper';
import AvaliacaoRow from '../_parts/AvaliacaoRow';

// Functions
import { getAvaliacoes } from '../../services/apis/avaliacoes';

const Avaliacoes = (props) => {
	const [avaliacoes, setAvaliacoes] = useState([]);
	const [focused_compra, setFocusedCompra] = useState(0);

	function buildResponse(_responses) {
		const responses = _responses || [];
		const data = ((responses[0] || {}).data || {}).data || [];
		setAvaliacoes(data);
	}

	useEffect(() => {
		const compra_id = props.match.params.compra_id || 0;
		const compra_to_focus = Number(compra_id);
		if (compra_to_focus !== 0) {
			setFocusedCompra(compra_to_focus);
			setTimeout(() => {
				const compra_elem = document.getElementById(`avaliacao-compra-row-${compra_to_focus}`);
				if (compra_elem) {
					compra_elem.scrollIntoView();
					window.scrollBy(0, -100);
				}
			}, 40);
		}
	}, [avaliacoes]);

	return (
		<Wrapper
			{...props}
			buildResponse={buildResponse}
			promises={[() => getAvaliacoes()]}
		>
			<div className="container" styleName="wrapper">
				{avaliacoes.length > 0 && (
					<div className="row">
						<div className="col-xs-12">
							<h3 styleName="title">Avaliações</h3>
							<p styleName="subtitle"><span>*</span> Suas notas gerais e os comentários recebidos nas avaliações ficarão disponíveis para os construtores que utilizam a Conaz.</p>
						</div>
					</div>
				)}
				{avaliacoes.map(avaliacao => {
					const compra = (avaliacao.compra || [])[0] || {};

					return (
						<AvaliacaoRow
							key={compra.id}
							avaliacao={avaliacao}
							focused_compra={focused_compra}
						/>
					);
				})}

				{avaliacoes.length === 0 && (
					<div className="row">
						<div className="col-xs-12" styleName="empty-wrapper">
							<i className="fa fa-star-o" aria-hidden="true" />
							<p styleName="p-title">Esta é uma nova funcionalidade do Sistema Conaz!</p>
							<p>Agora você pode acompanhar a satisfação dos seus clientes com relação a sua empresa e seu atendimento.</p>
							<p>Assim que sua empresa receber uma nova avaliação, o sistema lhe enviará um e-mail de notificação e os detalhes da avaliação ficarão disponíveis nesta página do portal.</p>
							<p>Para saber mais detalhes sobre as avaliações, <a href="https://conaz.zendesk.com/hc/pt-br/articles/360039397434-O-que-s%C3%A3o-minhas-Avalia%C3%A7%C3%B5es-" rel="noopener noreferrer" target="_blank">clique aqui</a>.</p>
							<p><hr /></p>
							<p styleName="p-obs">Apenas as vendas efetuadas a partir de 05/12/2019 podem ser avaliadas pelas construtoras.</p>
						</div>
					</div>
				)}
			</div>
		</Wrapper>
	);
};

Avaliacoes.propTypes = {
	match: PropTypes.object.isRequired,
};

const mapStateToProps = ({ cadastro, ui }) => ({
	cadastro,
	ui,
});

const mapDispatchToProps = dispatch => ({
	resetCadastro: () => dispatch(actions.resetCadastro()),
	updateCadastro: (field, value) => dispatch(actions.updateCadastro(field, value)),
	updateUi: (field, value) => dispatch(actions.updateUi(field, value)),
	updateModals: (modal, value) => dispatch(actions.updateModals(modal, value)),
	reportErrors: (page, error, codigo_pedido, full_error, test) => dispatch(actions.reportErrors(page, error, codigo_pedido, full_error, test)),
});

const with_css = CSSModules(Avaliacoes, styles, { allowMultiple: true });
export default connect(mapStateToProps, mapDispatchToProps)(with_css);
