export const getLocalstorageData = (object, field = null) => {
	const item = localStorage.getItem(object);
	if (item !== undefined && item !== null) {
		let value;
		try {
			value = JSON.parse(item);
			if (field) {
				value = value[field];
			}
		} catch (err) {
			value = item;
		}
		return value !== undefined ? value : '';
	}
	return '';
};

export const setLocalstorageData = (name, params) => {
	localStorage.setItem(name, typeof params === 'object' ? JSON.stringify(params) : params);
};
