import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

const UnsubscribeCheckbox = ({
	option_id,
	label,
	handleVal,
	selected,
}) => {
	const btn_class = selected
		? 'checkbox-button checked'
		: 'checkbox-button';
	const icon = selected
		? <div styleName="checkbox-icon"><i className="fa fa-check" aria-hidden="true" /></div>
		: <div styleName="checkbox-icon" />;

	return (
		<li>
			<button
				type="button"
				styleName={btn_class}
				onClick={() => handleVal(option_id)}>
				{icon}
				{label}
			</button>
		</li>
	);
};

UnsubscribeCheckbox.propTypes = {
	option_id: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	label: PropTypes.string.isRequired,
	handleVal: PropTypes.func.isRequired,
	selected: PropTypes.bool.isRequired,
};

UnsubscribeCheckbox.defaultProps = {
	option_id: null,
};

export default CSSModules(UnsubscribeCheckbox, styles, { allowMultiple: true });
