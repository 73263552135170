import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import InputNumber from '../_inputs/InputNumber/InputNumber';
import TagSugestao from '../TagSugestao/TagSugestao';
import ItemRowUnidade from './ItemRowUnidade';
import ItemRowObs from './ItemRowObs';

// Functions
import { formatCurrency, formatDecimals } from '../../_functions/_formatNumber';
import { clearItensAlerts } from '../../_functions/_clearItensAlerts';

function ItemRowValues({
	item,
	updateItem,
	autoSaveTimeout,
	itens_observacoes,
	updateCotacao,
}) {
	const {
		id,
		item_de_pedido,
		unidade,
		quantidade,
		preco_unitario,
		status,
	} = item;

	function tagPreenchimentoFunction(crctPreenchida, type) {
		const field = type === 'unidade'
			? 'unidade'
			: 'quantidade';
		updateItem(id, field, crctPreenchida);
		autoSaveTimeout(id);
		clearItensAlerts();
	}

	const _quantidade = quantidade !== null ? quantidade : 0;
	const _unidade = unidade !== null ? unidade : '';
	const _preco_unitario = preco_unitario !== null ? preco_unitario : 0;
	// sub_total
	const sub_total = _preco_unitario * _quantidade;
	// desconto
	const desconto = item.desconto || 0;
	const valor_desconto = (sub_total / 100) * desconto;
	// total
	const total = (sub_total - valor_desconto) || 0;

	const qtd_value = formatDecimals(item_de_pedido.quantidade);
	const un_value = item_de_pedido.unidade;

	return (
		<div className="col-xs-12 col-lg-6" styleName="right-column">
			<div id={`tooltip_quantidade_${id}`} className="tooltip top" role="tooltip" style={{ marginTop: '-34px', zIndex: '0' }}>
				<div className="tooltip-arrow" style={{ borderTopColor: '#ee2622', left: '10%' }} />
				<div className="tooltip-inner" style={{ backgroundColor: '#ee2622' }}>Este campo é obrigatório</div>
			</div>
			<div styleName="qtd-und-wrapper">
				<div styleName="quantidade-wrapper">
					<label>
						<span styleName="label-quantidade">Quantidade</span>&nbsp;
						<span className="obrigatorio-label">*</span>
					</label>
					<InputNumber
						type="item"
						field_id={`quantidade_${id}`}
						item_id={id}
						field="quantidade"
						default_value={_quantidade}
						updateFunction={updateItem}
						autoSaveTimeout={autoSaveTimeout}
					/>
					{_quantidade === 0 && (
						<TagSugestao
							value={qtd_value}
							onClick={() => tagPreenchimentoFunction(parseFloat(item_de_pedido.quantidade), 'quantidade')}
						/>
					)}
				</div>
				<div styleName="unidade-wrapper">
					<div id={`tooltip_unidade_${id}`} className="tooltip top" role="tooltip" style={{ marginTop: '-34px', zIndex: '0' }}>
						<div className="tooltip-arrow" style={{ borderTopColor: '#ee2622', left: '10%' }} />
						<div className="tooltip-inner" style={{ backgroundColor: '#ee2622' }}>Este campo é obrigatório</div>
					</div>
					<ItemRowUnidade
						item={item}
						updateItem={updateItem}
						autoSaveTimeout={autoSaveTimeout}
					/>
					{_unidade === '' && (
						<TagSugestao
							value={un_value}
							onClick={() => tagPreenchimentoFunction(un_value, 'unidade')}
						/>
					)}
				</div>
			</div>
			<div styleName="valor-unit-wrapper">
				<div styleName="valor-unit-inside">
					<div id={`tooltip_valor_unitario_${id}`} className="tooltip top" role="tooltip" style={{ marginTop: '-34px', zIndex: '0' }}>
						<div className="tooltip-arrow" style={{ borderTopColor: '#ee2622', left: '10%' }} />
						<div className="tooltip-inner" style={{ backgroundColor: '#ee2622' }}>Este campo é obrigatório</div>
					</div>
					<label>Valor unitário <span className="obrigatorio-label">*</span></label>
					{status === 1 ? (
						<div id={`caracteristica_wrapper_${id}`} className="caracteristica-wrapper">
							<InputNumber
								type="item"
								field_id={`preco_unitario_${id}`}
								item_id={id}
								field="preco_unitario"
								default_value={_preco_unitario}
								autoSaveTimeout={autoSaveTimeout}
								updateFunction={updateItem}
								currency
							/>
						</div>
					) : (
						<input
							id={`preco_unitario_${id}_incluso`}
							type="text"
							className="form-control input-md"
							defaultValue="Preço incluso"
							disabled
						/>
					)}
				</div>
			</div>
			<div styleName="desconto-wrapper">
				<label>Desconto</label>
				{status === 1 ? (
					<div className="caracteristica-wrapper">
						<InputNumber
							type="item"
							field_id={`desconto_${id}`}
							item_id={id}
							field="desconto"
							default_value={desconto}
							autoSaveTimeout={autoSaveTimeout}
							updateFunction={updateItem}
							currency
							currency_icon="%"
						/>
					</div>
				) : (
					<input
						id={`desconto_${id}_incluso`}
						type="text"
						className="form-control input-md"
						defaultValue=""
						disabled
					/>
				)}
			</div>
			<div styleName="total-wrapper">
				<label>Valor total</label>
				<div styleName="valor-total">{formatCurrency(total, 'R$')}</div>
			</div>
			<ItemRowObs
				item={item}
				updateItem={updateItem}
				autoSaveTimeout={autoSaveTimeout}
				itens_observacoes={itens_observacoes}
				updateCotacao={updateCotacao}
			/>
		</div>
	);
}

ItemRowValues.propTypes = {
	item: PropTypes.object.isRequired,
	updateItem: PropTypes.func.isRequired,
	autoSaveTimeout: PropTypes.func.isRequired,
	itens_observacoes: PropTypes.array.isRequired,
	updateCotacao: PropTypes.func.isRequired,
};

export default CSSModules(ItemRowValues, styles, { allowMultiple: true });
