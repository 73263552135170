export const printStatus = (status, pedido_cancelado) => {
	if (pedido_cancelado || status === -30) {
		return 'Cancelada';
	}

	switch (status) {
	case 40:
		return 'Aguardando resposta';
	case 50:
		return 'Venda efetuada';
	case 60:
		return 'Sem sucesso';
	case -20:
		return 'Sem interesse';
	default:
		return '';
	}
};
