
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../../actions/index';

// Components
import CadastroNormal from './CadastroNormal';
import Alert2 from '../../_parts/Alerts/Alert2';

// Functions
import { getLogradouro } from '../../../services/apis/territorios';
import { isAuthenticated } from '../../../services/login_manager';
import {
	addUsuarioToFornecedor,
	create as createFornecedor,
} from '../../../services/apis/fornecedores';
import { getLocalstorageData, setLocalstorageData } from '../../_functions/_getLocalstorageData';

import { lockBody } from '../../_functions/_lockBody';
import { urlSearchParamsPolyfill } from '../../_functions/_urlSearchParamsPolyfill';

class CadastroNormalContainer extends Component {
	static titleHasRestrictChars(value) {
		return value.match(/[&\\#,+@$~^%'":/|*?<>{}]/g);
	}

	static razaoSocialNoApelido(apelido) {
		const nome_only_letters = apelido.toUpperCase().replace(/[^a-zA-z\s]/g, '');
		const nome_splitted = nome_only_letters.split(' ').filter(word => word !== '');
		const last_word = nome_splitted.length > 0 ? nome_splitted[nome_splitted.length - 1] : '';
		return ['ME', 'SA', 'LTDA'].indexOf(last_word) !== -1;
	}

	static setValidationErrors() {
		return { nome: false, documento: false, documento_cadastrado: false, documento_invalido: false, cep: false, logradouro: false, numero: false, bairro: false, cidade: false, estado: false };
	}

	constructor(props) {
		super(props);
		this.state = {
			searching_cep: true,
			loading_cep: false,
			request_state: 0,
			modal_error: false,
			page_locked: false,
			cep_state: 0,
			cnpj_cadastrado_error: false,
			cnpj_error_message: '',
			validation_errors: {
				nome: false,
				documento: false,
				documento_cadastrado: false,
				documento_invalido: false,
				cep: false,
				logradouro: false,
				numero: false,
				bairro: false,
				cidade: false,
				estado: false,
			},
			titulo_state: 0,
		};
		this.getCEP = this.getCEP.bind(this);
		this.submitFornecedor = this.submitFornecedor.bind(this);
		this.handleEdition = this.handleEdition.bind(this);
		this.handleModal = this.handleModal.bind(this);
	}

	componentDidMount() {
		const { documento, status } = this.props.cadastro;
		if (!documento) {
			this.props.history.push({
				pathname: '/cadastro',
				search: this.props.location.search,
			});
		}

		if (isAuthenticated() && status >= 0) {
			this.props.history.goForward();
		}
		window.scrollTo(0, 0);
	}

	getCEP(cep_raw) {
		let cep = cep_raw.replace(/[^\d]/g, '');
		if (cep.length === 8) {
			cep = `${cep.substring(0, 5)}-${cep.substring(5, 8)}`;
			this.setState({ searching_cep: true, loading_cep: true });
			getLogradouro(cep)
				.then(response => {
					if (response.data.erro) {
						const endereco_new = Object.assign({}, this.props.cadastro.endereco);
						endereco_new.cep = cep;
						endereco_new.logradouro = '';
						endereco_new.bairro = '';
						endereco_new.cidade = '';
						endereco_new.estado = '';
						this.props.updateCadastro(['endereco'], endereco_new);
						this.setState({ cep_state: 1, loading_cep: false });
					} else {
						const endereco_new = Object.assign({}, this.props.cadastro.endereco);
						endereco_new.cep = cep;
						endereco_new.logradouro = response.data.logradouro;
						endereco_new.bairro = response.data.bairro;
						endereco_new.cidade = response.data.localidade;
						endereco_new.estado = response.data.uf;
						this.props.updateCadastro(['endereco'], endereco_new);
						this.setState({ cep_state: 2, searching_cep: false, loading_cep: false });
					}
				})
				.catch(error => {
					const endereco_new = Object.assign({}, this.props.cadastro.endereco);
					endereco_new.cep = cep;
					endereco_new.logradouro = '';
					endereco_new.bairro = '';
					endereco_new.cidade = '';
					endereco_new.estado = '';
					this.props.updateCadastro(['endereco'], endereco_new);
					console.log(error); // eslint-disable-line
					this.setState({ searching_cep: false, loading_cep: false });
				});
		}
	}

	handleModal(modal, value) {
		this.setState({ [modal]: value, page_locked: value });
		lockBody(value);
	}

	handleEdition(fields, value) {
		const field_string = fields.join();

		if (field_string === 'nome') {
			if (this.constructor.titleHasRestrictChars(value)) {
				this.setState({ titulo_state: 1 });
			} else if (this.constructor.razaoSocialNoApelido(value)) {
				this.setState({ titulo_state: 2 });
			} else {
				this.setState({ titulo_state: 0 });
			}
		}

		this.props.updateCadastro(fields, value);
		const validation_new = this.constructor.setValidationErrors();
		this.setState({ request_state: 0, validation_errors: validation_new });
	}

	submitFornecedor(e) {
		e.preventDefault();
		document.getElementById('send_btn').focus();
		this.setState({ request_state: 1 });

		const { titulo_state } = this.state;

		const { nome, documento, endereco, site, razao_social } = this.props.cadastro;

		if (titulo_state === 1) {
			window.scrollTo(0, 100);
		} else {
			setTimeout(() => {
				if (nome === '') {
					// this.setState({ request_state: 1 });
					const validation_new = this.constructor.setValidationErrors();
					validation_new.nome = true;
					this.setState({ validation_errors: validation_new, request_state: 0 });
					window.scrollTo(0, 100);
					return;
				}

				if (documento === '' || documento.substring(0, 18).replace(/[^\d]+/g, '').length < 14) {
					const validation_new = this.constructor.setValidationErrors();
					validation_new.documento = true;
					this.setState({ validation_errors: validation_new, request_state: 0 });
					window.scrollTo(0, 100);
					return;
				}
				const cep_check = endereco.cep.substring(0, 9).replace(/[^\d]+/g, '');

				if (cep_check === '' || cep_check.length < 8) {
					const validation_new = this.constructor.setValidationErrors();
					validation_new.cep = true;
					this.setState({ validation_errors: validation_new, request_state: 0 });
					window.scrollTo(0, 300);
					return;
				}

				if (endereco.logradouro === '') {
					const validation_new = this.constructor.setValidationErrors();
					validation_new.logradouro = true;
					this.setState({ validation_errors: validation_new, request_state: 0 });
					window.scrollTo(0, 300);
					return;
				}

				if (endereco.numero === '') {
					const validation_new = this.constructor.setValidationErrors();
					validation_new.numero = true;
					this.setState({ validation_errors: validation_new, request_state: 0 });
					window.scrollTo(0, 300);
					return;
				}

				if (endereco.bairro === '') {
					const validation_new = this.constructor.setValidationErrors();
					validation_new.bairro = true;
					this.setState({ validation_errors: validation_new, request_state: 0 });
					window.scrollTo(0, 500);
					return;
				}

				if (endereco.cidade === '') {
					const validation_new = this.constructor.setValidationErrors();
					validation_new.cidade = true;
					this.setState({ validation_errors: validation_new, request_state: 0 });
					window.scrollTo(0, 500);
					return;
				}

				if (endereco.estado === '') {
					const validation_new = this.constructor.setValidationErrors();
					validation_new.estado = true;
					this.setState({ validation_errors: validation_new, request_state: 0 });
					window.scrollTo(0, 500);
					return;
				}

				const fornecedor = {
					nome,
					documento,
					site,
					razao_social,
					status: 0,
					cadastrado_por: null,
					endereco: {
						cep: endereco.cep.replace('-', ''),
						logradouro: endereco.logradouro,
						complemento: endereco.complemento,
						numero: endereco.numero,
						bairro: endereco.bairro,
						cidade: endereco.cidade,
						estado: endereco.estado,
					},
				};
				createFornecedor(fornecedor)
					.then(response => {
						const { id, status } = response.data;
						setLocalstorageData('fornecedor_id', id);
						const { search } = this.props.location;
						if (isAuthenticated()) {
							const user = getLocalstorageData('user');

							addUsuarioToFornecedor(id, user.id, { tipo_associacao: 1 })
								.then(resp => {
									const all_params = urlSearchParamsPolyfill(search);
									const _from = all_params.from || '';
									const _redirect_to = all_params.redirect_to || '';

									user.fornecedores.push(resp.data);
									setLocalstorageData('user', user);
									if (_from !== 'identificacao_vendedor') {
										this.props.history.push('/boas-vindas');
									} else {
										this.props.history.push(_redirect_to);
									}
								})
								.catch(err => {
									console.log(err); // eslint-disable-line
								});
						} else {
							this.props.updateCadastro(['id'], id);
							this.props.updateCadastro(['status'], status);
							this.props.history.push({
								pathname: '/cadastro/3',
								search,
							});
						}
					})
					.catch(error => {
						if (error.response && error.response.status !== 500) {
							const { message } = error.response.data;
							if (message === `Fornecedor com nome ${nome} já cadastrado`) {
								const validation_new = this.constructor.setValidationErrors();
								validation_new.nome_repetido = true;
								this.setState({ validation_errors: validation_new, request_state: 0 });
								window.scrollTo(0, 100);
								return;
							}
							if (message === `Fornecedor com CNPJ ${documento} já possui cadastro`) {
								this.setState({ cnpj_cadastrado_error: true, cnpj_error_message: message });
							}
						}
						console.log(error); // eslint-disable-line
					});
			}, 60);
		}
	}

	render() {
		const { cnpj_cadastrado_error, cnpj_error_message } = this.state;
		const { history } = this.props;

		return (
			<>
				<CadastroNormal
					{...this.state}
					{...this.props}
					getCEP={this.getCEP}
					submitFornecedor={this.submitFornecedor}
					handleEdition={this.handleEdition}
					handleLogin={this.handleLogin}
					handleModal={this.handleModal} />
				{ cnpj_cadastrado_error && (
					<Alert2
						color="red"
						externalFunction={() => history.push('/cadastro')}
						mensagem={cnpj_error_message} />
				)}
			</>
		);
	}
}

const mapStateToProps = props => ({
	cadastro: props.cadastro,
});

const mapDispatchToProps = dispatch => ({
	updateCadastro: (field, value) => dispatch(actions.updateCadastro(field, value)),
	resetCadastro: () => dispatch(actions.resetCadastro()),
	updateModals: (modal, value) => dispatch(actions.updateModals(modal, value)),
	reportErrors: (page, error, codigo_pedido, full_error, test) => dispatch(actions.reportErrors(page, error, codigo_pedido, full_error, test)),
});


CadastroNormalContainer.propTypes = {
	cadastro: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	updateCadastro: PropTypes.func.isRequired,
	resetCadastro: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CadastroNormalContainer);
