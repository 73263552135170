import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from '../style.module.scss';

class UnidadeItemOption extends Component {
	constructor() {
		super();
		this.handleOption = this.handleOption.bind(this);
	}

	handleOption(e) {
		e.preventDefault();
		const { option, handleEdition } = this.props;
		handleEdition(option);
	}

	render() {
		const { option, option_view } = this.props;
		return (
			<li data-dropchild>
				<Link to="#" onClick={this.handleOption} styleName={option === option_view && 'selected'}>{option}</Link>
			</li>
		);
	}
}

UnidadeItemOption.propTypes = {
	option: PropTypes.string.isRequired,
	handleEdition: PropTypes.func.isRequired,
	option_view: PropTypes.string,
};

UnidadeItemOption.defaultProps = {
	option_view: '',
};

export default CSSModules(UnidadeItemOption, styles, { allowMultiple: true });
