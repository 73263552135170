
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as actions from '../../../actions/index';
// Components
import Cadastro from './Cadastro';

import { verifyCnpjFornecedor as verifyCnpj } from '../../../services/apis/fornecedores';

// Functions
import { lockBody } from '../../_functions/_lockBody';

class CadastroContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			request_state: 0,
			modal_error: false,
			page_locked: false,
			validation_errors: {
				nome_empresa: false,
				cnpj: false,
				cnpj_cadastrado: false,
				cnpj_invalido: false,
			},
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleEdition = this.handleEdition.bind(this);
		this.handleModal = this.handleModal.bind(this);
		this.setValidationErrors = { cnpj: false, cnpj_cadastrado: false, cnpj_invalido: false };
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		this.props.resetCadastro();
	}

	handleModal(modal, value) {
		this.setState({ [modal]: value, page_locked: value });
		lockBody(value);
	}

	handleSubmit(e) {
		e.preventDefault();

		document.getElementById('send_btn').focus();
		this.setState({ request_state: 1 });

		let cnpj = this.props.cadastro.documento;
		cnpj = cnpj || '';

		if (cnpj === '' || cnpj.substring(0, 18).replace(/[^\d]+/g, '').length < 14) {
			const validation_new = this.setValidationErrors;
			validation_new.cnpj_invalido = true;
			this.setState({ validation_errors: validation_new, request_state: 0 });
			window.scrollTo(0, 100);
			return;
		}

		setTimeout(() => {
			const limpaCNPJ = cnpj.substring(0, 18).replace(/[^\d]+/g, '');
			verifyCnpj({ cnpj: limpaCNPJ })
				.then(response => {
					this.setState({ request_state: 2 });
					let pathname;
					if (!_.isEmpty(response.data)) {
						const fornecedor = response.data;

						this.props.updateCadastro(['id'], fornecedor.id);
						this.props.updateCadastro(['razao_social'], fornecedor.razao_social);
						this.props.updateCadastro(['nome'], fornecedor.nome.replace(/\s(ltda|sa|me)$/gi, ''));
						this.props.updateCadastro(['documento'], fornecedor.documento);
						this.props.updateCadastro(['site'], fornecedor.site);
						this.props.updateCadastro(['endereco'], fornecedor.endereco);
						this.props.updateCadastro(['usuarios'], fornecedor.usuarios);
						this.props.updateCadastro(['status'], fornecedor.status);
						this.props.updateCadastro(['site'], fornecedor.site);

						const grupos_outros_vendedores = fornecedor.usuarios.map(u => u.grupos).reduce((total, currentValue) => (
							total.concat(currentValue)), []).map(g => g.grupo.id);
						this.props.updateCadastro(['grupos_ids_outros_vendedores'], grupos_outros_vendedores);

						pathname = fornecedor.status >= 0 ? '/cadastro/3' : '/cadastro/2';
					} else {
						this.props.updateCadastro(['documento'], limpaCNPJ);
						pathname = '/cadastro/2';
					}
					this.props.history.push({
						pathname,
						search: this.props.location.search,
					});
				})
				.catch(error => {
					console.log(error); // eslint-disable-line
					this.setState({ modal_error: true });
					window.scrollTo(0, 1000);
				});
		}, 60);
	}

	handleEdition(fields, value) {
		const cnpj_limpo = value.length > 18 ? value.substring(0, 18) : value;
		this.props.updateCadastro(fields, cnpj_limpo);

		const validation_new = this.setValidationErrors;
		this.setState({ request_state: 0, validation_errors: validation_new });
	}

	render() {
		return (
			<Cadastro
				{...this.state}
				{...this.props}
				cnpj="0"
				handleSubmit={this.handleSubmit}
				handleEdition={this.handleEdition}
				handleModal={this.handleModal} />
		);
	}
}

const mapStateToProps = props => ({
	cadastro: props.cadastro,
});

const mapDispatchToProps = dispatch => ({
	updateCadastro: (field, value) => dispatch(actions.updateCadastro(field, value)),
	resetCadastro: () => dispatch(actions.resetCadastro()),
	updateModals: (modal, value) => dispatch(actions.updateModals(modal, value)),
	reportErrors: (page, error, codigo_pedido, full_error, test) => dispatch(actions.reportErrors(page, error, codigo_pedido, full_error, test)),
});


CadastroContainer.propTypes = {
	cadastro: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	updateCadastro: PropTypes.func.isRequired,
	resetCadastro: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CadastroContainer);
