
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../../actions/index';

// Components
import EditarEmpresa from './EditarEmpresa';
import Wrapper from '../../Wrapper/Wrapper';

// Functions
import { getLogradouro } from '../../../services/apis/territorios';
import { update as updateFornecedor } from '../../../services/apis/fornecedores';
import { setLocalstorageData } from '../../_functions/_getLocalstorageData';

import { lockBody } from '../../_functions/_lockBody';

class EditarEmpresaContainer extends Component {
	static titleHasRestrictChars(value) {
		return value.match(/[&\\#,+@$~^%'":/|*?<>{}]/g);
	}

	static razaoSocialNoApelido(apelido) {
		const nome_only_letters = apelido.toUpperCase().replace(/[^a-zA-z\s]/g, '');
		const nome_splitted = nome_only_letters.split(' ').filter(word => word !== '');
		const last_word = nome_splitted.length > 0 ? nome_splitted[nome_splitted.length - 1] : '';
		return ['ME', 'SA', 'LTDA'].indexOf(last_word) !== -1;
	}

	static setValidationErrors() {
		return { nome: false, documento: false, documento_cadastrado: false, documento_invalido: false, cep: false, logradouro: false, numero: false, bairro: false, cidade: false, estado: false };
	}

	constructor(props) {
		super(props);
		this.state = {
			searching_cep: false,
			loading_cep: false,
			enviando: false,
			success: false,
			modal_error: false,
			page_locked: false,
			cep_state: 0,
			validation_errors: {
				nome: false,
				documento: false,
				documento_cadastrado: false,
				documento_invalido: false,
				cep: false,
				logradouro: false,
				numero: false,
				bairro: false,
				cidade: false,
				estado: false,
			},
			errorMsg: '',
			errorTitle: '',
			titulo_state: 0,
		};
		this.getCEP = this.getCEP.bind(this);
		this.submitFornecedor = this.submitFornecedor.bind(this);
		this.handleEdition = this.handleEdition.bind(this);
		this.handleModal = this.handleModal.bind(this);
	}

	componentDidMount() {
		document.title = 'Editar Empresa - Conaz';
		if (!this.props.cadastro.id && this.props.cadastro.loaded) {
			this.props.history.replace('/empresas');
		}
	}

	UNSAFE_componentWillReceiveProps(newProps) {
		if (!newProps.cadastro.id && newProps.cadastro.loaded) {
			this.props.history.replace('/empresas');
		}
	}

	onClickToEdit(type) {
		switch (type) {
		case 'empresa': default: {
			this.props.history.push('/editar-empresa');
			break;
		}
		case 'entrega': {
			this.props.history.push('/editar-entrega');
			break;
		}
		case 'familias': {
			this.props.history.push('/editar-familias');
			break;
		}
		}
	}

	getCEP(cep_raw) {
		let cep = cep_raw.replace(/[^\d]/g, '');
		if (cep.length === 8) {
			cep = `${cep.substring(0, 5)}-${cep.substring(5, 8)}`;
			this.setState({ searching_cep: true, loading_cep: true });
			getLogradouro(cep)
				.then(response => {
					if (response.data.erro) {
						throw response;
					}
					const endereco_new = Object.assign({}, this.props.cadastro.endereco);
					endereco_new.cep = cep;
					endereco_new.logradouro = response.data.logradouro;
					endereco_new.bairro = response.data.bairro;
					endereco_new.cidade = response.data.localidade;
					endereco_new.estado = response.data.uf;
					this.props.updateCadastro(['endereco'], endereco_new);
					this.setState({ cep_state: 2, searching_cep: false, loading_cep: false });
				})
				.catch(error => {
					const endereco_new = Object.assign({}, this.props.cadastro.endereco);
					endereco_new.cep = cep;
					endereco_new.logradouro = '';
					endereco_new.bairro = '';
					endereco_new.cidade = '';
					endereco_new.estado = '';
					this.props.updateCadastro(['endereco'], endereco_new);
					this.setState({ searching_cep: false, loading_cep: false, cep_state: error.data.erro ? 1 : 0 });
				});
		}
	}

	handleModal(modal, value) {
		this.setState({ [modal]: value, page_locked: value });
		lockBody(value);
		if (this.state.success) {
			this.props.history.push('/empresas');
		}
	}

	handleEdition(fields, value) {
		const field_string = fields.join();

		if (field_string === 'nome') {
			if (this.constructor.titleHasRestrictChars(value)) {
				this.setState({ titulo_state: 1 });
			} else if (this.constructor.razaoSocialNoApelido(value)) {
				this.setState({ titulo_state: 2 });
			} else {
				this.setState({ titulo_state: 0 });
			}
		}

		this.props.updateCadastro(fields, value);
		const validation_new = this.constructor.setValidationErrors();
		this.setState({ enviando: false, validation_errors: validation_new });
	}

	submitFornecedor(e) {
		e.preventDefault();
		document.getElementById('send_btn').focus();
		this.setState({ enviando: true, success: false, errorTitle: '', errorMsg: '' });

		const { titulo_state } = this.state;

		const { nome, documento, endereco, site, razao_social } = this.props.cadastro;

		if (titulo_state === 1) {
			window.scrollTo(0, 100);
		} else {
			setTimeout(() => {
				if (nome === '') {
					const validation_new = this.constructor.setValidationErrors();
					validation_new.nome = true;
					this.setState({ validation_errors: validation_new, enviando: false });
					window.scrollTo(0, 100);
					return;
				}

				if (documento === '' || documento.substring(0, 18).replace(/[^\d]+/g, '').length < 14) {
					const validation_new = this.constructor.setValidationErrors();
					validation_new.documento = true;
					this.setState({ validation_errors: validation_new, enviando: false });
					window.scrollTo(0, 100);
					return;
				}
				const cep_check = endereco.cep.substring(0, 9).replace(/[^\d]+/g, '');

				if (cep_check === '' || cep_check.length < 8) {
					const validation_new = this.constructor.setValidationErrors();
					validation_new.cep = true;
					this.setState({ validation_errors: validation_new, enviando: false });
					window.scrollTo(0, 300);
					return;
				}

				if (endereco.logradouro === '') {
					const validation_new = this.constructor.setValidationErrors();
					validation_new.logradouro = true;
					this.setState({ validation_errors: validation_new, enviando: false });
					window.scrollTo(0, 300);
					return;
				}

				if (endereco.numero === '') {
					const validation_new = this.constructor.setValidationErrors();
					validation_new.numero = true;
					this.setState({ validation_errors: validation_new, enviando: false });
					window.scrollTo(0, 300);
					return;
				}

				if (endereco.bairro === '') {
					const validation_new = this.constructor.setValidationErrors();
					validation_new.bairro = true;
					this.setState({ validation_errors: validation_new, enviando: false });
					window.scrollTo(0, 500);
					return;
				}

				if (endereco.cidade === '') {
					const validation_new = this.constructor.setValidationErrors();
					validation_new.cidade = true;
					this.setState({ validation_errors: validation_new, enviando: false });
					window.scrollTo(0, 500);
					return;
				}

				if (endereco.estado === '') {
					const validation_new = this.constructor.setValidationErrors();
					validation_new.estado = true;
					this.setState({ validation_errors: validation_new, enviando: false });
					window.scrollTo(0, 500);
					return;
				}

				const fornecedor = {
					nome,
					documento: documento.replace(/[^\d]+/g, ''),
					site,
					razao_social,
					endereco: {
						cep: endereco.cep.replace('-', ''),
						logradouro: endereco.logradouro,
						complemento: endereco.complemento,
						numero: endereco.numero,
						bairro: endereco.bairro,
						cidade: endereco.cidade,
						estado: endereco.estado,
					},
				};
				const { id } = this.props.cadastro;
				updateFornecedor(id, fornecedor)
					.then(() => {
						setLocalstorageData('fornecedor_id', id);
						this.setState({
							success: true,
							modal_error: true,
							enviando: false,
							errorTitle: 'Sucesso',
							errorMsg: 'Empresa atualizada com sucesso!',
						});
					})
					.catch(error => {
						if (error.response && error.response.status !== 500) {
							const { message } = error.response.data;
							if (message === `Fornecedor com nome ${nome} já cadastrado`) {
								const validation_new = this.constructor.setValidationErrors();
								validation_new.nome_repetido = true;
								this.setState({ validation_errors: validation_new, enviando: false });
								window.scrollTo(0, 100);
							} else {
								this.setState({
									modal_error: true,
									enviando: false,
									errorMsg: error.response.data.message,
									errorTitle: 'Erro ao cadastrar usuário',
								});
							}
						} else {
							this.setState({
								modal_error: true,
								enviando: false,
							});
						}
					});
			}, 60);
		}
	}

	render() {
		return (
			<Wrapper
				{...this.props}>
				<EditarEmpresa
					{...this.state}
					{...this.props}
					getCEP={this.getCEP}
					submitFornecedor={this.submitFornecedor}
					handleEdition={this.handleEdition}
					handleLogin={this.handleLogin}
					handleModal={this.handleModal} />
			</Wrapper>
		);
	}
}

const mapStateToProps = props => ({
	cadastro: props.cadastro,
	ui: props.ui,
});

const mapDispatchToProps = dispatch => ({
	resetCadastro: () => dispatch(actions.resetCadastro()),
	updateCadastro: (field, value) => dispatch(actions.updateCadastro(field, value)),
	updateUi: (field, value) => dispatch(actions.updateUi(field, value)),
	updateModals: (modal, value) => dispatch(actions.updateModals(modal, value)),
	reportErrors: (page, error, codigo_pedido, full_error, test) => dispatch(actions.reportErrors(page, error, codigo_pedido, full_error, test)),
});


EditarEmpresaContainer.propTypes = {
	cadastro: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	updateCadastro: PropTypes.func.isRequired,
	resetCadastro: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditarEmpresaContainer);
