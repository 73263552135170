import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import NavigationBar from '../_parts/NavigationBar/NavigationBar';
import LoadingPage from '../_parts/_loadings/LoadingPage/LoadingPage';
import ModalWrapper from '../_parts/_modals/ModalWrapper/ModalWrapper';
import ModalCorona from '../_parts/_modals/ModalCorona/ModalCorona';
import ModalError from '../_parts/_modals/ModalError/ModalError';
import ModalTermosDeUso from '../_parts/_modals/ModalTermosDeUso/ModalTermosDeUso';

// Functions
import { getLocalstorageData, setLocalstorageData } from '../_functions/_getLocalstorageData';
import { handleRequestErrors } from '../_functions/_handleRequestErrors';
import { isAuthenticated, logout } from '../../services/login_manager';
import { me } from '../../services/apis/auth';

class Wrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			request_state: 0,
		};
		this.updateState = this.updateState.bind(this);
	}

	UNSAFE_componentWillMount() {
		const aux = localStorage.getItem('modal_corona_alert');
		if (!aux) this.props.updateModals('modal_corona_alert', 'nao_visualizado');
		if (this.state.request_state === 0) this.loadFornecedorInfo();
	}

	componentDidUpdate(prevProps) {
		if (this.state.request_state === 0 || prevProps.loaded !== this.props.loaded) {
			this.loadFornecedorInfo();
		}
	}

	componentWillUnmount() {
		if (this.cancel !== undefined) this.cancel('canceled_request');
	}

	updateState(field, value) {
		this.setState({ [field]: value });
	}

	loadFornecedorInfo() {
		this.setState({ request_state: 1 });
		const id = this.props.cadastro.id || null;
		const fornecedor_id = getLocalstorageData('fornecedor_id') || null;
		if (
			isAuthenticated() &&
			(!this.props.cadastro.id_vendedor ||
				(!fornecedor_id && fornecedor_id !== id) ||
				fornecedor_id !== id)
		) {
			this.props.updateCadastro(['loaded'], false);
			me()
				.then(res => {
					this.configUserData(fornecedor_id, res.data);
					this.checkTermosUso(res.data);
					if (this.checkUserAccount()) {
						this.handleFetch();
					}
				})
				.catch(err => {
					const { path } = this.props.match;
					if (err.response && err.response.status === 403 && path !== '/venda/:id/ver') {
						logout();
						this.props.history.push('/login');
					}
					console.log(err); // eslint-disable-line
				});
		} else {
			this.props.updateCadastro(['loaded'], true);
			if (this.checkUserAccount()) {
				this.handleFetch();
			}
		}
	}

	configUserData(fornecedor_id, usuario) {
		this.props.updateCadastro(['id_vendedor'], usuario.id);
		this.props.updateCadastro(['nome_vendedor'], usuario.nome);
		this.props.updateCadastro(['email'], usuario.email);
		this.props.updateCadastro(['telefones_vendedor'], usuario.telefones);
		this.props.updateCadastro(['estah_confirmado'], usuario.estah_confirmado);

		setLocalstorageData('user', usuario);

		let fornecedor = {};
		const fornecedores_cadastrados = usuario.fornecedores.filter(fu => fu.fornecedor.status >= 0);
		const find_fornecedor_cadastrado = fornecedores_cadastrados.filter(
			fu => fu.fornecedor.id === fornecedor_id,
		);

		if (find_fornecedor_cadastrado.length > 0) {
			[{ fornecedor }] = find_fornecedor_cadastrado;
		} else if (fornecedores_cadastrados.length > 0) {
			[{ fornecedor }] = fornecedores_cadastrados;
		}

		const empresas = {};
		const empresas_by_id = [];

		for (let i = 0; i < fornecedores_cadastrados.length; i++) {
			empresas[fornecedores_cadastrados[i].fornecedor.id] = fornecedores_cadastrados[i];
			empresas_by_id.push(fornecedores_cadastrados[i].fornecedor.id);
		}

		this.props.updateCadastro(['empresas'], empresas);
		this.props.updateCadastro(['empresas_by_id'], empresas_by_id);

		setLocalstorageData('fornecedor_id', fornecedor.id || null);
		if (fornecedor.id) {
			this.props.updateCadastro(['nome'], fornecedor.nome);
			this.props.updateCadastro(['status'], fornecedor.status);
			this.props.updateCadastro(['documento'], fornecedor.documento);
			this.props.updateCadastro(['razao_social'], fornecedor.razao_social);
			this.props.updateCadastro(['site'], fornecedor.site);
			this.props.updateCadastro(['endereco'], fornecedor.endereco);
			this.props.updateCadastro(['id'], fornecedor.id);
			this.props.updateCadastro(['loaded'], true);
		} else {
			this.props.updateCadastro(['loaded'], true);
		}
	}

	checkUserAccount() {
		if (this.props.checa_fornecedor_vendedor) {
			const { estah_confirmado, empresas, empresas_by_id } = this.props.cadastro;
			const filter_empresas_locais = empresas_by_id.filter(e => empresas[e].status >= 10);
			const filter_empresas_grupos = empresas_by_id.filter(e => empresas[e].status === 20);

			if (filter_empresas_locais.length === 0) {
				this.props.history.push('/c');
				return false;
			}
			if (filter_empresas_grupos.length === 0) {
				this.props.history.push('/boas-vindas-2');
				return false;
			}
			if (!estah_confirmado) {
				this.props.history.push('/confirmar-cadastro');
				return false;
			}
		}
		return true;
	}

	handleFetch() {
		const {
			loaded,
			promises,
			buildResponse,
			initial,
		} = this.props;
		if (!loaded) {
			this.setState({ request_state: 1 });
			const allPromises = !initial
				? promises.map(promise => promise())
				: promises.map((promise, i) => (i === 1 ? promise() : promise(1, 0)));
			Promise.all(allPromises)
				.then(responses => {
					buildResponse(responses);
					this.setState({ request_state: 2 });
				})
				.catch(err => this.handleResponseError(err));
		} else {
			this.setState({ request_state: 2 });
		}
	}

	handleResponseError(error) {
		const { match, reportErrors, history, type } = this.props;
		const error_status = (error.response || {}).status;
		const message = ((error.response || {}).data || {}).message || '';

		if (error.message !== 'canceled_request') {
			if (error_status === 404 && type === 'cotacao') {
				this.setState({ request_state: 4 });
				return;
			}

			if ((error_status === 404 || error_status === 403) && type === 'venda-sem-cotacao') {
				this.setState({ request_state: 5 });
				return;
			}

			if (error_status === 403 && type !== 'venda-sem-cotacao') {
				history.push('/login');
				return;
			}

			/**
			 * Erro de tamanho do uuid errado, não envia para slack
			 */
			if (
				type === 'cotacao'
				&& (message.indexOf('Odd-length string') !== -1
				|| message.indexOf('Non-hexadecimal digit found') !== -1
				|| message.indexOf('RC inexistente') !== -1)
			) {
				this.setState({ request_state: 4 });
				return;
			}

			this.setState({ request_state: 3 });
			handleRequestErrors(error, match.path, reportErrors);
		}
	}

	checkTermosUso(usuario) {
		const aceitou_termos_uso = usuario.aceitou_termos || false;
		if (!aceitou_termos_uso) {
			this.props.updateModals('termos_de_uso', true);
		}
	}

	render() {
		const { request_state } = this.state;
		const { history, ui, children, updateModals, updateUi, type } = this.props;
		const { modals, salvando_cotacao } = ui;

		switch (request_state) {
		/* ========================================================================== *\
			Render
	\* ========================================================================== */
		case 2:
		default:
			return (
				<div styleName="page-wrapper">
					<ModalWrapper visible={modals.termos_de_uso} updateModals={updateModals}>
						<ModalTermosDeUso />
					</ModalWrapper>
					<ModalWrapper visible={modals.error} updateModals={updateModals}>
						<ModalError />
					</ModalWrapper>
					<ModalWrapper visible={modals.modal_corona_alert !== 'ja_visualizado'} updateModals={updateModals}>
						<ModalCorona updateModals={updateModals} />
					</ModalWrapper>
					<NavigationBar
						updateUi={updateUi}
						history={history}
						salvando_cotacao={salvando_cotacao}
						resetCadastro={this.props.resetCadastro}
					/>
					{React.cloneElement(children, this.state)}
				</div>
			);

			/* ========================================================================== *\
				Loading
		\* ========================================================================== */
		case 0:
		case 1:
			return (
				<div styleName="page-wrapper">
					<ModalWrapper visible={modals.error} updateModals={updateModals}>
						<ModalError />
					</ModalWrapper>
					<NavigationBar
						updateUi={updateUi}
						history={history}
						resetCadastro={this.props.resetCadastro}
					/>
					<LoadingPage />
				</div>
			);

			/* ========================================================================== *\
				Error
		\* ========================================================================== */
		case 3:
			return (
				<div styleName="page-wrapper">
					<ModalWrapper visible={modals.error} updateModals={updateModals}>
						<ModalError />
					</ModalWrapper>
					<NavigationBar
						updateUi={updateUi}
						history={history}
						resetCadastro={this.props.resetCadastro}
					/>
					<div className="container" style={{ marginTop: '36px' }}>
						<div className="row">
							<div className="col-sm-12" styleName="error-wrapper">
								<i className="fa fa-exclamation-circle" aria-hidden="true" />
								<h3>Desculpe, tivemos um problema!</h3>
								<p>
									Atualize a página como descrito abaixo ou entre em contato com nossa equipe de
									suporte pelo chat.
								</p>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-12" style={{ textAlign: 'center' }}>
								<div styleName="keyboards-wrapper">
									<div styleName="keyboards">
										<div styleName="type">Windows:</div>
										<div styleName="keys">
											<div styleName="key c89" style={{ width: '70px' }}>
												<span>Ctrl</span>
											</div>
											<div styleName="key-plus">+</div>
											<div styleName="key c89">
												<span>F5</span>
											</div>
										</div>
									</div>
									<div styleName="keyboards">
										<div styleName="type">Mac OS:</div>
										<div styleName="keys">
											<div styleName="key c89" style={{ width: '70px' }}>
												<span>&#8984;</span>
											</div>
											<div styleName="key-plus">+</div>
											<div styleName="key c89">
												<span>R</span>
											</div>
										</div>
									</div>
									<div styleName="keyboards">
										<div styleName="type">Linux:</div>
										<div styleName="keys">
											<div styleName="key c89" style={{ width: '70px' }}>
												<span>Ctrl</span>
											</div>
											<div styleName="key-plus">+</div>
											<div styleName="key c89">
												<span>F5</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);

			/* ========================================================================== *\
				Cotação não existe
		\* ========================================================================== */
		case 4:
			return (
				<div styleName="page-wrapper">
					<NavigationBar
						updateUi={updateUi}
						history={history}
						resetCadastro={this.props.resetCadastro}
					/>
					<div className="container" style={{ marginTop: '36px' }}>
						<div className="row">
							<div className="col-sm-12" styleName="error-wrapper">
								<i className="fa fa-folder-o" aria-hidden="true" />
								<h3>Desculpe, esta cotação não existe!</h3>
								<p>
									Acesse nossa plataforma e encontre outras cotações{' '}
									<Link to="/propostas/enviadas">clicando aqui</Link>.
								</p>
							</div>
						</div>
					</div>
				</div>
			);

			/* ========================================================================== *\
				Venda não existe
		\* ========================================================================== */
		case 5:
			return (
				<div styleName="page-wrapper">
					<NavigationBar
						updateUi={updateUi}
						history={history}
						resetCadastro={this.props.resetCadastro}
					/>
					<div className="container" style={{ marginTop: '36px' }}>
						<div className="row">
							{type !== 'venda-sem-cotacao' && (
								<div className="col-sm-12" styleName="error-wrapper">
									<i className="fa fa-folder-o" aria-hidden="true" />
									<h3>Desculpe, esta venda não existe!</h3>
									<p>
										Acesse nossa plataforma e encontre outras cotações{' '}
										<Link to="/propostas/enviadas">clicando aqui</Link>.
									</p>
								</div>
							)}
							{type === 'venda-sem-cotacao' && (
								<div className="col-sm-12" styleName="error-wrapper">
									<i className="fa fa-warning" aria-hidden="true" />
									<h3>Acesso negado!</h3>
									<p>
										Realize seu <Link to="/login">login</Link> na plataforma para ter acesso aos
										dados desta venda.
									</p>
									<p>
										Caso você ainda não possua um login, faça seu cadastro{' '}
										<Link to="/cadastro">aqui</Link>.
									</p>
								</div>
							)}
						</div>
					</div>
				</div>
			);
		}
	}
}

Wrapper.propTypes = {
	// navbar: PropTypes.bool,
	type: PropTypes.string,
	buildResponse: PropTypes.func,
	promises: PropTypes.array,
	// rcs_length_promises: PropTypes.array,
	loaded: PropTypes.bool,
	initial: PropTypes.bool,
	resetCadastro: PropTypes.func,
	checa_fornecedor_vendedor: PropTypes.bool,
	// =========== store
	ui: PropTypes.object.isRequired,
	cadastro: PropTypes.object.isRequired,
	// =========== redux funcs
	updateCadastro: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	updateUi: PropTypes.func.isRequired,
	reportErrors: PropTypes.func.isRequired,
	// =========== router
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	children: PropTypes.object.isRequired,
};

Wrapper.defaultProps = {
	// navbar: true,
	type: '',
	buildResponse: () => {},
	promises: [],
	loaded: false,
	initial: false,
	resetCadastro: () => {},
	checa_fornecedor_vendedor: false,
};

export default CSSModules(Wrapper, styles, { allowMultiple: true });
