import React from 'react';
import PropTypes from 'prop-types';

const ButtonToolTip = ({
	text,
	display,
	arrow_left,
	margin_top,
	margin_left,
	width,
	styleArrow,
	styleTooltip,
	...otherProps
}) => (
	<div
		className={`tooltip ${display} in`}
		role="tooltip"
		style={{
			marginTop: margin_top,
			marginLeft: margin_left,
			width,
			opacity: '1',
			...otherProps,
		}}>
		<div className="tooltip-arrow" style={{ left: arrow_left, ...styleArrow }} />
		<div className="tooltip-inner" style={{ ...styleTooltip }}>{text}</div>
	</div>
);

ButtonToolTip.propTypes = {
	text: PropTypes.string.isRequired,
	styleArrow: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
	styleTooltip: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
	display: PropTypes.string,
	arrow_left: PropTypes.string,
	margin_top: PropTypes.string,
	margin_left: PropTypes.string,
	width: PropTypes.string,
};

ButtonToolTip.defaultProps = {
	styleTooltip: {},
	styleArrow: {},
	display: 'top',
	arrow_left: '50%',
	margin_top: '0px',
	margin_left: '0px',
	width: 'auto',
};

export default ButtonToolTip;
