import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { formatCurrency } from '../../_functions/_formatNumber';

const EnviadasRow = ({
	pedido_id,
	rc_id,
	titulo,
	tabindex,
	valor_total_compra: preco_total,
	cliente,
	data_de_entrega,
	data_de_entrega_desejada,
	// refetente a compra
	compra_id,
	data_envio,
	valor_total_compra,
	data_de_entrega_compra,
	data_de_resposta,
	nota_fornecedor,
	// avaliacaoDropdown,
	handleAvaliacao,
	// respostas,
}) => {
	// data_resposta
	const has_compra = compra_id !== null;
	let data_resposta_view = '';
	// if (data_de_resposta) {
	// 	const data_resposta_split_1 = data_de_resposta.split('T');
	// 	const data_resposta_split = data_resposta_split_1[0].split('-');
	// 	const data_resposta_ano = (data_resposta_split[0] || '').substring(2, 4);
	// 	data_resposta_view = data_resposta_split[2] !== undefined
	// 		? `${data_resposta_split[2]}/${data_resposta_split[1]}/${data_resposta_ano}`
	// 		: '-';
	// }
	// if (has_compra) {
	const data_envio_split_1 = data_envio !== null ? data_envio.split('T') : '';
	const data_envio_split = data_envio_split_1 !== '' ? data_envio_split_1[0].split('-') : '';
	data_resposta_view = data_envio_split[2] !== undefined || data_envio_split !== ''
		? `${data_envio_split[2]}/${data_envio_split[1]}/${data_envio_split[0].substring(2, 4)}`
		: '-';
	// }
	if (!data_de_resposta && !has_compra) {
		data_resposta_view = '-';
	}
	let link_url;

	if (rc_id !== null && compra_id !== null) link_url = `/proposta/${rc_id}/ver/${compra_id}`;
	else if (rc_id !== null) link_url = `/proposta/${rc_id}/ver`;
	else link_url = `/venda/${compra_id}/ver`;

	const codigo_cotacao = pedido_id !== null
		? `#${pedido_id}`
		: <div styleName="label-sem-cotacao">Sem cotação</div>;

	const _valor_total_compra = valor_total_compra || '';
	const _data_de_entrega_compra = data_de_entrega_compra || '';
	const _valor_total = has_compra && _valor_total_compra !== '' ? _valor_total_compra : preco_total;
	const data_entrega_rc_split = (
		tabindex === 'aguardando_confirmacao'
		|| tabindex === 'vendas'
		|| tabindex === 'canceladas'
		|| tabindex === 'recusadas')
		? (data_de_entrega || '').split('-')
		: (data_de_entrega_desejada || '').split('-');
	const data_entrega_rc = data_entrega_rc_split[2]
		? `${data_entrega_rc_split[2]}/${data_entrega_rc_split[1]}/${(data_entrega_rc_split[0] || '').substring(2, 4)}`
		: <i>Sem registro</i>;
	const data_entrega__compra_split = (data_de_entrega_compra || '').split('-');
	const data_entrega_compra = `${data_entrega__compra_split[2]}/${data_entrega__compra_split[1]}/${(data_entrega__compra_split[0] || '').substring(2, 4)}`;
	// data_de_entrega_desejada
	const _data_entrega = has_compra && _data_de_entrega_compra !== ''
		? data_entrega_compra
		: data_entrega_rc;

	// const resposta_texto = [
	// 	'entrega no prazo',
	// 	'material foi entregue conforme solicitado',
	// 	'material de boa qualidade',
	// 	'NFs e boletos corretos',
	// ];

	return (
		<div className="row" styleName="pedido-row">
			<div className="col-xs-12 col-sm-2 col-md-1" styleName="pedido-row-padding hide-for-mobile" style={{ textAlign: 'center', paddingRight: '70px' }}>
				{data_resposta_view}
			</div>
			<div className="col-xs-12 col-sm-3 col-md-4" styleName="pedido-row-padding-title" style={{ background: '#f6f6f6', minHeight: '72px', wordWrap: 'break-word' }}>
				<Link to={link_url}><strong style={{ color: '#000' }}>{titulo !== '' ? titulo : 'Sem título'}</strong></Link>
			</div>
			<div className="col-xs-12 col-sm-2 col-md-1" styleName="pedido-row-padding hide-for-mobile" style={{ textAlign: 'center', paddingLeft: '0', paddingRight: '0' }}>
				{codigo_cotacao}
			</div>
			<div className="col-xs-12 col-sm-2 col-md-2" styleName="pedido-row-padding hide-for-mobile" style={{ textAlign: 'center', background: '#f6f6f6', minHeight: '72px', alignItems: 'stretch' }}>
				{_data_entrega}<br /><span styleName="estimada">*estimada</span>
			</div>
			<div className="col-xs-12 col-sm-2 col-md-4" styleName="pedido-row-padding mobile-background last">
				<Link to={link_url}><button type="button" className="btn btn-primary btn-sm" styleName="visualizar-button">Visualizar</button></Link>
				<div styleName="preco-total cinza">{formatCurrency(_valor_total, 'R$')}</div>
				<p style={{ color: '#000' }}><i className="fa fa-building fa-fw" aria-hidden="true" /> {cliente}</p>
				{(tabindex === 'vendas' && nota_fornecedor !== null) && (
					<>
						<span>Avaliação </span>
						<button
							styleName="avaliacao-dropdown"
							onClick={() => handleAvaliacao(compra_id)}
						>
							{nota_fornecedor}.0/5.0
							{' '}<i className="fa fa-caret-down" />
						</button>
						{/* {avaliacaoDropdown && (
							<div>
								<h3>Avaliação da venda</h3>
								{respostas.map(({ tipo, resposta }) => (
									<>
										<i className={`fa fa-${resposta ? 'check' : 'times'}`} />
										<span> {resposta_texto[tipo - 1]}</span>
									</>
								))}
							</div>
						)} */}
					</>
				)}
			</div>
			<div
				className="col-xs-12 col-sm-2 col-md-2"
				styleName="pedido-row-padding hide-for-desktop"
				style={{ background: '#f6f6f6' }}>
				<div styleName="mobile-blocks">
					Entrega <br />{_data_entrega}
				</div>
				<div styleName="mobile-blocks">
					Código <br />#{pedido_id}
				</div>
				<div styleName="mobile-blocks">
					Enviado em <br />{data_resposta_view}
				</div>
			</div>
		</div>
	);
};

EnviadasRow.propTypes = {
	pedido_id: PropTypes.number,
	rc_id: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	titulo: PropTypes.string.isRequired,
	tabindex: PropTypes.string.isRequired,
	// avaliacaoDropdown: PropTypes.bool.isRequired,
	handleAvaliacao: PropTypes.func.isRequired,
	data_de_entrega_desejada: PropTypes.string,
	data_de_entrega: PropTypes.string,
	data_de_resposta: PropTypes.string,
	data_de_entrega_compra: PropTypes.string,
	data_envio: PropTypes.string,
	cliente: PropTypes.string,
	compra_id: PropTypes.number,
	valor_total_compra: PropTypes.number,
	// nota_vendedor: PropTypes.number,
	nota_fornecedor: PropTypes.number,
	// respostas: PropTypes.array,
};

EnviadasRow.defaultProps = {
	pedido_id: null,
	compra_id: null,
	rc_id: null,
	// nota_vendedor: null,
	nota_fornecedor: null,
	valor_total_compra: 0,
	data_de_resposta: '',
	cliente: '',
	data_de_entrega_compra: '',
	data_de_entrega_desejada: '',
	data_de_entrega: '',
	data_envio: '',
	// respostas: [],
};

export default CSSModules(EnviadasRow, styles, { allowMultiple: true });
