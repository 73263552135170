export const validaTelefone = telefone => {
	if (/^(0300|0500|0800|0900)( |\s)?(\d{3})( |\s)?(\d{4})$/.test(telefone)) {
		return true; // telefones nao geograficos
	}
	if (/^((\(\d{2}\))|\d{2})( |\s)?(\d{5})(-|\s)?(\d{4})$/.test(telefone)) {
		return true; // telefone 11 digitos
	}
	if (/^((\(\d{2}\))|\d{2})( |\s)?(\d{4})(-|\s)?(\d{4})$/.test(telefone)) {
		return true; // telefone 10 digitos
	}
	if (/^400(\d)(-|\s)?(\d{4})$/.test(telefone)) {
		return true; // telefones 400
	}
	return false;
};
