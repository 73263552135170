import { createRequest } from './http';

export const login = (email, senha) => createRequest({ method: 'post', url: '/conaz/v2/auth/fornecedor/login', data: { email, senha } });

export const esqueciMinhaSenha = email => createRequest({ method: 'post', url: `/conaz/v2/auth/fornecedor/esqueci_minha_senha/${email}` });

export const enviarConfirmacao = () => createRequest({ method: 'post', url: '/conaz/v2/auth/fornecedor/enviar_confirmacao' });

export const recuperarSenha = (token, senha) => createRequest({
	method: 'post',
	url: '/conaz/v2/auth/fornecedor/recuperar_senha',
	data: { token, senha },
});

export const me = () => createRequest({ method: 'get', url: '/conaz/v2/auth/me' });

export const alterarSenha = (nova_senha, senha_antiga) => createRequest({
	method: 'post',
	url: '/conaz/v2/auth/fornecedor/alterar_senha',
	data: { nova_senha, senha_antiga },
});

export const editarConta = data => createRequest({ method: 'post', url: '/conaz/v2/auth/fornecedor/editar_conta', data });

export const alterarEmail = data => createRequest({ method: 'post', url: '/conaz/v2/auth/fornecedor/alterar_email', data });
