import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const SecaoOportunidades = ({ children, section_open, rcs }) => (
	<React.Fragment>
		{section_open && (
			<div className="row">
				<div className="col-xs-12 col-sm-6">
					{rcs
						.filter((solicitacao, s) => s % 2 === 0)
						.map(solicitacao => (
							React.Children
								.map(children, child => (
									React.cloneElement(child, { ...solicitacao, key: solicitacao.rc_id })
								))
						))}
				</div>
				<div className="col-xs-12 col-sm-6">
					{rcs.filter((solicitacao, s) => s % 2 === 1).map(solicitacao => React
						.Children.map(children, child => React
							.cloneElement(child, {
								...solicitacao,
								key: solicitacao.rc_id,
								status: solicitacao.status,
							})))}
				</div>
			</div>
		)}
	</React.Fragment>
);

SecaoOportunidades.propTypes = {
	children: PropTypes.element.isRequired,
	section_open: PropTypes.bool.isRequired,
	rcs: PropTypes.array.isRequired,
};

export default CSSModules(SecaoOportunidades, styles, { allowMultiple: true });
