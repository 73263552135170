import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';


class InputFieldText extends Component {
	static bloqueiaEnter(e) {
		const key = e.charCode || e.keyCode || 0;
		if (key === 13) {
			e.preventDefault();
		}
	}

	static formatarCNPJ(value) {
		let _value = value;
		if (_value.length > 18) {
			_value.substring(0, 18);
		}

		_value = _value.replace(/\D/g, ''); // Remove tudo o que não é dígito
		_value = _value.replace(/^(\d{2})(\d)/, '$1.$2'); // Coloca ponto entre o segundo e o terceiro dígitos
		_value = _value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3'); // Coloca ponto entre o quinto e o sexto dígitos
		_value = _value.replace(/\.(\d{3})(\d)/, '.$1/$2'); // Coloca uma barra entre o oitavo e o nono dígitos
		return _value.replace(/(\d{4})(\d)/, '$1-$2'); // Coloca um hífen depois do bloco de quatro dígitos
	}

	constructor() {
		super();
		this.state = {
			value: '',
		};
		this.checkCEP = this.checkCEP.bind(this);
		this.handleEdition = this.handleEdition.bind(this);
	}

	UNSAFE_componentWillMount() {
		if (this.props.label === 'CNPJ') {
			this.setState({ value: this.constructor.formatarCNPJ(this.props.default_value) });
		} else {
			this.setState({ value: this.props.default_value });
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.default_value !== this.props.default_value) {
			if (this.props.label === 'CNPJ') {
				this.setState({ value: this.constructor.formatarCNPJ(nextProps.default_value) });
			} else {
				this.setState({ value: nextProps.default_value });
			}
		}
		return false;
	}

	checkCEP(cep) {
		const { label } = this.props;
		switch (label) {
		case 'CEP':
			if (cep.length >= 8 && cep.length <= 9) {
				this.props.getCEP(cep);
			}
			break;
		default:
		}
	}

	handleEdition(e) {
		const typed = e.target.value;
		const { label } = this.props;
		switch (label) {
		case 'CEP': {
			if (typed.length > 9) {
				typed.substring(0, 9);
			} else {
				const regex = new RegExp('[^0-9]', 'g');
				const cep_check = typed.replace(regex, '');

				this.setState({ value: cep_check });
				this.checkCEP(cep_check);
				this.props.auxiliarFunction(this.props.fields, cep_check);
			}
			break;
		}
		case 'CNPJ': {
			const _typed = this.constructor.formatarCNPJ(typed);
			this.setState({ value: _typed });
			this.props.auxiliarFunction(this.props.fields, _typed);
			break;
		}
		default: {
			this.setState({ value: typed });
			this.props.auxiliarFunction(this.props.fields, typed);
		}
		}
	}

	render() {
		const { value } = this.state;
		const { label, additional_class, obrigatorio, fields, maxLength, sublabel } = this.props;
		const input_class = additional_class !== '' ? additional_class : '';
		const label_class = additional_class !== '' ? `label-${additional_class}` : '';
		const form_group_class = label === 'CNPJ' ? 'form-group cnpj' : 'form-group';
		const is_obrigatorio = obrigatorio ? <span styleName="obrigatorio-label">obrigatório</span> : '';
		const field_id = fields.join('_');
		let field_type;
		if (label === 'Senha' || label === 'Confirmar senha' || label === 'Senha atual') {
			field_type = 'password';
		} else if (label === 'E-mail para recebimento de cotações') {
			field_type = 'email';
		} else {
			field_type = 'text';
		}
		if (label === 'CNPJ') {
			return (
				<div className="form-group" styleName={form_group_class}>
					<label styleName={label_class}>{label} {is_obrigatorio}</label>
					<input
						type={field_type}
						className="form-control input-md"
						styleName={input_class}
						id={field_id}
						value={value.substring(0, 18)}
						onKeyPress={this.constructor.bloqueiaEnter}
						onChange={this.handleEdition} />
				</div>
			);
		}
		if (label === 'CEP' && value.length > 9) {
			return (
				<div className="form-group" styleName={form_group_class}>
					<label styleName={label_class}>{label} {is_obrigatorio}</label>
					<input
						type={field_type}
						className="form-control input-md"
						styleName={input_class}
						id={field_id}
						value={value}
						onKeyPress={this.constructor.bloqueiaEnter}
						onChange={this.handleEdition} />
				</div>
			);
		}

		return (
			<div className="form-group" styleName={form_group_class}>
				<label styleName={label_class}>{label} {is_obrigatorio}</label>
				<input
					type={field_type}
					className="form-control input-md"
					styleName={input_class}
					id={field_id}
					value={value}
					maxLength={maxLength}
					onKeyPress={this.constructor.bloqueiaEnter}
					onChange={this.handleEdition} />
				{sublabel && <p style={{ paddingTop: '5px' }} className="small">{sublabel}</p>}
			</div>
		);
	}
}

InputFieldText.propTypes = {
	label: PropTypes.string.isRequired,
	fields: PropTypes.array,
	additional_class: PropTypes.string,
	default_value: PropTypes.string,
	obrigatorio: PropTypes.bool,
	getCEP: PropTypes.func,
	auxiliarFunction: PropTypes.func,
	maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	sublabel: PropTypes.string,
};

InputFieldText.defaultProps = {
	fields: [],
	additional_class: '',
	default_value: '',
	obrigatorio: false,
	maxLength: null,
	auxiliarFunction: () => {},
	getCEP: () => {},
	sublabel: '',
};

export default CSSModules(InputFieldText, styles, { allowMultiple: true });
