import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../../actions/index';

// Components
import ConfirmaCotacaoNovo from './ConfirmaCotacaoNovo';
import Wrapper from '../../Wrapper/Wrapper';

class ConfirmaCotacaoContainer extends Component {
	componentDidMount() {
		const { match, history } = this.props;
		const link_completar_cadastro = `/boas-vindas${match.params.link_boas_vindas === '2' ? '-2' : ''}`;
		history.replace(link_completar_cadastro);

		// const rc_id = this.props.match.params.id;
		// if (rc_id) {
		// 	const alerts = {
		// 		...this.props.ui.alerts,
		// 		success: { visible: true, message: 'Cotação enviada com sucesso!' },
		// 	};
		// 	this.props.updateUi(['alerts'], alerts);
		// }
		// setTimeout(() => this.showModalUpdateNomeComercial(), 1000);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.cadastro.loaded && this.props.cadastro.loaded !== nextProps.cadastro.loaded) {
			setTimeout(() => this.showModalUpdateNomeComercial(), 1000);
		}
	}

	showModalUpdateNomeComercial() {
		const { id, nome, empresas_by_id } = this.props.cadastro;
		const nome_only_letters = nome.toUpperCase().replace(/[^a-zA-z\s]/g, '');
		const nome_splitted = nome_only_letters.split(' ').filter(word => word !== '');
		const last_word = nome_splitted.length > 0 ? nome_splitted[nome_splitted.length - 1] : '';

		if (['ME', 'SA', 'LTDA'].indexOf(last_word) !== -1 && empresas_by_id.indexOf(id) !== -1) {
			this.props.updateModals(['alerta_nome_comercial'], true);
		}
	}

	render() {
		const { match } = this.props;
		const link_completar_cadastro = `/boas-vindas${match.params.link_boas_vindas === '2' ? '-2' : ''}`;
		return (
			<Wrapper
				{...this.props}>
				<ConfirmaCotacaoNovo
					link_completar_cadastro={link_completar_cadastro}
					{...this.props} />
			</Wrapper>
		);
	}
}

const mapStateToProps = props => ({
	ui: props.ui,
	cadastro: props.cadastro,
});

const mapDispatchToProps = dispatch => ({
	resetCadastro: () => dispatch(actions.resetCadastro()),
	updateCadastro: (field, value) => dispatch(actions.updateCadastro(field, value)),
	updateUi: (field, value) => dispatch(actions.updateUi(field, value)),
	updateModals: (modal, value) => dispatch(actions.updateModals(modal, value)),
	reportErrors: (page, error, codigo_pedido, full_error, test) => dispatch(actions.reportErrors(page, error, codigo_pedido, full_error, test)),
});

ConfirmaCotacaoContainer.propTypes = {
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	ui: PropTypes.object.isRequired,
	updateUi: PropTypes.func.isRequired,
	cadastro: PropTypes.object.isRequired,
	updateModals: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmaCotacaoContainer);
