export const clearItensAlerts = () => {
	const form_groups = document.getElementsByClassName('form-group has-error');
	for (let i = 0; i < form_groups.length; i++) form_groups[i].className = 'form-group';
	const caracteristica_wrapper_unidade = document.getElementsByClassName('caracteristica-wrapper has-unidade has-error');
	for (let i = 0; i < caracteristica_wrapper_unidade.length; i++) caracteristica_wrapper_unidade[i].className = 'caracteristica-wrapper has-unidade';
	const caracteristica_wrapper = document.getElementsByClassName('caracteristica-wrapper has-error');
	for (let i = 0; i < caracteristica_wrapper.length; i++) caracteristica_wrapper[i].className = 'caracteristica-wrapper';
	const unidade_wrapper = document.getElementsByClassName('unidade-wrapper has-error');
	for (let i = 0; i < unidade_wrapper.length; i++) unidade_wrapper[i].className = 'unidade-wrapper';
	const tooltips_top = document.getElementsByClassName('tooltip top in');
	for (let i = 0; i < tooltips_top.length; i++) tooltips_top[i].className = 'tooltip top';
};
