/* ------------------------------------ *\

	#UI

\* ------------------------------------ */
export const UPDATE_UI = 'UPDATE_UI';
export const UPDATE_MODALS = 'UPDATE_MODALS';

/* ------------------------------------ *\

	#USER

\* ------------------------------------ */
export const UPDATE_USER = 'UPDATE_USER';
export const CHANGE_OBRA = 'CHANGE_OBRA';
export const SET_AUTH = 'SET_AUTH';
export const UNSET_AUTH = 'UNSET_AUTH';

/* ------------------------------------ *\

	#COTACAO

\* ------------------------------------ */
export const UPDATE_COTACAO = 'UPDATE_COTACAO';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const UPDATE_ITEM_CARACTERISTICA = 'UPDATE_ITEM_CARACTERISTICA';
export const UPDATE_ITEM_CARACTERISTICA_UNIDADE = 'UPDATE_ITEM_CARACTERISTICA_UNIDADE';

/* ------------------------------------ *\

	#COTACOES

\* ------------------------------------ */
export const UPDATE_COTACOES = 'UPDATE_COTACOES';


/* ------------------------------------ *\

    #CADASTRO

\* ------------------------------------ */
export const UPDATE_CADASTRO = 'UPDATE_CADASTRO';
export const RESET_CADASTRO = 'RESET_CADASTRO';
