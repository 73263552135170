import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { lockBody } from '../../_functions/_lockBody';

class ModalWrapperPortal extends Component {
	state = {
		show: false,
	};

	_isMounted = false;

	componentDidMount() {
		this._isMounted = true;
		const { closeAlert } = this.props;
		setTimeout(() => {
			window.addEventListener('keyup', this.escClose);
			lockBody(true);
			this.setState({ show: true });
		}, 80);
		const { delay } = this.props;
		this.alert_timeout = window.setTimeout(() => {
			this.setState({ show: false });
			closeAlert();
		}, delay);
	}

	componentWillUnmount() {
		window.clearTimeout(this.alert_timeout);
		lockBody(false);
		window.removeEventListener('keyup', this.escClose);
		this._isMounted = false;
	}

	clickClose = (e) => {
		const { closeAlert, externalFunction } = this.props;
		if (externalFunction && e.target.getAttribute('data-clickmodal')) {
			this.setState({ show: false });
			closeAlert();
			externalFunction();
		}
		if (e.target.getAttribute('data-clickmodal')) {
			this.setState({ show: false });
			closeAlert();
		}
	}

	escClose = (e) => {
		const { closeAlert, externalFunction } = this.props;
		if (externalFunction && e.keyCode === 27) {
			this.setState({ show: false });
			if (!this._isMounted) return;
			closeAlert();
			externalFunction();
		}
		if (e.keyCode === 27) {
			if (!this._isMounted) return;
			this.setState({ show: false });
			closeAlert();
		}
	}

	render() {
		const { show } = this.state;
		const { children } = this.props;
		const modal_class = show
			? 'modal fade in'
			: 'modal fade';

		return (
			<div className={modal_class} styleName={modal_class} tabIndex="-1" role="none" data-clickmodal onClick={this.clickClose}>
				<div className="modal-dialog" role="document" styleName="modal-dialog">
					{React.cloneElement(children)}
				</div>
			</div>
		);
	}
}

ModalWrapperPortal.propTypes = {
	delay: PropTypes.number.isRequired,
	externalFunction: PropTypes.func,
	closeAlert: PropTypes.func.isRequired,
	children: PropTypes.object.isRequired,
};

ModalWrapperPortal.defaultProps = {
	externalFunction: () => null,
};

export default CSSModules(ModalWrapperPortal, styles, { allowMultiple: true });
