/* eslint-disable no-console */
import { getLocalstorageData } from './_getLocalstorageData';
import { _post } from '../../services/apis/requests';

function reportErrors(error_url, error_method, full_error, test, message_conaz) {
	const full_url = window.location.href;
	const email = getLocalstorageData('user', 'email');
	const error_text = `*Request:* ${error_url} (${error_method})
*Url:* ${full_url}
*E-mail do fornecedor:* ${email}
*Print erro request:* ${full_error}
*Print erro Conaz:* ${message_conaz}
----`;
	const channel = test ? '#erros-dev' : '#erros';
	_post('/conaz/legacy/send_slack', {
		channel,
		username: 'FORNECEDORES',
		text: error_text,
		icon_emoji: ':waving_black_flag:',
	});
}

export const handleRequestErrors = error => {
	const _data = ((error || {}).response || {}).data;
	const _message_conaz = (_data || {}).message || '';
	const _message = (error || {}).message || '';
	const _status = ((error || {}).response || {}).status;

	if (error.response) {
		console.log(_data);
		console.log(_status);
		console.log('Error', _message);

		if (_status === 403) {
			window.location.replace(`${window.location.origin}/login`);
			return;
		}
	} else if (error.request) {
		console.log(error.request);
	} else {
		console.log('Error', _message);
	}

	const is_test = (window.location.href.indexOf('fornecedor.conazweb.com.br/') === -1);
	const error_url = ((error || {}).config || {}).url || 'no-url';
	const error_method = ((error || {}).config || {}).method || 'no-method';

	if (_message_conaz === 'Token não fornecido') {
		window.location.replace(`${window.location.origin}/login`);
		return;
	}

	reportErrors(error_url, error_method, error.message, is_test, _message_conaz);
};
