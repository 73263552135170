
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import InputFieldText from '../../_parts/_inputs/InputCadastro/InputFieldText';
import InputFieldPhone from '../../_parts/_inputs/InputCadastro/InputFieldPhone';
import Modal from '../../_parts/Modal/Modal';
import MessageModal from '../../_parts/MessageModal/MessageModal';
import { formatNumbers } from '../../_functions/_formatNumber';

const CadastroComFornecedor = ({
	/* state */ request_state, modal_error, validation_errors, errorTitle, errorMsg, associado,
	/* props */ cadastro, location,
	/* funcs */ handleSubmit, handleEdition, handleModal, isAuthenticated, handleClickVoltar, handleClickAssociar, handleEditionTelefone,
}) => {
	const { nome, documento, nome_vendedor, telefones_vendedor, email, senha, senha_repeat, usuarios } = cadastro;
	const nome_editado = nome === '' ? 'EMPRESA' : nome;

	const { search } = location;

	return (
		<div>
			{modal_error && (
				<Modal modal="modal_error" handleModal={handleModal}>
					<MessageModal
						type="alert"
						title={errorTitle || 'Ops... aconteceu algo errado.'}
						message={errorMsg || 'Desculpe, não conseguimos identificar o que aconteceu. Espere alguns segundos e tente novamente.'} />
				</Modal>
			)}
			<div className="container" styleName="main-container">
				<div className="row">
					<div className="col-xs-12" style={{ textAlign: 'center' }}>
						<h2>Cadastro de fornecedor</h2>
					</div>
				</div>
				<div className="row">
					<div className="col-xs-12">
						<h4 styleName="section-title">{nome_editado}</h4>
					</div>
					<div className="col-xs-12 col-sm-6" styleName="label-margin">
						<label htmlFor="">CNPJ </label>
						<p> {documento} </p>
					</div>
				</div>
				{
					isAuthenticated() ?
						(
							<div className="row">
								<div className="col-xs-12">
									<p>Encontramos em nosso banco de dados um fornecedor já cadastrado com o CNPJ fornecido.</p>
									<p>Deseja associar sua conta a esse fornecedor?</p>
									<button
										type="button"
										style={{ marginRight: '10px' }}
										className="btn btn-success btn-lg"
										onClick={handleClickVoltar}>
										Voltar
									</button>
									<button
										type="button"
										className="btn btn-success btn-lg"
										onClick={handleClickAssociar}>
										Associar
									</button>
									{ associado &&
										<div className="alert alert-success">
											Associação realizada com sucesso. Você está sendo redirecionado...
										</div>
									}
								</div>
							</div>
						) :
						(
							<form onSubmit={handleSubmit}>
								{
									usuarios.length > 0 &&
									<div>
										<div className="row">
											<div className="col-xs-12">
												<h5 styleName="section-title"><i className="fa fa-user fa-fw" aria-hidden="true" /> Usuários</h5>
											</div>
										</div>

										<div className="row" style={{ marginTop: '10px' }}>
											<div className="form-group col-md-3 col-xs-4">
												<label htmlFor="">Nome </label>
											</div>
											<div className="form-group col-md-4 col-xs-4 ">
												<label htmlFor="">Email </label>
											</div>
											<div className="form-group col-md-3 col-xs-4">
												<label htmlFor="" styleName="label-telefone">Telefone </label>
											</div>
										</div>
									</div>
								}
								{ usuarios.map(usuario_f => (
									usuario_f.usuario.status >= 0 && (

										<div key={usuario_f.id} className="row">
											<div className="form-group col-md-3 col-xs-4" styleName="" style={{ float: 'left' }}>
												<div styleName="break-word">
													<p> {usuario_f.usuario.nome} </p>
												</div>
											</div>
											<div className="form-group col-md-4 col-xs-4" styleName="">
												<div styleName="break-word">
													<p>  {usuario_f.usuario.email} </p>
												</div>
											</div>
											<div className="form-group col-md-3" styleName="">
												<div styleName="list-telefones">
													<p> {usuario_f.usuario.telefones ? formatNumbers(usuario_f.usuario.telefones[0].numero, 'telefone') : ''} </p>
												</div>
											</div>
											<div className="col-xs-4 col-md-2" styleName="btn-logar">
												<div className="form-group" styleName="list-btn-logar">
													<Link to={`/login${search}`}><button className="btn btn-success btn-xs">Entrar</button></Link>
												</div>
											</div>
										</div>
									)))}

								{/* LOGIN */}
								<div className="row">
									<div className="col-xs-12">
										<h5 styleName="section-title"><i className="fa fa-user fa-fw" aria-hidden="true" /> Novo Usuário</h5>
									</div>
								</div>
								<div className="row">
									<div className="col-xs-12 col-sm-8">
										{validation_errors.nome_vendedor && (
											<div className="tooltip top in" styleName="tooltip" role="tooltip" style={{ marginLeft: '-19px', marginTop: '-25px' }}>
												<div className="tooltip-arrow" />
												<div className="tooltip-inner" styleName="tooltip-inner">Preencha o nome do vendedor</div>
											</div>
										)}
										<InputFieldText
											label="Nome do(a) vendedor(a)"
											fields={['nome_vendedor']}
											obrigatorio
											maxLength={60}
											default_value={nome_vendedor}
											auxiliarFunction={handleEdition} />
									</div>
									<div className="col-xs-12 col-sm-4">
										{validation_errors.telefone_vendedor && (
											<div className="tooltip top in" styleName="tooltip" role="tooltip" style={{ marginLeft: '-19px', marginTop: '-25px' }}>
												<div className="tooltip-arrow" />
												<div className="tooltip-inner" styleName="tooltip-inner">Preencha o telefone do vendedor</div>
											</div>
										)}
										<InputFieldPhone
											label="Telefone"
											obrigatorio
											default_value={telefones_vendedor[0].numero}
											auxiliarFunction={(value) => handleEditionTelefone(value)} />
									</div>
								</div>

								<div className="row">
									<div className="col-xs-12 col-sm-7">
										{validation_errors.email && (
											<div className="tooltip top in" styleName="tooltip" role="tooltip" style={{ marginLeft: '-19px', marginTop: '-22px' }}>
												<div className="tooltip-arrow" />
												<div className="tooltip-inner">Preencha o campo email!</div>
											</div>
										)}
										{validation_errors.email_cadastrado && (
											<div className="tooltip top in" styleName="tooltip" role="tooltip" style={{ marginLeft: '-19px', marginTop: '-22px' }}>
												<div className="tooltip-arrow" />
												<div className="tooltip-inner">Este e-mail já está cadastrado!</div>
											</div>
										)}
										<InputFieldText
											label="E-mail para recebimento de cotações"
											fields={['email']}
											obrigatorio
											maxLength={80}
											default_value={email}
											auxiliarFunction={handleEdition} />
									</div>
								</div>
								<div className="row">
									<div className="col-xs-12 col-sm-7">
										{validation_errors.senha && (
											<div className="tooltip top in" styleName="tooltip" role="tooltip" style={{ marginLeft: '-19px', marginTop: '-22px' }}>
												<div className="tooltip-arrow" />
												<div className="tooltip-inner">Preencha o campo senha!</div>
											</div>
										)}
										<InputFieldText
											label="Senha"
											fields={['senha']}
											obrigatorio
											maxLength={100}
											default_value={senha}
											auxiliarFunction={handleEdition} />
									</div>
								</div>
								<div className="row">
									<div className="col-xs-12 col-sm-7">
										<InputFieldText
											label="Confirmar senha"
											fields={['senha_repeat']}
											obrigatorio
											maxLength={100}
											default_value={senha_repeat}
											auxiliarFunction={handleEdition} />
									</div>
								</div>
								<div className="row">
									<div className="col-xs-12">
										<div className="form-group" style={{ textAlign: 'center', maxWidth: '360px', margin: '30px auto' }}>
											Ao clicar em <b>Cadastrar</b>, você concorda com nossos <b><a href={process.env.URL_TERMOS_DE_USO} target="_blank" rel="noopener noreferrer">Termos de Uso</a></b> e <b><a href={process.env.URL_POLITICA_DE_PRIVACIDADE} target="_blank" rel="noopener noreferrer">Política de Privacidade</a></b>.
										</div>
									</div>
								</div>
								{(request_state === 1) ? (
									<div className="row">
										<div className="col-xs-12" style={{ textAlign: 'center' }}>
											<button className="btn btn-success btn-lg" disabled="disabled">Validando</button>
										</div>
									</div>
								) : (
									<div className="row">
										<div className="col-xs-12" style={{ textAlign: 'center' }}>
											<button type="submit" id="send_btn" className="btn btn-success btn-lg">Cadastrar</button>
										</div>
									</div>
								)}
								{validation_errors.senha_repeat && (
									<div className="alert alert-danger" styleName="alert" role="alert">
										<i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" /> As senhas não correspondem ou estão em branco!
									</div>
								)}
							</form>
						)
				}
			</div>
		</div>
	);
};

CadastroComFornecedor.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	handleEdition: PropTypes.func.isRequired,
	handleModal: PropTypes.func.isRequired,
	cadastro: PropTypes.object.isRequired,
	request_state: PropTypes.number.isRequired,
	modal_error: PropTypes.bool.isRequired,
	validation_errors: PropTypes.object.isRequired,
	errorMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
	errorTitle: PropTypes.string.isRequired,
	isAuthenticated: PropTypes.func.isRequired,
	handleClickVoltar: PropTypes.func.isRequired,
	handleClickAssociar: PropTypes.func.isRequired,
	associado: PropTypes.bool.isRequired,
	location: PropTypes.object.isRequired,
	handleEditionTelefone: PropTypes.func.isRequired,
};


export default CSSModules(CadastroComFornecedor, styles, { allowMultiple: true });
