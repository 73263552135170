
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import InputFieldMultiple from '../../_parts/_inputs/InputCadastro/InputFieldMultiple';
import InputFieldSelect from '../../_parts/_inputs/InputCadastro/InputFieldSelect';
import Loading from '../../_parts/_loadings/Loading/Loading';
import Modal from '../../_parts/Modal/Modal';
import MessageModal from '../../_parts/MessageModal/MessageModal';

// Vars
import { ufs as all_ufs } from './ufs';

class SelecionaLocaisEntrega extends Component {
	state = {
		allSelected: false,
	}

	UNSAFE_componentWillMount() {
		const { cadastro, allowSendState } = this.props;
		const { id, empresas } = cadastro;
		const { status } = (empresas[id] || {});
		if (status !== -10 && status !== 0) allowSendState();
	}

	handleSelectAllStates = () => {
		this.setState({ allSelected: true });
	}

	handleLabel = () => {
		const { cadastro, regioesLimpas } = this.props;
		const { allSelected } = this.state;
		const { id, empresas } = cadastro;
		const { status } = (empresas[id] || {});
		if ((status === 0 && !allSelected) || regioesLimpas) return '';
		return 'Todo o Brasil';
	}

	hanldeLimparRegioes = () => {
		const { regioesLimpas } = this.props;
		if (regioesLimpas) return false;
		return true;
	}

	render() {
		const {
			/* state */ estrutura_montada, ufs, ufs_by_id, message, enviando, modal_error, success, errorTitle,
			/* funcs */ addUF, deleteUF, obterMesorregioes, obterMunicipios, allowSendState, selecionarMunicipio, salvarAreasAtendimento, handleModal, limparLocais,
			/* props */ ui, updateUi, cadastro, history, match, regioesLimpas,
		} = this.props;
		const { nome } = cadastro;
		const ufs_br = Object.keys(all_ufs).map(uf => ({ view: all_ufs[uf], value: uf }));

		if (modal_error) {
			return (
				<Modal modal="modal_error" handleModal={handleModal}>
					<MessageModal
						type={success ? 'success' : 'alert'}
						title={errorTitle || 'Ops... aconteceu algo errado.'}
						message={message || 'Desculpe, não conseguimos identificar o que aconteceu. Espere alguns segundos e tente novamente.'} />
				</Modal>
			);
		}
		if (estrutura_montada) {
			return (
				<div className="container" styleName="main-container">
					<div className="row">
						<div className="col-sm-12" style={{ textAlign: 'center' }}>
							<h3>Você quer receber pedidos de cotações para quais regiões?</h3>
							<p styleName="h5">Atendo pela {nome} as regiões:</p>
						</div>
					</div>
					{
						ufs_by_id.length === 1 && ufs_by_id[0] === 1 ? (

							<div className="row">
								<div className="col-sm-12 col-md-4" />
								<div className="col-sm-12 col-md-4" style={{ marginTop: '-15px' }}>
									<InputFieldSelect
										label=""
										opcoes={ufs_br}
										opcao_selecionada={ufs_by_id[0].toString()}
										all_options
										regioesLimpas={regioesLimpas}
										allowSendState={allowSendState}
										all_options_label={this.handleLabel()}
										handleSelectAllStates={this.handleSelectAllStates}
										all_options_selected={this.hanldeLimparRegioes()}
										dropdown_open={ui.dropdown_open}
										updateUi={updateUi}
										list_key={ufs_by_id[0]}
										auxiliarFunction={obterMesorregioes} />
									<p><br /></p><p><br /></p>
								</div>
								<div className="col-sm-12 col-md-4" />
							</div>

						) : (

							<React.Fragment>
								{ufs_by_id.map(cod_uf => {
									const opcoes_selecionadas = [];
									const mesorregioes_multiple_format = ufs[cod_uf].mesorregioes_by_id.length >= 1
										? ufs[cod_uf].mesorregioes_by_id.map(cod_mr => {
											const mr = ufs[cod_uf].mesorregioes[cod_mr];
											if (mr.selected) { opcoes_selecionadas.push(cod_mr); }
											return { view: mr.nome, value: `${cod_mr}` };
										})
										: [];
									const button_wrapper_class = ufs_by_id.length > 1 ? 'add-field-option' : '';

									return (
										<React.Fragment key={cod_uf}>
											<div className="row">
												<div className="col-sm-12 col-md-3" styleName={button_wrapper_class}>
													<InputFieldSelect
														label="UF"
														opcoes={ufs_br}
														allowSendState={allowSendState}
														opcao_selecionada={cod_uf.toString()}
														all_options
														regioesLimpas={regioesLimpas}
														all_options_label="Todo o Brasil"
														dropdown_open={ui.dropdown_open}
														updateUi={updateUi}
														list_key={cod_uf}
														auxiliarFunction={obterMesorregioes} />
													{ufs_by_id.length > 1 && <button styleName="delete-field-button" onClick={() => deleteUF(cod_uf)}><i className="fa fa-trash" aria-hidden="true" /></button>}
												</div>
												<div className="col-sm-12 col-md-9">
													{ufs[cod_uf].loading && <div styleName="fields-loading"><Loading /> <div>carregando...</div></div>}
													{(ufs[cod_uf].mesorregioes_by_id.length >= 1 && !ufs[cod_uf].loading) && (
														<InputFieldMultiple
															label="&nbsp;"
															opcoes={mesorregioes_multiple_format}
															opcoes_selecionadas={opcoes_selecionadas}
															all_options
															all_options_label="Todas"
															dropdown_open={ui.dropdown_open}
															updateUi={updateUi}
															list_keys={[cod_uf]}
															auxiliarFunction={obterMunicipios} />
													)}
												</div>
												{
													ufs[cod_uf].mesorregioes_by_id.filter(c_meso => ufs[cod_uf].mesorregioes[c_meso].selected).map(cod_meso => {
														const municipios_selecionados = [];
														const { municipios } = ufs[cod_uf].mesorregioes[cod_meso];
														const municipios_multiple_format = ufs[cod_uf].mesorregioes[cod_meso].municipios_by_id.length >= 0
															? ufs[cod_uf].mesorregioes[cod_meso].municipios_by_id.map(m => {
																if (municipios[m].selected) { municipios_selecionados.push(m); }
																return { view: municipios[m].nome, value: `${m}` };
															})
															: [];

														return (
															<React.Fragment key={cod_meso}>
																<div className="col-sm-12 col-md-3" />
																<div className="col-sm-12 col-md-5">
																	{ufs[cod_uf].mesorregioes[cod_meso].loading && <div styleName="fields-loading"><Loading /> <div>carregando...</div></div>}
																	{(ufs[cod_uf].mesorregioes[cod_meso].municipios_by_id.length >= 0 && !ufs[cod_uf].mesorregioes[cod_meso].loading && !ufs[cod_uf].all) && (
																		<InputFieldMultiple
																			label={ufs[cod_uf].mesorregioes[cod_meso].nome}
																			opcoes={municipios_multiple_format}
																			opcoes_selecionadas={municipios_selecionados}
																			all_options
																			all_options_label="Todos os municípios"
																			dropdown_open={ui.dropdown_open}
																			updateUi={updateUi}
																			list_keys={[cod_uf, cod_meso]}
																			auxiliarFunction={selecionarMunicipio} />
																	)}
																</div>
															</React.Fragment>
														);
													})}
											</div>
										</React.Fragment>
									);
								})}
								<div className="row">
									<div className="col-xs-12" style={{ textAlign: 'center' }}>
										<button styleName="add-field-button" onClick={addUF}><i className="fa fa-plus" aria-hidden="true" /> Adicionar UF</button>
									</div>
								</div>
							</React.Fragment>

						)}
					<div className="row">
						<div className="col-xs-12" style={{ textAlign: 'center' }}>
							<p><br /></p>

							{message && <div className="alert alert-success" role="alert">{message}</div>}

							<p><br /></p>
							{
								match.path !== '/boas-vindas' &&
								<button
									type="button"
									className="btn btn-default btn-lg"
									style={{ marginRight: '10px' }}
									onClick={() => history.push('/empresas')}>
									Voltar
								</button>
							}
							<button
								className="btn btn-success btn-lg"
								style={{ padding: '10px 36px 11px' }}
								onClick={salvarAreasAtendimento}
								disabled={enviando}>
								{enviando ? 'Enviando...' : 'Enviar'}
							</button>
						</div>
						<div className="col-xs-12" style={{ textAlign: 'center', marginTop: '44px' }}>
							<button
								type="button"
								className="btn btn-link"
								style={{ color: '#6c7174' }}
								onClick={limparLocais}>
								Limpar todas as regiões
							</button>
						</div>
					</div>
				</div>
			);
		}
		return (
			<div className="container" style={{ marginTop: '36px' }}>
				<div className="row"><div className="col-sm-12"><Loading /></div></div>
			</div>
		);
	}
}

SelecionaLocaisEntrega.propTypes = {
	allowSendState: PropTypes.func.isRequired,
	regioesLimpas: PropTypes.bool.isRequired,
	addUF: PropTypes.func.isRequired,
	deleteUF: PropTypes.func.isRequired,
	modal_error: PropTypes.bool.isRequired,
	handleModal: PropTypes.func.isRequired,
	enviando: PropTypes.bool.isRequired,
	ui: PropTypes.object.isRequired,
	cadastro: PropTypes.object.isRequired,
	updateUi: PropTypes.func.isRequired,
	estrutura_montada: PropTypes.bool.isRequired,
	ufs: PropTypes.object.isRequired,
	ufs_by_id: PropTypes.array.isRequired,
	message: PropTypes.string.isRequired,
	obterMesorregioes: PropTypes.func.isRequired,
	obterMunicipios: PropTypes.func.isRequired,
	salvarAreasAtendimento: PropTypes.func.isRequired,
	selecionarMunicipio: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	success: PropTypes.bool.isRequired,
	errorTitle: PropTypes.string.isRequired,
	limparLocais: PropTypes.func.isRequired,
};

export default CSSModules(SelecionaLocaisEntrega, styles, { allowMultiple: true });
