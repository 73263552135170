import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

class GroupsMultiLevel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checked: props.checked,
		};
		this.toogleCheck = this.toogleCheck.bind(this);
	}

	toogleCheck() {
		this.setState({ checked: !this.state.checked });
		this.props.auxiliarFunction(this.props.keys);
	}

	render() {
		const { checked } = this.state;
		const { class_name, view } = this.props;
		const element_class = checked ? `${class_name} open` : class_name;

		return (
			<button styleName={element_class} onClick={this.toogleCheck}>
				<i className="fa fa-angle-right" styleName="fa-angle-right" aria-hidden="true" />
				<i className="fa fa-angle-down" styleName="fa-angle-down" aria-hidden="true" />
				<i className="fa fa-folder" styleName="fa-folder" aria-hidden="true" />
				<i className="fa fa-folder-open" styleName="fa-folder-open" aria-hidden="true" />
				{view}
			</button>
		);
	}
}

GroupsMultiLevel.propTypes = {
	class_name: PropTypes.string,
	keys: PropTypes.array.isRequired,
	view: PropTypes.string,
	checked: PropTypes.bool,
	auxiliarFunction: PropTypes.func,
};

GroupsMultiLevel.defaultProps = {
	class_name: 'grupo-element',
	view: '',
	checked: false,
	auxiliarFunction: () => {},
};

export default CSSModules(GroupsMultiLevel, styles, { allowMultiple: true });
