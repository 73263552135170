export const formas_pagamento = {
	0: { value: 0, view: '' },
	1: { value: 1, view: 'Boleto' },
	2: { value: 2, view: 'Transferência bancária' },
	3: { value: 3, view: 'Cartão de crédito' },
	4: { value: 4, view: 'Cartão de débito' },
	5: { value: 5, view: 'Cartão da loja' },
	6: { value: 6, view: 'Cartão BNDES' },
	7: { value: 7, view: 'Título em carteira' },
	8: { value: 8, view: 'Dinheiro' },
	'-1': { value: -1, view: 'Outra opção' },
};

export const prazos_pagamento = {
	0: { value: 0, view: '', order: 0 },
	1: { value: 1, view: 'À vista (antecipado)', order: 1 },
	3: { value: 3, view: 'À vista (na entrega)', order: 2 },
	19: { value: 19, view: '7 dias', order: 3 },
	20: { value: 20, view: '15 dias', order: 4 },
	5: { value: 5, view: '21 dias', order: 5 },
	6: { value: 6, view: '28 dias', order: 6 },
	15: { value: 15, view: '30 dias', order: 7 },
	25: { value: 25, view: '35 dias', order: 8 },
	16: { value: 16, view: '30/60 dias', order: 9 },
	17: { value: 17, view: '30/60/90 dias', order: 10 },
	24: { value: 24, view: '30/45/60 dias', order: 11 },
	7: { value: 7, view: '21/28/42 dias', order: 12 },
	8: { value: 8, view: '28/56 dias', order: 13 },
	9: { value: 9, view: '28/56/84 dias', order: 14 },
	11: { value: 11, view: 'Entrada + 30 dias', order: 15 },
	12: { value: 12, view: 'Entrada + 30/60 dias', order: 16 },
	13: { value: 13, view: 'Entrada + 30/60/90 dias', order: 17 },
	21: { value: 21, view: '10 dias após medição', order: 18 },
	22: { value: 22, view: '15 dias após medição', order: 19 },
	23: { value: 23, view: '50% no pedido + 50% na entrega', order: 20 },
	'-1': { value: -1, view: 'Outra opção', order: -1 },
};

export const prazos_pagamento_full = {
	0: { value: 0, view: '' },
	1: { value: 1, view: 'À vista (antecipado)' },
	2: { value: 2, view: 'À vista' },
	3: { value: 3, view: 'À vista (na entrega)' },
	19: { value: 19, view: '7 dias' },
	4: { value: 4, view: '10 dias' },
	20: { value: 20, view: '15 dias' },
	5: { value: 5, view: '21 dias' },
	6: { value: 6, view: '28 dias' },
	15: { value: 15, view: '30 dias' },
	25: { value: 25, view: '35 dias' },
	16: { value: 16, view: '30/60 dias' },
	17: { value: 17, view: '30/60/90 dias' },
	18: { value: 18, view: '30/60/90/120 dias' },
	24: { value: 24, view: '30/45/60 dias' },
	7: { value: 7, view: '21/28/42 dias' },
	8: { value: 8, view: '28/56 dias' },
	9: { value: 9, view: '28/56/84 dias' },
	10: { value: 10, view: '28/56/84/112 dias' },
	11: { value: 11, view: 'Entrada + 30 dias' },
	12: { value: 12, view: 'Entrada + 30/60 dias' },
	13: { value: 13, view: 'Entrada + 30/60/90 dias' },
	14: { value: 14, view: 'Entrada + 30/60/90/120 dias' },
	21: { value: 21, view: '10 dias após medição' },
	22: { value: 22, view: '15 dias após medição' },
	23: { value: 23, view: '50% no pedido + 50% na entrega' },
	'-1': { value: -1, view: 'Outra opção' },
};
