import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';


import CanceledStage from './CanceledStage';
import NormalStage from './NormalStage';


class StatusBar extends React.Component {
	static NormalStage = NormalStage;

	static CanceledStage = CanceledStage;

	render() {
		return (
			<div styleName="status-wrapper">
				<div styleName="icons-wrapper">
					{this.props.children}
				</div>
			</div>
		);
	}
}

StatusBar.propTypes = {
	children: PropTypes.array.isRequired,
};

export default CSSModules(StatusBar, styles, { allowMultiple: true });
