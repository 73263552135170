import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Components
import withValidationSteps from './withValidationSteps';
import LeftModalWrapper from '../_modals/LeftModalWrapper/LeftModalWrapper';

// Functions
import { isAuthenticated } from '../../../services/login_manager';

const StepbarMobile = ({
	cotacao,
	history,
	updateModals,
	modals,
	new_saving,
	validateSteps,
	title,
}) => {
	// eslint-disable-next-line no-unused-vars
	const [alert, setAlert] = useState(false);
	const [waiting, setWaiting] = useState(false);

	const pages = isAuthenticated()
		? ['itens', 'dadosfinais', 'empresa', 'revisao']
		: ['itens', 'dadosfinais', 'identificacao', 'revisao'];
	const page = document.URL.substr(document.URL.lastIndexOf('/') + 1);
	const current_page_key = _.findIndex(pages, p => page.indexOf(p) !== -1);

	const clearAlert = () => {
		setTimeout(() => setAlert(false), 4600);
	};

	const handleAlerts = () => {
		setAlert(true);
		clearAlert();
	};

	const handleNextNavigation = () => {
		clearInterval(window.waiting_interval);
		const uuid = (cotacao.rc || {}).uuid || '';
		const nextPage = pages[current_page_key + 1] || pages[pages.length - 1];

		const check = validateSteps(current_page_key + 1);
		if (!check) {
			handleAlerts();
			return;
		}

		history.push(`/cotacao/${uuid}/${nextPage}`);
	};

	const checkWaiting = () => {
		if (new_saving < 2) {
			setWaiting(true);
			return;
		}
		if (new_saving >= 2) {
			setWaiting(false);
		}
		handleNextNavigation();
	};

	useEffect(() => {
		if (waiting) checkWaiting();
	});

	const handleNavigation = ({ target }) => {
		updateModals('steper_mobile', false);
		const nextIndex = Number(target.getAttribute('data-index')) || 0;
		const check = validateSteps(nextIndex);
		if (!check && nextIndex > current_page_key) {
			handleAlerts();
			return;
		}

		const link = target.getAttribute('data-link') || '';
		const uuid = (cotacao.rc || {}).uuid || '';
		if (link !== '') {
			history.push(`/cotacao/${uuid}/${link}`);
		}
	};

	const handleActivePage = index => (current_page_key === index ? 'active' : '');

	return (
		<>
			<div styleName="stepper-button">
				<button onClick={() => updateModals('steper_mobile', true)}>
					<i className="fa fa-bars" />
					<p>
						<i className="fa fa-angle-left" />
						{title}
					</p>
				</button>
			</div>
			<LeftModalWrapper
				visible={modals.steper_mobile}
				updateModals={updateModals}
				modal="steper_mobile"
			>
				<div styleName="stepper-mobile">
					<button
						type="button"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => updateModals('steper_mobile', false)}
					>
						<i className="fa fa-angle-left" />
						<p>Etapas de preenchimento da cotação</p>
						{/* {alert && (
								<div styleName="error-alert-2 visible">
									<div styleName="message">
										<p>
											<i className="fa fa-exclamation-circle" aria-hidden="true" /> Campos
											obrigatórios em aberto
										</p>
										Preencha os campos destacados em vermelho para proceder com o envio da proposta.
									</div>
								</div>
							)} */}
					</button>
					<div styleName="stepbar mobile">
						<button
							type="button"
							styleName={handleActivePage(0)}
							data-index={0}
							data-link="itens"
							onClick={handleNavigation}
						>
							<span
								styleName={handleActivePage(0)}
								onClick={handleNavigation}
								aria-hidden
								data-link="itens"
								data-index={0}
							>
								1. Preenchimento dos itens
							</span>
						</button>
						<button
							type="button"
							styleName={handleActivePage(1)}
							data-index={1}
							data-link="dadosfinais"
							onClick={handleNavigation}
						>
							<span
								styleName={handleActivePage(1)}
								onClick={handleNavigation}
								data-index={1}
								data-link="dadosfinais"
								aria-hidden
							>
								2. Condições da proposta
							</span>
						</button>
						<button
							type="button"
							styleName={handleActivePage(2)}
							data-index={2}
							data-link={isAuthenticated() ? 'empresa' : 'identificacao'}
							onClick={handleNavigation}
						>
							<span
								styleName={handleActivePage(2)}
								onClick={handleNavigation}
								aria-hidden
								data-link={isAuthenticated() ? 'empresa' : 'identificacao'}
								data-index={2}
							>
								3. Informações da empresa
							</span>
						</button>
						<button
							type="button"
							styleName={handleActivePage(3)}
							data-index={3}
							data-link="revisao"
							onClick={handleNavigation}
						>
							<span
								styleName={handleActivePage(3)}
								onClick={handleNavigation}
								data-index={3}
								data-link="revisao"
								aria-hidden
							>
								4. Revisão da proposta
							</span>
						</button>
					</div>
				</div>
			</LeftModalWrapper>
		</>
	);
};

StepbarMobile.propTypes = {
	history: PropTypes.object.isRequired,
	cotacao: PropTypes.object.isRequired,
	// isPreRefinamento: PropTypes.bool,
	// setAttetion: PropTypes.func,
	title: PropTypes.string.isRequired,
	new_saving: PropTypes.number,
	validateSteps: PropTypes.func.isRequired,
	updateModals: PropTypes.func,
	modals: PropTypes.object.isRequired,
	// updateUi: PropTypes.func,
	// handleModalPedidoEmEdicao: PropTypes.func,
};

StepbarMobile.defaultProps = {
	new_saving: 2,
	// isPreRefinamento: false,
	// setAttetion: () => null,
	updateModals: () => {},
	// updateUi: () => {},
	// handleModalPedidoEmEdicao: () => {},
};

const _Stepbar = CSSModules(StepbarMobile, styles, { allowMultiple: true });
export default withValidationSteps(_Stepbar);
