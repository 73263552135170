import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AllRedirects extends Component {
	UNSAFE_componentWillMount() {
		this.autoRedirect();
	}

	autoRedirect() {
		const { match, history } = this.props;
		switch (match.path) {
		case '/proposta/:id':
			history.push(`/cotacao/${match.params.id}`);
			break;
		default:
			history.push('/solicitacoes');
		}
	}

	render() {
		return <div />;
	}
}

AllRedirects.propTypes = {
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};

export default AllRedirects;
